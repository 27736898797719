import React from "react";
import PropTypes from "prop-types";
import InternalTag from "./InternalTag";
import {Form, Grid} from "semantic-ui-react";

class ReceiverSummary extends React.Component {
  render() {
    const receiver = this.props.value;

    if (receiver.internalTag?.sensor) {
      receiver.internalTag = {
        ...receiver.internalTag.sensor,
        ...receiver.internalTag,
        sensorType: receiver.internalTag.sensor.sensorTypeName,
        idCode: receiver.internalTag.sensor.idCode.toString(),
        serialNumber: receiver.serialNumber
      };
    }

    return (
      <Grid columns={2}>
        <Grid.Column key={0}>
          <Form.Input
            fluid
            transparent
            readOnly
            label="Receiver ID"
            value={`${receiver.deviceModelName}-${receiver.serialNumber}`}
            data-testid="test-receiver-id-ro"
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Current Receiver Status"
            value={receiver.status}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Purchasing Organisation"
            value={receiver.organisation.name}
            data-testid="test-receiver-organisationName-ro"
          />
          {receiver.modemId ? (
            <Form.Input
              fluid
              transparent
              readOnly
              label="Modem ID"
              value={receiver.modemId}
              data-testid="test-modem-id-ro"
            />
          ) : null}
        </Grid.Column>
        <Grid.Column key={1}>
          {receiver.internalTag?.transmitterId ? (
            <InternalTag value={receiver.internalTag} />
          ) : null}
        </Grid.Column>
      </Grid>
    );
  }
}

ReceiverSummary.propTypes = {
  value: PropTypes.any
};

export default ReceiverSummary;
