import React from "react";
import {Table} from "semantic-ui-react";
import {toDisplayUtc} from "./helpers";

class ReceiverDeploymentsList extends React.Component {
  row(deployment, index) {
    const cells = [
      {
        key: 0,
        content: toDisplayUtc(
          deployment.deploymentDate,
          deployment.deploymentTimezone
        )
      },
      {key: 1, content: deployment.receiverIdString},
      {
        key: 2,
        content: deployment.recovery
          ? toDisplayUtc(
              deployment.recovery.recoveryDate,
              deployment.recovery.recoveryTimezone
            )
          : "-"
      },
      {key: 3, content: deployment.recovery?.status ?? deployment.deviceStatus}
    ];

    return {key: index, cells: cells};
  }

  render() {
    const {row} = this;
    let {data} = this.props;
    let tableData = Array.isArray(data) ? [...data] : [];

    const headerRow = (
      <Table.Row
        cells={[
          {
            key: 0,
            as: () => <Table.HeaderCell content="Deployment Date (UTC)" />
          },
          {key: 1, as: () => <Table.HeaderCell content="Receiver ID" />},
          {
            key: 2,
            as: () => <Table.HeaderCell content="Recovery Date (UTC)" />
          },
          {key: 3, as: () => <Table.HeaderCell content="Current Receiver Status" />}
        ]}
      />
    );

    return tableData?.length > 0 ? (
      <>
        <strong>Receiver Deployment History</strong>
        <Table
          celled
          tableData={tableData}
          renderBodyRow={row}
          headerRow={headerRow}
        />
      </>
    ) : (
      <strong>No receiver deployments</strong>
    );
  }
}

export default ReceiverDeploymentsList;
