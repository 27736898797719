import React from "react";
import {Dropdown, Grid, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Search from "./Search";
import {updateReturnToPage} from "../actions/index";

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "",
      pendingDeletionRequests: 0
    };

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(e, {name}) {
    if (e.currentTarget.href) {
      const url = new URL(e.currentTarget.href);
      const pathname = url.pathname;
      this.props.setReturnToPage({returnToPage: pathname});
    } else {
      this.props.setReturnToPage({returnToPage: window.location.pathname});
    }

    this.setState({
      activeItem: name
    });
  }

  render() {
    const {activeItem} = this.state;
    const {handleItemClick} = this;
    const {roles, pendingRequestCount} = this.props;

    const adminMenu = (
      <Dropdown
        item
        className="at-menu-item"
        text={
          <span>
            Admin{" "}
            {pendingRequestCount > 0 ? (
              <div className="horizontal ui red label">
                {pendingRequestCount}
              </div>
            ) : null}
          </span>
        }
        active={activeItem === "admin" ? true : undefined}
        onClick={handleItemClick}
      >
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/deletion-request">
            Metadata deletion requests{" "}
            {pendingRequestCount > 0 ? (
              <div className="horizontal ui red label">
                {pendingRequestCount}
              </div>
            ) : null}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/user">
            User list
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );

    return (
      <Grid columns={1} verticalAlign="middle" textAlign="center">
        <Grid.Column color="blue" className="at-menu-container">
          <Menu
            className="at-menu"
            size="large"
            widths={roles.includes("ROLE_ADMIN") ? 10 : 9}
            secondary
          >
            <Menu.Item
              className="at-menu-item"
              name="about"
              active={activeItem === "about"}
              onClick={handleItemClick}
              as={Link}
              to="/about"
            />
            <Dropdown
              item
              className="at-menu-item"
              text="Projects"
              active={activeItem === "projects" ? true : undefined}
              onClick={handleItemClick}
            >
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/projects">
                  Projects
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/organisations">
                  Organisations
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/installations">
                  Installations
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/stations">
                  Stations
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              item
              className="at-menu-item"
              text="Receivers"
              active={activeItem === "receivers" ? true : undefined}
              onClick={handleItemClick}
            >
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/receivers/receiver">
                  Receivers
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/receivers/deployment">
                  Receiver Deployments
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/receivers/upload">
                  Upload Events and Detections (VUE)
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/receivers/fathom/upload">
                  Upload Events and Detections (Fathom)
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              item
              className="at-menu-item"
              text="Transmitters"
              active={activeItem === "transmitters" ? true : undefined}
              onClick={handleItemClick}
            >
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/transmitters/transmitter">
                  Transmitters
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/transmitters/deployment">
                  Deploy a transmitter
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/transmitters/recover">
                  Recover a transmitter
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item
              className="at-menu-item"
              name="detections"
              active={activeItem === "detections"}
              onClick={handleItemClick}
              as={Link}
              to="/detection"
            />
            <Menu.Item
              className="at-menu-item"
              name="Mystery Tags"
              active={activeItem === "about"}
              onClick={handleItemClick}
              as={Link}
              to="/tools/mysterytags"
            />
            <Menu.Item
              className="at-menu-item"
              name="resources"
              active={activeItem === "resources"}
              onClick={handleItemClick}
              as={Link}
              to="/resources"
            />
            <Menu.Item
              className="at-menu-item"
              name="contact us"
              active={activeItem === "contact us"}
              onClick={(e) => {
                window.location = "mailto:info@aodn.org.au";
                e.preventDefault();
              }}
              as={Link}
              to="#"
            />
            {roles.includes("ROLE_ADMIN") ? adminMenu : null}
            <Menu.Item className="at-menu-item-search">
              <Search pageSize={20} />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const {user, pendingRequestCount} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects,
    pendingRequestCount: pendingRequestCount.pendingRequestCount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setReturnToPage: (route) => dispatch(updateReturnToPage(route.returnToPage))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
