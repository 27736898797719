import React from "react";

class ModalContainer extends React.Component {

  constructor(props) {
    // TODO: Store the referring URL in state. This is where user should land if modal is closed.
    // <Link to={{pathname: '/nextpath', state: { referrer: location.pathname }}}>Example Link</Link>
    super(props);
    this.modalOpener = this.modalOpener.bind(this);
    this.setModel = this.setModel.bind(this);
  }

  componentDidMount() {
    this.setModel();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setModel();
    }
  }

  setModel() {
    if (this.props.model) {
      this.setState({showModal: this.props.modal, model: this.props.model});
    } else {
      const id = this.props.match.params.id ? this.props.match.params.id : this.props.id;
      this.props.fetcher(id).then((model) => {
        this.setState({showModal: this.props.modal, model: model, extraProps: this.props.extraProps});
      });
    }
  }

  modalOpener(component, model) {
    this.setState({showModal: component, model: model ? model : this.state.model});
  }

  render() {

    if (!this.state) return null;

    return <
      this.state.showModal
      { ...this.state.model }
      { ...this.state.extraProps }
      onUpdate={ (model) => {
        this.setState({model: model});
      }}
      onClose={ () => {
        // TODO: Go to the page that created this modal container (this.props.location.state.referrer)
        if (!this.props.trigger && !this.props.noRedirectOnClose) {
          window.location = '/';
        }
        else if (this.props.onClose) {
          this.props.onClose();
        }
      }}
      open
      modalOpener={ this.modalOpener }
      hasContainer
    />

  }

}

export default ModalContainer;
