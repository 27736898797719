import React from "react";
import FormModal from "./FormModal";
import ActionsMenu from "./ActionsMenu";
import {Form} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import TransmitterSummary from "./TransmitterSummary";
import TransmitterHistoryList from "./TransmitterHistoryList";
import {
  getSelectedTransmitterDeployment,
  isAdmin,
  toDisplayUtc
} from "./helpers";
import TransmitterEdit from "./TransmitterEdit";
import TransmitterReleaseRecoveryEdit from "./TransmitterReleaseRecoveryEdit";

const defaultState = {
  transmitter: {}
};

class TransmitterView extends React.Component {
  constructor(props) {
    super(props);
    const transmitter = this.props.transmitter;
    const deploymentId =
      this.props.transmitter.releasePostDto?.id ??
      this.props.transmitter.releasePostDto.tagId;
    this.state = {
      ...defaultState,
      transmitter: transmitter,
      open: this.props.open,
      deploymentID: deploymentId
    };
    this.onClose = this.onClose.bind(this);
    this.focusHistory = this.focusHistory.bind(this);
    this.allowEdit = this.allowEdit.bind(this);
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  focusHistory(id) {
    const deployment = this.state.transmitter.tagPostDto.history?.find(
      (h) => h.release.id === id
    );
    this.setState({
      deploymentID: id,
      releaseDate: deployment.release
        ? toDisplayUtc(
            deployment.release.releaseDate,
            deployment.release.releaseTimezone
          )
        : null,
      recoveryDate: deployment.recovery
        ? toDisplayUtc(
            deployment.recovery.recoveryDate,
            deployment.recovery.recoveryTimezone
          )
        : null,
      selectedDeploymentHasRecovery: deployment.recovery
    });
  }

  allowEdit() {
    const {roles, projects} = this.props;
    const {transmitter} = this.state;
    return (
      isAdmin(roles) ||
      projects.find((p) => p.id === transmitter.tagPostDto.project.id)
    );
  }

  render() {
    const {onClose, focusHistory, allowEdit} = this;
    const {trigger, modalOpener} = this.props;
    const {
      deploymentID,
      releaseDate,
      recoveryDate,
      selectedDeploymentHasRecovery,
      transmitter
    } = this.state;

    const menuItems = [];
    if (allowEdit() && !transmitter.tagPostDto.owningReceiverId) {
      menuItems.push({
        name: "Edit Transmitter",
        onClick: () => {
          if (this.props.hasContainer) {
            modalOpener(TransmitterEdit);
          } else {
            modalOpener("edit", deploymentID);
          }
        }
      });

      if (transmitter.tagPostDto.history?.length > 0) {
        menuItems.push({
          name: "Edit Transmitter Deployment",
          content: (
            <>
              Edit&nbsp;Transmitter&nbsp;Deployment
              <br />
              {releaseDate}
            </>
          ),
          onClick: () => {
            if (this.props.hasContainer) {
              modalOpener(TransmitterReleaseRecoveryEdit, {
                editRelease: true,
                transmitter: transmitter
              });
            } else {
              modalOpener("editDeployment", deploymentID);
            }
          }
        });
      }

      if (selectedDeploymentHasRecovery) {
        menuItems.push({
          name: "Edit Transmitter Recovery",
          content: (
            <>
              Edit&nbsp;Transmitter&nbsp;Recovery
              <br />
              {recoveryDate}
            </>
          ),
          onClick: () => {
            if (this.props.hasContainer) {
              modalOpener(TransmitterReleaseRecoveryEdit, {
                editRecovery: true,
                ...getSelectedTransmitterDeployment({
                  ...transmitter,
                  id: deploymentID
                })
              });
            } else {
              modalOpener("editRecovery", deploymentID);
            }
          }
        });
      }
    }

    const actions =
      menuItems.length > 0 ? <ActionsMenu menuItems={menuItems} /> : null;

    const formInputs = (
      <>
        <TransmitterSummary value={transmitter.tagPostDto} />
        {transmitter.tagPostDto.history?.length > 0 ? (
          <TransmitterHistoryList
            data={{
              history: transmitter.tagPostDto.history,
              status: transmitter.tagPostDto.status
            }}
            onRowClick={focusHistory}
          />
        ) : (
          <Form.Input
            fluid
            transparent
            readOnly
            label="Transmitter Deployment History"
            value="No History"
          />
        )}
        {actions}
      </>
    );

    return (
      <FormModal
        modalSize="fullscreen"
        formInputs={formInputs}
        headerIcon="tag"
        headerContent="Transmitter"
        onClose={onClose}
        open={this.state.open}
        trigger={trigger}
      />
    );
  }
}

TransmitterView.propTypes = {
  onClose: PropTypes.func,
  transmitter: PropTypes.object
};

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    userId: user.userId,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(TransmitterView);
