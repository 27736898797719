import React from "react";
import {Grid, Image, Label} from "semantic-ui-react";
import {properties} from "../properties";

class Footer extends React.Component {
  render() {
    return (
      <>
        <Grid columns={2}>
          <Grid.Row color="grey" className="at-footer-font">
            <Grid.Column className="at-footer-left-column">
              <Image src={properties.ncrisLogo} />
            </Grid.Column>
            <Grid.Column textAlign="left" className="at-footer-right-column">
              <span>
                Australia’s Integrated Marine Observing System (IMOS) is enabled
                by the National Collaborative Research Infrastructure Strategy
                (NCRIS). It is operated by a consortium of institutions as an
                unincorporated joint venture, with the University of Tasmania as
                Lead Agent. <a href="http://www.imos.org.au">www.imos.org.au</a>
              </span>
              <Label
                size="mini"
                attached="bottom right"
                className="at-footer-right-label"
              >
                Build:{" "}
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : "none"}
              </Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

export default Footer;
