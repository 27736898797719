import {Confirm, Icon, Menu} from "semantic-ui-react";
import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

class ActionsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goto: "/not-implemented",
      menuItems: props.menuItems
    };

    this.getMenuItem = this.getMenuItem.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.menuItems !== this.props.menuItems) {
      this.setState({menuItems: this.props.menuItems});
    }
  }

  getMenuItem(item, index, className) {
    const {menuItems} = this.state;

    const onClick = item.onClick
      ? (e, d) =>
          item.onClick(e, d)?.then(() => {
            let newMenu = menuItems;
            newMenu[d.index] = {
              ...menuItems[d.index],
              disabled: false
            };
            this.setState({menuItems: newMenu});
          })
      : () => this.setState({goto: item.goto});

    const doConfirm = () => {
      this.setState({confirm: {labels: item.confirm, callback: onClick}});
    };

    className = item.className ? className + " " + item.className : className;

    const menuItem = (
      <Menu.Item
        disabled={item.disabled || item.disabledNoData}
        className={className}
        key={index}
        onClick={item.confirm ? doConfirm : onClick}
        goto={typeof item.triggered === "undefined" ? item.goto : undefined}
        data-testid={item.name}
        name={item.name}
        icon={
          item.disabled && item.disableOnClick ? (
            <Icon name="circle notch" loading />
          ) : null
        }
        active={false}
        content={item.content}
      />
    );

    return menuItem;
  }

  render() {
    const {goto, menuItems, confirm} = this.state;
    const {getMenuItem} = this;

    if (goto !== "/not-implemented") {
      return <Redirect push to={{pathname: goto}} />;
    }

    if (this.props.horizontal) {
      const menu = menuItems.map((item, index) => {
        let menuItem = getMenuItem(item, index, "at-menu-item-horiz");
        return item.triggered
          ? React.cloneElement(item.triggered, {trigger: menuItem, key: index})
          : menuItem;
      });
      return (
        <Menu
          secondary
          items={menu}
          onItemClick={(e, d) => {
            if (menuItems[d.index].disableOnClick) {
              let newMenu = menuItems;
              newMenu[d.index] = {
                ...menuItems[d.index],
                disabled: true
              };
              this.setState({menuItems: newMenu});
            }
          }}
        />
      );
    } else {
      const menu = menuItems.map((item, index) => {
        let menuItem = getMenuItem(
          item,
          index,
          item.destructive ? "at-menu-item-destructive" : "at-menu-item"
        );
        return item.triggered
          ? React.cloneElement(item.triggered, {trigger: menuItem, key: index})
          : menuItem;
      });
      return (
        <>
          {confirm ? (
            <Confirm
              open
              header={confirm.labels.header}
              content={confirm.labels.content}
              confirmButton={confirm.labels.button}
              onCancel={() => this.setState({confirm: null})}
              onConfirm={() => {
                const onClick = confirm.callback;
                this.setState({confirm: null}, onClick);
              }}
            />
          ) : null}
          <Menu
            color="blue"
            size="mini"
            compact
            fluid={this.props.fluid}
            inverted
            vertical
            items={menu}
            onItemClick={(e, d) => {
              if (menuItems[d.index].disableOnClick) {
                let newMenu = menuItems;
                newMenu[d.index] = {
                  ...menuItems[d.index],
                  disabled: true
                };
                this.setState({menuItems: newMenu});
              }
            }}
          />
        </>
      );
    }
  }
}
export const menuItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    goto: PropTypes.string,
    trigger: PropTypes.element,
    onClick: PropTypes.func,
    horizontal: PropTypes.bool,
    fluid: PropTypes.bool
  })
);

ActionsMenu.propTypes = {
  menuItems: menuItemsType.isRequired
};

export default ActionsMenu;
