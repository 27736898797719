import React from "react";
import {connect} from "react-redux";
import FormModal from "./FormModal";
import {
  activeProjectSummary,
  installation,
  me,
  stationCreate,
  stationExists
} from "../axios/api";
import {Form, Message} from "semantic-ui-react";
import Location from "./Location";
import {getOptions, isAdmin} from "./helpers";
import {updateUser} from "../actions";

const defaultState = {
  success: false,
  failed: false,
  savedStation: {},
  installationOptions: [],
  projectName: "Select an installation first",
  locationValid: false,
  locationExists: false,
  station: {
    id: null,
    name: "",
    installationId: null,
    longitude: "",
    latitude: ""
  },
  projectMetadataResolved: false,
  installationMetadataResolved: false
};

class StationCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      projectMetadataResolved:
        this.props.roles.includes("ROLE_ADMIN") ||
        this.props.roles.includes("ROLE_ATF_ADMIN"),
      installationMetadataResolved:
        this.props.roles.includes("ROLE_ADMIN") ||
        this.props.roles.includes("ROLE_ATF_ADMIN")
    };

    if (props.installationId) {
      this.state = {
        ...this.state,
        installationId: parseInt(props.installationId)
      };
    }

    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
    this.failedAction = this.failedAction.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleInstallationChange = this.handleInstallationChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.updateLocationState = this.updateLocationState.bind(this);
    this.getInstallations = this.getInstallations.bind(this);
    this.isMemberOfActiveProject = this.isMemberOfActiveProject.bind(this);
  }

  componentDidMount() {
    this.isMemberOfActiveProject();
    this.getInstallations();
  }

  isMemberOfActiveProject() {
    const getUserActiveProjects = (s) => {
      return this.props.projects.filter((project) =>
        s.find((active) => active.id === project.id)
      );
    };

    if (
      this.props.roles.includes("ROLE_ADMIN") ||
      this.props.roles.includes("ROLE_ATF_ADMIN")
    ) {
      this.setState({
        hasActiveProject: true,
        projectMetadataResolved: true,
        installationMetadataResolved: true
      });
    } else {
      activeProjectSummary().then((result) => {
        const activeProjects = result.data;
        if (getUserActiveProjects(activeProjects) < 1) {
          me().then((result) => {
            this.props.user(
              result.data.roles,
              result.data.id,
              result.data.organisationId,
              result.data.projects,
              result.data.name
            );
            const isMemberOfActiveProject =
              result.data.projects.filter((p) =>
                activeProjects.find((a) => a.id === p.id)
              ).length > 0;
            this.setState({
              projectMetadataResolved: true,
              hasActiveProject: isMemberOfActiveProject
            });
          });
        } else {
          this.setState({
            hasActiveProject: true,
            projectMetadataResolved: true
          });
        }
      });
    }
  }

  checkFormValid() {
    const {station, locationValid, savedStation} = this.state;
    const isValid =
      station.name !== savedStation.name &&
      station.name.length > 0 &&
      Number.isInteger(station.installationId) &&
      locationValid;
    return !isValid;
  }

  successAction(station) {
    this.setState(
      {
        success: true,
        failed: false,
        savedStation: this.state.station,
        station: {
          ...this.state.station,
          id: station.id
        }
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate({
            ...this.state.station,
            projectId: this.state.projectId,
            projectName: this.state.projectName
          });
        }
      }
    );
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose(this.state);
      this.setState(defaultState);
    }
  }

  async getInstallations() {
    let query = {activeProjectSummary: true};

    const projectQuery = isAdmin(this.props.roles)
      ? {}
      : this.props.projects.length > 0
      ? {projectIdList: this.props.projects.map((p) => p.id).join(",")}
      : null;

    if (projectQuery) {
      query = {...query, ...projectQuery};
      getOptions(
        installation,
        {text: "name", value: "id"},
        undefined,
        query
      ).then((allInstallations) => {
        this.setState(
          {
            installationOptions: allInstallations,
            installationMetadataResolved: true
          },
          () => {
            if (
              this.state.installationId >= 0 &&
              allInstallations.find(
                (i) => i.value === this.state.installationId
              )
            ) {
              this.handleInstallationChange(this, {
                options: allInstallations,
                value: this.state.installationId
              });
            }
          }
        );
      });
    } else {
      this.setState({installationMetadataResolved: true});
    }
  }

  handleNameChange(e) {
    let newValue = e.target.value;
    let oldSuccess = this.state.success;
    this.setState(
      {
        success: false,
        failed: false,
        station: {
          ...this.state.station,
          name: newValue
        }
      },
      () => {
        if (oldSuccess) {
          this.handleLocationChange(e, {
            longitude: this.state.station.longitude.toString(),
            latitude: this.state.station.latitude.toString(),
            valid: true
          });
        }
      }
    );
  }

  handleInstallationChange(e, {value, options}) {
    const installation = options.find((o) => o.value === value).object;
    this.setState({
      projectName: installation.projectName,
      projectId: installation.projectId,
      installationName: installation.name,
      station: {
        ...this.state.station,
        installationId: value
      }
    });
  }

  handleLocationChange(e, value) {
    if (value.latitude && value.longitude)
      stationExists(`${value.latitude},${value.longitude}`).then((res) => {
        const exists = res.data.exists;
        this.updateLocationState(exists, value);
      });
    else this.updateLocationState(false, value);
  }

  updateLocationState(exists, value) {
    this.setState({
      locationExists: exists,
      locationValid: value.valid,
      station: {
        ...this.state.station,
        longitude: parseFloat(value.longitude),
        latitude: parseFloat(value.latitude)
      }
    });
  }

  metadataChecksAreResolved() {
    return (
      this.state.projectMetadataResolved &&
      this.state.installationMetadataResolved
    );
  }

  render() {
    const {
      checkFormValid,
      successAction,
      onClose,
      handleNameChange,
      handleInstallationChange,
      handleLocationChange,
      failedAction,
      onOpen
    } = this;
    const {
      station,
      success,
      failed,
      installationOptions,
      projectName,
      locationExists,
      savedStation,
      hasActiveProject
    } = this.state;
    const {trigger, modalOpener} = this.props;

    const hasInstallatons = this.state.installationOptions.length >= 1;

    let menuItems = [];
    let formInputs = [];
    let successContent;

    if (this.metadataChecksAreResolved()) {
      if (!hasActiveProject && !isAdmin(this.props.roles)) {
        menuItems.push({
          name: "Create Project",
          onClick: () => {
            modalOpener("createProject");
          }
        });

        formInputs = (
          <Message warning>
            To create a station, you must be a member of an active project.
          </Message>
        );
      } else if (!hasInstallatons && !isAdmin(this.props.roles)) {
        menuItems.push({
          name: "Create Installation",
          onClick: () => {
            modalOpener("createInstallation");
          }
        });

        formInputs = (
          <Message warning>
            To create a station, you must first create an installation
          </Message>
        );
      } else {
        if (success) {
          menuItems.push(
            {
              name: "Create Station",
              onClick: () => {
                this.setState((s) => {
                  return {
                    ...defaultState,
                    installationOptions,
                    projectMetadataResolved: true,
                    installationMetadataResolved: true
                  };
                });
              }
            },
            {
              name: `Deploy Receiver`,
              onClick: () => {
                modalOpener("deployReceiver");
              }
            }
          );
        }

        formInputs = this.state.success ? (
          <>
            <Form.Input
              fluid
              readOnly
              transparent
              label="Installation Name"
              value={this.state.installationName}
            />
            <Form.Input
              fluid
              readOnly
              transparent
              label="Station Name"
              value={station.name}
            />
            <Form.Field
              control={Location}
              label="Location"
              readOnly
              value={{latitude: station.latitude, longitude: station.longitude}}
            />
          </>
        ) : (
          <>
            <Form.Input
              // error={nameError}
              fluid
              required
              label="Station Name"
              placeholder="Station name"
              autoComplete="none"
              onChange={handleNameChange}
              value={station.name}
            />
            <Form.Dropdown
              placeholder="Installation Name"
              label="Installation Name"
              required
              search
              selection
              options={installationOptions.sort(function(a, b) {
                return a.text > b.text ? 1 : -1;
              })}
              onChange={handleInstallationChange}
              value={station.installationId}
            />
            <Form.Input
              fluid
              readOnly
              transparent
              label="Project Name"
              value={projectName}
            />
            <Form.Field
              control={Location}
              label="Location"
              required
              warn={locationExists}
              warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
              value={{longitude: station.longitude, latitude: station.latitude}}
              onChange={handleLocationChange}
            />
          </>
        );

        successContent = (
          <>
            <p>
              Station {savedStation.name} created.
              <br />
              Enter a deployment for this station by clicking the Deploy
              Receiver button below.
            </p>
          </>
        );
      }
    }

    return (
      <FormModal
        formSubmitAPI={stationCreate}
        formData={station}
        optionalButtons={menuItems}
        checkFormValid={checkFormValid}
        testId="station-form"
        successHeader="Confirmation"
        onSuccess={successAction}
        onFail={failedAction}
        success={success}
        failed={failed}
        formInputs={formInputs}
        headerIcon="dot circle"
        trigger={trigger}
        successContent={successContent}
        headerContent="Create Station"
        submitContent={"Create Station"}
        onClose={onClose}
        onOpen={onOpen}
        open
        loading={!this.metadataChecksAreResolved()}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    user: (roles, userId, organisationId, projects, name) =>
      dispatch(updateUser(roles, userId, organisationId, projects, name))
  };
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    roles: user.roles,
    userOrganisation: user.organisationId,
    userId: user.userId,
    username: user.username,
    projects: user.projects
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StationCreate);
