import React from "react";
import FormModal from "./FormModal";
import {resetPassword} from "../axios/api";
import {Form} from "semantic-ui-react";
import Alert from "./Alert";

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameOrEmail: "",
      emailSent: false
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.successAction = this.successAction.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  handleUsernameChange(e) {
    this.setState({usernameOrEmail: e.target.value});
  }

  checkFormValid() {
    return false;
  }

  successAction(responseData) {
    this.setState({emailSent: true});
  }

  onClose() {
    if (this.props.onClose) this.props.onClose();
    this.setState({emailSent: false});
  }

  render() {
    const {handleUsernameChange, checkFormValid, successAction, onClose} = this;
    const {usernameOrEmail, emailSent} = this.state;
    const formInputs = (
      <div>
        <p>
          Submit your user name and we will send you an email with a link to
          enable you to reset your password.
        </p>
        <Form.Input
          fluid
          required
          value={usernameOrEmail}
          autoComplete="none"
          label="Username"
          placeholder="Username"
          onChange={handleUsernameChange}
          data-testid="username"
        />
      </div>
    );

    const alert = emailSent ? (
      <Alert
        icon="user"
        headerContent="Reset Password"
        content={
          "Your request for a password reset has been successful. An email has been sent to your " +
          "email address. Please check your email and follow the link included to create your new password. " +
          "If you cannot find the password reset email in your inbox, please check in your spam or junk mail."
        }
        closeButtonContent="OK"
        hideCancel
      />
    ) : null;

    return (
      <>
        <FormModal
          formSubmitAPI={resetPassword}
          formData={{usernameOrEmail: usernameOrEmail}}
          checkFormValid={checkFormValid}
          testId="resetpassword"
          onSuccess={successAction}
          formInputs={formInputs}
          headerContent="Reset Password"
          headerIcon="user"
          submitContent="Reset Password"
          redirectOnClose="/"
          onClose={onClose}
          open
        />
        {alert}
      </>
    );
  }
}

export default PasswordReset;
