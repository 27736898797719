import React from "react";
import config from "react-global-configuration";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {Form, Grid} from "semantic-ui-react";
import moment from "moment";
import PropTypes from "prop-types";
import {toUTC} from "./helpers";

class DateSelector extends React.Component {
  constructor(props) {
    super(props);

    const date =
      props.date && props.timezone
        ? moment.utc(props.date).tz(props.timezone)
        : moment().utc();

    if (props.readOnly) {
      this.state = {
        readOnlyDate: `${date.format("YYYY-MM-DD HH:mm")}`
      };
      return;
    }

    // Australian time zones should be the first options, then 'UTC' then the other time zones.
    const timezones = config.get("timezones");
    const tzAustralia = timezones.filter((v) => v.startsWith("Australia"));
    const tzUTC = timezones.filter((v) => v.startsWith("UTC"));
    const tzOther = timezones.filter(
      (v) => !(tzAustralia.includes(v) || tzUTC.includes(v))
    );
    const timezonesOrdered = [...tzAustralia, ...tzUTC, ...tzOther];
    this.state = {
      timezone: this.props.timezone ?? "UTC",
      timezoneList: timezonesOrdered.map((v) => {
        return {text: v, value: v};
      }),
      date: date,
      maxDate: new Date(),
      valid: true
    };
    this.onTzChange = this.onTzChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onDateBlur = this.onDateBlur.bind(this);
  }

  onTzChange(e, {value}) {
    const dateString = this.state.date.format("YYYY-MM-DD HH:mm");
    const date = toUTC(dateString, value);
    this.props.onChange({
      timezone: value,
      utc: date
    });
  }

  onDateBlur() {
    if (!this.state.valid) {
      this.props.onChange({
        timezone: this.props.timezone,
        utc: null
      });
    }
  }

  onDateChange(e) {
    // invalid dates are passed as strings
    if (typeof e === "string") {
      this.setState({valid: false});
    } else {
      const dateString = e.format("YYYY-MM-DD HH:mm");
      const date = toUTC(dateString, this.props.timezone);
      this.props.onChange({
        timezone: this.props.timezone,
        utc: date
      });
      this.setState({valid: true, date: e});
    }
  }

  render() {
    return (
      <div
        className={
          (this.props.required && !this.props.dontShowFieldsRequired // Hack for ReceiverDeploymentCreate to avoid changing all other DateSelector instances
            ? "required"
            : "") + " field"
        }
      >
        <label>{this.props.label}</label>
        <Grid>
          <Grid.Column key={0}>
            {this.props.readOnly ? (
              <Form.Input
                fluid
                transparent
                readOnly
                value={this.state.readOnlyDate}
              />
            ) : (
              <Datetime
                utc
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                initialValue={this.state.date}
                onChange={this.onDateChange}
                inputProps={
                  this.state.valid
                    ? {}
                    : {className: "at-invalid-date", onBlur: this.onDateBlur}
                }
                isValidDate={(currentDate) => {
                  return this.props.minDate
                    ? currentDate.isSameOrAfter(
                        moment(this.props.minDate),
                        "day"
                      )
                    : true;
                }}
              />
            )}
            {this.props.readOnly ? (
              <Form.Input
                fluid
                transparent
                readOnly
                value={`${this.props.timezone}`}
              />
            ) : (
              <Form.Dropdown
                search
                required
                selection
                value={this.props.timezone}
                placeholder="Select Timezone"
                options={this.state.timezoneList}
                onChange={this.onTzChange}
              />
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

DateSelector.propTypes = {
  readOnly: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  date: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string
};

export default DateSelector;
