import {Form} from "semantic-ui-react";
import React from "react";
import {organisationSummary, organisationById} from "../axios/api";
import {COUNTRY_OPTIONS} from "./countriesData";
import config from "react-global-configuration";
import axios from "axios";
import {countryFullName, formatOrganisationDescription} from "./helpers";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

class OrganisationSelect extends React.Component {
  constructor(props) {
    super(props);

    this.defaultCountry = "Australia";

    this.state = {
      loading: true,
      organisations: [],
      organisationTypes: [],
      organisationStates: [],
      organisation: props.value
    };

    this.handleNewOrganisation = this.handleNewOrganisation.bind(this);
    this.handleOrganisationChange = this.handleOrganisationChange.bind(this);
    this.handleOrganisationTypeChange = this.handleOrganisationTypeChange.bind(
      this
    );
    this.handleOrganisationCityBlur = this.handleOrganisationCityBlur.bind(
      this
    );
    this.handleOrganisationStateChange = this.handleOrganisationStateChange.bind(
      this
    );
    this.validateOrganisation = this.validateOrganisation.bind(this);
    this.getOrganisationName = this.getOrganisationName.bind(this);
    this.handleOrganisationCountryChange = this.handleOrganisationCountryChange.bind(
      this
    );
    this.handleOrganisationCityChange = this.handleOrganisationCityChange.bind(
      this
    );
    this.handleOrganisationNameBlur = this.handleOrganisationNameBlur.bind(
      this
    );
  }

  componentDidMount() {
    let newTypes = config.get("organisation_type").map((orgType) => {
      return {text: orgType, value: orgType};
    });
    let newStates = config.get("organisation_state").map((orgState) => {
      return {text: orgState, value: orgState};
    });
    this.getOrganisationOptions().then((allOrgs) => {
      let orgId;
      if (Number.isInteger(this.props.value) && this.props.value !== -1)
        orgId = this.props.value;
      else
        orgId = allOrgs.find((o) => o.text === "No Affiliation")?.value ?? -1;

      if (this.props.allowEdit) {
        allOrgs = allOrgs.map((org) => {
          return {...org, disabled: org.value !== orgId};
        });
      }
      if (orgId !== -1) {
        this.ct = axios.CancelToken.source();
        organisationById(orgId,{
          cancelToken: this.ct.token
        }).then((result) => {
          this.setState(
            {
              loading: false,
              organisationTypes: [...this.state.organisationTypes, ...newTypes],
              organisationStates: [
                ...this.state.organisationStates,
                ...newStates
              ],
              organisations: allOrgs.sort(function(a,b){return a.text > b.text ? 1 : -1}),
              organisation: result.value
            },
            () => this.handleOrganisationChange(null, {value: orgId})
          );
        });
      } else {
        const other =
          this.props.value.id < 0
            ? {
                organisation: {
                  id: null,
                  name: "",
                  type: "",
                  state: "",
                  suburb: "",
                  country: this.defaultCountry
                }
              }
            : null;

        this.setState({
          loading: false,
          organisationTypes: [...this.state.organisationTypes, ...newTypes],
          organisationStates: [...this.state.organisationStates, ...newStates],
          organisations: allOrgs,
          ...other
        });
      }
    }).catch(err => {
      if(!axios.isCancel(err))
        console.debug(err)
    });;
  }

  componentWillUnmount() {
    if (this.ct) this.ct.cancel();
    if (this.ct2) this.ct2.cancel();
  }

  async getOrganisationOptions() {
    this.ct = axios.CancelToken.source();
    return organisationSummary(
      this.props.isAdmin ?? false,
      this.props.includeNoAffiliation,
      this.ct.token
    ).then((result) => {
      this.ct = null;
      let options = result.data.map((org) => {
        return {
          value: org.id,
          text: org.name,
          description: formatOrganisationDescription(org.suburb, org.country)
        };
      });

      return options;
    })
  }

  handleNewOrganisation(e, {value}) {
    const newId =
      Math.max.apply(
        Math,
        this.state.organisations.map(function(o) {
          return o.value;
        })
      ) + 1;
    this.setState(
      (prevState) => ({
        organisations: [
          {text: value, value: newId, description: "New"},
          ...prevState.organisations
        ],
        organisation: {
          id: newId,
          name: value,
          suburb: this.state.organisation.suburb,
          type: this.state.organisation.type,
          state: this.state.organisation.state,
          country: this.state.organisation.country
        }
      }),
      () => this.validateOrganisation()
    );
  }

  handleOrganisationChange(e, {value}) {
    const selectedOrganisation = this.state.organisations.find(
      (o) => o.value === value
    );
    // undefined if it's a new organisation that was just added
    if (typeof selectedOrganisation !== "undefined") {
      this.setState(
        {
          organisation: {
            id: value,
            name: selectedOrganisation.text,
            suburb: this.state.organisation.suburb,
            type: this.state.organisation.type,
            state: this.state.organisation.state,
            country: this.state.organisation.country
          }
        },
        () => {
          this.validateOrganisation();
        }
      );
    }
  }

  handleOrganisationNameBlur(e, {value}) {
    this.validateOrganisation();
  }

  handleOrganisationTypeChange(e, {value}) {
    this.setState(
      {
        organisation: {
          ...this.state.organisation,
          type: value
        }
      },
      () => this.validateOrganisation()
    );
  }

  handleOrganisationCityBlur(e) {
    let newValue = e.target.value;
    this.setState(
      {
        organisation: {
          ...this.state.organisation,
          suburb: newValue
        }
      },
      () => this.validateOrganisation()
    );
  }

  handleOrganisationCityChange(e) {
    this.setState({
      organisation: {
        ...this.state.organisation,
        suburb: e.target.value
      }
    });
  }

  handleOrganisationStateChange(e, {value}) {
    this.setState(
      {
        organisation: {
          ...this.state.organisation,
          state: value
        }
      },
      () => this.validateOrganisation()
    );
  }

  handleOrganisationCountryChange(e, {value}) {
    const state =
      value === this.defaultCountry ? this.state.organisation.state : null;
    this.setState(
      {
        organisation: {
          ...this.state.organisation,
          country: value,
          state: state
        }
      },
      () => this.validateOrganisation()
    );
  }

  validateOrganisation() {
    const error =
      this.state.organisation.country === "" ||
      (this.state.organisation.country === this.defaultCountry &&
        this.state.organisation.state === "");

    let organisation = this.state.organisations.find(
      (x) => x.value === this.state.organisation.id
    );

    if (
      typeof organisation.description !== "undefined" &&
      organisation.description === "New"
    ) {
      this.props.onValidate(this.state.organisation, error);
    } else {
      this.props.onValidate(
        this.state.organisation.id,
        error,
        this.state.searchQuery,
        this.state.organisation
      );
    }
  }

  getOrganisationName(id) {
    let org = this.state.organisations.find((x) => x.value === id);
    return org !== undefined ? org.text : "";
  }

  handleSearchChange = (e, {searchQuery}) => this.setState({searchQuery});

  render() {
    const {
      allowCreate,
      readOnly,
      allowEdit,
      selectOnly,
      readOnlyName,
      asLink
    } = this.props;
    const {
      organisations,
      organisationTypes,
      organisationStates,
      organisation
    } = this.state;
    const {
      handleNewOrganisation,
      handleOrganisationChange,
      handleOrganisationTypeChange,
      handleOrganisationCityBlur,
      handleOrganisationCityChange,
      handleOrganisationStateChange,
      getOrganisationName,
      handleOrganisationCountryChange,
      handleOrganisationNameBlur
    } = this;

    const org = this.state.organisations.find(
      (x) => x.value === organisation.id
    );
    const isNew =
      typeof org !== "undefined" &&
      typeof org.description !== "undefined" &&
      org.description === "New";

    const editFields = (
      <>
        <Form.Select
          search
          options={organisationTypes}
          loading={this.state.loading}
          text={this.props.value?.type}
          placeholder="Select organisation type"
          label="Type"
          required
          onChange={handleOrganisationTypeChange}
          value={organisation.type}
        />
        <Form.Input
          label="Suburb"
          placeholder="Suburb"
          required
          autoComplete="none"
          onBlur={handleOrganisationCityBlur}
          onChange={handleOrganisationCityChange}
          value={organisation.suburb}
        />
        <Form.Dropdown
          fluid
          selection
          search
          placeholder="Select country"
          options={COUNTRY_OPTIONS}
          label="Country"
          onChange={handleOrganisationCountryChange}
          required
          value={organisation.country}
        />
        <Form.Dropdown
          fluid
          selection
          search
          label="State"
          options={organisationStates}
          loading={this.state.loading}
          text={this.props.value?.state}
          placeholder="Select state"
          required={organisation.country === this.defaultCountry}
          disabled={organisation.country !== this.defaultCountry}
          onChange={handleOrganisationStateChange}
          value={organisation.state}
        />
      </>
    );

    const createFields = (
      <>
        <Form.Field
          label="Create Organisation"
          disabled={!isNew}
          data-testid="neworganisation"
        />
        <Form.Select
          search
          options={organisationTypes}
          placeholder="Select organisation type"
          label="Type"
          required={isNew}
          disabled={!isNew}
          onChange={handleOrganisationTypeChange}
          data-testid="type"
        />
        <Form.Input
          label="Suburb"
          placeholder="Suburb"
          autoComplete="none"
          required={isNew}
          disabled={!isNew}
          onBlur={handleOrganisationCityBlur}
          data-testid="city"
        />
        <Form.Dropdown
          fluid
          selection
          search
          placeholder="Select country"
          options={COUNTRY_OPTIONS}
          label="Country"
          onChange={handleOrganisationCountryChange}
          required={isNew}
          disabled={!isNew}
          value={organisation.country}
          data-testid="country"
        />
        <Form.Dropdown
          fluid
          selection
          search
          label="State"
          options={organisationStates}
          placeholder="Select state"
          required={isNew && organisation.country === this.defaultCountry}
          disabled={!isNew || organisation.country !== this.defaultCountry}
          onChange={handleOrganisationStateChange}
          data-testid="state"
        />
      </>
    );

    const readOnlyFields = (
      <>
        <Form.Input
          label="Type"
          readOnly
          transparent
          defaultValue={organisation.type}
        />
        <Form.Input
          label="Suburb"
          readOnly
          transparent
          value={organisation.suburb}
        />
        <p>
          <strong>Country</strong>
          <br />
          {countryFullName(organisation.country)}
        </p>
        <Form.Input
          label="State"
          readOnly
          transparent
          defaultValue={organisation.state}
        />
      </>
    );

    const organisationNameReadOnly = (
      <Form.Input
        label="Organisation"
        readOnly
        transparent
        value={getOrganisationName(organisation.id) || organisation.name}
      />
    );

    const organisationNameCreate = (
      <Form.Dropdown
        allowAdditions={allowCreate}
        placeholder={
          allowCreate
            ? "Select or type a new organisation name"
            : "Select an organisation name"
        }
        fluid
        search
        selection
        required
        label="Organisation"
        options={organisations}
        onAddItem={handleNewOrganisation}
        onChange={handleOrganisationChange}
        value={organisation.id}
        data-testid="organisation"
      />
    );

    const organisationNameEdit = (
      <Form.Dropdown
        placeholder="Type a unique organisation name"
        fluid
        search
        selection
        required
        label="Organisation Name"
        loading={this.state.loading}
        text={this.state.loading ? this.props.loadingText ?? "" : null}
        options={organisations}
        onChange={handleOrganisationChange}
        value={organisation.id}
        onBlur={handleOrganisationNameBlur}
        searchQuery={this.state.searchQuery}
        onSearchChange={this.handleSearchChange}
        noResultsMessage="This organisation name is unique"
      />
    );

    const organisationNameSelectOnly = (
      <Form.Dropdown
        fluid
        search
        selection
        required
        loading={this.state.loading}
        text={this.state.loading ? this.props.loadingText ?? "" : null}
        label={this.props.label ?? "Organisation Name"}
        options={organisations}
        onChange={handleOrganisationChange}
        value={organisation.id}
        data-testid="organisation"
      />
    );

    let fields = null;
    let organisationName = null;
    if (readOnly) {
      organisationName = organisationNameReadOnly;
      fields = readOnlyFields;
    } else if (allowCreate) {
      organisationName = organisationNameCreate;
      fields = createFields;
    } else if (allowEdit) {
      organisationName = organisationNameEdit;
      fields = editFields;
    } else if (selectOnly) {
      organisationName = organisationNameSelectOnly;
      fields = null;
    } else if (readOnlyName) {
      organisationName = asLink ? (
        <>
          <strong>Organisation</strong>
          <br />
          <Link to={`/organisation/${organisation.id}`}>
            {getOrganisationName(organisation.id) || organisation.name}
          </Link>
          <br />
        </>
      ) : (
        organisationNameReadOnly
      );
      fields = null;
    }

    return (
      <>
        {organisationName}
        {fields}
      </>
    );
  }
}

OrganisationSelect.propTypes = {
  loadingText: PropTypes.string,
  onValidate: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  allowCreate: PropTypes.bool,
  allowEdit: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyName: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  isAdmin: PropTypes.bool,
  includeNoAffiliation: PropTypes.bool.isRequired
};

export default OrganisationSelect;
