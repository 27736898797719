import React from "react";
import {Button, Header, Segment} from "semantic-ui-react";
import MysteryTagsForm from "./MysteryTagsForm";

class MysteryTags extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false
        };
    }

    render() {
        return (
            <div>
            <Segment padded="very" basic>
                <Header as="h2">Mystery Tags</Header>
                <p>Click the 'Check' button below to find the status of your tags. </p>
                <Button
                    primary
                    content={"Check"}
                    onClick={() => {
                        this.setState({
                            modalOpen: true
                        });
                    }}
                    data-testid="mystertags-modal"
                />
            </Segment>
        <MysteryTagsForm open={this.state.modalOpen} onClose={() => {
            this.setState({
                modalOpen: false
            });
        }}/>
            </div>
        );
    }
};

export default MysteryTags;
