import React from "react";
import {Button} from "semantic-ui-react";
import {userLogout} from "../actions";
import {userLogout as APIUserLogout} from "../axios/api";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {didLogOut: false};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({didLogOut: true}, () => {
      APIUserLogout({
        tokenType: this.props.tokenType,
        accessToken: this.props.accessToken
      }).then(() => {
        this.props.userLogout();
      });
    });
  }

  render() {
    if (this.state.didLogOut) {
      return <Redirect to={{pathname: "/"}} />;
    }

    return (
      <Button
        primary
        content="Sign out"
        onClick={this.handleClick}
        data-testid="signout"
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogout: () => dispatch(userLogout())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
