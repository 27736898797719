import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import axios from "axios";
import {statistics} from "../axios/api";
import {Grid, Header, Segment} from "semantic-ui-react";
import {Timeline} from "react-twitter-widgets";
import ReactPlayer from "react-player";
import Cell from "./StatisticCell";
import LoginForm from "./LoginForm";
import ImageButton from "./ImageButton";
import DetectionsImage from "../images/animal-detections.jpg";
import ReceiverDeploymentImage from "../images/receiver-deployment.jpg";
import ReceiverRecoveryImage from "../images/receiver-recovery.jpg";
import TransmitterDeploymentImage from "../images/transmitter-deployment.jpg";
import TransmitterRecoveryImage from "../images/transmitter-recovery.jpg";
import {CurrentReceiverLocationsUrl} from "./helpers";
import {properties} from "../properties";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      redirectNoAuth: false,
      showVideoLeft: true,
      showVideoRight: true,
      showTwitterLeft: true,
      showTwitterRight: true,
      stats: null
    };
  }

  componentDidMount() {
    this.ct = axios.CancelToken.source();
    statistics(this.ct.token)
      .then((result) => {
        this.ct = null;
        if (
          Object.keys(result.data).findIndex((k) => result.data[k] === "-") < 0
        )
          this.setState({stats: result.data});
      })
      .catch((err) => {
        if (axios.isCancel(err)) console.debug("Statistics Request Cancelled");
      });
  }

  componentWillUnmount() {
    if (this.ct) this.ct.cancel();
  }

  render() {
    const stats = this.state?.stats;
    return this.state.redirect &&
      (this.props.isLoggedIn || this.state.redirectNoAuth) ? (
      <Redirect to={this.state.redirect} push />
    ) : (
      <>
        {this.state.stats ? (
          <Grid className="at-stats-row" doubling columns={6}>
            <Grid.Row className="at-extra-row-bottom-margin at-extra-row-top-margin">
              <Grid.Column>
                <Cell
                  dark
                  count={stats.activeReceiversCount}
                  label="Active receivers"
                />
              </Grid.Column>
              <Grid.Column>
                <Cell
                  count={stats.receiversDeployedCount}
                  label="Receivers deployed"
                />
              </Grid.Column>
              <Grid.Column>
                <Cell
                  dark
                  count={stats.speciesTrackedCount}
                  label="Species tracked"
                />
              </Grid.Column>
              <Grid.Column>
                <Cell count={stats.animalsTaggedCount} label="Animals tagged" />
              </Grid.Column>
              <Grid.Column>
                <Cell
                  dark
                  millions={stats.validDetectionsCount > 1000000}
                  count={stats.validDetectionsCount}
                  label={`${
                    stats.validDetectionsCount > 1000000
                      ? "Million detections"
                      : "Detections"
                  }`}
                />
              </Grid.Column>
              <Grid.Column>
                <Cell count={stats.activeProjectsCount} label="Projects" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
        <Grid className="at-primary-image-button-grid">
          <Grid.Row className="at-extra-row-bottom-margin">
            <Grid.Column width={4} />
            <Grid.Column width={8} className="at-primary-image-button">
              <ImageButton
                size="small"
                position="top-left"
                primary="true"
                label="Click to explore the network map"
                image={properties.homepage.map}
                onClick={() => {
                  window.open(CurrentReceiverLocationsUrl, "_blank");
                }}
              />
            </Grid.Column>
            <Grid.Column width={4} />
          </Grid.Row>
        </Grid>
        <Grid className="at-action-button-grid" columns={5}>
          <Grid.Row className="at-extra-row-bottom-margin">
            <Grid.Column>
              <ImageButton
                label="Access Animal detections"
                image={DetectionsImage}
                onClick={() => {
                  this.setState({redirect: "/detection", redirectNoAuth: true});
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <ImageButton
                label="Add a new transmitter deployment"
                image={TransmitterDeploymentImage}
                onClick={() => {
                  this.setState({redirect: "/transmitters/transmitter/deploy"});
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <ImageButton
                label="Add a transmitter recovery"
                image={TransmitterRecoveryImage}
                onClick={() => {
                  this.setState({
                    redirect: "/transmitters/transmitter/recover"
                  });
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <ImageButton
                label="Add a new receiver deployment"
                image={ReceiverDeploymentImage}
                onClick={() => {
                  this.setState({redirect: "/receivers/deployment/deploy"});
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <ImageButton
                label="Add a receiver recovery"
                image={ReceiverRecoveryImage}
                onClick={() => {
                  this.setState({redirect: "/receivers/deployment/recover"});
                }}
              />{" "}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid className="at-primary-image-button-grid">
          <Grid.Row
            columns={4}
            className="at-media-rows at-extra-row-bottom-margin"
            stretched
          >
            <Grid.Column width="2"></Grid.Column>
            <Grid.Column width="6">
              {this.state.showVideoLeft ? (
                <>
                  <Header as="h4">
                    {properties.homepage.videos.left.title}
                  </Header>
                  <div className="at-player-wrapper">
                    <ReactPlayer
                      onError={() => {
                        this.setState({showVideoLeft: false});
                      }}
                      url={properties.homepage.videos.left.url}
                      controls={true}
                      width="100%"
                      height="100%"
                      className="at-react-player"
                    />
                  </div>
                </>
              ) : null}
            </Grid.Column>
            <Grid.Column width="6">
              {this.state.showVideoRight ? (
                <>
                  <Header as="h4">
                    {properties.homepage.videos.right.title}
                  </Header>
                  <div className="at-player-wrapper">
                    <ReactPlayer
                      onError={() => {
                        this.setState({showVideoRight: false});
                      }}
                      url={properties.homepage.videos.right.url}
                      controls={true}
                      width="100%"
                      height="100%"
                      className="at-react-player"
                    />
                  </div>
                </>
              ) : null}
            </Grid.Column>
            <Grid.Column width="2"></Grid.Column>
          </Grid.Row>
          <Grid.Row
            columns={4}
            className="at-media-rows at-extra-row-bottom-margin at-tweet-media-row"
            stretched
          >
            <Grid.Column width="2"></Grid.Column>
            <Grid.Column width="6">
              {this.state.showTwitterLeft ? (
                <>
                  <Header as="h4">Latest news from IMOS</Header>
                  <Segment className="at-tweet-container">
                    <Timeline
                      renderError={() => {
                        this.setState({showTwitterLeft: false});
                      }}
                      dataSource={{
                        sourceType: "profile",
                        screenName: properties.homepage.twitterHandles[0]
                      }}
                      options={{tweetLimit: 5}}
                    />
                  </Segment>
                </>
              ) : null}
            </Grid.Column>
            <Grid.Column width="6">
              {this.state.showTwitterRight ? (
                <>
                  <Header as="h4">
                    Latest updates from the IMOS Animal Tracking Facility
                  </Header>
                  <Segment className="at-tweet-container">
                    <Timeline
                      renderError={() => {
                        this.setState({showTwitterRight: false});
                      }}
                      dataSource={{
                        sourceType: "profile",
                        screenName: properties.homepage.twitterHandles[1]
                      }}
                      options={{tweetLimit: 5}}
                    />
                  </Segment>
                </>
              ) : null}
            </Grid.Column>
            <Grid.Column width="2"></Grid.Column>
          </Grid.Row>
        </Grid>
        {this.state.redirect ? (
          <LoginForm
            open
            onClose={(success) =>
              this.setState((s) => {
                return {redirect: success ? s.redirect : null};
              })
            }
          />
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    isLoggedIn: user?.roles?.includes("ROLE_USER") ?? false
  };
}

export default connect(mapStateToProps)(Home);
