import React from "react";
import FormModal from "./FormModal";
import ActionsMenu from "./ActionsMenu";
import {Form} from "semantic-ui-react";
import Location from "./Location";
import {deploymentsCsv, receiverDeployment, stationById} from "../axios/api";
import ReceiverDeploymentsList from "./ReceiverDeploymentsList";
import FileDownload from "js-file-download";
import {csvAndZipTimestamp, isAdmin} from "./helpers";
import {connect} from "react-redux";
import StationEdit from "./StationEdit";

const defaultState = {
  open: false,
  station: {
    installation: {
      project: {name: ""},
      name: ""
    },
    name: "",
    location: {}
  }
};

class StationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    this.setState({loading: true});
    stationById(this.props.station).then((s) => {
      receiverDeployment({stationId: this.props.station}).then((d) => {
        const history = d.data.content.length > 0 ? d.data.content : [];
        this.setState({
          station: s.data,
          deployments: history,
          loading: false
        });
      });
    });
  }

  render() {
    const {onOpen} = this;
    const {station, deployments, loading} = this.state;
    const {trigger, onClose, modalOpener} = this.props;

    const menuItems = [];
    if (
      isAdmin(this.props.roles) ||
      this.props.projects.find((p) => station.installation.project.id === p.id)
    ) {
      menuItems.push({
        name: "Edit Station",
        onClick: () => {
          if (this.props.hasContainer) {
            modalOpener(StationEdit);
          } else {
            modalOpener("edit");
          }
        }
      });
    }
    menuItems.push({
      name: "Download Data",
      onClick: async () => {
        const response = await deploymentsCsv(
          this.state.station.id,
          this.state.tableFilters
        );
        FileDownload(response.data, `deployments_${csvAndZipTimestamp()}.csv`);
      }
    });
    const actions =
      menuItems.length > 0 ? <ActionsMenu menuItems={menuItems} /> : null;

    const formInputs = (
      <>
        <Form.Input
          fluid
          readOnly
          transparent
          label="Project Name"
          value={station.installation.project.name}
        />
        <Form.Input
          fluid
          readOnly
          transparent
          label="Installation Name"
          value={station.installation.name}
        />
        <Form.Input
          fluid
          readOnly
          transparent
          label="Station Name"
          value={station.name}
        />
        <Form.Field
          control={Location}
          label="Location"
          readOnly
          value={station.location}
        />
        {actions}
        <ReceiverDeploymentsList data={deployments} />
      </>
    );

    return (
      <FormModal
        testId="station-form"
        formInputs={formInputs}
        headerIcon="dot circle"
        trigger={trigger}
        headerContent="Station View"
        onClose={onClose}
        onOpen={onOpen}
        open
        loading={loading}
      />
    );
  }
}
function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    userId: user.userId,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(StationView);
