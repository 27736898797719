import React from "react";
import {Form} from "semantic-ui-react";
import PropTypes from "prop-types";

class FilterDropDown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      searchString: null,
      selectedValue: false,
      autoFocus: false
    };

    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleDropDownChange(e, {value}) {
    this.setState(
      {
        error: null,
        searchString: null,
        selectedValue: value !== "",
        autoFocus: true
      },
      this.props.onChange(this, {
        name: this.props.name,
        value: value
      })
    );
  }

  handleBlur() {
    if (!this.state.selectedValue && this.state.searchString) {
      // test if the searchString matches a valid dropdown value
      const matchingOption = this.props.options.find(
        (o) => o.text.toUpperCase() === this.state.searchString?.toUpperCase()
      );
      if (matchingOption) {
        this.props.onChange(this, {
          name: this.props.name,
          value: matchingOption.value
        });
      } else {
        this.props.onChange(this, {name: this.props.name, value: -1});
        this.setState({error: "No results found"});
      }
    }
  }

  handleSearchChange(e, data) {
    this.setState({
      error: null,
      searchString: data.searchQuery,
      selectedValue: false
    });
  }

  render() {
    return (
      <>
        <div hidden>
          <Form.Input
            autoComplete="none"
            name={this.props.name ?? ""}
            value={this.props.value ?? ""}
          />
        </div>
        <Form.Select
          fluid
          selection
          search
          clearable
          error={this.props.value === -1 ? this.state.error : null}
          onChange={this.handleDropDownChange}
          onBlur={this.handleBlur}
          onSearchChange={this.handleSearchChange}
          value={this.props.value === -1 ? null : this.props.value}
          placeholder={
            this.props.value === -1
              ? this.state.searchString
              : this.props.placeholder
          }
          options={this.props.options}
          multiple={this.props.multiple}
          selectOnBlur={false}
        />
      </>
    );
  }
}

FilterDropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array
  ]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default FilterDropDown;
