import {
  USER_DETAIL,
  USER_LOGOUT,
  NAVIGATE,
  PENDING_REQUEST_COUNTER
} from "../constants/action-types";

export function updateToken(username, accessToken, tokenType, tokenExp) {
  return {
    type: USER_DETAIL,
    payload: {username, accessToken, tokenType, tokenExp}
  };
}

export function userLogout() {
  return {type: USER_LOGOUT};
}

export function updateUser(roles, userId, organisationId, projects, name) {
  return {
    type: USER_DETAIL,
    payload: {roles, userId, organisationId, projects, name}
  };
}

export function updatePendingRequestCount(pendingRequestCount) {
  return {
    type: PENDING_REQUEST_COUNTER,
    payload: {pendingRequestCount}
  };
}

export function updateReturnToPage(returnToPage) {
  return {type: NAVIGATE, payload: {returnToPage}};
}
