import React from "react";
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import {activate} from "../axios/api";
import {Redirect} from "react-router-dom";

// All errors
const errorMessage = "Please try again later or contact us for assistance";
const errorHeader = "Account activation failed";
const successMessage =
  "Thank you for creating your account with the IMOS Animal Tracking Facility.";
const successHeader = "Your registration was successful";

class UserConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: true,
      loading: true,
      success: false,
      error: false,
      signIn: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  getParams(location) {
    const searchParams = new URLSearchParams(location.search);
    return {
      token: searchParams.get("token") || "",
      username: searchParams.get("username") || ""
    };
  }

  componentDidMount() {
    activate(this.getParams(this.props.location))
      .then((result) => {
        this.setState({
          loading: false,
          error: false,
          success: true
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: true,
          success: false
        });
      });
  }

  handleClose(e) {
    this.setState({
      modalOpen: false
    });
  }

  handleSignIn(e) {
    this.setState({
      signIn: true
    });
  }

  render() {
    const {activating, success, error, modalOpen, signIn} = this.state;

    if (signIn) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: {referrer: this.props.location.pathname}
          }}
        />
      );
    }

    if (!modalOpen) {
      return <Redirect to={{pathname: "/"}} />;
    }

    return (
      <Modal open={modalOpen}>
        <Header icon="user" content="Registration confirmation" />
        <Modal.Content>
          <Segment>
            Activating account
            <Dimmer active={activating} inverted>
              <Loader>Activating account</Loader>
            </Dimmer>
            <Message
              error
              hidden={!error}
              header={errorHeader}
              content={errorMessage}
              data-testid="errormessage"
            />
            <Message
              success
              hidden={!success}
              header={successHeader}
              content={successMessage}
              data-testid="successmessage"
            />
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={this.handleClose} />
          <Button primary content="Sign In" onClick={this.handleSignIn} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default UserConfirmation;
