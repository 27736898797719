import React from "react";
import {Form, Segment} from "semantic-ui-react";
import config from "react-global-configuration";

import FormModal from "./FormModal";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ProjectSelect from "./ProjectSelect";
import Location from "./Location";
import DateSelector from "./DateSelector";
import {transmitter, tagRecover, transmitterById} from "../axios/api";
import TransmitterSummary from "./TransmitterSummary";
import TransmitterHistoryList from "./TransmitterHistoryList";
import {getSelectedTransmitterDeployment, isAdmin} from "./helpers";

class TransmitterRecoveryCreate extends React.Component {
  constructor(props) {
    super(props);
    const configurations = config.get("tag_recovery_status").map((c) => {
      return {text: c, value: c};
    });
    this.state = {
      tag: props.transmitter,
      projectId: props.transmitter?.releasePostDto.projectId ?? -1,
      recovery: {
        transmitterId: props.transmitter?.tagPostDto.tagId ?? -1,
        animalReleaseId: "",
        status: "",
        latitude: "",
        longitude: "",
        recoveryDate: "",
        recoveryTimezone: "",
        comments: "",
        version: 0
      },
      transmitters: [],
      statusTypes: configurations
    };
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
  }

  checkFormValid() {
    const r = this.state.recovery;
    const isValid =
      (r.projectId > 0,
      r.animalReleaseId,
      r.recoveryDate,
      r.recoveryTimezone,
      r.status);
    return !isValid;
  }

  successAction(result) {
    this.setState((s) => {
      return {recovery: {...s.recovery, version: result.version}};
    });
    this.props.onUpdate(result);
  }

  render() {
    const {tag} = this.state;

    const transmitterSelected =
      this.state.projectId > 0 && this.state.recovery.animalReleaseId > 0;

    const tagDetails = tag ? (
      <Segment>
        <TransmitterSummary value={tag.tagPostDto} />
        {tag.tagPostDto.history?.length > 0 ? (
          <TransmitterHistoryList
            data={{
              history: tag.tagPostDto.history,
              status: tag.tagPostDto.status
            }}
          />
        ) : (
          <Form.Input
            fluid
            transparent
            readOnly
            label="Transmitter Deployment History"
            value="No History"
          />
        )}
      </Segment>
    ) : null;

    const recoveryInputs = (
      <>
        <ProjectSelect
          required
          value={this.state.projectId}
          showAll={isAdmin(this.props.roles)}
          onChange={(e, {value}) => {
            this.setState(
              {
                serialNumber: "",
                loading: true,
                projectId: value,
                transmitters: []
              },
              () => {
                transmitter({projectId: value, page: 0, size: 100000}).then(
                  (result) => {
                    const transmitters = result.data.content.reduce(
                      (released, t, i) => {
                        if (t.status === "DEPLOYED") {
                          released.push({
                            key: i,
                            text: t.serialNumber,
                            value: t.tagId,
                            data: {
                              transmitterId: t.tagId,
                              releaseId: t.releaseId
                            }
                          });
                        }
                        return released;
                      },
                      []
                    );
                    this.setState((s) => {
                      const releaseId =
                        s.recovery.transmitterId >= 0
                          ? transmitters.find(
                              (t) => t.value === s.recovery.transmitterId
                            )?.data?.releaseId
                          : "";
                      return {
                        loading: false,
                        transmitters: transmitters,
                        recovery: {
                          ...s.recovery,
                          animalReleaseId: releaseId
                        }
                      };
                    });
                  }
                );
              }
            );
          }}
        />
        <Form.Dropdown
          fluid
          selection
          search
          required
          error={this.props.error}
          loading={this.state.loading}
          label="Serial Number"
          text={this.state.loading ? this.props.loadingText ?? "" : null}
          options={this.state.transmitters}
          placeholder="Select Tag"
          value={this.state.recovery.transmitterId}
          selectOnBlur={false}
          onChange={(e, {value}) => {
            const t = this.state.transmitters.find((t) => t.value === value);
            transmitterById(t.data.transmitterId).then((tag) => {
              this.setState((s) => {
                return {
                  tag: getSelectedTransmitterDeployment(tag.transmitter),
                  recovery: {
                    ...s.recovery,
                    transmitterId: t.data.transmitterId,
                    animalReleaseId: t.data.releaseId
                  }
                };
              });
            });
          }}
          disabled={this.state.projectId < 0}
          data-testid="transmitter-dropdown"
          noResultsMessage="No Tags Available"
        />
        <Form.Field
          control={Location}
          label="Location"
          warn={false}
          disabled={!transmitterSelected}
          value={{
            longitude: this.state.recovery.longitude,
            latitude: this.state.recovery.latitude
          }}
          onChange={(e, value) =>
            this.setState((s) => {
              return {
                recovery: {
                  ...s.recovery,
                  longitude: value.longitude,
                  latitude: value.latitude
                }
              };
            })
          }
        />
        <Form.Field
          control={DateSelector}
          required
          disabled={!transmitterSelected}
          label="Recovery Date and Timezone"
          timezone={this.state.recovery.recoveryTimezone}
          date={this.state.recovery.recoveryDate}
          onChange={(value) => {
            this.setState((s) => {
              return {
                recovery: {
                  ...s.recovery,
                  recoveryDate: value.utc,
                  recoveryTimezone: value.timezone
                }
              };
            });
          }}
        />
        <Form.Dropdown
          fluid
          selection
          search
          required
          disabled={!transmitterSelected}
          label="Status"
          options={this.state.statusTypes}
          placeholder="Select Status"
          value={this.state.recovery.status}
          onChange={(e, {value}) => {
            this.setState((s) => {
              return {recovery: {...s.recovery, status: value}};
            });
          }}
          data-testid="transmitter-status"
          noResultsMessage="No Tags Available"
        />
        <Form.TextArea
          disabled={!transmitterSelected}
          label="Comments"
          onBlur={(e) => {
            const value = e.target.value;
            this.setState((s) => {
              return {recovery: {...s.recovery, comments: value}};
            });
          }}
        />
      </>
    );

    const formInputs = (
      <>
        {tagDetails}
        {tag ? <Segment>{recoveryInputs}</Segment> : <>{recoveryInputs}</>}
      </>
    );

    return (
      <FormModal
        modalSize="fullscreen"
        checkFormValid={this.checkFormValid}
        formInputs={formInputs}
        formData={this.state.recovery}
        formSubmitAPI={tagRecover}
        onSuccess={this.successAction}
        submitContent="Submit Transmitter Recovery"
        successContent="Transmitter Recovered"
        headerIcon="tag"
        headerContent="Recover Transmitter"
        onClose={this.props.onClose}
        open
      />
    );
  }
}

TransmitterRecoveryCreate.propTypes = {
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  transmitterId: PropTypes.string
};

function mapStateToProps(state) {
  const {user} = state;

  return {
    roles: user.roles
  };
}

export default connect(mapStateToProps)(TransmitterRecoveryCreate);
