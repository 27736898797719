import React from "react";
import {connect} from "react-redux";
import FileDownload from "js-file-download";
import {
  installation,
  installationCsv,
  installationById,
  installationListConfig
} from "../axios/api";
import axios from "axios";
import FilteredTable from "./FilteredTable";
import FilterBoundingBox from "./FilterBoundingBox";
import Installation from "./Installation";
import FilterDropDown from "./FilterDropDown";
import {
  toDisplayUtc,
  csvAndZipTimestamp,
  isProjectMember,
  isAdmin,
  isAdminNotATFAdmin
} from "./helpers";
import InstallationDelete from "./InstallationDelete";
import RequestDelete from "./RequestDelete";

class InstallationTable extends React.Component {
  tableColumns = [
    {
      title: "Installation Name",
      dataSource: "name",
      width: "15%",
      isAPIFilter: true,
      filter: () => "id",
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "installation",
      default: "",
      placeholder: "Filter Installation Name"
    },
    {
      title: "Configuration",
      width: "10%",
      dataSource: "configurationType",
      default: "",
      isAPIFilter: true,
      filter: () => "configurationType",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "installation", by: "configuration"}, // string
            {field: "organisation", by: "configuration"}, // Array
            {field: "project", by: "configuration"} // array
          ]
        }
      },
      filterOptionsKey: "configuration",
      placeholder: "Filter Configuration"
    },
    {
      title: "Project Name",
      dataSource: "projectName",
      width: "20%",
      default: "",
      isAPIFilter: true,
      filter: () => "projectIdList",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "installation", by: "projectId"},
            {field: "organisation", by: "projectIds"}
          ]
        }
      },
      filterOptionsKey: "project",
      placeholder: "Filter Project Name"
    },
    {
      title: "Organisation",
      width: "20%",
      dataSource: "organisationName",
      default: "",
      isAPIFilter: true,
      filter: () => "organisationId",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "installation", by: "organisationIds"},
            {field: "project", by: "organisationIds"}
          ]
        }
      },
      filterOptionsKey: "organisation",
      placeholder: "Filter Organisation"
    },
    {
      title: "No. Stations",
      width: "5%",
      dataSource: "stationCount",
      default: "0"
    },
    {
      title: "Station Location Bounding Box (N,S,W,E) - WGS84 datum",
      dataSource: "stationLocation",
      icon: "",
      default: "",
      width: "10%",
      isAPIFilter: true,
      filter: () => "stationBbox",
      component: {
        control: FilterBoundingBox
      }
    },
    {
      title: "First Deployment Date (UTC)",
      dataSource: "firstDeploymentDate",
      format: (d) => {
        return toDisplayUtc(d.timestamp, d.timezone);
      }
    },
    {
      title: "Latest Receiver Recovery Date (UTC)",
      dataSource: "latestRecoveryDate",
      format: (d) => {
        return toDisplayUtc(d.timestamp, d.timezone);
      }
    },
    {
      title: "",
      dataSource: "rowActions",
      width: "5%"
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      focusQuery: -1,
      tableFilters: null,
      editInstallation: null,
      refreshFilterOptions: false,
      downloadDataEnabled: true
    };
    this.getInstallation = this.getInstallation.bind(this);
    this.editInstallation = this.editInstallation.bind(this);
    this.deleteInstallation = this.deleteInstallation.bind(this);
    this.requestDeleteInstallation = this.requestDeleteInstallation.bind(this);
    this.getInstallations = this.getInstallations.bind(this);
  }

  componentWillUnmount() {
    if (this.installationCt) this.installationCt.cancel();
  }

  getInstallations(pageSize, page, filter, projectIds) {
    if (this.installationCt) this.installationCt.cancel();
    this.installationCt = axios.CancelToken.source();
    return installation(
      {size: pageSize, page: page, ...filter},
      this.installationCt.token
    ).then((result) => {
      this.setState({downloadDataEnabled: result.data.totalElements >= 1});

      this.installationCt = null;
      const content = result.data.content.map((e) => {
        const len = e.project.organisations.length;
        let orgs = e.project.organisations.map((ie, idx) => {
          return `${ie.name}${idx < len - 1 ? ", " : ""}`;
        });
        console.log(e);
        return {
          id: e.id,
          name: e.name,
          configurationType: e.configurationType,
          organisationName: orgs,
          projectName: e.project.name,
          projectId: e.project.id,
          stationCount: e.stations?.length > 0 ? e.stations.length : 0,
          stationLocation: e.boundingBox
            ? `${e.boundingBox.north}, ${e.boundingBox.south}, ${e.boundingBox.west}, ${e.boundingBox.east}`
            : `N/A`,
          firstDeploymentDate: e.firstDeploymentDate,
          latestRecoveryDate: e.latestRecoveryDate,
          version: e.version,
          edit:
            isAdmin(this.props.roles) ||
            isProjectMember(projectIds, e.project.id),
          delete: isAdminNotATFAdmin(this.props.roles),
          requestDelete:
            !isAdminNotATFAdmin(this.props.roles) &&
            isProjectMember(projectIds, e.project.id) &&
            (e.stations?.length === 0 ||
              e.stations?.length === undefined ||
              e.stations === null)
        };
      });
      result.data.content = content;
      return result.data;
    });
  }

  getInstallation(id) {
    return installationById(id).then((res) => {
      let orgs = res.installation.project.organisations.map((ie, ii) => {
        return ie.name;
      });
      const projectIds = this.props.projects
        ? this.props.projects.map((p) => {
            return p.id;
          })
        : [];
      return {
        header: "",
        data: [],
        row: {
          id: res.installation.id,
          name: res.installation.name,
          configurationType: res.installation.configurationType,
          organisationName: orgs,
          projectName: res.installation.project.name,
          projectId: res.installation.project.id,
          stationCount: res.installation.stations.length,
          stationLocation: res.installation.boundingBox
            ? `${res.installation.boundingBox.north}, ${res.installation.boundingBox.south}, ${res.installation.boundingBox.west}, ${res.installation.boundingBox.east}`
            : `N/A`,
          firstDeploymentDate: res.installation.firstDeploymentDate,
          latestRecoveryDate: res.installation.latestRecoveryDate,
          version: res.installation.version,
          edit:
            isAdmin(this.props.roles) ||
            isProjectMember(projectIds, res.installation.project.id),
          delete: isAdminNotATFAdmin(this.props.roles),
          requestDelete:
            !isAdminNotATFAdmin(this.props.roles) &&
            isProjectMember(projectIds, res.installation.project.id) &&
            res.installation.stations?.length === 0
        }
      };
    });
  }

  editInstallation(item) {
    this.setState({editInstallation: item});
  }

  deleteInstallation(item) {
    this.setState({deleteInstallation: item});
  }

  requestDeleteInstallation(item) {
    this.setState({requestDeleteInstallation: item});
  }

  getTableActions() {
    return [
      {
        name: "Create Installation",
        onClick: () => this.setState({createInstallation: true})
      },
      {
        name: "Download Data",
        disableOnClick: true,
        disabledNoData: !this.state.downloadDataEnabled,
        onClick: () => {
          return installationCsv(this.state.tableFilters).then((response) => {
            FileDownload(
              response.data,
              `IMOS_receiver_installations_${csvAndZipTimestamp()}.csv`
            );
          });
        }
      }
    ];
  }

  render() {
    const {
      getInstallations,
      getInstallation,
      editInstallation,
      deleteInstallation,
      requestDeleteInstallation,
      tableColumns
    } = this;
    const {focusQuery, refreshFilterOptions} = this.state;

    let modal = null;

    if (this.state.editInstallation) {
      modal = (
        <Installation
          installation={this.state.editInstallation}
          open={true}
          onClose={(data) => {
            this.setState(
              {
                editInstallation: false
              },
              () => {
                if (data.success) {
                  this.setState(
                    {
                      focusQuery: {id: data.installation.id},
                      editInstallation: false,
                      refreshFilterOptions:
                        data.installation.name !==
                        data.originalInstallation.name
                    },
                    () => this.setState({refreshFilterOptions: false})
                  );
                }
              }
            );
          }}
        />
      );
    }

    if (this.state.createInstallation) {
      modal = (
        <Installation
          open={true}
          onClose={(data) => {
            this.setState(
              {
                createInstallation: false
              },
              () => {
                if (data.success) {
                  this.setState(
                    {
                      focusQuery: {id: data.installation.id},
                      refreshFilterOptions: true
                    },
                    () => {
                      this.setState({
                        refreshFilterOptions: false
                      });
                    }
                  );
                }
              }
            );
          }}
        />
      );
    }

    if (this.state.deleteInstallation) {
      modal = (
        <InstallationDelete
          installation={this.state.deleteInstallation}
          open
          onClose={() => {
            this.setState({deleteInstallation: false});
          }}
          onUpdate={() => {
            this.setState({
              focusQuery: {
                id: this.state.deleteInstallation.id,
                eventType: "delete"
              }
            });
          }}
        />
      );
    }

    if (this.state.requestDeleteInstallation) {
      modal = (
        <RequestDelete
          entity="installation"
          name={
            "Project: " +
            this.state.requestDeleteInstallation.projectName +
            ", Installation: " +
            this.state.requestDeleteInstallation.name
          }
          id={this.state.requestDeleteInstallation.id}
          onClose={() => {
            this.setState({
              showModal: "none",
              requestDeleteInstallation: false
            });
          }}
          onUpdate={() => {
            this.setState({
              focusQuery: null,
              requestDeleteInstallation: false
            });
          }}
        />
      );
    }

    return (
      <>
        <FilteredTable
          disableDetailView
          fetchingData={true}
          header="Installations"
          headerIcon="microphone"
          columns={tableColumns}
          detailFetcher={getInstallation}
          filterOptionsFetcher={installationListConfig}
          fetcher={(pageSize, page, apiFilters) => {
            this.setState({tableFilters: {...apiFilters}});
            const projectIds = this.props.projects
              ? this.props.projects.map((p) => {
                  return p.id;
                })
              : [];
            return getInstallations(pageSize, page, apiFilters, projectIds);
          }}
          pageSize={50}
          icon="microphone"
          tableActions={this.getTableActions()}
          focus={focusQuery}
          editRow={editInstallation}
          deleteRow={deleteInstallation}
          requestDeleteRow={requestDeleteInstallation}
          refreshFilterOptions={refreshFilterOptions}
        />
        {modal}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(InstallationTable);
