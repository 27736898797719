import React from "react";
import {connect} from "react-redux";
import FormModal from "./FormModal";
import {
  organisationSummary,
  projectRegister,
  allActiveUsers
} from "../axios/api";
import {
  Roles,
  formatOrganisationDescription,
  ProtectedStatusFormUrl,
  DataPolicyUrl
} from "./helpers";
import {Checkbox, Form, Label, Segment} from "semantic-ui-react";
import SelectList from "./SelectList";

class ProjectCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberOptions: [],
      leaderOptions: [],
      organisationOptions: [],
      loading: true,
      success: false,
      leader: {text: "", value: null},
      defaultMembers: [],
      successContent: null,
      projectsCreated: [],
      projectDetails: {
        isPositionSystem: false,
        name: "",
        organisations: [],
        projectRoles: [],
        version: 0
      }
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleOrganisationChange = this.handleOrganisationChange.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.handleProjectLeaderChange = this.handleProjectLeaderChange.bind(this);
    this.handleIsPositionSystemChange = this.handleIsPositionSystemChange.bind(
      this
    );
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const isAdmin =
      this.props.roles.includes("ROLE_ADMIN") ||
      this.props.roles.includes("ROLE_ATF_ADMIN");
    organisationSummary(isAdmin, false).then((response) => {
      const organisationOptions = response.data.map((o) => ({
        text: o.name,
        value: o.id,
        description: formatOrganisationDescription(o.suburb, o.country)
      }));
      allActiveUsers().then((response) => {
        const allUsers = response.data.map((u) => ({
          text: u.name,
          value: u.id
        }));
        const filteredMemberOptions = allUsers.filter((o) => {
          return o.value !== this.props.userId;
        });
        this.setState({
          loading: false,
          memberOptions: filteredMemberOptions,
          organisationOptions: organisationOptions,
          leader: {text: this.props.username, value: this.props.userId},
          leaderOptions: allUsers,
          projectsCreated: [],
          projectDetails: {
            ...this.state.projectDetails,
            name: "",
            organisations: [this.props.userOrganisation],
            projectRoles: [
              {roleType: Roles.PROJECT_LEADER, userId: this.props.userId}
            ]
          }
        });
      });
    });
  }

  checkFormValid() {
    const {projectDetails} = this.state;
    return (
      projectDetails.organisations.length < 1 ||
      projectDetails.name.length < 3 ||
      projectDetails.name.length > 200
    );
  }

  successAction(project) {
    const successContent = (
      <>
        The <strong>{project.name}</strong> project has been created. It's
        current status is
        <Label color="yellow"> PENDING APPROVAL </Label>. The listed project
        members and leader will not be able to enter metadata until the project
        is approved by administration. You will receive a confirmation email
        when a decision is made.
      </>
    );

    this.setState({
      successContent: successContent,
      success: true,
      projectDetails: {
        ...this.state.projectDetails,
        id: project.id,
        status: project.status
      },
      projectsCreated: [...this.state.projectsCreated, project.id]
    });

    if (this.props.onUpdate) this.props.onUpdate(project);
  }

  onClose() {
    if (typeof this.props.onClose !== "undefined")
      this.props.onClose(this.state);
  }

  handleNameChange(e) {
    let newValue = e.target.value;
    this.setState(() => ({
      projectDetails: {
        ...this.state.projectDetails,
        name: newValue
      }
    }));
  }

  handleOrganisationChange(value) {
    this.setState({
      projectDetails: {
        ...this.state.projectDetails,
        organisations: value
      }
    });
  }

  handleMemberChange(value) {
    let projectRoles = value.map((item) => {
      return {roleType: Roles.PROJECT_MEMBER, userId: item};
    });
    projectRoles.push(
      this.state.projectDetails.projectRoles.find(
        (i) => i.roleType === Roles.PROJECT_LEADER
      )
    );

    this.setState({
      projectDetails: {
        ...this.state.projectDetails,
        projectRoles: projectRoles
      }
    });
  }

  handleIsPositionSystemChange(e, {checked}) {
    this.setState({
      projectDetails: {
        ...this.state.projectDetails,
        isPositionSystem: checked
      }
    });
  }

  handleProjectLeaderChange(e, {value}) {
    // Update memberOptions
    // remove current
    let current = this.state.memberOptions.find((item) => item.value === value);
    let newMemberOptions = this.state.memberOptions.filter((o) => {
      return o.value !== current.value;
    });
    // add previous
    let prevLeader = this.state.leader;
    let defaultMembers = [];
    if (prevLeader.value === this.props.userId) {
      defaultMembers = [...defaultMembers, prevLeader.value];
    }
    newMemberOptions.push(prevLeader);

    // Change projectRoles
    let newProjectRoles = this.state.projectDetails.projectRoles.filter((o) => {
      return o.userId !== this.state.leader.value && o.userId !== value;
    });
    newProjectRoles.push({roleType: Roles.PROJECT_LEADER, userId: value});

    this.setState({
      leader: current,
      memberOptions: newMemberOptions,
      defaultMembers: defaultMembers,
      projectDetails: {
        ...this.state.projectDetails,
        projectRoles: newProjectRoles
      }
    });
  }

  render() {
    const {userOrganisation, trigger, open} = this.props;
    const {
      projectDetails,
      organisationOptions,
      memberOptions,
      leader,
      leaderOptions,
      defaultMembers,
      successContent,
      loading
    } = this.state;
    const {
      checkFormValid,
      success,
      handleNameChange,
      handleOrganisationChange,
      handleMemberChange,
      handleProjectLeaderChange,
      handleIsPositionSystemChange,
      successAction,
      onClose
    } = this;

    const formInputs = (
      <>
        <Form.Input
          fluid
          required
          autoComplete="none"
          label="Project Name"
          placeholder="Project name"
          onChange={handleNameChange}
          value={projectDetails.name}
          data-testid="name"
        />
        <Form.Dropdown
          placeholder="Project leader"
          label="Project Leader"
          required
          search
          selection
          options={leaderOptions}
          onChange={handleProjectLeaderChange}
          value={leader.value}
        />
        <SelectList
          data-testid="organisations"
          label="Organisations"
          options={organisationOptions}
          value={projectDetails.organisations}
          onChange={handleOrganisationChange}
          defaultValue={[userOrganisation]}
          required
        />
        <SelectList
          data-testid="members"
          label="Project Members"
          options={memberOptions}
          value={projectDetails.projectRoles}
          onChange={handleMemberChange}
          excludeItem={leader}
          defaultValue={defaultMembers}
          requireDefault
        />
        <Checkbox
          label="Does this project include an underwater acoustic fine-scale positioning system? For example, your project
         is a VEMCO Position System (VPS) project."
          checked={projectDetails.isPositionSystem}
          onChange={handleIsPositionSystemChange}
          toggle
        />
        <Segment>
          <p>
            <a
              href={DataPolicyUrl}
              target={"_blank"}
              download
              rel="noopener noreferrer"
            >
              Data policy document
            </a>
          </p>
          <p>
            <a href={ProtectedStatusFormUrl} download>
              Application form for protected data status
            </a>
          </p>
        </Segment>
      </>
    );

    return (
      <FormModal
        open={open ?? true}
        formSubmitAPI={projectRegister}
        formData={projectDetails}
        checkFormValid={checkFormValid}
        testId="project"
        successContent={successContent}
        successHeader="Project creation status confirmation"
        success={success}
        onSuccess={successAction}
        formInputs={formInputs}
        headerContent="Create Project"
        headerIcon="tasks"
        submitContent={"Create Project"}
        trigger={trigger}
        onClose={onClose}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    roles: user.roles,
    userOrganisation: user.organisationId,
    userId: user.userId,
    username: user.username
  };
}

export default connect(mapStateToProps)(ProjectCreate);
