import {USER_DETAIL, USER_LOGOUT} from "../constants/action-types";

const initialState = {
  accessToken: null,
  username: null,
  projects: [],
  roles: [],
  tokenType: null,
  organisationId: null,
  userId: null,
  name: null,
  tokenExp: 0
};

function userReducer(state = initialState, action) {
  if (action.type === USER_DETAIL) {
    return {...state, ...action.payload};
  }

  if (action.type === USER_LOGOUT) {
    return {...state, ...initialState};
  }

  return state;
}

export default userReducer;
