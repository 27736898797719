import React from "react";
import {connect} from "react-redux";
import FormModal from "./FormModal";
import {projectUpdate, allActiveUsers, organisationSummary} from "../axios/api";
import {
  DataPolicyUrl,
  formatOrganisationDescription,
  ProtectedStatusFormUrl,
  Roles
} from "./helpers";
import {Checkbox, Form, Label, Segment} from "semantic-ui-react";
import SelectList from "./SelectList";
import ActionsMenu from "./ActionsMenu";
import OrganisationList from "./OrganisationList";

class ProjectEdit extends React.Component {
  constructor(props) {
    super(props);

    let leader = props.project.projectRoles.find(
      (role) => role.roleType === "PROJECT_LEADER"
    );
    leader = leader
      ? {
          text: leader.user.name,
          value: leader.user.id
        }
      : {text: "", value: ""};
    const projectRoles = props.project.projectRoles.map((r) => {
      return {
        access: r.access,
        userId: r.user.id,
        roleType: r.roleType
      };
    });
    const members = props.project.projectRoles
      .filter((r) => r.roleType !== Roles.PROJECT_LEADER)
      .map((r) => {
        return {
          value: r.user.id,
          strikeThrough: r.access === "REVOKED" ? true : false
        };
      });
    const organisationIds = props.project.organisations.map((o) => {
      return o.id;
    });

    this.state = {
      memberOptions: [],
      leaderOptions: [leader],
      organisationOptions: [],
      loading: true,
      leader: leader,
      members: members,
      organisations: props.project.organisations,
      projectDetails: {
        id: props.project.id,
        version: props.project.version,
        name: props.project.name,
        isPositionSystem: props.project.isPositionSystem,
        protected: props.project.protected,
        isProtected: props.project.isProtected,
        projectRoles: projectRoles,
        status: props.project.status,
        organisations: organisationIds
      }
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.handleProjectLeaderChange = this.handleProjectLeaderChange.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
    this.onClose = this.onClose.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.toggleProtected = this.toggleProtected.bind(this);
    this.handleOrganisationChange = this.handleOrganisationChange.bind(this);
    this.togglePositionSystem = this.togglePositionSystem.bind(this);
  }

  componentDidMount() {
    const isAdmin =
      this.props.roles.includes("ROLE_ADMIN") ||
      this.props.roles.includes("ROLE_ATF_ADMIN");
    organisationSummary(isAdmin, false).then((response) => {
      const organisationOptions = response.data.map((o) => ({
        text: o.name,
        value: o.id,
        description: formatOrganisationDescription(o.suburb, o.country)
      }));
      allActiveUsers().then((result) => {
        const allUsers = [];
        for (const user of result.data) {
          allUsers.push({text: user.name, value: user.id});
        }
        const filteredMemberOptions = allUsers.filter((o) => {
          return o.value !== this.state.leader.value;
        });
        this.setState({
          loading: false,
          memberOptions: filteredMemberOptions,
          leaderOptions: allUsers,
          organisationOptions: organisationOptions
        });
      });
    });
  }

  checkFormValid() {
    return false;
  }

  successAction(project) {
    this.setState({
      success: true,
      projectDetails: {
        ...this.state.projectDetails,
        version: project.version
      }
    });
    this.props.onUpdate(project);
  }

  onClose() {
    if (typeof this.props.onClose !== "undefined")
      this.props.onClose(this.state);
  }

  handleNameChange(e) {
    let newValue = e.target.value;
    console.log('handleNameChange: ' + newValue);

    this.setState({
      projectDetails: {
        ...this.state.projectDetails,
        name: newValue
      }
    });
  }

  handleMemberChange(value) {
    const projectRoles = value.map((item) => {
      return {
        access: item.strikeThrough ? "REVOKED" : "NOT_REVOKED",
        roleType: Roles.PROJECT_MEMBER,
        userId: item.value
      };
    });
    projectRoles.push(
      this.state.projectDetails.projectRoles.find(
        (i) => i.roleType === Roles.PROJECT_LEADER
      )
    );

    this.setState({
      projectDetails: {
        ...this.state.projectDetails,
        projectRoles: projectRoles
      }
    });
  }

  handleOrganisationChange(value) {
    this.setState({
      projectDetails: {
        ...this.state.projectDetails,
        organisations: value
      }
    });
  }

  handleProjectLeaderChange(e, {value}) {
    // remove new leader from member options
    let current = this.state.memberOptions.find((item) => item.value === value);
    let newMemberOptions = this.state.memberOptions.filter((o) => {
      return o.value !== current.value;
    });

    // Remove new leader from members
    let members = this.state.members.filter((m) => m.value !== value);

    // add previous leader to member options
    newMemberOptions.push(this.state.leader);

    // Change the leader in project roles
    let newProjectRoles = this.state.projectDetails.projectRoles.filter((r) => {
      return r.roleType !== Roles.PROJECT_LEADER && r.userId !== value;
    });
    newProjectRoles.push({
      access: "NOT_REVOKED",
      userId: value,
      roleType: Roles.PROJECT_LEADER
    });

    // add previous leader to members
    members.push({value: this.state.leader.value, strikeThrough: false});
    newProjectRoles.push({
      access: "NOT_REVOKED",
      userId: this.state.leader.value,
      roleType: Roles.PROJECT_MEMBER
    });

    // add current user to members if not the leader
    if (
      value !== this.props.userId &&
      !members.some((m) => m.value === this.props.userId)
    ) {
      members.push({value: this.props.userId, strikeThrough: false});
      newProjectRoles.push({
        access: "NOT_REVOKED",
        userId: this.props.userId,
        roleType: Roles.PROJECT_MEMBER
      });
    }

    this.setState({
      leader: current,
      memberOptions: newMemberOptions,
      members: members,
      projectDetails: {
        ...this.state.projectDetails,
        projectRoles: newProjectRoles
      }
    });
  }

  togglePositionSystem() {
    this.setState({
      projectDetails: {
        ...this.state.projectDetails,
        isPositionSystem: !this.state.projectDetails.isPositionSystem
      }
    });
  }

  toggleStatus() {
    const newProjectDetails = {...this.state.projectDetails};
    this.setState(
      {
        loading: true,
        projectDetails: {
          ...newProjectDetails,
          status:
            this.state.projectDetails.status === "ACTIVE"
              ? "DEACTIVATED"
              : "ACTIVE"
        }
      },
      () =>
        projectUpdate(this.state.projectDetails).then((result) => {
          const newProjectDetails = {...this.state.projectDetails};
          this.setState(
            {
              loading: false,
              projectDetails: {
                ...newProjectDetails,
                version: result.data.version
              }
            },
            () => {
              this.props.onUpdate(result.data);
            }
          );
        })
    );
  }

  toggleProtected() {
    const newProjectDetails = {...this.state.projectDetails};
    this.setState(
      {
        loading: true,
        projectDetails: {
          ...newProjectDetails,
          protected: !newProjectDetails.protected,
          isProtected: !newProjectDetails.isProtected
        }
      },
      () =>
        projectUpdate(this.state.projectDetails).then((result) => {
          const newProjectDetails = {...this.state.projectDetails};
          this.setState(
            {
              loading: false,
              projectDetails: {
                ...newProjectDetails,
                version: result.data.version
              }
            },
            () => {
              this.props.onUpdate(result.data);
            }
          );
        })
    );
  }

  render() {
    const {trigger, roles} = this.props;
    const {
      projectDetails,
      organisations,
      memberOptions,
      leader,
      leaderOptions,
      members,
      loading,
      organisationOptions
    } = this.state;
    const {
      checkFormValid,
      success,
      toggleStatus,
      handleMemberChange,
      handleProjectLeaderChange,
      successAction,
      onClose,
      toggleProtected,
      handleOrganisationChange,
      togglePositionSystem,
      handleNameChange
    } = this;

    const nameInput = roles.includes(Roles.ROLE_ADMIN) ? (
        <Form.Input
            fluid
            readonly
            label="Project Name"
            value={projectDetails.name}
            onChange={handleNameChange}
        />
    ) : (
        <Form.Input
            fluid
            readonly
            transparent
            label="Project Name"
            value={projectDetails.name}
        />
    );

    const leaderInput = roles.includes(Roles.ROLE_ADMIN) ? (
      <Form.Dropdown
        placeholder="Project leader"
        label="Project Leader"
        required
        search
        selection
        options={leaderOptions}
        onChange={handleProjectLeaderChange}
        value={leader.value}
      />
    ) : (
      <Form.Input
        fluid
        readOnly
        transparent
        label="Project Leader"
        value={leader.text}
      />
    );

    let status = null;
    if (projectDetails.status === "ACTIVE") {
      status = (
        <>
          <Label color="green" attached="top" content="PROJECT IS ACTIVE" />
          <br />
          <br />
        </>
      );
    } else if (projectDetails.status === "DEACTIVATED") {
      status = (
        <>
          <Label color="red" attached="top" content="PROJECT IS DEACTIVATED" />
          <br />
          <br />
        </>
      );
    } else if (projectDetails.status === "PENDING") {
      status = (
        <>
          <Label
            color="yellow"
            attached="top"
            content="PROJECT ACTIVATION IS PENDING"
          />
          <br />
          <br />
        </>
      );
    }

    const action =
      projectDetails.status === "ACTIVE" ? "Deactivate" : "Activate";
    const menuItems = [];
    if (roles.includes(Roles.ROLE_ADMIN)) {
      menuItems.push({
        name: `${action} Project`,
        onClick: toggleStatus,
        confirm: {
          header: `${action} "${projectDetails.name}"?`,
          content: `This change will apply immediately.`,
          button: `${action} "${projectDetails.name}"`
        }
      });
      if (!projectDetails.protected) {
        menuItems.push({
          name: "Protect Project",
          onClick: toggleProtected,
          confirm: {
            header: `Protect "${projectDetails.name}"?`,
            content: `This change will apply immediately.`,
            button: `Protect "${projectDetails.name}"`
          }
        });
      }
    }

    const organisation =
      roles.includes(Roles.ROLE_ADMIN) ||
      roles.includes(Roles.ROLE_ATF_ADMIN) ||
      this.state.projectDetails.projectRoles.find((r) => {
        return r.userId === this.props.userId && r.access === "NOT_REVOKED";
      }) ? (
        <SelectList
          data-testid="organisations"
          label="Organisations"
          options={organisationOptions}
          value={projectDetails.organisations}
          onChange={handleOrganisationChange}
          defaultValue={projectDetails.organisations}
          required
        />
      ) : (
        <OrganisationList header="Organisations" data={organisations} />
      );

    const actions =
      menuItems.length > 0 ? <ActionsMenu menuItems={menuItems} /> : null;
    const formInputs = (
      <>
        {status}
        {nameInput}
        {leaderInput}
        {organisation}
        <SelectList
          data-testid="members"
          label="Project Members"
          options={memberOptions}
          onChange={handleMemberChange}
          excludeItem={leader}
          defaultValue={members}
          requireDefault
          allowDefaultStrikeThrough
          strikeThroughLabel="REVOKED"
          strikeThroughButtonVisible={
            this.props.roles?.includes(Roles.ROLE_ATF_ADMIN) ||
            this.props.roles?.includes(Roles.ROLE_ADMIN) ||
            leader.value === this.props.userId
          }
        />
        <Checkbox
          label="Does this project include an underwater acoustic fine-scale positioning system? For example, your project
         is a VEMCO Position System (VPS) project."
          checked={projectDetails.isPositionSystem}
          onChange={togglePositionSystem}
          toggle
        />
        <Segment>
          <p>
            <strong>
              Is this a sensitive project for which you require data to be
              protected?
            </strong>
          </p>
          <p>
            <a
              href={DataPolicyUrl}
              target={"_blank"}
              download
              rel="noopener noreferrer"
            >
              Data policy document
            </a>
          </p>
          <p>
            <a href={ProtectedStatusFormUrl} download>
              Application form for protected data status
            </a>
          </p>
        </Segment>
        {projectDetails.protected ? (
          <p>
            <strong>This project is protected.</strong>
          </p>
        ) : null}
        {actions}
      </>
    );

    return (
      <FormModal
        open
        formSubmitAPI={projectUpdate}
        formData={projectDetails}
        checkFormValid={checkFormValid}
        successContent="Project changes saved"
        successHeader="Project registration status confirmation"
        success={success}
        onSuccess={successAction}
        formInputs={formInputs}
        headerContent="Edit Project"
        headerIcon="tasks"
        submitContent="Save Changes"
        trigger={trigger}
        onClose={onClose}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    roles: user.roles,
    userOrganisation: user.organisationId,
    userId: user.userId,
    username: user.username,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(ProjectEdit);
