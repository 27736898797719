import React from "react";
import {
  Grid,
  GridRow,
  Header as SUIHeader,
  Image,
  Message
} from "semantic-ui-react";
import UserLoginState from "./UserLoginState";
import {Link} from "react-router-dom";
import {updateReturnToPage} from "../actions";
import {connect} from "react-redux";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.setReturnToPage({
      returnToPage: "/"
    });
  }

  render() {
    const {title, logo, messageOfTheDay} = this.props;

    return (
      <Grid verticalAlign="middle" padded>
        {messageOfTheDay ? (
          <GridRow>
            <Grid.Column columns={1}>
              <Message color="orange" content={messageOfTheDay} />
            </Grid.Column>
          </GridRow>
        ) : null}
        <GridRow columns={3}>
          <Grid.Column>
            <Image
              size="small"
              src={logo}
              as={Link}
              to="/"
              onClick={this.onClick}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <SUIHeader
              content={title}
              as={Link}
              to="/"
              className="at-header-font"
              onClick={this.onClick}
            />
          </Grid.Column>
          <Grid.Column floated="right">
            <UserLoginState />
          </Grid.Column>
        </GridRow>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setReturnToPage: (route) => dispatch(updateReturnToPage(route.returnToPage))
  };
}

export default connect(null, mapDispatchToProps)(Header);
