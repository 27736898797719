import React from "react";
import FormModal from "./FormModal";
import {changePassword, me, meUpdate} from "../axios/api";
import Password from "./Password";
import Alert from "./Alert";
import {passwordValidate} from "./helpers";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      oldPassword: "",
      passwordNotValid: false,
      success: false,
      signIn: false,
      ...this.getParams(this.props.location)
    };
    this.validPassword = this.validPassword.bind(this);
    this.validCurrentPassword = this.validCurrentPassword.bind(this);
    this.successAction = this.successAction.bind(this);
    this.loginAction = this.loginAction.bind(this);
  }

  componentDidMount() {
    if (this.props.accessToken) {
      me().then((res) => {
        this.setState({me: res.data});
      });
    }
  }

  getParams(location) {
    const searchParams = new URLSearchParams(location.search);
    return {
      token: searchParams.get("token") ?? "",
      username: searchParams.get("username") ?? ""
    };
  }

  validPassword(password, valid) {
    this.setState({
      password: password,
      passwordNotValid: !valid
    });
  }

  validCurrentPassword(oldPassword) {
    this.setState({
      oldPassword: oldPassword
    });
  }

  successAction(responseData) {
    this.setState({success: true});
  }

  loginAction() {
    this.setState({signIn: true});
  }

  render() {
    const {
      password,
      oldPassword,
      token,
      username,
      success,
      signIn,
      passwordNotValid
    } = this.state;
    const {
      validPassword,
      validCurrentPassword,
      successAction,
      loginAction
    } = this;

    const formInputs = (
      <div>
        <p>
          Create a new password for <b>{username}</b>
        </p>
        <Password
          createPassword
          passwordRegex={passwordValidate.regex}
          passwordRegexMessage={passwordValidate.regexMessage}
          onValidate={validPassword}
        />
      </div>
    );

    const formInputsChange = (
      <div>
        <p>
          Change password for <b>{username}</b>
        </p>
        <Password
          fieldLabel="Current Password"
          onValidate={validCurrentPassword}
        />
        <Password
          fieldLabel="New Password"
          createPassword
          passwordRegex={passwordValidate.regex}
          passwordRegexMessage={passwordValidate.regexMessage}
          onValidate={validPassword}
        />
      </div>
    );

    if (signIn) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: {referrer: this.props.location.pathname}
          }}
        />
      );
    }

    if (success) {
      return (
        <Alert
          icon="user"
          headerContent="Password reset confirmation"
          content="Your password has been reset. Use the new password next time you sign in."
          closeButtonContent="OK"
          closeAction={loginAction}
        />
      );
    }

    return (
      <FormModal
        formSubmitAPI={
          this.props.username && token === "" ? meUpdate : changePassword
        }
        formData={
          this.props.username && token === ""
            ? {
                ...this.state.me,
                oldPassword: oldPassword,
                newPassword: password
              }
            : {password: password, token: token}
        }
        checkFormValid={() => {
          return passwordNotValid;
        }}
        testId="changepassword"
        onSuccess={successAction}
        formInputs={
          this.props.username && token === "" ? formInputsChange : formInputs
        }
        headerContent="Create New Password"
        headerIcon="user"
        submitContent="Save New Password"
        redirectOnClose="/"
        open={true}
        onClose={this.props.onClose}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    username: user.username,
    accessToken: user.accessToken
  };
}

export default connect(mapStateToProps)(PasswordChange);
