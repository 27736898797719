import React from "react";
import axios from "axios";
import {
  Search as SUISearch,
  Pagination,
  PaginationItem,
  Icon
} from "semantic-ui-react";
import {search} from "../axios/api";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import AwesomeDebouncePromise from "awesome-debounce-promise";
const debouncedSearchAPI = AwesomeDebouncePromise(search, 300);

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      results: [],
      value: "",
      activePage: 1,
      last: true,
      totalElements: 0,
      totalPages: 0,
      openResults: false
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.resultRenderer = this.resultRenderer.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
    this.showResults = this.showResults.bind(this);

    this.cancelToken = null;
  }

  handleSearchChange(e, {value}) {
    this.setState(
      {
        value: value,
        loading: true,
        activePage: 1 // Restart the results list
      },
      () => this.getSearchResults()
    );
  }

  getSearchResults() {
    const {pageSize} = this.props;
    const {activePage, value} = this.state;

    if (this.cancelToken) this.cancelToken.cancel("Search cancelled");
    this.cancelToken = axios.CancelToken.source();

    if (value.length > 2) {
      debouncedSearchAPI(
        {size: pageSize, page: activePage - 1, keyword: value},
        this.cancelToken.token
      )
        .then((result) => {
          let results = [];
          if (result.data.content.length > 0) {
            let paddedResults = result.data.content;
            paddedResults.push(
              ...new Array(pageSize - paddedResults.length).fill({
                text: "-",
                value: null
              })
            );
            results = paddedResults.map((r, i) => {
              return {
                key: i,
                title: r.text,
                value: r.value,
                lastitemonpage: (i === paddedResults.length - 1).toString(),
                padding: (!r.value).toString()
              };
            });
          }
          this.setState({
            loading: false,
            results: results,
            activePage: result.data.page + 1,
            last: result.data.last,
            totalElements: result.data.totalElements,
            totalPages: result.data.totalPages,
            openResults: true
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else {
      this.setState({loading: false, results: [], openResults: false});
    }
  }

  handlePaginationChange(e, {activePage}) {
    this.setState(
      {activePage: activePage, loading: true, openResults: true},
      () => this.getSearchResults()
    );
  }

  showResults(show) {
    this.setState({openResults: show && this.state.value.length > 2});
  }

  resultRenderer(item) {
    const {pageSize} = this.props;
    const {
      results,
      activePage,
      totalPages,
      totalElements,
      value,
      last
    } = this.state;
    const {handlePaginationChange} = this;

    const lastItemOnPage = last ? totalElements : pageSize * activePage;
    const entry = item.value ? (
      <Link to={item.value}>{item.title}</Link>
    ) : (
      item.title
    );
    const result =
      item.id === 0 ? (
        <>
          <div className="at-search-results-heading">
            <strong>
              Showing&nbsp;{pageSize * (activePage - 1) + 1}&nbsp;-&nbsp;
              {lastItemOnPage}&nbsp;of&nbsp;
              {totalElements}&nbsp;results&nbsp;for&nbsp;"{value}"
            </strong>
          </div>
          <p>{entry}</p>
        </>
      ) : results.length - 1 === item.id ? (
        <>
          {entry}
          <div className="at-search-results-footer">
            <Pagination
              activePage={activePage}
              totalPages={totalPages}
              size="mini"
              onPageChange={handlePaginationChange}
              ellipsisItem={
                <PaginationItem
                  disabled
                  type="ellipsisItem"
                  content={<Icon name="ellipsis horizontal" />}
                />
              }
            />
          </div>
        </>
      ) : (
        <>{entry}</>
      );

    return result;
  }

  render() {
    const {loading, value, results, openResults} = this.state;
    const {handleSearchChange, resultRenderer, showResults} = this;

    return (
      <SUISearch
        aligned="right"
        loading={loading}
        onSearchChange={handleSearchChange}
        onResultSelect={(e) => {
          if (
            (!e.target.type || e.target.lastitemonpage === "false") &&
            e.target.type !== undefined
          )
            showResults(false);
        }}
        onFocus={() => showResults(true)}
        onBlur={() => showResults(false)}
        onMouseDown={() => showResults(true)}
        resultRenderer={resultRenderer}
        results={results}
        value={value}
        size="mini"
        noResultsMessage={
          value.length > 0
            ? `No results found for "${value}"`
            : `No results found`
        }
        open={openResults}
      />
    );
  }
}

Search.propTypes = {
  pageSize: PropTypes.number.isRequired
};

export default Search;
