import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import "semantic-ui-less/semantic.less";
import "./semantic-ui/custom.css";
import axios from "axios";
import {store, persistor} from "../src/store/index";
import AnimalTracking from "./components/AnimalTracking";
import ErrorPage from "./components/ErrorPage";
import {siteConfig} from "./axios/api";
import config from "react-global-configuration";
import moment from "moment-timezone";
import MaintenancePage from "./components/MaintenancePage";

let ct = axios.CancelToken.source();
if (window.location.pathname !== "/error") {
  siteConfig(ct.token)
    .then((result) => {
      ct = null;
      const tzNames = moment.tz.names();
      result.data.timezones = result.data.timezones.filter((t) =>
        tzNames.includes(t)
      );
      config.set(result.data);

      ReactDOM.render(
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AnimalTracking />
          </PersistGate>
        </Provider>,
        document.getElementById("root")
      );
    })
    .catch((err) => {
      if (axios.isAxiosError(err)) {
        ReactDOM.render(
          <MaintenancePage />,
          document.getElementById("root")
        );
      }
      if (axios.isCancel(err)) console.debug("siteConfig Cancelled");
    });
} else {
  const params = new URL(document.location).searchParams;
  ReactDOM.render(
    <ErrorPage uuid={params.get("uuid") ? params.get("uuid") : undefined} />,
    document.getElementById("root")
  );
}

window.onbeforeunload = () => {
  if (ct) ct.cancel();
};
