import axios from "axios";
import {properties} from "../properties";
import {store} from "../store";

const getTokenHeader = () => {
  const token = store.getState().user.accessToken;
  const tokenType = store.getState().user.tokenType;
  const header = token && tokenType ? `${tokenType} ${token}` : null;
  return header;
};

const axiosInstance = axios.create({
  baseURL: properties.APIBaseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});

axiosInstance.interceptors.request.use((config) => {
  const header = getTokenHeader();
  if (header) config.headers.Authorization = header;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      401 === error.response?.status &&
      error.config.url !== "/api/auth/signin" &&
      !error.config.params?.noRedirectOn401
    ) {
      if (error.response.headers["animal-tracking-unauthorized"]) {
        window.location = "/notpermitted";
      } else {
        localStorage.clear();
        window.location = `/login?redirect=${window.location.pathname}`;
      }
    } else if (
      404 === error.response?.status &&
      error.config.url !== "/api/auth/resetPassword" &&
      error.data?.errors?.length === 0
    ) {
      window.location = `/notfound?resource=${window.location.pathname}`;
    } else if (403 === error.response?.status) {
      window.location = "/notpermitted";
    } else if (
      500 === error.response?.status &&
      window.location.pathname !== "/error"
    ) {
      window.location = error.response.data["uuid"]
        ? `error?uuid=${error.response.data["uuid"]}`
        : "/error";
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
