import React from "react";
import {connect} from "react-redux";
import {Button, Form, Label} from "semantic-ui-react";
import {Roles} from "./helpers";
import ActionsMenu from "./ActionsMenu";
import FormModal from "./FormModal";
import OrganisationList from "./OrganisationList";
import UserList from "./UserList";
import ProjectEdit from "./ProjectEdit";

class ProjectView extends React.Component {
  constructor(props) {
    super(props);

    const members = props.project.projectRoles.reduce((accum, role) => {
      if (role.roleType === Roles.PROJECT_MEMBER) {
        role.user.access = role.access;
        accum.push(role.user);
      }
      return accum;
    }, []);

    const apiProjectRoles = props.project.projectRoles.map((r) => {
      return {
        access: r.access,
        userId: r.user.id,
        roleType: r.roleType
      };
    });
    const organisations = props.project.organisations;
    const apiOrganisations = organisations.map((o) => {
      return o.id;
    });
    let leader = props.project.projectRoles.find(
      (role) => role.roleType === "PROJECT_LEADER"
    );
    this.state = {
      open: this.props.open,
      leader: leader ? [{
        name: leader.user.name,
        emailAddress: leader.user.emailAddress,
        organisation: leader.user.organisation.name,
        id: leader.user.id
      }] : [],
      members: members,
      organisations: organisations,
      projectDetails: {
        id: props.project.id,
        version: props.project.version,
        name: props.project.name,
        isPositionSystem: props.project.isPositionSystem,
        protected: props.project.protected,
        isProtected: props.project.protected,
        projectRoles: apiProjectRoles,
        status: props.project.status,
        organisations: apiOrganisations
      }
    };
  }

  render() {
    const {trigger, roles, userId, modalOpener} = this.props;
    const {
      projectDetails,
      leader,
      members,
      organisations,
      loading
    } = this.state;

    let status = null;
    if (projectDetails.status === "ACTIVE") {
      status = (
        <>
          <Label color="green" attached="top" content="PROJECT IS ACTIVE" />
          <br />
          <br />
        </>
      );
    } else if (projectDetails.status === "DEACTIVATED") {
      status = (
        <>
          <Label color="red" attached="top" content="PROJECT IS DEACTIVATED" />
          <br />
          <br />
        </>
      );
    } else if (projectDetails.status === "PENDING") {
      status = (
        <>
          <Label
            color="yellow"
            attached="top"
            content="PROJECT ACTIVATION IS PENDING"
          />
          <br />
          <br />
        </>
      );
    }

    const menuItems = [];
    if (
      roles.includes("ROLE_ADMIN") ||
      projectDetails.projectRoles.some(
        (r) => r.userId === userId && r.access !== "REVOKED"
      )
    )
      menuItems.push({
        name: "Edit Project",
        onClick: () => {
          if (this.props.hasContainer) {
            modalOpener(ProjectEdit);
          } else {
            modalOpener("edit", projectDetails.id);
          }
        }
      });
    const actions =
      menuItems.length > 0 ? <ActionsMenu menuItems={menuItems} /> : null;

    const formInputs = (
      <>
        {status}
        <Form.Input
          fluid
          readOnly
          transparent
          label="Project Name"
          value={projectDetails.name}
        />
        <OrganisationList data={organisations} />
        <UserList title="Project Leader" data={leader} showEmail showOrganisation />
        <UserList title="Project Members" data={members} showOrganisation />
        {projectDetails.isPositionSystem ? (
          <p>
            <strong>
              This project includes an underwater acoustic fine-scale
              positioning system.
            </strong>
          </p>
        ) : null}
        {projectDetails.protected ? (
          <p>
            <strong>This project is protected.</strong>
          </p>
        ) : null}
        {this.props.isWorkflow ? (
          <Button
            primary
            onClick={() => {
              this.props.onClose(true);
            }}
            content="Create Another Project"
          />
        ) : (
          actions
        )}
      </>
    );

    return (
      <FormModal
        open={this.state.open}
        testId="project"
        formInputs={formInputs}
        headerContent="Project"
        headerIcon="tasks"
        trigger={trigger}
        onClose={this.props.onClose}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    roles: user.roles,
    userOrganisation: user.organisationId,
    userId: user.userId,
    username: user.username
  };
}

export default connect(mapStateToProps)(ProjectView);
