import React from "react";
import FormModal from "./FormModal";
import {Form, Message} from "semantic-ui-react";
import Location from "./Location";
import {receiverDeployment, stationById, stationDelete} from "../axios/api";
import ReceiverDeploymentsList from "./ReceiverDeploymentsList";
import {connect} from "react-redux";
import {isAdminNotATFAdmin} from "./helpers";

const defaultState = {
  open: false,
  success: false,
  failed: false,
  station: {
    installation: {
      project: {name: ""},
      name: ""
    },
    name: "",
    location: {}
  }
};

class StationDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
    this.failedAction = this.failedAction.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true});
    stationById(this.props.station).then((s) => {
      receiverDeployment({stationId: this.props.station}).then((d) => {
        const history = d.data.content.length > 0 ? d.data.content : [];
        this.setState({
          station: s.data,
          deployments: history,
          loading: false,
          canBeDeleted: history?.length === 0,
          userCanDelete: isAdminNotATFAdmin(this.props.roles)
        });
      });
    });
  }

  checkFormValid() {
    const isValid = this.state.canBeDeleted && this.state.userCanDelete;
    return !isValid;
  }

  successAction() {
    this.setState(
      {
        success: true,
        failed: false,
        deletedStation: this.state.station.name
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.station.id);
        }
      }
    );
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  render() {
    const {onOpen, checkFormValid, successAction, failedAction} = this;
    const {
      station,
      deployments,
      loading,
      canBeDeleted,
      userCanDelete,
      success
    } = this.state;
    const {trigger, onClose} = this.props;

    const successContent = `Station ${station.name} has been deleted.`;

    const canBeDeletedMessage = canBeDeleted ? null : (
      <p>
        The station cannot be deleted because it has receiver deployments
        associated with it.
      </p>
    );
    const userCanDeleteMessage = userCanDelete ? null : (
      <p>You do not have permission to delete the station.</p>
    );

    const messageContent = (
      <>
        {canBeDeletedMessage}
        {userCanDeleteMessage}
      </>
    );

    const formInputs = !success ? (
      <>
        <Message info header="Deleting station" content={messageContent} />
        <Form.Input
          fluid
          readOnly
          transparent
          label="Project Name"
          value={station.installation.project.name}
        />
        <Form.Input
          fluid
          readOnly
          transparent
          label="Installation Name"
          value={station.installation.name}
        />
        <Form.Input
          fluid
          readOnly
          transparent
          label="Station Name"
          value={station.name}
        />
        <Form.Field
          control={Location}
          label="Location"
          readOnly
          value={station.location}
        />
        <ReceiverDeploymentsList data={deployments} />
      </>
    ) : null;

    return (
      <FormModal
        formSubmitAPI={!success ? stationDelete : false}
        submitContent={"Delete Station"}
        formData={station.id}
        checkFormValid={checkFormValid}
        onSuccess={successAction}
        onFail={failedAction}
        formInputs={formInputs}
        headerIcon="dot circle"
        trigger={trigger}
        headerContent="Delete Station"
        successHeader="Station Deleted"
        successContent={successContent}
        onClose={onClose}
        onOpen={onOpen}
        open
        loading={loading}
      />
    );
  }
}
function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    userId: user.userId,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(StationDelete);
