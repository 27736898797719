import React from "react";
import FilteredTable from "./FilteredTable";
import axios from "axios";
import {
  getAllDeletionRequests,
  deletionRequestById,
  deletionRequestListConfig,
  pendingDeletionRequestCount
} from "../axios/api";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {FormMode} from "./helpers";
import FilterDropDown from "./FilterDropDown";
import DeletionRequest from "./DeletionRequest";
import {updatePendingRequestCount} from "../actions";

class DeletionRequestTable extends React.Component {
  actions = [
    {name: "Process Request", onClick: () => this.setState({showModal: "view"})}
  ];

  tableColumns = [
    {
      title: "Requesting username",
      width: "10%",
      dataSource: "username",
      filter: () => "username",
      isAPIFilter: true,
      default: "",
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "user"
    },
    {
      title: "Email address",
      dataSource: "email",
      default: ""
    },
    {
      title: "Requested record type",
      dataSource: "entityType",
      filter: () => "entityType",
      isAPIFilter: true,
      default: "",
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "entityType"
    },
    {
      title: "Details",
      dataSource: "entityDetails",
      default: ""
    },
    {
      title: "Status",
      dataSource: "status",
      width: "10%",
      icons: {
        PENDING: {color: "yellow", name: "circle"},
        APPROVED: {color: "green", name: "circle"},
        CANCELLED: {color: "red", name: "circle"}
      },
      isAPIFilter: true,
      filter: () => "status",
      default: "",
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "status"
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      showModal: "none"
    };
    this.getDeletionRequestDetail = this.getDeletionRequestDetail.bind(this);
    this.getDeletionRequest = this.getDeletionRequest.bind(this);
    this.modalOpener = this.modalOpener.bind(this);
  }

  componentWillUnmount() {
    if (this.requestCt) this.requestCt.cancel();
  }

  componentDidMount() {
    pendingDeletionRequestCount().then((result) => {
      this.props.dispatch(updatePendingRequestCount(result.data));
    });
  }

  getDeletionRequestDetail(id) {
    return deletionRequestById(id)
      .then((result) => {
        this.setState({requestData: id});
        return {
          header: "Deletion Request",
          row: {...result.data}
        };
      })
      .catch((result) => {
        return result;
      });
  }

  getDeletionRequest(pageSize, page, filter) {
    if (this.requestCt) this.requestCt.cancel();
    this.requestCt = axios.CancelToken.source();
    return getAllDeletionRequests(
      {size: pageSize, page: page, ...filter},
      this.requestCt.token
    ).then((result) => {
      this.requestCt = null;
      let promises = result.data.content.map((currentRequest) => {
        return {...currentRequest};
      });
      return Promise.all(promises).then(() => {
        return {...result.data};
      });
    });
  }

  modalOpener(modalName) {
    this.setState({showModal: modalName});
  }

  render() {
    const {
      getDeletionRequest,
      tableColumns,
      getDeletionRequestDetail,
      actions,
      modalOpener
    } = this;
    const {roles, accessToken} = this.props;
    const {focusQuery, showModal, requestData} = this.state;

    if (accessToken === null || !roles.includes("ROLE_ADMIN")) {
      return <Redirect to={{pathname: "/"}} />;
    }

    return (
      <>
        {showModal === "view" ? (
          <DeletionRequest
            open
            modalOpener={modalOpener}
            onClose={() => {
              this.setState({showModal: "none", focusQuery: {id: requestData}});
            }}
            mode={FormMode.VIEW}
            id={requestData}
          />
        ) : null}

        <FilteredTable
          header="Metadata Deletion Requests"
          headerIcon="trash"
          columns={tableColumns}
          fetcher={getDeletionRequest}
          detailFetcher={getDeletionRequestDetail}
          filterOptionsFetcher={deletionRequestListConfig}
          detailKey="id"
          pageSize={50}
          icon="trash"
          actions={actions}
          focus={focusQuery}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles
  };
}

export default connect(mapStateToProps)(DeletionRequestTable);
