import React from "react";
import {Form, Header, Segment} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";

class InternalTag extends React.Component {
  originalState = {};

  constructor(props) {
    super(props);

    const transmitterTypes = config.get("tag_device_model_names").map((c) => {
      return {text: c, value: c};
    });

    this.state = {
      transmitterTypes: transmitterTypes,
      internalTag: props.value
    };

    this.originalState = {...this.state};

    this.handleIDCodeChange = this.handleIDCodeChange.bind(this);
    this.handleTransmitterSerialNumberChange = this.handleTransmitterSerialNumberChange.bind(
      this
    );
    this.handleTransmitterTypeChange = this.handleTransmitterTypeChange.bind(
      this
    );

    if (props.onUpdate) this.checkFormValid();
  }

  componentDidUpdate(prevProps) {

    if (prevProps.value.serialNumber !== this.props.value.serialNumber){
      this.setState((s) => ({
        internalTag: {
          ...s.internalTag,
          serialNumber: this.props.value.serialNumber.trim()
        }
      }),
      () => {
          this.checkFormValid();
        }
      );
    }

  }

  checkFormValid() {
    const tag = this.state.internalTag;
    let valid =
      tag.serialNumber.length > 0 &&
      tag.idCode.length > 0 &&
      tag.deviceModelName !== "";
    this.props.onUpdate(tag, valid);
  }

  handleTransmitterSerialNumberChange(e, {value}) {
    this.setState(
      (s, p) => ({
        internalTag: {
          ...s.internalTag,
          serialNumber: value.trim()
        }
      }),
      () => {
        this.checkFormValid();
      }
    );
  }

  handleIDCodeChange(e, {value}) {
    e.persist();
    const idCode = value.replace(/[^0-9]+/, "");
    const caretPos =
      idCode === value ? e.target.selectionStart : e.target.selectionStart - 1;

    this.setState(
      (s, p) => ({
        internalTag: {
          ...s.internalTag,
          idCode: idCode,
          transmitterId: `${s.internalTag?.codeSpaceName ?? ""}-${idCode}`
        }
      }),
      () => {
        e.target.setSelectionRange(caretPos, caretPos);
        this.checkFormValid();
      }
    );
  }

  handleTransmitterTypeChange(e, {value}) {
    this.setState(
      (s, p) => ({
        internalTag: {
          ...s.internalTag,
          deviceModelName: value
        }
      }),
      () => {
        this.checkFormValid();
      }
    );
  }

  handleFormReset() {
    this.setState({...this.originalState});
  }

  render() {
    const {internalTag, transmitterTypes} = this.state;
    const {onUpdate} = this.props;

    const {
      handleIDCodeChange,
      handleTransmitterTypeChange
    } = this;

    let results;

    if (onUpdate) {
      results = (
        <>
          <Header as="h5">Internal Sentinel Tag</Header>
          <Segment>
            <Form.Input
                fluid
                transparent
                readOnly
                label="Transmitter ID"
                value={internalTag.transmitterId}
                data-testid="test-modem-id"
            />
            <Form.Input
                fluid
                readOnly
                transparent
                label="Codespace"
                value={internalTag.codeSpaceName}
                data-testid="test-modem-id"
            />
            <Form.Input
                fluid
                label="ID Code"
                required={true}
                autoComplete="none"
                onChange={handleIDCodeChange}
                value={internalTag.idCode}
                data-testid="test-modem-id"
            />
            <Form.Input
                fluid
                readOnly
                transparent
                label="Sensor Type"
                value={internalTag.sensorType}
            />
            <Form.Input
                fluid
                transparent
                readOnly
                label="Transmitter Serial Number"
                value={
                  internalTag.serialNumber === "" ? "N/A" : internalTag.serialNumber
                }
            />
            <Form.Dropdown
                fluid
                selection
                search
                required
                label="Transmitter Type"
                options={transmitterTypes}
                onChange={handleTransmitterTypeChange}
                value={internalTag.deviceModelName}
            />
          </Segment>
        </>
      )
    } else if (internalTag.transmitterId === "" || internalTag.transmitterId == null) {
      results = (
          <>
            <Header as="h5">Internal Sentinel Tag</Header>
            <Segment>
              <Form.Input
                  fluid
                  transparent
                  readOnly
                  value={"An internal tag is required for this receiver type but missing. Please edit this receiver to add it."}
              />
            </Segment>
          </>
      )
    } else {
      results = (<>
        <Header as="h5">Internal Sentinel Tag</Header>
        <Segment>
          <Form.Input
              fluid
              transparent
              readOnly
              label="Transmitter ID"
              value={
                internalTag.transmitterId === ""
                    ? "N/A"
                    : internalTag.transmitterId
              }
              data-testid="test-modem-id"
          />
          <Form.Input
              fluid
              transparent
              readOnly
              label="Codespace"
              value={
                internalTag.codeSpaceName === ""
                    ? "N/A"
                    : internalTag.codeSpaceName
              }
              data-testid="test-codespace-id-ro"
          />
          <Form.Input
              fluid
              transparent
              readOnly
              label="ID Code"
              value={internalTag.idCode === "" ? "N/A" : internalTag.idCode}
          />
          <Form.Input
              fluid
              transparent
              readOnly
              label="Sensor Type"
              value={
                internalTag.sensorType === "" ? "N/A" : internalTag.sensorType
              }
          />
          <Form.Input
              fluid
              transparent
              readOnly
              label="Transmitter Serial Number"
              value={
                internalTag.serialNumber === "" ? "N/A" : internalTag.serialNumber
              }
          />
          <Form.Input
              fluid
              transparent
              readOnly
              label="Transmitter Type"
              value={
                internalTag.deviceModelName === ""
                    ? "N/A"
                    : internalTag.deviceModelName
              }
          />
        </Segment>
      </>);
    }

    return results;
  }
}

InternalTag.propTypes = {
  value: PropTypes.shape({
    deviceModelName: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    sensorType: PropTypes.string.isRequired,
    codeSpaceName: PropTypes.string.isRequired,
    idCode: PropTypes.string.isRequired
  }).isRequired,
  onUpdate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};

export default InternalTag;
