import React from "react";
import Dropzone from "react-dropzone";
import {Button, Form} from "semantic-ui-react";
import FormModal from "./FormModal";
import {uploadDetections} from "../axios/api";
import {containsActiveProject, isAdmin} from "./helpers";
import Alert from "./Alert";
import {connect} from "react-redux";
import LoginForm from "./LoginForm";

class DetectionFileUpload extends React.PureComponent {
  maxFiles = 1;
  dropEventsFileHere = "Drop events file here.";
  dropDetectionsFileHere = "Drop detections file here.";
  acceptedClassName = "at-dropzone-accepted";
  rejectedClassName = "at-dropzone-error";
  defaultClassName = "at-dropzone-default";

  constructor(props) {
    super(props);

    this.maxFilesMessage = `(Only ${this.maxFiles} *.csv file${
      this.maxFiles > 1 ? "s" : ""
    } will be accepted)`;

    this.state = {
      errors: {},
      success: false,
      failed: false,
      open: true,
      upload: {
        detection: null,
        event: null
      },
      eventsFileMessage: this.dropEventsFileHere,
      detectionsFileMessage: this.dropDetectionsFileHere,
      rejectedFiles: [],
      modal: "upload"
    };

    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleDetectionsFileDrop = this.handleDetectionsFileDrop.bind(this);
    this.handleEventsFileDrop = this.handleEventsFileDrop.bind(this);
    this.failedAction = this.failedAction.bind(this);
    this.successAction = this.successAction.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleFileDropReject = this.handleFileDropReject.bind(this);
    this.getColor = this.getColor.bind(this);
    this.selectModal = this.selectModal.bind(this);
  }

  componentDidMount() {
    this.selectModal();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.projects !== prevProps.projects ||
      this.props.roles !== prevProps.roles
    ) {
      this.selectModal();
    }
  }

  selectModal() {
    if (!this.props.accessToken) {
      this.setState({modal: "login"});
    } else if (
      containsActiveProject(this.props.projects) ||
      isAdmin(this.props.roles)
    ) {
      this.setState({modal: "upload"});
    } else {
      this.setState({modal: "alert"});
    }
  }

  checkFormValid() {
    const {upload} = this.state;
    const valid = upload.detection && upload.event;
    return !valid;
  }

  handleDetectionsFileDrop(acceptedFiles) {
    this.setState((s) => {
      return {
        upload: {
          detection: acceptedFiles[0],
          event: s.upload.event
        },
        success: false,
        failed: false,
        detectionsFileMessage: `Accepted file: ${acceptedFiles[0].name} - ${acceptedFiles[0].size} bytes`,
        acceptedDetectionsFileColor: this.acceptedClassName,
        errors: {events: s.errors.events}
      };
    });
  }

  handleEventsFileDrop(acceptedFiles) {
    this.setState((s) => {
      return {
        upload: {
          detection: s.upload.detection,
          event: acceptedFiles[0]
        },
        success: false,
        failed: false,
        eventsFileMessage: `Accepted file: ${acceptedFiles[0].name} - ${acceptedFiles[0].size} bytes`,
        acceptedEventsFileColor: this.acceptedClassName,
        errors: {detections: s.errors.detections}
      };
    });
  }

  handleFileDropReject(rejectedFiles, e, type) {
    let errors = {};
    errors[type] = {};
    errors[type]["files"] = [];
    errors[type]["message"] = "";
    errors[type] = rejectedFiles.reduce((errors, reject) => {
      if (
        reject.errors.find((r) => {
          return r.code === "too-many-files";
        })
      ) {
        errors.message = `You cannot upload more than ${
          this.maxFiles
        } ${type} file${this.maxFiles > 1 ? "s" : ""}. ${
          rejectedFiles.length
        } files were selected.`;
      }
      errors.files.push({
        name: reject.file.name,
        message: reject.errors[0].message
      });
      return errors;
    }, errors[type]);
    this.setState((s) => {
      return {
        errors: {...s.errors, ...errors},
        upload: {
          detection: type === "detections" ? false : s.upload.detection,
          event: type === "events" ? false : s.upload.event
        },
        eventsFileMessage:
          type === "events" ? this.dropEventsFileHere : s.eventsFileMessage,
        detectionsFileMessage:
          type === "detections"
            ? this.dropDetectionsFileHere
            : s.detectionsFileMessage,
        acceptedDetectionsFileColor:
          type === "detections"
            ? this.defaultClassName
            : s.acceptedDetectionsFileColor,
        acceptedEventsFileColor:
          type === "events" ? this.defaultClassName : s.acceptedEventsFileColor
      };
    });
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  successAction() {
    this.setState({
      success: true,
      failed: false,
      upload: {
        detection: null,
        event: null
      },
      eventsFileMessage: this.dropEventsFileHere,
      detectionsFileMessage: this.dropDetectionsFileHere,
      acceptedEventsFileColor: "",
      acceptedDetectionsFileColor: ""
    });
  }

  onClose() {
    this.setState({open: false});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  getColor(accept, reject) {
    return accept
      ? "at-dropzone-accept"
      : reject
      ? "at-dropzone-reject"
      : this.defaultClassName;
  }

  renderMessages(errors) {
    return (
      <>
        {errors?.message ? (
          <p className={this.rejectedClassName}>{errors?.message}</p>
        ) : null}
        {errors?.files ? (
          <>
            <table>
              <thead className={this.acceptedClassName}>
                <tr>
                  <td>Rejected files</td>
                  <td>Errors</td>
                </tr>
              </thead>
              <tbody>
                {errors?.files?.map((f, i) => (
                  <tr key={i}>
                    <td className={this.acceptedClassName}>{f.name}:</td>
                    <td className={this.rejectedClassName}>{f.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
          </>
        ) : null}
      </>
    );
  }

  render() {
    const {
      handleEventsFileDrop,
      checkFormValid,
      getColor,
      successAction,
      failedAction,
      handleDetectionsFileDrop,
      onClose,
      handleFileDropReject,
      maxFiles
    } = this;
    const {
      upload,
      success,
      failed,
      eventsFileMessage,
      detectionsFileMessage,
      open,
      acceptedDetectionsFileColor,
      acceptedEventsFileColor,
      errors,
      modal
    } = this.state;

    if (modal === "login") {
      return (
        <LoginForm
          open={open}
          onClose={(success) => {
            if (!success) {
              if (!this.props.noRedirectOnClose) {
                this.props.history.goBack();
              } else {
                onClose();
              }
            }
          }}
        />
      );
    } else if (modal === "alert") {
      return (
        <Alert
          content="You must be part of a project to be able to upload receiver detection data to the project"
          icon="exclamation triangle"
          headerContent="Access Restricted"
          hideCancel
          closeButtonContent="OK"
          closeAction={() => {
            if (!this.props.noRedirectOnClose) {
              this.props.history.goBack();
            } else {
              onClose();
            }
          }}
          open={open}
        />
      );
    } else if (modal === "upload") {
      const formInputs = (
        <>
          <Form.Input label="Detections File">
            <Dropzone
              onDropAccepted={handleDetectionsFileDrop}
              onDropRejected={(rejects, e) =>
                handleFileDropReject(rejects, e, "detections")
              }
              maxFiles={maxFiles}
              multiple={false}
              accept="text/csv,.csv"
            >
              {({getRootProps, getInputProps, isDragAccept, isDragReject}) => {
                return (
                  <div
                    {...getRootProps({
                      className: `at-dropzone ${getColor(
                        isDragAccept,
                        isDragReject
                      )}`
                    })}
                  >
                    <input {...getInputProps()} />
                    <p className={acceptedDetectionsFileColor}>
                      {detectionsFileMessage}
                    </p>
                    <p>
                      <em>{this.maxFilesMessage}</em>
                    </p>
                    {isDragReject && (
                      <p className={this.rejectedClassName}>
                        Some files will be rejected
                      </p>
                    )}
                    {this.renderMessages(errors.detections)}
                    <Button primary onClick={(e) => e.preventDefault()}>
                      Browse for detections file ...
                    </Button>
                  </div>
                );
              }}
            </Dropzone>
          </Form.Input>
          <Form.Input label="Events File">
            <Dropzone
              onDropAccepted={handleEventsFileDrop}
              onDropRejected={(rejects, e) =>
                handleFileDropReject(rejects, e, "events")
              }
              maxFiles={maxFiles}
              multiple={false}
              accept="text/csv,.csv"
            >
              {({getRootProps, getInputProps, isDragAccept, isDragReject}) => (
                <div
                  {...getRootProps({
                    className: `at-dropzone ${getColor(
                      isDragAccept,
                      isDragReject
                    )}`
                  })}
                >
                  <input {...getInputProps()} />
                  <p className={acceptedEventsFileColor}>{eventsFileMessage}</p>
                  <p>
                    <em>{this.maxFilesMessage}</em>
                  </p>
                  {isDragReject && (
                    <p className={this.rejectedClassName}>
                      Some files will be rejected
                    </p>
                  )}
                  {this.renderMessages(errors.events)}
                  <Button primary onClick={(e) => e.preventDefault()}>
                    Browse for events file ...
                  </Button>
                </div>
              )}
            </Dropzone>
          </Form.Input>
        </>
      );

      return (
        <FormModal
          open={open}
          headerIcon="upload"
          formSubmitAPI={uploadDetections}
          formData={upload}
          formInputs={formInputs}
          headerContent="Upload Receiver Detections and Events"
          submitContent="Upload Files"
          checkFormValid={checkFormValid}
          success={success}
          successHeader="Success"
          successContent={
            "Files were successfully uploaded. " +
            "You will receive an email to notify you when the detections have been ingested into the database. " +
            "If the email does not appear in your inbox, please check your spam or junk mail."
          }
          failed={failed}
          onSuccess={successAction}
          onFail={failedAction}
          onClose={onClose}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    roles: user.roles,
    userOrganisation: user.organisationId,
    userId: user.userId,
    username: user.username,
    projects: user.projects,
    accessToken: user.accessToken
  };
}

export default connect(mapStateToProps)(DetectionFileUpload);
