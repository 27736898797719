import React from "react";
import {connect} from "react-redux";
import {Button, Form, Segment} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";

import ActionsMenu from "./ActionsMenu";
import DateSelector from "./DateSelector";
import FormModal from "./FormModal";
import Location from "./Location";
import ReceiverSummary from "./ReceiverSummary";
import {fromUTC} from "./helpers";
import ReceiverDeploymentEdit from "./ReceiverDeploymentEdit";

class ReceiverDeploymentView extends React.PureComponent {
  originalState = {};

  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      loadingReceiver: false,
      loadingStations: false,
      valid: false,
      stations: [],
      projectOrganisationIds: [],
      receiverTypes: config.get("receiver_device_model_names"),
      receiver: props.value.receiver,
      createLabel: "Receiver Deployment",
      viewLabel: "Deployment Details",
      success: this.props.success ?? false,
      deployment: props.value
    };

    this.originalState = {...this.state};
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({...this.originalState});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const {success, createLabel, deployment} = this.state;
    const {trigger} = this.props;
    const {onClose} = this;

    const menuItems = [];
    const isAdmin =
      this.props.roles.includes("ROLE_ADMIN") ||
      this.props.roles.includes("ROLE_ATF_ADMIN");
    if (
      this.props.showEditButton &&
      (isAdmin ||
        this.props.organisationId === deployment.organisationId ||
        this.state.projects.includes(deployment.projectId))
    )
      menuItems.push({
        name: "Edit Deployment",
        onClick: () => {
          if (this.props.hasContainer) {
            this.props.modalOpener(ReceiverDeploymentEdit);
          } else {
            this.props.onClose(this.state.deployment);
          }
        }
      });
    const actions =
      menuItems.length > 0 && this.props.accessToken ? (
        <ActionsMenu menuItems={menuItems} />
      ) : null;

    const formInputs = (
      <>
        <Segment>
          <ReceiverSummary value={this.state.receiver} />
        </Segment>
        <DateSelector
          readOnly
          date={fromUTC(
            {
              timestamp: this.state.deployment.deploymentDate,
              timezone: this.state.deployment.deploymentTimezone
            },
            "YYYY-MM-DD HH:mm"
          )}
          timezone={this.state.deployment.deploymentTimezone}
          label="Deployment Date and Timezone"
        />
        <Form.Input
          fluid
          transparent
          label="Project"
          readOnly
          value={`${this.props.value.projectName ?? ""}`}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Station"
          value={`${this.props.value.stationName}`}
        />
        <Form.Field
          control={Location}
          label="Location"
          readOnly
          value={{
            longitude: deployment.location?.longitude ?? deployment.longitude,
            latitude: deployment.location?.latitude ?? deployment.latitude
          }}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Acoustic Release Brand"
          value={this.state.deployment.acousticReleaseBrand}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Acoustic Release ID"
          value={this.state.deployment.acousticReleaseId}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Mooring Descriptor"
          value={this.state.deployment.mooringDescriptor}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Bottom (Sea Floor) Depth (m)"
          value={this.state.deployment.bottomDepthMeters}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Receiver Depth Below Surface (m)"
          value={this.state.deployment.depthBelowSurface}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Receiver Orientation"
          value={this.state.deployment.receiverOrientation}
        />
        <Form.TextArea
          readOnly
          label="Comments"
          value={this.state.deployment.comments}
        />
        {this.props.showCreateButton ? (
          <Button
            primary
            onClick={this.props.onShowCreate}
            content="Deploy Another Receiver"
          />
        ) : (
          actions
        )}
      </>
    );

    return (
      <FormModal
        open={this.state.open}
        formData={deployment}
        testId="installation-form"
        successHeader="Success"
        success={success}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        successContent={this.props.successContent}
        headerContent={createLabel}
        onClose={onClose}
      />
    );
  }
}

ReceiverDeploymentView.propTypes = {
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    accessToken: user.accessToken,
    roles: user.roles
  };
}

export default connect(mapStateToProps)(ReceiverDeploymentView);
