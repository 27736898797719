import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import moment from "moment";
import {Form} from "semantic-ui-react";

class FilterDateSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onDateChange = this.onDateChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.state = {value: ""}
  }

  onDateChange(value) {
    this.props.onChange(this, {
      name: this.props.name,
      value: value
    });
    this.setState({value:value});
  }

  onBlur() {
    // If value is a valid date (only missing time) then append time
    const time = this.props.end ? '23:59' : '00:00';
    let value = moment.utc(`${this.state.value} ${time}`, "YYYY-MM-DD HH:mm", true);
    this.props.onChange(this, {
      name: this.props.name,
      value: value.isValid() ? value : ""
    });
  }
  
  render() {
    const date = moment(this.props.value, "YYYY-MM-DD HH:mm", true);
    const dateString = date.isValid() ? date.toISOString() : "";
    return (
      <>
        <div hidden>
          <Form.Input name={this.props.name} value={dateString} />
        </div>
        {date.isValid() ? (
          <Datetime
            utc
            dateFormat="YYYY-MM-DD"
            timeFormat="HH:mm"
            onChange={this.onDateChange}
            value={date}
          />
        ) : (
          <>
            <Datetime
              utc
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm"
              onChange={this.onDateChange}
              inputProps={{
                placeholder: "YYYY-MM-DD HH:mm",
                value: this.props.value ?? "",
                onBlur: this.onBlur
              }}
            />
          </>
        )}
      </>
    );
  }
}

FilterDateSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default FilterDateSelector;
