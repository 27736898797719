import React from "react";
import {Form, Icon, Table} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";
import {dataSensorTypes, unit} from "./helpers";

const referenceSensorTypes = ["RANGE_TEST", "REFERENCE_TAG", "SYNCHRONISED_TAG"];

const newSensor = {
  sensorTypeName: null,
  idCode: "",
  slope: "",
  intercept: "",
  unit: ""
}

class SensorSelect extends React.Component {

  constructor(props) {
    super(props);

    const value = this.props.value;

    const addNewSensor =
      value[0].sensorTypeName === "PINGER" ? false :
        referenceSensorTypes.includes(value[0].sensorTypeName) ? value.length === 1 : value.length < 3;

    if (addNewSensor && value[value.length-1].sensorTypeName) {
      value.push({...newSensor});
    }

    this.state = {
      showSlopeInterceptUnit: !(referenceSensorTypes.includes(value[0].sensorTypeName) || value[0].sensorTypeName === "PINGER"),
      sensorTypeNames: config.get("sensor_type_name").map(s => {return {text: s, value: s};}),
      otherSensorTypeNames: referenceSensorTypes.includes(value[0].sensorTypeName) ?
        [{text: value[0].sensorTypeName, value: value[0].sensorTypeName}] :
        dataSensorTypes.map(s => {return {text: s, value: s}}),
      sensors: value
    }

    this.handleSensorTypeChange = this.handleSensorTypeChange.bind(this);
    this.handleIdCodeChange = this.handleIdCodeChange.bind(this);
    this.handleSlopeChange = this.handleSlopeChange.bind(this);
    this.handleInterceptChange = this.handleInterceptChange.bind(this);
    this.checkValid = this.checkValid.bind(this);
    this.handleRemove = this.handleRemove.bind(this);

  }

  handleSensorTypeChange(e, { value, index }) {

    let sensors = [];
    let showSlopeInterceptUnit = false;
    let otherSensorTypeNames = null;

    if (value === "PINGER") {
      sensors[0] = { sensorTypeName: value, slope: "", intercept: "", unit: "" };
    } else if (referenceSensorTypes.includes(value)) {
      if (this.state.sensors[0].sensorTypeName === value) {
        sensors[0] = this.state.sensors[0];
        sensors[1] = { sensorTypeName: value, slope: "", intercept: "", unit: "" };
      } else {
        sensors[0] = { sensorTypeName: value, slope: "", intercept: "", unit: "" };
        sensors[1] = newSensor;
      }
      otherSensorTypeNames = [{text: value, value: value}];
    } else {
      if (referenceSensorTypes.includes(this.state.sensors[0].sensorTypeName) ||
        this.state.sensors[0].sensorTypeName === "PINGER" ||
        this.state.sensors[0].sensorTypeName === null ) {
        sensors[0] = { sensorTypeName: value, unit: unit[value] };
        sensors[1] = newSensor;
      } else {
        sensors = this.state.sensors;
        sensors[index].sensorTypeName = value;
        sensors[index].unit = unit[value];
        if (this.state.sensors.length < 3) sensors[index + 1] = newSensor;
      }
      showSlopeInterceptUnit = true;
      otherSensorTypeNames = dataSensorTypes.map(s => {return {text: s, value: s}});
    }

    sensors = sensors.map((s,i) => {
      return {...newSensor, ...this.state.sensors[i], ...s};
    });

    this.setState({
      otherSensorTypeNames: otherSensorTypeNames,
      showSlopeInterceptUnit: showSlopeInterceptUnit,
      sensors: sensors
    }, () => this.checkValid());

  }

  handleIdCodeChange(e, { value, index }) {

    const sensors = this.state.sensors;
    const idCode = isNaN(parseInt(value)) ? "" : parseInt(value);
    sensors[index].idCode = idCode.toString();

    this.setState({
      sensors: sensors
    }, () => this.checkValid());

  }

  handleSlopeChange(e, { value, index }) {

    const sensors = this.state.sensors;
    sensors[index].slope = value;

    this.setState({
      sensors: sensors
    }, () => this.checkValid());

  }

  handleInterceptChange(e, { value, index }) {

    const sensors = this.state.sensors;
    sensors[index].intercept = value;

    this.setState({
      sensors: sensors
    }, () => this.checkValid());

  }

  validSensor(sensor) {

    if (sensor.sensorTypeName === "PINGER" || referenceSensorTypes.includes(sensor.sensorTypeName)) {
      return sensor.idCode.toString().length > 0;
    } else {
      return (
        sensor.idCode.toString().length > 0 &&
        sensor.slope.toString().length > 0 &&
        sensor.intercept.toString().length > 0
      )
    }

  }

  checkValid() {

    const isValid = (
      this.state.sensors[0] &&
      this.state.sensors.reduce((accum, s) => {
        return s.sensorTypeName ? accum && this.validSensor(s) : accum;
      }, true)
    );

    if (isValid) {
      let sensors = [...this.state.sensors];
      if (!sensors[sensors.length-1].sensorTypeName) {
        sensors.pop();
      }
      this.props.onChange(true, sensors);
    } else {
      this.props.onChange(false);
    }

  }

  handleRemove(e, { index }) {
    let sensors = this.state.sensors;

    if (index === sensors.length-1) {
      sensors[index] = {...newSensor};
    } else {
      sensors.splice(index, 1);
      if (sensors[sensors.length-1].sensorTypeName) sensors.push({...newSensor});
    }

    this.setState({
      sensors: sensors
    }, () => this.checkValid());

  }

  render() {

    const { handleSensorTypeChange, handleIdCodeChange, handleSlopeChange, handleInterceptChange, handleRemove
       } = this;
    const { sensors, showSlopeInterceptUnit, sensorTypeNames, otherSensorTypeNames } = this.state;

    const sensorTypes = sensorTypeNames;

    const tableBody = sensors.map((s, i) => {
      let typeNameOptions = i===0 ?  sensorTypes : otherSensorTypeNames

      typeNameOptions = this.props.value[0].id || !this.props.validSensors ? typeNameOptions : typeNameOptions.filter(opt => this.props.validSensors.includes(opt.value));

      return <Table.Row key={i}>
        <Table.Cell>
          <Form.Dropdown
            fluid
            selection
            required={i === 0}
            options={typeNameOptions}
            placeholder="Select Type"
            value={s.sensorTypeName}
            index={i}
            onChange={handleSensorTypeChange}
          />
        </Table.Cell>
        <Table.Cell>
          <Form.Input
            fluid
            required={i === 0}
            placeholder="ID Code"
            onChange={handleIdCodeChange}
            value={s.idCode}
            index={i}
          />
        </Table.Cell>
        { showSlopeInterceptUnit ?
          <><Table.Cell>
            <Form.Input
              fluid
              required={i === 0}
              type="number"
              step="0.01"
              placeholder="Slope"
              onChange={handleSlopeChange}
              value={s.slope}
              index={i}
            />
          </Table.Cell>
            <Table.Cell>
              <Form.Input
                fluid
                required={i === 0}
                type="number"
                step="0.01"
                placeholder="Intercept"
                onChange={handleInterceptChange}
                value={s.intercept}
                index={i}
              />
            </Table.Cell>
            <Table.Cell>
              <Form.Input
                fluid
                transparent
                readOnly
                value={s.unit}
                index={i}
              />
            </Table.Cell></> :
          null }
        <Table.Cell>
          {i !== 0 && sensors[i].sensorTypeName ?
            <Icon
              name="remove"
              color="red"
              onClick={handleRemove}
              size="large"
              index={i}
              className=".at-list-icon at-list-icon"
            /> : null
          }
        </Table.Cell>
      </Table.Row>
    });

    return <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Type</Table.HeaderCell>
            <Table.HeaderCell width={3}>ID Code</Table.HeaderCell>
            {showSlopeInterceptUnit ?
              <><Table.HeaderCell width={3}>Slope</Table.HeaderCell>
              <Table.HeaderCell width={3}>Intercept</Table.HeaderCell>
              <Table.HeaderCell width={4}>Unit</Table.HeaderCell></> :
              null
            }
            <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableBody}
        </Table.Body>
      </Table>
    </>

  }

}

SensorSelect.propTypes = {
  projectPositioning: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default SensorSelect;