import React from "react";
import {Container, Header, Image, Message} from "semantic-ui-react";

const MaintenancePage = () => {
  return (
    <>
      <Container textAlign="center" style={{marginTop: "50px"}}>
        <Image
          src="https://static.emii.org.au/images/logo/IMOS_logo-stacked_transparent.png"
          size="small"
          centered
        />
        <Header as="h3">We'll be back soon!</Header>
        <Message>
          <p>
            The Australian Animal Acoustic Telemetry Database web application is
            currently undergoing maintenance. Please try again later.
          </p>
          <p>
            If you require urgent assistance, please contact info@aodn.org.au{" "}
          </p>
          <p>We apologise for any inconvenience</p>
        </Message>
      </Container>
    </>
  );
};

export default MaintenancePage;
