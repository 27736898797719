import React from "react";
import {connect} from "react-redux";
import {Form, Label, Segment} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";
import ReceiverSummary from "./ReceiverSummary";

import {
  deploymentUpdate,
  recoveryUpdate,
  projectById,
  station,
  receiverById,
  receiverSummary
} from "../axios/api";

import FormModal from "./FormModal";
import DateSelector from "./DateSelector";
import StationSelect from "./StationSelect";
import Location from "./Location";
import {fromUTC, receiverDeploymentEventDatesAreNotInRange} from "./helpers";

const receiverOrientations = ["UP", "DOWN"];

class ReceiverDeploymentRecoveryEdit extends React.PureComponent {
  originalState = {};

  constructor(props) {
    super(props);

    const recovery = {
      ...props.value.recovery,
      ...props.value.recovery?.location
    };
    const deployment = {
      ...props.value,
      latitude: props.value?.location?.latitude,
      longitude: props.value?.location?.longitude
    };

    const mode = this.props.editRecovery ? "Recovery" : "Deployment";
    this.state = {
      loadingStations: false,
      valid: false,
      stations: [],
      projectOrganisationIds: [],
      recoveredByOptions: [],
      receiverTypes: config.get("receiver_device_model_names"),
      receiverRecoveryStatus: config.get("receiver_recovery_status"),
      createLabel: `Edit Receiver ${mode}`,
      viewLabel: `${mode} Details`,
      success: false,
      deployment: deployment,
      recovery: recovery,
      receiverIds: []
    };

    this.originalState = {...this.state};

    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleRecoveryDateChange = this.handleRecoveryDateChange.bind(this);
  }

  componentDidMount() {
    receiverById(this.state.deployment.receiverId).then((receiverResult) => {
      this.setState(
        (s) => {
          return {
            deployment: {...s.deployment, receiver: receiverResult.receiver}
          };
        },
        () => {
          if (this.props.editDeployment) {
            station({
              projectId: this.state.deployment.projectId,
              size: 1000
            }).then((result) => {
              const stations = result.data.content;
              const station = stations.find(
                (s) => s.id === this.state.deployment.stationId
              );
              this.setState((s) => {
                return {
                  loadingStations: false,
                  stations: stations,
                  stationLocation: station?.lastDeployedLocation
                };
              }, this.checkFormValid);
            });
          } else {
            // Set this first, then go and get the rest of the options
            this.setState(
              {
                recovery: {
                  ...this.state.recovery,
                  recoveredByUserId: this.state.recovery.recoverer.userId
                },
                recoveredByOptions: [
                  {
                    text: this.props.value.recovery.recoverer.name,
                    value: this.props.value.recovery.recoverer.userId,
                    object: {name: this.props.value.recovery.recoverer.name}
                  }
                ]
              },
              () => {
                projectById(this.state.recovery.recoverer.projectId).then(
                  (result) => {
                    const members = result.project.projectRoles.filter((r) => {
                      return r.access !== "REVOKED";
                    });
                    const recoveredByOptions = members.map((m) => {
                      return {
                        text: m.user.name,
                        value: m.user.id,
                        object: m.user
                      };
                    });
                    this.setState({
                      recoveredByOptions: recoveredByOptions
                    });
                  }
                );
              }
            );
          }
        }
      );
    });

    receiverSummary().then((result) => {
      this.setState((s) => {
        const receiverIds = result.data;
        return {receiverIds: receiverIds};
      });
    });
  }

  onClose() {
    this.setState({...this.originalState});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    let valid = false;
    if (this.props.editDeployment) {
      const deployment = this.state.deployment;
      valid =
        deployment.stationId &&
        deployment.deploymentDate &&
        deployment.deploymentTimezone &&
        !this.state.deploymentDateNotValid &&
        deployment.latitude &&
        deployment.longitude &&
        deployment.bottomDepthMeters > 0 &&
        deployment.depthBelowSurface > 0 &&
        deployment.receiverOrientation;
    } else {
      const recovery = this.state.recovery;
      valid =
        ((recovery.latitude && recovery.longitude) ||
          recovery.status === "LOST") &&
        !this.state.recoveryDateNotValid;
    }
    return !valid;
  }

  successAction(response) {
    if (this.props.onUpdate) {
      this.setState(
        (s) => {
          return {
            recovery: {...s.recovery, version: response.version},
            deployment: {...s.deployment, version: response.version}
          };
        },
        this.props.onUpdate({
          ...response,
          ...response.location
        })
      );
    }
  }

  handleLocationChange(e, value) {
    this.setState(
      (s) => ({
        deployment: {
          ...s.deployment,
          latitude: parseFloat(value.latitude),
          longitude: parseFloat(value.longitude)
        }
      }),
      this.checkFormValid
    );
  }

  handleRecoveryDateChange(value) {
    this.setState(
      (s) => {
        return {
          recoveryDateNotValid:
            value.timezone !== "" &&
            this.props.value.receiverId === this.state.deployment.receiverId
              ? receiverDeploymentEventDatesAreNotInRange(
                  s.deployment.receiver.history,
                  {
                    deploymentDate: {
                      utc: s.deployment.deploymentDate,
                      timezone: s.deployment.deploymentTimezone
                    },
                    recoveryDate: value
                  },
                  false,
                  s.deployment.id
                ).recoveryDateNotInRange
              : false,
          recovery: {
            ...s.recovery,
            recoveryDate: value.utc,
            recoveryTimezone: value.timezone
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  render() {
    const {
      success,
      createLabel,
      deployment,
      recovery,
      recoveredByOptions
    } = this.state;
    const {trigger} = this.props;
    const {
      checkFormValid,
      successAction,
      onClose,
      handleRecoveryDateChange
    } = this;

    let formInputs = null;
    let formData = recovery;
    let passiveElements = null;
    let endpoint = recoveryUpdate;
    let successContent = `Recovery Updated`;
    let submitContent = `Update Recovery`;

    const headerRow = (
      <Segment>
        <ReceiverSummary
          value={{...deployment.receiver, status: deployment.deviceStatus}}
        />
      </Segment>
    );

    if (this.props.editDeployment) {
      endpoint = deploymentUpdate;
      formData = deployment;
      successContent = `Deployment Updated`;
      submitContent = `Update Deployment`;
      formInputs = (
        <Segment>
          <Label attached="top left">Deployment</Label>
          <Form.Dropdown
            required
            fluid
            selection
            search
            label="Receiver Id"
            options={this.state.receiverIds.map((v) => ({
              text: v.receiverId,
              value: v.id
            }))}
            value={this.state.deployment.receiverId}
            onChange={(e, {value}) => {
              this.setState(
                {deployment: {...deployment, receiverId: value}},
                checkFormValid
              );
              receiverById(value).then((receiverResult) => {
                this.setState((s) => {
                  return {
                    deployment: {
                      ...s.deployment,
                      receiver: receiverResult.receiver
                    }
                  };
                });
              });
            }}
          />
          <Form.Field
            control={DateSelector}
            required
            error={this.state.deploymentDateNotValid}
            dontShowFieldsRequired
            timezone={this.state.deployment.deploymentTimezone}
            date={fromUTC(
              {
                timestamp: this.state.deployment.deploymentDate,
                timezone: this.state.deployment.deploymentTimezone
              },
              "YYYY-MM-DDTHH:mm:ss.SSS"
            )}
            minDate={
              this.state.receiverStatus === "RECOVERED"
                ? this.state.receiver.recoveryDate
                : null
            }
            onChange={(v) => {
              this.setState(
                (s) => ({
                  deploymentDateNotValid:
                    v.timezone !== "" &&
                    this.props.value.receiverId ===
                      this.state.deployment.receiverId
                      ? receiverDeploymentEventDatesAreNotInRange(
                          s.deployment.receiver.history,
                          {
                            deploymentDate: v,
                            recoveryDate: {
                              utc: recovery.recoveryDate,
                              timezone: recovery.recoveryTimezone
                            }
                          },
                          false,
                          s.deployment.id
                        ).deploymentDateNotInRange
                      : false,
                  deployment: {
                    ...s.deployment,
                    deploymentDate: v.utc,
                    deploymentTimezone: v.timezone
                  }
                }),
                checkFormValid
              );
            }}
            label="Deployment Date and Timezone"
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Project Name"
            value={deployment.projectName}
            data-testid="test-project-id-ro"
          />
          <StationSelect
            required
            loading={this.state.loadingStations}
            disabled={this.state.stations.length === 0}
            stations={this.state.stations}
            options={this.state.stations.map((s) => {
              return {text: s.name, value: s.id};
            })}
            value={this.state.deployment.stationId}
            onChange={(station) => {
              this.setState(
                (s) => ({
                  stationLocation: station.location,
                  deployment: {
                    ...s.deployment,
                    stationId: station.id,
                    longitude: station.location.longitude,
                    latitude: station.location.latitude
                  }
                }),
                checkFormValid
              );
            }}
          />
          {this.state.stationLocation ? (
            <Form.Field
              control={Location}
              label="Location"
              required
              warn={false}
              station={{
                longitude: this.state.stationLocation.longitude,
                latitude: this.state.stationLocation.latitude,
                radius: 500
              }}
              radiusWarning="Coordinates are outside a 500m radius from the station's registered location"
              warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
              value={{
                longitude: deployment.longitude,
                latitude: deployment.latitude
              }}
              onChange={this.handleLocationChange}
            />
          ) : null}
          <Form.Input
            autoComplete="none"
            placeholder="e.g. VEMCO"
            label="Acoustic Release Brand"
            maxLength={255}
            defaultValue={deployment.acousticReleaseBrand}
            onBlur={(e) => {
              const value = e.target.value;
              this.setState((s) => ({
                deployment: {
                  ...s.deployment,
                  acousticReleaseBrand: value
                }
              }));
            }}
          />
          <Form.Input
            label="Acoustic Release ID"
            maxLength={255}
            defaultValue={deployment.acousticReleaseId}
            autoComplete="none"
            onBlur={(e) => {
              const value = e.target.value;
              this.setState((s) => ({
                deployment: {
                  ...s.deployment,
                  acousticReleaseId: value
                }
              }));
            }}
          />
          <Form.Input
            label="Mooring Descriptor"
            maxLength={255}
            autoComplete="none"
            placeholder="eg. Star Picket, Float Mooring, Fish Aggregating Device, Permanent mooring (e.g. navigation aid), Other (please specify)"
            defaultValue={this.state.deployment.mooringDescriptor}
            onBlur={(e) => {
              this.setState({
                deployment: {...deployment, mooringDescriptor: e.target.value}
              });
            }}
          />
          <Form.Input
            required
            label="Bottom (Sea Floor) Depth (m)"
            type="number"
            min={0}
            step={0.00001}
            autoComplete="none"
            defaultValue={this.state.deployment.bottomDepthMeters}
            error={this.state.bottomDepthMetersError}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              this.setState(
                {
                  bottomDepthMetersError:
                    value <= 0
                      ? "Depth below surface must be greater than zero"
                      : null,
                  deployment: {...deployment, bottomDepthMeters: value}
                },
                checkFormValid
              );
            }}
          />
          <Form.Input
            required
            label="Receiver Depth Below Surface (m)"
            type="number"
            min={0}
            step={0.00001}
            autoComplete="none"
            value={this.state.deployment.depthBelowSurface ?? ""}
            error={this.state.depthBelowSurfaceError}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              this.setState(
                {
                  depthBelowSurfaceError:
                    value <= 0
                      ? "Depth below surface must be greater than zero"
                      : null,
                  deployment: {...deployment, depthBelowSurface: value}
                },
                checkFormValid
              );
            }}
          />
          <Form.Dropdown
            required
            fluid
            selection
            search
            label="Receiver Orientation"
            options={receiverOrientations.map((v) => ({text: v, value: v}))}
            value={this.state.deployment.receiverOrientation}
            onChange={(e, {value}) => {
              this.setState(
                {deployment: {...deployment, receiverOrientation: value}},
                checkFormValid
              );
            }}
          />
          <Form.TextArea
            label="Comments"
            defaultValue={this.state.deployment.comments}
            onBlur={(e) => {
              const value = e.target.value;
              this.setState((s) => ({
                deployment: {...s.deployment, comments: value}
              }));
            }}
          />
        </Segment>
      );
      passiveElements = (
        <Segment>
          <Label attached="top left">Recovery</Label>
          <Form.Input
            fluid
            readOnly
            transparent
            label="Recovery Status"
            value={recovery.status}
          />
          <Form.Input
            fluid
            label="Recovered By"
            value={recovery.recoverer.name}
            readOnly
            transparent
          />
          <DateSelector
            label="Recovery Date and Timezone"
            readOnly
            date={recovery.recoveryDate}
            timezone={recovery.recoveryTimezone}
          />
          <Form.Field
            control={Location}
            label="Location"
            readOnly
            value={{longitude: recovery.longitude, latitude: recovery.latitude}}
          />
          <Form.Input
            fluid
            readOnly
            transparent
            label="Comments"
            value={recovery.comments ?? ""}
          />
        </Segment>
      );
    }

    if (this.props.editRecovery) {
      formInputs = (
        <Segment>
          <Label attached="top left">Recovery</Label>
          <Form.Dropdown
            required
            fluid
            selection
            search
            name="recoveredByUserId"
            label="Recovered By"
            options={recoveredByOptions}
            value={recovery.recoveredByUserId}
            onChange={(e, {value}) => {
              this.setState((s) => ({
                recovery: {...s.recovery, recoveredByUserId: value}
              }));
            }}
          />
          <Form.Dropdown
            required
            fluid
            selection
            search
            label="Recovery Status"
            options={this.state.receiverRecoveryStatus.map((v) => ({
              text: v,
              value: v
            }))}
            value={recovery.status}
            onChange={(e, {value}) => {
              this.setState((s) => ({
                recovery: {...s.recovery, status: value}
              }));
            }}
          />
          <Form.Field
            control={DateSelector}
            required
            error={this.state.recoveryDateNotValid}
            dontShowFieldsRequired
            name="recoveryDate"
            label="Recovery Date and Timezone"
            timezone={recovery.recoveryTimezone}
            date={fromUTC(
              {
                timestamp: recovery.recoveryDate,
                timezone: recovery.recoveryTimezone
              },
              "YYYY-MM-DDTHH:mm:ss.SSS"
            )}
            minDate={deployment.deploymentDate}
            onChange={handleRecoveryDateChange}
          />
          <Form.Field
            disabled={recovery.status === "LOST"}
            control={Location}
            label={
              recovery.status === "LOST"
                ? "Location - will not be saved when receiver is LOST"
                : "Location"
            }
            required={recovery.status !== "LOST"}
            warn={false}
            radiusWarning="Coordinates are outside a 500m radius from the station's registered location"
            warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
            value={{longitude: recovery.longitude, latitude: recovery.latitude}}
            onChange={(e, value) =>
              this.setState(
                (s) => ({
                  recovery: {
                    ...s.recovery,
                    latitude: parseFloat(value.latitude),
                    longitude: parseFloat(value.longitude)
                  }
                }),
                this.checkFormValid
              )
            }
          />
          <Form.TextArea
            label="Comments"
            defaultValue={this.state.recovery.comments ?? ""}
            onBlur={(e) => {
              const value = e.target.value;
              this.setState((s) => ({
                recovery: {...s.recovery, comments: value}
              }));
            }}
          />
        </Segment>
      );

      passiveElements = (
        <>
          <Segment>
            <Label attached="top left">Deployment</Label>
            <DateSelector
              readOnly
              date={fromUTC(
                {
                  timestamp: deployment.deploymentDate,
                  timezone: deployment.deploymentTimezone
                },
                "YYYY-MM-DD HH:mm"
              )}
              timezone={deployment.deploymentTimezone}
              label="Deployment Date and Timezone"
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Station Name"
              value={deployment.stationName}
            />
            <Form.Field
              control={Location}
              label="Deployment Location"
              readOnly
              value={deployment.location}
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Acoustic Release Brand"
              value={deployment.acousticReleaseBrand ?? ""}
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Acoustic Release ID"
              value={deployment.acousticReleaseId ?? ""}
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Mooring Descriptor"
              value={deployment.mooringDescriptor ?? ""}
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Depth Below Surface (metres)"
              value={deployment.depthBelowSurface ?? ""}
            />
            <Form.Input
              fluid
              readOnly
              transparent
              label="Comments"
              value={deployment.comments ?? ""}
            />
          </Segment>
        </>
      );
    }

    return (
      <FormModal
        open
        headerRow={headerRow}
        passiveColumn={this.props.editRecovery ? 1 : 2}
        passiveElements={passiveElements}
        formSubmitAPI={endpoint}
        formData={formData}
        checkFormValid={checkFormValid}
        testId="receiver-deployment-recovery-edit"
        successHeader="Success"
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        successContent={successContent}
        headerContent={createLabel}
        submitContent={submitContent}
        onClose={onClose}
      />
    );
  }
}

ReceiverDeploymentRecoveryEdit.propTypes = {
  onClose: PropTypes.func,
  editDeployment: PropTypes.bool,
  editRecovery: PropTypes.bool
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles
  };
}

export default connect(mapStateToProps)(ReceiverDeploymentRecoveryEdit);
