import React from "react";
import {Header, List, Segment} from "semantic-ui-react";
import {
  CitationsTemplateUrl,
  DataPolicyUrl,
  DeploymentRecordsTemplateUrl,
  IntroductionToAcousticTelemetryUrl,
  ProtectedStatusFormUrl,
  WebInterfaceUserManual
} from "./helpers";

export const Resources = () => {
  return (
    <Segment padded="very" basic>
      <Header as="h2">Resources:</Header>
      <List selection verticalAlign="middle">
        <List.Item>
          <List.Icon name="file" />
          <List.Content>
            <List.Header
              as="a"
              href={WebInterfaceUserManual}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Web-Interface User Manual
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="file" />
          <List.Content>
            <List.Header
              as="a"
              href={DataPolicyUrl}
              target={"_blank"}
              rel="noopener noreferrer"
              download
            >
              IMOS Animal Tracking Facility Data Policy
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="file" />
          <List.Content>
            <List.Header as="a" href={ProtectedStatusFormUrl} download>
              IMOS Animal Tracking Protected Data Application Form
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="file" />
          <List.Content>
            <List.Header as="a" href={DeploymentRecordsTemplateUrl} download>
              IMOS Animal Tracking Acoustic Telemetry Deployment Records
              Template
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="file" />
          <List.Content>
            <List.Header
              as="a"
              href={CitationsTemplateUrl}
              download
              target={"_blank"}
              rel="noopener noreferrer"
            >
              IMOS Animal Tracking Citations Template
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="file" />
          <List.Content>
            <List.Header
              as="a"
              href={IntroductionToAcousticTelemetryUrl}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Introduction To Acoustic Telemetry
            </List.Header>
          </List.Content>
        </List.Item>
      </List>
    </Segment>
  );
};
