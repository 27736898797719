import React from "react";
import FilteredTable from "./FilteredTable";
import {
  organisation,
  organisationUsersById,
  organisationListConfig
} from "../axios/api";
import axios from "axios";
import {connect} from "react-redux";
import Organisation from "./Organisation";
import LoginForm from "./LoginForm";
import {FormMode, getEntityDetailLists} from "./helpers";
import FilterDropDown from "./FilterDropDown";
import ProjectList from "./ProjectList";
import UserList from "./UserList";

class OrganisationTable extends React.Component {
  actions = [];

  tableActions = [
    {
      name: "Create Organisation",
      onClick: () => {
        this.setState({showCreateModal: true});
      }
    }
  ];

  tableColumns = [
    {
      title: "Name",
      width: "40%",
      dataSource: "name",
      default: "",
      isAPIFilter: true,
      filter: () => "id",
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "organisation",
      placeholder: "Filter Name"
    },
    {
      title: "Type",
      width: "15%",
      dataSource: "type",
      default: "",
      isAPIFilter: true,
      component: {
        control: FilterDropDown,
        props: {narrows: [{field: "organisation", by: "type"}]}
      },
      filter: () => "type",
      filterOptionsKey: "type"
    },
    {
      title: "Suburb",
      width: "15%",
      dataSource: "suburb",
      default: ""
    },
    {
      title: "State",
      width: "15%",
      dataSource: "state",
      isAPIFilter: true,
      default: "",
      component: {
        control: FilterDropDown,
        props: {narrows: [{field: "organisation", by: "state"}]}
      },
      filter: () => "state",
      filterOptionsKey: "state"
    },
    {
      title: "Country",
      width: "15%",
      dataSource: "country",
      isAPIFilter: true,
      default: "",
      component: {
        control: FilterDropDown,
        props: {narrows: [{field: "organisation", by: "country"}]}
      },
      filter: () => "country",
      filterOptionsKey: "country"
    },
    {
      title: "Projects",
      dataSource: "projects",
      field: "name",
      icon: "tasks",
      default: [],
      detail: true,
      renderAs: ProjectList
    },
    {
      title: "Users",
      dataSource: "users",
      field: "name",
      icon: "user",
      default: [],
      detail: true,
      renderAs: UserList
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      focusQuery: undefined,
      refreshFilterOptions: false
    };
    this.getOrganisationDetail = this.getOrganisationDetail.bind(this);
    this.getOrganisation = this.getOrganisation.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.updateOrg = this.updateOrg.bind(this);
  }

  componentWillUnmount() {
    if (this.organisationCt) this.organisationCt.cancel();
  }

  resetModal() {
    this.setState({
      showCreateModal: false,
      showViewModal: false,
      showEditModal: false
    });
  }

  updateOrg(org) {
    const dataSource = [...this.state.dataSource];
    const idx = dataSource.findIndex((e) => e.id === org.id);
    const refresh = idx < 0 || dataSource[idx].name !== org.name;
    idx >= 0 ? (dataSource[idx] = org) : dataSource.push(org);
    this.setState(
      {
        dataSource: dataSource,
        focusQuery: {id: org.id},
        refreshFilterOptions: refresh
      },
      () => this.setState({refreshFilterOptions: false})
    );
  }

  getOrganisation(pageSize, page, filter) {
    this.organisationCt = axios.CancelToken.source();
    return organisation(
      {page: page, size: pageSize, ...filter},
      this.organisationCt.token
    ).then((result) => {
      this.organisationCt = null;
      // Update dataSource
      this.setState((state) => {
        return {
          dataSource: [...state.dataSource, ...result.data.content]
        };
      });
      return result.data;
    });
  }

  getOrganisationDetail(id) {
    return new Promise(async (resolve, reject) => {
      const idx = this.state.dataSource.findIndex((e) => e.id === id);
      const selected = this.state.dataSource[idx];
      if (selected) {
        this.actions.length = 0;
        this.actions.push({
          name: "View Organisation",
          onClick: () => {
            this.setState({showViewModal: selected});
          }
        });

        if (
          this.props.accessToken &&
          (this.props.roles.includes("ROLE_ADMIN") ||
            this.props.roles.includes("ROLE_ATF_ADMIN") ||
            this.props.organisationId === selected.organisationId)
        ) {
          this.actions.push({
            name: "Edit Organisation",
            onClick: () => {
              this.setState({showEditModal: selected});
            }
          });
        }

        // Organisation users
        const users = await organisationUsersById(id);
        selected.users = users.data.content;

        let detailColumns = this.tableColumns.filter((item) => {
          return item.detail;
        });
        let details = getEntityDetailLists(selected, detailColumns);
        details.row = selected;
        resolve(details);
      } else {
        reject();
      }
    });
  }

  render() {
    const {
      getOrganisation,
      tableColumns,
      getOrganisationDetail,
      actions,
      tableActions
    } = this;
    const {focusQuery, refreshFilterOptions} = this.state;

    const modal = this.state.showViewModal ? (
      <Organisation
        mode={FormMode.VIEW}
        value={this.state.showViewModal}
        onClose={this.resetModal}
        onUpdate={this.updateOrg}
      />
    ) : this.state.showCreateModal && !this.props.accessToken ? (
      <LoginForm
        open
        onClose={(success) => {
          if (!success) this.resetModal();
        }}
      />
    ) : this.state.showCreateModal ? (
      <Organisation
        mode={FormMode.CREATE}
        onUpdate={this.updateOrg}
        onClose={this.resetModal}
      />
    ) : this.state.showEditModal ? (
      <Organisation
        mode={FormMode.EDIT}
        value={this.state.showEditModal}
        onUpdate={this.updateOrg}
        onClose={this.resetModal}
      />
    ) : null;

    return (
      <>
        <FilteredTable
          header="Organisations"
          headerIcon="sitemap"
          filterOptionsFetcher={organisationListConfig}
          columns={tableColumns}
          fetcher={getOrganisation}
          detailFetcher={getOrganisationDetail}
          detailKey="id"
          pageSize={50}
          icon="sitemap"
          actions={actions}
          tableActions={tableActions}
          focus={focusQuery}
          refreshFilterOptions={refreshFilterOptions}
        />
        {modal}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    organisationId: user.organisationId,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(OrganisationTable);
