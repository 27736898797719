import React from "react";
import {editOrganisation, organisationCreate} from "../axios/api";
import {FormMode} from "./helpers";
import FormModal from "./FormModal";
import {connect} from "react-redux";
import ActionsMenu from "./ActionsMenu";
import OrganisationSelect from "./OrganisationSelect";

const formHeaders = {};
formHeaders[FormMode.CREATE] = "Create New Organisation";
formHeaders[FormMode.EDIT] = "Modify Organisation";
formHeaders[FormMode.VIEW] = "Organisation";

const saveButtonNames = {};
saveButtonNames[FormMode.CREATE] = "Create New Organisation";
saveButtonNames[FormMode.EDIT] = "Save Changes";
saveButtonNames[FormMode.VIEW] = "Close";

class Organisation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.mode ?? FormMode.VIEW,
      organisation: props.value ?? {
        name: "",
        type: "",
        state: "",
        suburb: "",
        country: ""
      }
    };

    this.handleOrganisationChange = this.handleOrganisationChange.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    let organisation = {};
    if (this.props.mode === FormMode.CREATE) {
      organisation = this.getValues(-1);
      this.setState({mode: this.props.mode, organisation: organisation});
    }
  }

  checkFormValid() {
    // Returns true or an error object if there are errors
    if (
      this.props.mode === FormMode.CREATE ||
      this.props.mode === FormMode.EDIT
    ) {
      if (
        typeof this.state.organisation.id !== "undefined" &&
        typeof this.state.organisation.name !== "undefined"
      ) {
        return this.state.errors;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  successAction(responseData) {
    if (this.props.onUpdate) {
      this.props.onUpdate(responseData);
    }
    this.setState({
      success: true,
      readOnly: this.props.mode === FormMode.CREATE,
      version: responseData.version,
      organisation: {
        ...this.state.organisation,
        id: responseData.id
      }
    });
  }

  onClose() {
    this.setState({
      mode:
        this.state.prevMode !== "undefined" &&
        this.state.prevMode === FormMode.VIEW
          ? this.state.prevMode
          : this.state.mode,
      readOnly: false,
      success: false,
      organisation:
        this.state.mode === FormMode.VIEW || this.state.mode === FormMode.EDIT
          ? {id: this.state.organisation.id}
          : {id: -1}
    });
    if (typeof this.props.onClose !== "undefined")
      this.props.onClose(this.state);
  }

  getValues(value) {
    let organisation = {};
    if (isNaN(value)) {
      organisation = {
        id: value.id,
        name: value.name,
        type: value.type,
        state: value.state,
        suburb: value.suburb,
        country: value.country
      };
    } else {
      organisation = {
        id: value
      };
    }
    return organisation;
  }

  handleOrganisationChange(orgId, errors, name, details) {
    let orgValues = {...details};
    if (this.state.mode === FormMode.EDIT) {
      orgValues.version = this.state.version
        ? this.state.version
        : details.version;
      if (typeof name !== "undefined" || name === "") {
        orgValues.name = name;
      }
    } else {
      orgValues = this.getValues(orgId);
    }
    this.setState({
      organisation: orgValues,
      errors: errors
    });
  }

  render() {
    const {roles} = this.props;
    const {organisation, success, readOnly, mode} = this.state;
    const {
      checkFormValid,
      handleOrganisationChange,
      successAction,
      onClose
    } = this;

    // Actions menu
    let actionMenuItems = [];
    if (mode === FormMode.VIEW && roles.includes("ROLE_ADMIN")) {
      actionMenuItems = [
        {
          name: "Edit Organisation",
          onClick: () => {
            this.setState({mode: FormMode.EDIT, prevMode: FormMode.VIEW});
          },
          getData: true
        }
      ];
    }
    const actions =
      actionMenuItems.length > 0 ? (
        <ActionsMenu menuItems={actionMenuItems} />
      ) : null;

    let formSubmitAPI;
    if (mode === FormMode.EDIT) {
      formSubmitAPI = editOrganisation;
    } else if (mode === FormMode.VIEW || readOnly) {
      formSubmitAPI = false;
    } else if (mode === FormMode.CREATE) {
      formSubmitAPI = organisationCreate;
    }

    const formInputs = (
      <>
        <OrganisationSelect
          loadingText={this.props.value?.name}
          onValidate={handleOrganisationChange}
          allowCreate={mode === FormMode.CREATE}
          readOnly={mode === FormMode.VIEW || readOnly}
          value={organisation}
          required={mode === FormMode.EDIT || mode === FormMode.CREATE}
          allowEdit={mode === FormMode.EDIT}
          includeNoAffiliation={false}
        />
        {actions}
      </>
    );

    const successContent =
      mode === FormMode.CREATE
        ? `New organisation created`
        : `Organisation changes saved`;

    const successHeader =
      mode === FormMode.CREATE
        ? `Organisation creation`
        : `Organisation change`;

    return (
      <FormModal
        open
        formSubmitAPI={formSubmitAPI}
        formData={organisation}
        checkFormValid={checkFormValid}
        testId="organisation"
        successContent={successContent}
        successHeader={successHeader}
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerContent={formHeaders[mode]}
        headerIcon="sitemap"
        submitContent={saveButtonNames[mode]}
        onClose={onClose}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles
  };
}

export default connect(mapStateToProps)(Organisation);
