import React, {Fragment} from "react";
import {connect} from "react-redux";
import qs from "qs";
import {properties} from "../properties";
import FilteredTable from "./FilteredTable";
import {
  detection,
  detectionListConfig,
  installationById,
  projectById,
  receiverDeploymentById,
  speciesSearch,
  transmitterById,
  tagSearch,
  detectionToken,
  transmitter,
  speciesCountByTransmitterId,
  areDownloadableDetectionsAvailable,
} from "../axios/api";
import {
  toDisplayUtc,
  getSelectedTransmitterDeployment,
  CitationsTemplateUrl,
  containsActiveProject,
  isAdmin,
  csvAndZipTimestamp
} from "./helpers";
import FilterDropDown from "./FilterDropDown";
import FilterSearchable from "./FilterSearchable";
import FilterDateSelector from "./FilterDateSelector";
import ProjectView from "./ProjectView";
import ModalContainer from "./ModalContainer";
import ReceiverDeploymentView from "./ReceiverDeploymentView";
import InstallationView from "./InstallationView";
import TransmitterView from "./TransmitterView";
import axios from "axios";
import {Button, Header, Modal} from "semantic-ui-react";
import _ from "lodash";

class DetectionTable extends React.Component {
  actions = [];
  modals = {};
  tableColumns = [
    {
      title: "Detection Timestamp (UTC)",
      dataSource: "detectionTimestamp",
      default: "",
      format: (timestamp) => {
        return toDisplayUtc(timestamp, null);
      }
    },
    {
      title: "Time Range Start (UTC)",
      hide: true,
      isAPIFilter: true,
      default: null,
      filter: () => "startDate",
      dataSource: "startDateFilter",
      component: {
        control: FilterDateSelector
      }
    },
    {
      title: "Time Range End (UTC)",
      isAPIFilter: true,
      hide: true,
      filter: () => "endDate",
      dataSource: "endDateFilter",
      default: null,
      component: {
        control: FilterDateSelector,
        props: {end: true}
      }
    },
    {
      title: "Receiver ID",
      dataSource: "deployment.receiverIdString",
      default: ""
    },
    {
      title: "Receiver Deployment Project Name",
      dataSource: "deployment.projectName",
      default: "",
      filter: () => "receiverDeploymentProjectId",
      isAPIFilter: true,
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "installation", by: "receiverDeploymentProjectId"},
            {field: "station", by: "receiverDeploymentProjectId"}
          ]
        }
      },
      filterOptionsKey: "receiverDeploymentProject",
      placeholder: "Filter Receiver Deployment Project Name"
    },
    {
      title: "Installation Name",
      dataSource: "deployment.installationName",
      default: "",
      filter: () => "installationId",
      isAPIFilter: true,
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "station", by: "installationId"},
            {field: "receiverDeploymentProject", by: "installationIds"}
          ]
        }
      },
      filterOptionsKey: "installation",
      placeholder: "Filter Installation Name"
    },
    {
      title: "Station Name",
      dataSource: "deployment.stationName",
      default: "",
      filter: () => "stationId",
      isAPIFilter: true,
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "installation", by: "stationIds"},
            {field: "receiverDeploymentProject", by: "stationIds"}
          ]
        }
      },
      filterOptionsKey: "station",
      placeholder: "Filter Station Name"
    },
    {
      title: "Transmitter ID",
      dataSource: "transmitterId",
      default: "",
      isAPIFilter: true,
      filter: () => "transmitterId",
      component: {
        control: FilterSearchable,
        props: {
          searchApi: tagSearch,
          replaceNonAlphanumericCharsWithHyphen: true,
          isSearchable: true,
          optionsFetcher: this.getTransmitterIdOptions,
          multiple: true,
          resetInputValueOnSubmit: true,
          noResultsMessage:
            " does not currently exist in the database. If you are the owner, you will first need to " +
            "create a deployment for this transmitter in order to access detections from the continental receiver network."
        }
      },
      filterOptionsKey: "transmitterId"
    },
    {
      title: "Transmitter Serial Number",
      dataSource: "transmitterSerialNumber",
      default: ""
    },
    {
      title: "Species Common Name",
      dataSource: "speciesCommonName",
      default: ""
    },
    {
      title: "Species Scientific Name",
      dataSource: "speciesScientificName",
      default: ""
    },
    {
      title: "Species Name",
      dataSource: "speciesFilter",
      icon: "",
      default: "",
      hide: true,
      isAPIFilter: true,
      filter: () => "speciesId",
      component: {
        control: FilterSearchable,
        props: {
          searchApi: speciesSearch,
          narrows: [
            {
              field: "transmitterId",
              by: "speciesId"
            },
            {field: "tagProject", by: "speciesIds"}
          ],
          isSearchable: true,
          optionsFetcher: this.getSpeciesOptions,
          multiple: true,
          resetInputValueOnSubmit: true
        }
      },
      filterOptionsKey: "species"
    },
    {
      title: "Tag Project Name",
      dataSource: "tagProject.name",
      default: "",
      filter: () => "tagProjectId",
      isAPIFilter: true,
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "transmitterId", by: "projectId"},
            {field: "species", by: "projectId"}
          ]
        }
      },
      filterOptionsKey: "tagProject"
    }
  ];

  constructor(props) {
    super(props);

    const exportButton = {
      name: "Download Data",
      disabled: false,
      disableOnClick: true,
      onClick: () => {
        this.downloadZip();
      }
    };

    this.state = {
      focusQuery: undefined,
      tableActions: [{...exportButton}],
      showDownloadAlert: false,
      confirmedDownload: false,
      showMultiSpeciesAlert: false,
      warningTransmitters: []
    };

    this.downloadZip = this.downloadZip.bind(this);
    this.getDetections = this.getDetections.bind(this);
    this.getDetection = this.getDetection.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.fetchReceiverDeployment = this.fetchReceiverDeployment.bind(this);
    this.fetchInstallation = this.fetchInstallation.bind(this);
    this.fetchTransmitter = this.fetchTransmitter.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.resetDownloadButton = this.resetDownloadButton.bind(this);
  }

  componentWillUnmount() {
    if (this.detectionsCt) this.detectionsCt.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.tableFilters?.transmitterId !==
      this.state.tableFilters?.transmitterId
    ) {
      if (this.state.tableFilters?.transmitterId !== undefined) {
        const transmitterIdsRaw = this.state.tableFilters.transmitterId;

        const filterTransmitterIds = transmitterIdsRaw?.includes(",")
          ? transmitterIdsRaw.split(",")
          : [this.state.tableFilters.transmitterId];

        filterTransmitterIds.map(async (transmitterId) => {
          const species_count = await speciesCountByTransmitterId(
            transmitterId
          );
          if (species_count.data > 1) {
            this.setState({
              showMultiSpeciesAlert: true,
              warningTransmitters: [
                ...this.state.warningTransmitters,
                transmitterId
              ].filter((transmitterId) =>
                filterTransmitterIds.includes(transmitterId)
              )
            });
          }
        });
      } else {
        this.setState({
          showMultiSpeciesAlert: false,
          warningTransmitters: []
        });
      }
    }
    if ((prevState.tableFilters?.receiverDeploymentProjectId !== this.state.tableFilters?.receiverDeploymentProjectId) ||
      (prevState.tableFilters?.installationId !== this.state.tableFilters?.installationId) ||
      (prevState.tableFilters?.stationId !== this.state.tableFilters?.stationId) ||
      (prevState.tableFilters?.speciesId !== this.state.tableFilters?.speciesId) ||
      (prevState.tableFilters?.tagProjectId !== this.state.tableFilters?.tagProjectId) ||
      (prevState.tableFilters?.transmitterId !== this.state.tableFilters?.transmitterId) ||
      (prevState.tableFilters?.startDate !== this.state.tableFilters?.startDate) ||
      (prevState.tableFilters?.endDate !== this.state.tableFilters?.endDate)
    ) {
      // disable the Download button while waiting for the API to respond, also shows the loading spinner to indicate something is happening
      this.setState({
        tableActions: [{...this.state.tableActions[0], name: "Checking Download", disabled: true, disableOnClick: true}]
      });
    }
  }

  downloadZip() {
    if (
      qs.stringify(this.state.tableFilters).length === 0 &&
      !this.state.confirmedDownload
    ) {
      this.setState({
        showDownloadAlert: true,
        tableActions: [
          {
            ...this.state.tableActions[0],
            name: "Download Data",
            disabled: false
          }
        ]
      });
    } else {
      detectionToken().then((response) => {
        const token =
          response.data.token != null ? `&token=${response.data.token}` : "";
        const userFileName = `IMOS_detections_${csvAndZipTimestamp()}.zip`;
        const url = `${
          properties.APIBaseUrl
        }/api/detection/detections.zip?${qs.stringify(
          this.state.tableFilters
        )}&${token}&userFileName=${userFileName}`;

        var link = document.createElement("a");
        link.href = url;
        link.download = `IMOS_detections.zip`;
        link.click();

        this.setState({
          tableActions: [
            {
              ...this.state.tableActions[0],
              name: "Preparing Download",
              disabled: true
            }
          ],
          confirmedDownload: false
        });
        setTimeout(() => {
          this.setState({
            tableActions: [
              {
                ...this.state.tableActions[0],
                name: "Download Data",
                disabled: false
              }
            ]
          });
        }, 5000);
      });
    }
  }

  getDetections(pageSize, page, filter) {
    this.setState({tableFilters: {...filter}});
    if (this.detectionsCt) this.detectionsCt.cancel();
    this.detectionsCt = axios.CancelToken.source();
    return detection(
      {size: pageSize, page: page, ...filter},
      this.detectionsCt.token
    ).then((result) => {
      this.detectionsCt = null;
      // check if there are any downloadable detections available and enable the Download button if there are
      // always enable the Download button if there are detections shown on the page
      if (result.data.content?.length > 0) {
        this.setState({
          tableActions: [
            {
              ...this.state.tableActions[0],
              name: "Download Data",
              disabled: false,
              disableOnClick: true
            }
          ]
        })
      } else {
        // otherwise, scan through the database
        areDownloadableDetectionsAvailable({...filter})
          .then((response) => {
            this.setState({
              tableActions: [
                {
                  ...this.state.tableActions[0],
                  name: response.data["isDownloadable"] ? "Download Data" : "No Data Available",
                  disabled: !response.data["isDownloadable"],
                  disableOnClick: response.data["isDownloadable"]
                }
              ]
            })
          });
      }
      return result.data;
    });
  }

  closeModal() {
    this.setState({modal: null});
  }

  getDetection(detection) {
    let {actions, closeModal} = this;
    const {
      fetchReceiverDeployment,
      fetchInstallation,
      fetchTransmitter,
      fetchProject
    } = this;

    actions.length = 0;
    actions.push(
      {
        name: "View Receiver Deployment",
        disableOnClick: true,
        onClick: () => {
          return fetchReceiverDeployment(detection.deployment.id).then(
            (model) => {
              const modal = (
                <ModalContainer
                  {...this.props}
                  modal={ReceiverDeploymentView}
                  model={model}
                  noRedirectOnClose
                  onClose={closeModal}
                />
              );
              this.setState({modal: modal});
            }
          );
        }
      },
      {
        name: "View Installation",
        disableOnClick: true,
        onClick: () => {
          return fetchInstallation(detection.installation.id).then((model) => {
            const modal = (
              <ModalContainer
                {...this.props}
                modal={InstallationView}
                model={model}
                noRedirectOnClose
                onClose={closeModal}
              />
            );
            this.setState({modal: modal});
          });
        }
      },
      {
        name: "View Transmitter",
        disableOnClick: true,
        onClick: () => {
          return fetchTransmitter(detection.tagId).then((model) => {
            const modal = (
              <ModalContainer
                {...this.props}
                modal={TransmitterView}
                model={{
                  transmitter: getSelectedTransmitterDeployment(
                    model.transmitter
                  )
                }}
                noRedirectOnClose
                onClose={closeModal}
              />
            );
            this.setState({modal: modal});
          });
        }
      },
      {
        name: "View Transmitter Project",
        disableOnClick: true,
        onClick: () => {
          return fetchProject(detection.tagProject?.id).then((model) => {
            const modal = (
              <ModalContainer
                {...this.props}
                modal={ProjectView}
                model={model}
                noRedirectOnClose
                onClose={closeModal}
              />
            );
            this.setState({modal: modal});
          });
        }
      }
    );
    if (detection.installation?.project?.id) {
      actions.push({
        name: "View Receiver Project",
        disableOnClick: true,
        onClick: () => {
          return fetchProject(detection.installation?.project?.id).then(
            (model) => {
              const modal = (
                <ModalContainer
                  {...this.props}
                  modal={ProjectView}
                  model={model}
                  noRedirectOnClose
                  onClose={closeModal}
                />
              );
              this.setState({modal: modal});
            }
          );
        }
      });
    }

    return new Promise((resolve, reject) => {
      resolve({
        header: (
          <Fragment>
            Detection {detection.transmitterId}
            <br />
            {toDisplayUtc(detection.detectionTimestamp, "UTC")}
          </Fragment>
        ),
        data: [],
        row: detection
      });
    });
  }

  fetchReceiverDeployment(id) {
    return receiverDeploymentById(id).then((r) => {
      return r;
    });
  }

  fetchInstallation(id) {
    return installationById(id).then((i) => {
      return i;
    });
  }

  fetchTransmitter(id) {
    return transmitterById(id).then((t) => {
      return t;
    });
  }

  fetchProject(id) {
    return projectById(id).then((t) => {
      return t;
    });
  }

  resetDownloadButton() {
    this.setState({
      tableActions: [
        {...this.state.tableActions[0], name: "Download Data", disabled: false}
      ]
    });
  }

  getTransmitterIdOptions(query) {
    if (this.searchableFilterOptionsFetcherCt)
      this.searchableFilterOptionsFetcherCt.cancel();
    this.searchableFilterOptionsFetcherCt = axios.CancelToken.source();
    return transmitter(query, this.searchableFilterOptionsFetcherCt.token)
      .then((r) => {
        this.searchableFilterOptionsFetcherCt = null;
        const options = r.data.content.map((c, i) => {
          return [c.transmitterIds, c.transmitterIds];
        });
        return options;
      })
      .catch((err) => {
        if (axios.isCancel(err))
          console.debug("searchableFilterOptionsFetcher cancelled");
      });
  }

  getSpeciesOptions(query) {
    if (this.speciesFilterOptionsFetcherCt)
      this.speciesFilterOptionsFetcherCt.cancel();
    this.speciesFilterOptionsFetcherCt = axios.CancelToken.source();
    return speciesSearch("", this.speciesFilterOptionsFetcherCt.token, query)
      .then((r) => {
        this.speciesFilterOptionsFetcherCt = null;
        return r.data;
      })
      .catch((err) => {
        if (axios.isCancel(err))
          console.debug("searchableFilterOptionsFetcher cancelled");
      });
  }

  render() {
    const {tableColumns, actions, getDetections, getDetection} = this;
    const {
      focusQuery,
      modal,
      tableActions,
      showDownloadAlert,
      showMultiSpeciesAlert,
      warningTransmitters
    } = this.state;
    let alertModal;

    let uniqWarningTransmitters = _.uniq(warningTransmitters);

    if (uniqWarningTransmitters.length > 0) {
      alertModal = (
        <Modal
          open={showMultiSpeciesAlert}
          onClose={() => {
            this.setState({showMultiSpeciesAlert: false});
          }}
          size="small"
        >
          <Header
            icon={"exclamation triangle"}
            content="Multiple species detected"
          />
          <Modal.Content>
            <p>
              Please, be aware that the selected transmitters IDs below are associated with multiple species.
            </p>
            <ul>
              {uniqWarningTransmitters.map((transmitter) => (
                <li>{transmitter}</li>
              ))}
            </ul>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              content={"OK"}
              onClick={() => {
                this.setState({
                  showMultiSpeciesAlert: false
                });
              }}
              data-testid="closealert"
            />
          </Modal.Actions>
        </Modal>
      );
    }

    if (
      showDownloadAlert &&
      !isAdmin(this.props.roles) &&
      (this.props.accessToken === null ||
        !containsActiveProject(this.props.projects))
    ) {
      alertModal = (
        <Modal
          open={showDownloadAlert}
          onClose={() => {
            this.setState({showDownloadAlert: false});
          }}
          size="small"
        >
          <Header icon={"exclamation triangle"} content="Access Restricted" />
          <Modal.Content>
            <p>
              Please apply filters to download a subset of the detections data.
              If you are needing the full dataset, please contact
              info@aodn.org.au.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              content={"OK"}
              onClick={() => {
                this.setState({
                  showDownloadAlert: false
                });
              }}
              data-testid="closealert"
            />
          </Modal.Actions>
        </Modal>
      );
    } else if (showDownloadAlert && this.props.accessToken !== null) {
      alertModal = (
        <Modal
          open={showDownloadAlert}
          onClose={() => {
            this.setState({showDownloadAlert: false});
          }}
          size="small"
        >
          <Header icon={"exclamation triangle"} content="Warning" />
          <Modal.Content>
            <p>
              You are about to download the entire detections content of the
              database, this will take a significant amount of time and
              resources to be downloaded to your device. You can choose to
              continue or try again applying some filtering to download a
              smaller subset.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Cancel"
              onClick={() => {
                this.setState({showDownloadAlert: false});
              }}
            />
            <Button
              primary
              content={"Continue"}
              onClick={() => {
                this.setState(
                  {showDownloadAlert: false, confirmedDownload: true},
                  () => {
                    this.downloadZip();
                  }
                );
              }}
              data-testid="closealert"
            />
          </Modal.Actions>
        </Modal>
      );
    }

    return (
      <>
        {alertModal}
        <FilteredTable
          header={
            <>
              Detections
              <br />
              <p className="at-subheading">
                Please note: The table and download will only contain detections
                associated with created and deployed transmitters in the
                database. If there are missing detections, please check that the
                status of your transmitters is up to date.
              </p>
              <p className="at-subheading">
                Only the most recent detections (last 2 years) for the selected
                search criteria are displayed. If there are historical data available, you should be able to click on the 'Download Data' button.
              </p>
              <p className="at-subheading">
                Publications making use of data from the Database must include
                citations as described in the{" "}
                <a
                  href={CitationsTemplateUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  IMOS Animal Tracking Citations Template
                </a>
                .
              </p>
            </>
          }
          headerIcon="download"
          fetchingData={true}
          filterOptionsFetcher={detectionListConfig}
          columns={tableColumns}
          fetcher={getDetections}
          detailFetcher={getDetection}
          pageSize={50}
          icon="tag"
          actions={actions}
          tableActions={tableActions}
          focus={focusQuery}
          detailKey="row"
          noDataMessage="There are no recent data within the last 2 years matching the search criteria. If there are historical data available, you should be able to click on the 'Download Data' button."
        />
        {modal}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(DetectionTable);
