import React from "react";
import { Icon, List } from "semantic-ui-react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

class SelectListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      strikeThrough: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleStrikeThroughClick = this.handleStrikeThroughClick.bind(this);
  }

  componentDidMount() {
    this.setState({strikeThrough: this.props.content.strikeThrough})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({strikeThrough: this.props.content.strikeThrough})
    }
  }

  handleClick(e) {
    const { onClick, index } = this.props;
    e.preventDefault();
    onClick(index);
  }

  handleStrikeThroughClick(e) {
    const { onClick, index } = this.props;
    e.preventDefault();
    this.setState(
      {strikeThrough: !this.state.strikeThrough},
      () => {
        onClick(index, this.state.strikeThrough)
      });
  }

  render() {
    const { handleClick, handleStrikeThroughClick } = this;
    const { content } = this.props;

    const text = (this.state.strikeThrough) ? <del>{content.text}</del> : content.text

    return (
      <List.Item>
        <List.Content floated="right">
          { content.required ?
            null :
          <Icon
            name="remove"
            color="red"
            onClick={handleClick}
            size="big"
            className=".at-list-icon at-list-icon"
          /> }
          { content.allowStrikeThrough && content.required ?
            <>
              { this.state.strikeThrough ? <Label content={content.strikeThroughLabel} /> : null}
              { content.strikeThroughButtonVisible ?
                <Icon
                  name="ban"
                  color="yellow"
                  onClick={handleStrikeThroughClick}
                  size="big"
                  className=".at-list-icon at-list-icon"
                /> : null }
            </> :
          null }
        </List.Content>
        <List.Content floated="left" className="at-list-text">
          {text}
        </List.Content>
      </List.Item>
    );
  }
}

export default SelectListItem;
