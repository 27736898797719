import React, {Fragment} from "react";
import moment from "moment-timezone";
import {connect} from "react-redux";
import config from "react-global-configuration";
import FormModal from "./FormModal";
import {
  projectById,
  speciesSearch,
  tagRegisterRelease,
  tagRelease,
  tagValidate,
  transmitterBySerialNumber
} from "../axios/api";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Label,
  Message,
  Segment
} from "semantic-ui-react";
import ProjectSelect from "./ProjectSelect";
import {
  getEmbargoUntilDate,
  getSelectedTransmitterDeployment,
  getTransmitterId,
  Roles,
  noReleaseSensorTypes
} from "./helpers";
import SensorSelect from "./SensorSelect";
import TransmitterSummary from "./TransmitterSummary";
import Location from "./Location";
import DateSelector from "./DateSelector";
import FilterSearchable from "./FilterSearchable";
import Measurements from "./Measurements";
import {Link} from "react-router-dom";
import AwesomeDebouncePromise from "awesome-debounce-promise";
const debouncedSpeciesSearch = AwesomeDebouncePromise(speciesSearch, 300);

class TransmitterCreateDeploy extends React.Component {
  defaultState = {
    formSubmitAPI: tagValidate,
    success: false,
    submissionWas: null,
    failed: false,
    loading: false,
    sensorsValid: false,
    measurementsValid: false,
    locationValid: true,
    project: null,
    speciesSearchValue: "",
    transmitterStatus: null,
    showSearch: true,
    showCreate: false,
    showDeploy: false,
    hideAnimalRelease: false,
    protectedProject: false,
    sensors: [
      {
        sensorTypeName: null,
        idCode: "",
        slope: "",
        intercept: "",
        unit: ""
      }
    ],
    measurements: [
      {
        measurementType: null,
        value: "",
        unit: "",
        comment: ""
      }
    ],
    // Payload for API
    transmitter: {
      tagPostDto: {
        projectId: null,
        deviceModelName: "",
        serialNumber: "",
        codeSpaceName: "",
        expectedLifeTimeDays: "",
        sensors: []
      },
      releasePostDto: {
        speciesId: null,
        sex: null,
        surgeryType: null,
        measurements: [],
        releaseLocality: "",
        longitude: null,
        latitude: null,
        releaseDate: moment(),
        releaseTimezone: "",
        comments: "",
        embargoThisTag: false
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = this.defaultState;

    this.codeSpaceNames = config.get("code_space_names").map((c) => {
      return {text: c, value: c};
    });
    this.tagDeviceModelNames = config.get("tag_device_model_names").map((m) => {
      return {text: m, value: m};
    });
    this.sexes = config.get("animal_sex").map((s) => {
      return {text: s, value: s};
    });
    this.surgeryTypes = config.get("surgery_type").map((s) => {
      return {text: s, value: s};
    });

    this.sensorTypeNames = config.get("sensor_type_name").map((s) => {
      return {text: s, value: s};
    });
    this.codespace_sensors = config.get("codespace_sensors");
    this.codespace_non_sensors = config.get("codespace_non_sensors");
    this.broad_type_sensors = config.get("broad_type_sensors");
    this.broad_type_non_sensors = config.get("broad_type_non_sensors");

    this.successAction = this.successAction.bind(this);
    this.failedAction = this.failedAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleTransmitterTypeChange = this.handleTransmitterTypeChange.bind(
      this
    );
    this.handleSerialNumberChange = this.handleSerialNumberChange.bind(this);
    this.handleCodeSpaceNameChange = this.handleCodeSpaceNameChange.bind(this);
    this.handleExpectedLifetimeChange = this.handleExpectedLifetimeChange.bind(
      this
    );
    this.handleSensorsChange = this.handleSensorsChange.bind(this);
    this.handleReleaseSpeciesChange = this.handleReleaseSpeciesChange.bind(
      this
    );
    this.handleReleaseSexChange = this.handleReleaseSexChange.bind(this);
    this.handleReleasePlacementChange = this.handleReleasePlacementChange.bind(
      this
    );
    this.handleReleaseLocalityChange = this.handleReleaseLocalityChange.bind(
      this
    );
    this.handleDeploymentLocationChange = this.handleDeploymentLocationChange.bind(
      this
    );
    this.handleDeploymentDateTimeChange = this.handleDeploymentDateTimeChange.bind(
      this
    );
    this.handleDeploymentCommentsBlur = this.handleDeploymentCommentsBlur.bind(
      this
    );
    this.handleEmbargoChange = this.handleEmbargoChange.bind(this);
    this.handleMeasurementsChange = this.handleMeasurementsChange.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  checkFormValid() {
    const {
      transmitter,
      sensorsValid,
      formSubmitAPI,
      locationValid,
      measurementsValid,
      hideAnimalRelease
    } = this.state;

    let isValid =
      (formSubmitAPI === tagValidate &&
        transmitter.tagPostDto.projectId !== null &&
        transmitter.tagPostDto.deviceModelName !== "" &&
        transmitter.tagPostDto.serialNumber.length > 0 &&
        transmitter.tagPostDto.codeSpaceName !== "" &&
        parseInt(transmitter.tagPostDto.expectedLifeTimeDays) > 0 &&
        sensorsValid) ||
      (formSubmitAPI !== tagValidate &&
        (hideAnimalRelease ||
          (transmitter.releasePostDto.speciesId !== "" &&
            transmitter.releasePostDto.sex !== "" &&
            transmitter.releasePostDto.surgeryType !== "" &&
            measurementsValid)) &&
        transmitter.releasePostDto.releaseLocality !== "" &&
        locationValid &&
        transmitter.releasePostDto.releaseDate !== "Invalid date" &&
        transmitter.releasePostDto.releaseTimezone !== "");

    return !isValid;
  }

  successAction(result) {
    // result will be a transmitter for NEW tags
    // result will be a release for DEPLOYED tags

    const {defaultState} = this;
    const submissionWas = this.state.formSubmitAPI;

    // NEW tag so deploy
    if (result.status === "NEW") {
      this.setState({
        showSearch: false,
        showCreate: false,
        showDeploy: true,
        success: true,
        failed: false,
        submissionWas: submissionWas,
        formSubmitAPI: tagRegisterRelease,
        project: result.project
      });
    }

    // DEPLOYED tag so reset form
    // Stored values while modal is open
    this.defaultState = {
      ...defaultState,
      transmitter: {
        ...defaultState.transmitter,
        releasePostDto: {
          ...defaultState.transmitter.releasePostDto,
          releaseLocality: this.state.transmitter.releasePostDto
            .releaseLocality,
          releaseDate: this.state.transmitter.releasePostDto.releaseDate,
          releaseTimezone: this.state.transmitter.releasePostDto
            .releaseTimezone,
          latitude: this.state.transmitter.releasePostDto.latitude,
          longitude: this.state.transmitter.releasePostDto.longitude
        }
      }
    };
    if (result.tag?.status === "DEPLOYED") {
      this.setState({...defaultState, success: true}, () => {
        if (this.props.onUpdate) {
          this.props.onUpdate({
            tag: result.tag,
            id: result.release ? result.release.id : result.id
          });
        }
      });
    }
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  onClose() {
    if (this.props.onClose) {
      this.setState(this.defaultState);
      this.props.onClose(this.state);
    }
  }

  handleProjectChange(e, {value}) {
    projectById(value).then((result) => {
      this.setState({
        protectedProject: result.project.protected,
        transmitter: {
          ...this.state.transmitter,
          tagPostDto: {
            ...this.state.transmitter.tagPostDto,
            projectId: value,
            project: result.project
          }
        }
      });
    });
  }

  handleTransmitterTypeChange(e, {value}) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        tagPostDto: {
          ...this.state.transmitter.tagPostDto,
          deviceModelName: value
        }
      }
    });
  }

  handleContinue() {
    const serialNumber = this.state.transmitter.tagPostDto.serialNumber;

    transmitterBySerialNumber(serialNumber, {noRedirectOn401: true}).then(
      (result) => {
        if (result) {
          const transmitter = result.transmitter;

          if (result.transmitter.history) {
            this.setState({
              success: false,
              showSearch: true,
              showCreate: false,
              showDeploy: transmitter.status === "RECOVERED",
              transmitterStatus: transmitter.status,
              project: transmitter.project,
              formSubmitAPI:
                transmitter.status === "RECOVERED" || "NEW"
                  ? tagRelease
                  : tagValidate,
              transmitter: getSelectedTransmitterDeployment(transmitter),
              hideAnimalRelease: noReleaseSensorTypes.includes(
                transmitter.sensors[0]?.sensorTypeName
              )
            });
          } else {
            this.setState({
              success: false,
              showSearch: true,
              showCreate: false,
              showDeploy: true,
              transmitterStatus: transmitter.status,
              project: transmitter.project,
              formSubmitAPI: tagRelease,
              transmitter: getSelectedTransmitterDeployment(transmitter),
              hideAnimalRelease: noReleaseSensorTypes.includes(
                transmitter.sensors[0]?.sensorTypeName
              )
            });
          }
        } else {
          this.setState({
            showSearch: false,
            showCreate: true,
            showDeploy: false,
            transmitterStatus: "NEW",
            transmitter: {
              ...this.state.transmitter,
              tagPostDto: {
                ...this.state.transmitter.tagPostDto,
                serialNumber: serialNumber
              }
            }
          });
        }
      },
      () => {
        this.setState({
          showSearch: true,
          showCreate: false,
          showDeploy: false,
          transmitterStatus: "RESTRICTED"
        });
      }
    );
  }

  handleSerialNumberChange(e, {value}) {
    const {defaultState} = this;
    const serialNumber =
      value === ""
        ? ""
        : /^\d+$/.test(value)
        ? value
        : this.state.transmitter?.tagPostDto?.serialNumber ?? "";
    this.setState({
      showSearch: true,
      showCreate: false,
      showDeploy: false,
      transmitterStatus: null,
      formSubmitAPI: tagValidate,
      transmitter: {
        ...defaultState.transmitter,
        tagPostDto: {
          ...defaultState.transmitter.tagPostDto,
          serialNumber: serialNumber
        }
      }
    });
  }

  handleCodeSpaceNameChange(e, {value}) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        tagPostDto: {
          ...this.state.transmitter.tagPostDto,
          codeSpaceName: value
        }
      }
    });
  }

  handleExpectedLifetimeChange(e, {value}) {
    value = value !== "" ? parseInt(value) : "";
    if ((!isNaN(value) && value > 0) || value === "") {
      this.setState({
        transmitter: {
          ...this.state.transmitter,
          tagPostDto: {
            ...this.state.transmitter.tagPostDto,
            expectedLifeTimeDays: value
          }
        }
      });
    }
  }

  handleSensorsChange(valid, value) {
    const newState = valid
      ? {
          sensorsValid: valid,
          sensors: value,
          hideAnimalRelease: noReleaseSensorTypes.includes(
            value[0]?.sensorTypeName
          ),
          transmitter: {
            ...this.state.transmitter,
            tagPostDto: {
              ...this.state.transmitter.tagPostDto,
              sensors: value.map((s) => {
                return {
                  ...s,
                  slope: s.slope === "" ? null : s.slope,
                  intercept: s.intercept === "" ? null : s.intercept,
                  unit: s.unit === "" ? null : s.unit
                };
              })
            }
          }
        }
      : {sensorsValid: valid};

    this.setState(newState);
  }

  handleMeasurementsChange(valid, value) {
    const newState = valid
      ? {
          measurementsValid: valid,
          measurements: value,
          transmitter: {
            ...this.state.transmitter,
            releasePostDto: {
              ...this.state.transmitter.releasePostDto,
              measurements: value[0].measurementType === "N/A" ? [] : value
            }
          }
        }
      : {measurementsValid: valid};

    this.setState(newState);
  }

  handleReleaseSpeciesChange(e, {value}) {
    this.setState({
      speciesSearchValue: value,
      transmitter: {
        ...this.state.transmitter,
        releasePostDto: {
          ...this.state.transmitter.releasePostDto,
          speciesId: value.code
        }
      }
    });
  }

  handleReleaseSexChange(e, {value}) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        releasePostDto: {
          ...this.state.transmitter.releasePostDto,
          sex: value
        }
      }
    });
  }

  handleReleasePlacementChange(e, {value}) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        releasePostDto: {
          ...this.state.transmitter.releasePostDto,
          surgeryType: value
        }
      }
    });
  }

  handleReleaseLocalityChange(e, {value}) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        releasePostDto: {
          ...this.state.transmitter.releasePostDto,
          releaseLocality: value
        }
      }
    });
  }

  handleDeploymentLocationChange(e, value) {
    this.setState({
      locationValid: value.valid,
      transmitter: {
        ...this.state.transmitter,
        releasePostDto: {
          ...this.state.transmitter.releasePostDto,
          longitude: value.longitude,
          latitude: value.latitude
        }
      }
    });
  }

  handleDeploymentDateTimeChange(value) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        releasePostDto: {
          ...this.state.transmitter.releasePostDto,
          releaseDate: value.utc,
          releaseTimezone: value.timezone
        }
      }
    });
  }

  handleDeploymentCommentsBlur(e) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        releasePostDto: {
          ...this.state.transmitter.releasePostDto,
          comments: e.target.value
        }
      }
    });
  }

  handleEmbargoChange(e, {checked}) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        releasePostDto: {
          ...this.state.transmitter.releasePostDto,
          embargoThisTag: checked
        }
      }
    });
  }

  sensorTypes() {
    const codespace = this.state.transmitter.tagPostDto.codeSpaceName;

    if (codespace === "") {
      return this.sensorTypeNames;
    }

    let validSensors = this.codespace_sensors.includes(codespace)
      ? this.broad_type_sensors
      : this.broad_type_non_sensors;
    return this.state.transmitter.tagPostDto.project?.isPositionSystem
      ? validSensors
      : validSensors.filter(
          (s) => !["REFERENCE_TAG", "SYNCHRONISED_TAG"].includes(s)
        );
  }

  render() {
    const {
      transmitter,
      success,
      failed,
      loading,
      transmitterStatus,
      sensors,
      formSubmitAPI,
      submissionWas,
      speciesSearchValue,
      measurements,
      showSearch,
      showCreate,
      showDeploy,
      hideAnimalRelease,
      protectedProject
    } = this.state;
    const {tagPostDto, releasePostDto} = transmitter;
    const {
      checkFormValid,
      successAction,
      failedAction,
      onClose,
      handleProjectChange,
      handleTransmitterTypeChange,
      handleSerialNumberChange,
      handleCodeSpaceNameChange,
      handleSensorsChange,
      handleExpectedLifetimeChange,
      codeSpaceNames,
      tagDeviceModelNames,
      handleReleaseSpeciesChange,
      handleReleaseSexChange,
      handleReleasePlacementChange,
      handleReleaseLocalityChange,
      handleDeploymentLocationChange,
      handleDeploymentDateTimeChange,
      handleDeploymentCommentsBlur,
      handleEmbargoChange,
      sexes,
      surgeryTypes,
      handleMeasurementsChange
    } = this;
    const {trigger, roles} = this.props;

    const deployInputs = (
      <>
        <Segment>
          <TransmitterSummary value={tagPostDto} />
        </Segment>
        {hideAnimalRelease ? null : (
          <>
            <Form.Field
              fluid
              required
              label="Species Name"
              placeholder="Species search"
              control={FilterSearchable}
              searchApi={debouncedSpeciesSearch}
              name="speciesSearchValue"
              onChange={handleReleaseSpeciesChange}
              value={speciesSearchValue}
            />
            <Form.Dropdown
              fluid
              selection
              required
              options={sexes}
              label="Sex"
              onChange={handleReleaseSexChange}
              value={releasePostDto.sex}
            />
            <Form.Dropdown
              fluid
              selection
              required
              options={surgeryTypes}
              label="Placement"
              onChange={handleReleasePlacementChange}
              value={releasePostDto.surgeryType}
            />
            <Form.Field
              control={Measurements}
              label="Measurements"
              required
              fluid
              value={measurements}
              onChange={handleMeasurementsChange}
            />
          </>
        )}
        <Form.Input
          fluid
          required
          label="Deployment locality"
          placeholder="Deployment locality"
          onChange={handleReleaseLocalityChange}
          value={releasePostDto.releaseLocality}
        />
        <Form.Field
          control={Location}
          label="Deployment Location"
          required
          value={{
            longitude: releasePostDto.longitude,
            latitude: releasePostDto.latitude
          }}
          onChange={handleDeploymentLocationChange}
        />
        <DateSelector
          required
          timezone={releasePostDto.releaseTimezone}
          onChange={handleDeploymentDateTimeChange}
          label="Deployment Date and Timezone"
          date={moment(releasePostDto.releaseDate).format("YYYY-MM-DD HH:mm")}
        />
        <Form.TextArea
          label="Comments"
          defaultValue={releasePostDto.comments}
          onBlur={handleDeploymentCommentsBlur}
        />
        {hideAnimalRelease ? null : (
          <Segment>
            {protectedProject ? (
              <>
                The project associated with this transmitter is protected and
                data will be embargoed until{" "}
                {moment(releasePostDto.releaseDate)
                  .add(protectedProject ? 99 : 5, "years")
                  .format("YYYY-MM-DD")}
              </>
            ) : (
              <Grid columns={3}>
                <Grid.Column key={0} width={6}>
                  <Checkbox
                    label="Embargo this transmitter?"
                    checked={protectedProject || releasePostDto.embargoThisTag}
                    onChange={handleEmbargoChange}
                    toggle
                  />
                </Grid.Column>
                <Grid.Column key={1} width={5}>
                  <Link target="_blank" to="/datapolicy">
                    Data policy document
                  </Link>
                </Grid.Column>
                <Grid.Column key={2} width={5}>
                  {releasePostDto.embargoThisTag ? (
                    <Label basic>
                      Embargo Until{" "}
                      {getEmbargoUntilDate(
                        releasePostDto.releaseDate,
                        protectedProject
                      ).format("YYYY-MM-DD")}
                    </Label>
                  ) : null}
                </Grid.Column>
              </Grid>
            )}
          </Segment>
        )}
      </>
    );

    const createInputs = (
      <>
        <Form.Input
          fluid
          transparent
          readOnly
          label="Serial Number"
          value={tagPostDto.serialNumber}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Transmitter ID"
          value={getTransmitterId(tagPostDto)}
        />
        <ProjectSelect
          required
          value={tagPostDto.projectId}
          onChange={handleProjectChange}
          showAll={roles.some(
            (r) => [Roles.ROLE_ADMIN, Roles.ROLE_ATF_ADMIN].indexOf(r) >= 0
          )}
          loadingText="Loading"
        />
        <Form.Dropdown
          fluid
          selection
          search
          required
          label="Transmitter Type"
          options={tagDeviceModelNames}
          placeholder="Select Transmitter Type"
          value={tagPostDto.deviceModelName}
          onChange={handleTransmitterTypeChange}
        />
        <Form.Dropdown
          fluid
          selection
          search
          required
          label="Codespace"
          options={codeSpaceNames}
          placeholder="Select Codespace"
          value={tagPostDto.codeSpaceName}
          onChange={handleCodeSpaceNameChange}
        />
        <Form.Input
          fluid
          required
          label="Expected Lifetime (days)"
          placeholder="Expected Lifetime (days)"
          onChange={handleExpectedLifetimeChange}
          value={tagPostDto.expectedLifeTimeDays}
        />
        <Form.Field
          control={SensorSelect}
          disabled={
            tagPostDto.projectId === null || tagPostDto.codeSpaceName === ""
          }
          label="Sensors"
          required
          fluid
          value={sensors}
          validSensors={this.sensorTypes()}
          onChange={handleSensorsChange}
          projectPositioning={tagPostDto.project?.isPositionSystem}
        />
      </>
    );

    const searchInputs = (
      <>
        <Form.Input
          fluid
          minLength={1}
          required
          autoComplete="none"
          label="Serial Number"
          onChange={handleSerialNumberChange}
          placeholder={`Enter serial number`}
          value={tagPostDto.serialNumber}
        />
        <Button
          primary
          disabled={transmitter.tagPostDto.serialNumber.length < 1}
          onClick={(e) => {
            e.preventDefault();
            this.setState({success: false}, this.handleContinue);
          }}
        >
          Next
        </Button>
        {transmitterStatus === "DEPLOYED" ? (
          <Button
            primary
            onClick={(e) => {
              e.preventDefault();
              this.props.modalOpener("addRecovery", tagPostDto.id);
            }}
          >
            Recover Transmitter
          </Button>
        ) : null}
        <Message
          warning
          hidden={transmitterStatus !== "DEPLOYED"}
          header="New transmitter serial numbers must be unique."
          content={`Transmitter ${tagPostDto.serialNumber} is already deployed. You can recover it now or search for 
          another transmitter serial number instead.`}
        />
        <Message
          warning
          hidden={transmitterStatus !== "RECOVERED"}
          header="New transmitter serial numbers must be unique."
          content={`Transmitter ${tagPostDto.serialNumber} already exists and is currently available to be re-deployed.
            You can deploy it now or search for another transmitter serial number instead.`}
        />
        <Message
          warning
          hidden={transmitterStatus !== "RESTRICTED"}
          header="Resource has restricted access."
          content={
            <Fragment>
              You do not have permission to deploy transmitter{" "}
              {tagPostDto.serialNumber}. If you feel this is in error, please
              contact <a href="mailto:info@aodn.org.au">info@aodn.org.au</a>.
              Otherwise you can search for another transmitter serial number
              instead.
            </Fragment>
          }
        />
      </>
    );

    const formInputs = (
      <>
        {showSearch ? searchInputs : null}
        {showCreate ? createInputs : null}
        {showDeploy ? deployInputs : null}
      </>
    );

    const registerDeployString =
      formSubmitAPI === tagValidate
        ? "Validate"
        : formSubmitAPI === tagRelease
        ? "Deploy"
        : "Create and Deploy";
    const headerContent = `${registerDeployString} Transmitter ${
      formSubmitAPI === tagValidate ? "" : tagPostDto.serialNumber
    }`;

    return (
      <FormModal
        formSubmitAPI={formSubmitAPI}
        formData={
          formSubmitAPI === tagValidate
            ? transmitter.tagPostDto
            : formSubmitAPI === tagRelease
            ? transmitter.releasePostDto
            : transmitter
        }
        checkFormValid={checkFormValid}
        successHeader="Confirmation"
        onSuccess={successAction}
        onFail={failedAction}
        success={success}
        failed={failed}
        formInputs={formInputs}
        headerIcon="tag"
        trigger={trigger}
        successContent={
          submissionWas === tagValidate
            ? "Transmitter is available for registration and deployment"
            : "Transmitter deployed. Enter serial number to create and deploy another."
        }
        headerContent={headerContent}
        submitContent={registerDeployString}
        open
        loading={loading}
        onClose={onClose}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(TransmitterCreateDeploy);
