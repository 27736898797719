import React from "react";
import FormModal from "./FormModal";

function MobileDetection() {
  const [open, setOpen] = React.useState(true);
  const contents = (
    <React.Fragment>
      <p>Your mobile browser is unsupported.</p>
      <p>Please use a desktop browser for the best user experience.</p>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <FormModal
        open={open}
        headerContent={"Mobile detected"}
        headerRow={contents}
        onClose={() => setOpen(false)}
        headerIcon="mobile"
      />
    </React.Fragment>
  );
}

export default MobileDetection;
