import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Form} from "semantic-ui-react";
import {activeProjectSummary} from "../axios/api";

class ProjectSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabled: props.disabled ?? false,
      projects: []
    };
  }

  componentDidMount() {
    const cb = () => {
      if (this.props.value) {
        this.props.onChange(this, {value: this.props.value});
      }
    };

    activeProjectSummary().then((result) => {
      let projects = result.data.map((p) => {
        return {text: p.name, value: p.id};
      });
      if (this.props.showAll) {
        this.setState(
          {
            loading: false,
            projects: projects
          },
          cb
        );
      } else {
        projects = projects.filter((p) =>
          this.props.projects.some((p2) => p.value === p2.id)
        );
        const projectAccessible = projects.length > 0;
        if (this.props.value) {
          const index = projects.findIndex((x) => x.value === this.props.value);
          if (index === -1)
            projects.push({
              value: this.props.value,
              text: this.props.loadingText
            });
        }
        this.setState(
          {
            loading: false,
            projects: projectAccessible
              ? projects
              : [{value: this.props.value, text: this.props.loadingText ?? ""}],
            disabled: !projectAccessible
          },
          cb
        );
      }
    });
  }

  render() {
    const {projects, loading} = this.state;
    const {value, required, onChange} = this.props;

    return (
      <>
        {this.props.readOnly ?
          <Form.Input
            fluid
            transparent
            readOnly
            label="Project"
            value={projects.length > 0 ? projects.find((p) => {return p.value === value}).text : ""}
          /> :
          <Form.Dropdown
            fluid
            selection
            search
            error={this.props.error}
            loading={loading}
            required={required ?? false}
            label="Project"
            text={loading ? this.props.loadingText ?? "" : null}
            options={projects.sort((a, b) => {
              return a.text > b.text ? 1 : -1;
            })}
            placeholder="Select Project"
            value={value}
            onChange={onChange}
            disabled={this.state.disabled}
            data-testid="project-dropdown"
            noResultsMessage="You are not a member of any project"
          />
        }
      </>
    );
  }
}

ProjectSelect.propTypes = {
  loadingText: PropTypes.string,
  value: PropTypes.number,
  required: PropTypes.bool,
  showAll: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool
};

function mapStateToProps(state) {
  const {user} = state;

  return {
    projects: user.projects
  };
}

export default connect(mapStateToProps)(ProjectSelect);
