import React from "react";
import {connect} from "react-redux";
import {Button, Form, Segment, Message, Label} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";
import ReceiverFind from "./ReceiverFind";
import {
  doesReceiverHaveId,
  isAdmin,
  receiverDeploymentEventDatesAreNotInRange
} from "./helpers";

import {
  station,
  projectById,
  historicDeploymentRecoveryCreate
} from "../axios/api";

import FormModal from "./FormModal";
import DateSelector from "./DateSelector";
import ReceiverSummary from "./ReceiverSummary";
import ProjectSelect from "./ProjectSelect";
import StationSelect from "./StationSelect";
import Location from "./Location";
import {toUTC} from "./helpers";
import moment from "moment";

const receiverOrientations = ["UP", "DOWN"];
const validReceiverDeploymentStatus = ["DEPLOYED", "RECOVERED", "LOST"];

const emptyDeployment = {
  receiverId: "",
  stationId: null,
  latitude: null,
  longitude: null,
  deploymentDate: "",
  deploymentTimezone: "",
  acousticReleaseBrand: "",
  acousticReleaseId: "",
  mooringDescriptor: "",
  bottomDepthMeters: 0,
  depthBelowSurface: 0,
  receiverOrientation: "",
  comments: "",
  receiverError: null,
  projectError: null
};

const emptyRecovery = {
  deploymentId: null,
  status: "RECOVERED",
  recoveredByUserId: "",
  recoveryTimezone: "",
  recoveryDate: toUTC(new Date(), "UTC"),
  latitude: "",
  longitude: "",
  comments: "",
  receiverFindValue: ""
};

const payload = {
  deployment: {...emptyDeployment},
  recovery: {...emptyRecovery}
};

class ReceiverHistoricDeploymentRecovery extends React.Component {
  originalState = {};

  constructor(props) {
    super(props);

    this.state = {
      receiverFullId: "",
      loadingReceiver: false,
      loadingStations: false,
      valid: false,
      stations: [],
      projectOrganisationIds: [],
      receiverTypes: config.get("receiver_device_model_names"),
      receiver: null,
      createLabel: "Create Historic Deployment",
      viewLabel: "Deployment Details",
      success: false,
      showSearch: true,
      recoveredByOptions: [],
      receiverRecoveryStatus: config.get("receiver_recovery_status"),
      projectId: null,
      payload: {...payload},
      locationValid: true
    };

    this.originalState = {...this.state};

    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleReceiverIdChange = this.handleReceiverIdChange.bind(this);
    this.handleDeployDateChange = this.handleDeployDateChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleStationChange = this.handleStationChange.bind(this);
    this.handleFoundReceiver = this.handleFoundReceiver.bind(this);
    this.handleReceiverIdNext = this.handleReceiverIdNext.bind(this);
    this.handleDeploymentLocationChange = this.handleDeploymentLocationChange.bind(
      this
    );
    this.handleReleaseBrandBlur = this.handleReleaseBrandBlur.bind(this);
    this.handleAcousticReleaseIdBlur = this.handleAcousticReleaseIdBlur.bind(
      this
    );
    this.handleMooringDescriptorBlur = this.handleMooringDescriptorBlur.bind(
      this
    );
    this.handleBottomDepthChange = this.handleBottomDepthChange.bind(this);
    this.handleReceiverDepthBelowSurfaceChange = this.handleReceiverDepthBelowSurfaceChange.bind(
      this
    );
    this.handleReceiverOrientationChange = this.handleReceiverOrientationChange.bind(
      this
    );
    this.handleDeploymentCommentsChange = this.handleDeploymentCommentsChange.bind(
      this
    );

    this.handleRecoveryLocationChange = this.handleRecoveryLocationChange.bind(
      this
    );
    this.handleRecoveryStatusChange = this.handleRecoveryStatusChange.bind(
      this
    );
    this.handleRecoveredByChange = this.handleRecoveredByChange.bind(this);
    this.handleRecoveryDateChange = this.handleRecoveryDateChange.bind(this);
    this.handleRecoveryCommentsBlur = this.handleRecoveryCommentsBlur.bind(
      this
    );
  }

  componentDidMount() {
    if (this.props.deployReceiverId) {
      this.setState({receiverFullId: this.props.deployReceiverId});
      this.lookupReceiver(this.props.deployReceiverId);
    }
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    const {deployment, recovery} = this.state.payload;
    const {locationValid} = this.state;
    const valid =
      deployment.receiverId === this.state.receiver?.id &&
      deployment.stationId &&
      deployment.deploymentDate &&
      deployment.deploymentTimezone &&
      deployment.latitude &&
      deployment.longitude &&
      deployment.bottomDepthMeters > 0 &&
      deployment.depthBelowSurface > 0 &&
      deployment.receiverOrientation &&
      recovery.status &&
      recovery.recoveredByUserId &&
      recovery.recoveryDate &&
      recovery.recoveryTimezone &&
      (locationValid || recovery.status === "LOST") &&
      !this.state.deploymentDateNotValid &&
      !this.state.recoveryDateNotValid;

    return !valid;
  }

  successAction(response) {
    this.setState((s) => {
      return {
        ...this.originalState,
        success: true,
        successContent: `Historic Receiver Deployment and Recovery has been entered for receiver ${s.receiverFullId}`
      };
    });
    if (this.props.onUpdate) {
      this.props.onUpdate({
        ...response,
        ...response.location,
        projectName: this.state.projectName,
        stationName: this.state.stationName,
        receiver: {...this.state.receiver}
      });
    }
  }

  handleReceiverIdChange(value) {
    this.setState({receiverFullId: value});
  }

  handleFoundReceiver(receiver) {
    if (receiver.history.length === 0) {
      this.setState({
        loadingReceiver: false,
        valid: false,
        receiverError:
          "Error: Receiver must have existing deployment/recovery information in order to create historic events."
      });
      return;
    }
    if (validReceiverDeploymentStatus.includes(receiver.status)) {
      this.setState((s) => {
        return {
          loadingReceiver: false,
          receiver: {...receiver},
          payload: {
            deployment: {
              ...s.payload.deployment,
              receiverId: receiver.id
            },
            recovery: {...s.payload.recovery}
          }
        };
      }, this.checkFormValid);
    }
  }

  handleDeployDateChange(value) {
    const datesNotValid =
      value.timezone !== ""
        ? receiverDeploymentEventDatesAreNotInRange(
            this.state.receiver.history,
            {
              deploymentDate: value,
              recoveryDate: {
                utc: this.state.payload.recovery.recoveryDate,
                timezone: this.state.payload.recovery.recoveryTimezone
              }
            },
            true
          )
        : false;
    this.setState(
      (s) => {
        return {
          recoveryDateNotValid: datesNotValid.recoveryDateNotInRange,
          deploymentDateNotValid: datesNotValid.deploymentDateNotInRange,
          payload: {
            deployment: {
              ...s.payload.deployment,
              deploymentDate: value.utc,
              deploymentTimezone: value.timezone
            },
            recovery: {
              ...s.payload.recovery,
              recoveryTimezone: s.payload.recovery.recoveryTimezone
                ? s.payload.recovery.recoveryTimezone
                : value.timezone
            }
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleProjectChange(e, {value}) {
    this.setState({
      projectId: value,
      projectName: e.target.textContent,
      stations: [],
      loadingStations: true
    });
    station({projectId: value, size: 1000}).then((result) => {
      const stations = result.data.content;
      this.setState((s) => {
        return {
          loadingStations: false,
          stations: stations,
          projectError: stations?.length > 0 ? null : "Project has no stations",
          stationLocation: null,
          payload: {
            deployment: {
              ...s.payload.deployment,
              stationId: null,
              latitude: null,
              longitude: null
            },
            recovery: {...s.payload.recovery}
          }
        };
      });
    });

    projectById(value).then((result) => {
      const members = result.project.projectRoles.filter((r) => {
        return r.access !== "REVOKED";
      });
      const recoveredByOptions = members.map((m) => {
        return {
          key: m.id,
          text: m.user.name,
          value: m.user.id,
          object: m.user
        };
      });
      recoveredByOptions.push({
        key: this.props.userId,
        text: this.props.name,
        value: this.props.userId,
        object: {name: this.props.name}
      });
      this.setState(
        {
          loadingReceiver: false,
          recoveredByOptions: recoveredByOptions,
          projectOrganisationIds: result.project.organisations.map((o) => o.id)
        },
        () => this.checkFormValid()
      );
    });
  }

  handleStationChange(station) {
    this.setState(
      (s) => {
        return {
          stationLocation: station.location,
          stationName: station.name,
          payload: {
            deployment: {
              ...s.payload.deployment,
              stationId: station.id,
              longitude: station.location.longitude,
              latitude: station.location.latitude
            },
            recovery: {...s.payload.recovery}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleDeploymentLocationChange(e, value) {
    this.setState(
      (s) => {
        return {
          payload: {
            deployment: {
              ...s.payload.deployment,
              latitude: parseFloat(value.latitude),
              longitude: parseFloat(value.longitude)
            },
            recovery: {
              ...s.payload.recovery,
              latitude: s.payload.recovery.latitude
                ? s.payload.recovery.latitude
                : parseFloat(value.latitude),
              longitude: s.payload.recovery.longitude
                ? s.payload.recovery.longitude
                : parseFloat(value.longitude)
            }
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleReleaseBrandBlur(e) {
    const value = e.target.value;
    this.setState(
      (s) => {
        return {
          payload: {
            deployment: {
              ...s.payload.deployment,
              acousticReleaseBrand: value
            },
            recovery: {...s.payload.recovery}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleAcousticReleaseIdBlur(e) {
    const value = e.target.value;
    this.setState(
      (s) => {
        return {
          payload: {
            deployment: {
              ...s.payload.deployment,
              acousticReleaseId: value
            },
            recovery: {...s.payload.recovery}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleMooringDescriptorBlur(e) {
    const value = e.target.value;
    this.setState(
      (s) => {
        return {
          payload: {
            deployment: {
              ...s.payload.deployment,
              mooringDescriptor: value
            },
            recovery: {
              ...s.payload.recovery
            }
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleBottomDepthChange(e) {
    const value = parseFloat(e.target.value);
    this.setState(
      (s) => {
        return {
          bottomDepthMetersError:
            value <= 0 ? "Depth below surface must be greater than zero" : null,
          payload: {
            deployment: {
              ...s.payload.deployment,
              bottomDepthMeters: value
            },
            recovery: {...s.payload.recovery}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleReceiverDepthBelowSurfaceChange(e) {
    const value = parseFloat(e.target.value);
    this.setState(
      (s) => {
        return {
          depthBelowSurfaceError:
            value <= 0 ? "Depth below surface must be greater than zero" : null,
          payload: {
            deployment: {
              ...s.payload.deployment,
              depthBelowSurface: value
            },
            recovery: {...s.payload.recovery}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleReceiverOrientationChange(e, {value}) {
    this.setState(
      (s) => {
        return {
          payload: {
            deployment: {
              ...s.payload.deployment,
              receiverOrientation: value
            },
            recovery: {...s.payload.recovery}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleDeploymentCommentsChange(e) {
    const value = e.target.value;
    this.setState(
      (s) => {
        return {
          payload: {
            deployment: {
              ...s.payload.deployment,
              comments: value
            },
            recovery: {...s.payload.recovery}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleRecoveryLocationChange(e, value) {
    this.setState(
      (s) => {
        return {
          locationValid: value.valid,
          payload: {
            recovery: {
              ...s.payload.recovery,
              latitude: parseFloat(value.latitude),
              longitude: parseFloat(value.longitude)
            },
            deployment: {...s.payload.deployment}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleRecoveryStatusChange(e, {value}) {
    this.setState(
      (s) => {
        return {
          payload: {
            recovery: {...s.payload.recovery, status: value},
            deployment: {...s.payload.deployment}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleRecoveredByChange(e, {value}) {
    this.setState(
      (s) => {
        return {
          payload: {
            recovery: {
              ...s.payload.recovery,
              recoveredByUserId: value
            },
            deployment: {...s.payload.deployment}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleRecoveryDateChange(value) {
    const datesNotValid =
      value.timezone !== ""
        ? receiverDeploymentEventDatesAreNotInRange(
            this.state.receiver.history,
            {
              deploymentDate: {
                utc: this.state.payload.deployment.deploymentDate,
                timezone: this.state.payload.deployment.deploymentTimezone
              },
              recoveryDate: value
            },
            true
          )
        : false;
    this.setState(
      (s) => {
        return {
          recoveryDateNotValid: datesNotValid.recoveryDateNotInRange,
          deploymentDateNotValid: datesNotValid.deploymentDateNotInRange,
          payload: {
            recovery: {
              ...s.payload.recovery,
              recoveryDate: value.utc,
              recoveryTimezone:
                value.timezone !== "" &&
                value.timezone !== s.payload.recovery.recoveryTimezone
                  ? value.timezone
                  : s.payload.recovery.recoveryTimezone
            },
            deployment: {...s.payload.deployment}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleRecoveryCommentsBlur(e) {
    const value = e.target.value;
    this.setState(
      (s) => {
        return {
          payload: {
            recovery: {
              ...s.payload.recovery,
              comments: value
            },
            deployment: {...s.payload.deployment}
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleReceiverIdNext(e) {
    e.preventDefault();
    this.setState((s) => {
      return {showSearch: false};
    });
  }

  render() {
    const {
      success,
      createLabel,
      showSearch,
      receiver,
      receiverFullId,
      receiverRecoveryStatus,
      recoveredByOptions,
      payload,
      successContent
    } = this.state;
    const {deployment, recovery} = payload;
    const {trigger, roles} = this.props;
    const {
      checkFormValid,
      successAction,
      onClose,
      handleReceiverIdChange,
      handleFoundReceiver,
      handleDeployDateChange,
      handleProjectChange,
      handleStationChange,
      handleDeploymentLocationChange,
      handleReleaseBrandBlur,
      handleAcousticReleaseIdBlur,
      handleMooringDescriptorBlur,
      handleBottomDepthChange,
      handleReceiverDepthBelowSurfaceChange,
      handleReceiverOrientationChange,
      handleDeploymentCommentsChange,
      handleRecoveryLocationChange,
      handleRecoveryStatusChange,
      handleRecoveredByChange,
      handleRecoveryDateChange,
      handleRecoveryCommentsBlur
    } = this;

    const canContinue = doesReceiverHaveId(receiver, receiverFullId);
    const workflowWarning = (
      <Message color="orange">
        Please use this workflow only if entering historic deployment or
        recovery information for receivers that have been deployed in the
        database more recently (e.g. entering 2015 deployment information for
        receiver {receiverFullId !== "" ? receiverFullId : "VR2W-111111"}, which
        is already deployed in the database for{" "}
        {receiverFullId !== "" ? "2020" : moment().format("YYYY")})
      </Message>
    );

    const searchInputs = (
      <>
        {workflowWarning}
        <ReceiverFind
          onChange={handleReceiverIdChange}
          onFound={handleFoundReceiver}
          value={receiverFullId}
        />
        <Button
          primary
          onClick={this.handleReceiverIdNext}
          disabled={!canContinue}
        >
          Next
        </Button>
      </>
    );

    const headerRow = (
      <>
        {workflowWarning}
        <Segment>
          <ReceiverSummary value={this.state.receiver} />
        </Segment>
      </>
    );

    const deployInputs = (
      <>
        <Segment>
          <Label attached="top left">Deployment</Label>
          <Form.Field
            control={DateSelector}
            error={this.state.deploymentDateNotValid}
            required
            dontShowFieldsRequired
            timezone={this.state.payload.deployment.deploymentTimezone}
            onChange={handleDeployDateChange}
            label="Deployment Date and Timezone"
          />
          <ProjectSelect
            required
            value={deployment.projectId}
            error={this.state.projectError}
            showAll={isAdmin(roles)}
            onChange={handleProjectChange}
          />
          {this.state.projectOrganisationIds.length > 0 &&
          !this.state.projectOrganisationIds.includes(
            this.state.receiver.organisation.id
          ) ? (
            <Message color="orange">
              Warning: Project deploying the receiver is not registered under
              the organisation who purchased the receiver. The Admin and the
              Project Leader of the Project who registered the receiver will be
              notified via email of this new deployment.
            </Message>
          ) : null}
          <StationSelect
            required
            loading={this.state.loadingStations}
            disabled={this.state.stations.length === 0}
            stations={this.state.stations}
            options={this.state.stations.map((s) => {
              return {text: s.name, value: s.id};
            })}
            value={this.state.payload.deployment.stationId}
            onChange={handleStationChange}
          />
          {this.state.stationLocation ? (
            <Form.Field
              control={Location}
              label="Location"
              required
              warn={false}
              station={{
                longitude: this.state.stationLocation.longitude,
                latitude: this.state.stationLocation.latitude,
                radius: 500
              }}
              radiusWarning="Coordinates are outside a 500m radius from the station's registered location"
              warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
              value={{
                longitude: deployment.longitude,
                latitude: deployment.latitude
              }}
              onChange={handleDeploymentLocationChange}
            />
          ) : null}
          <Form.Input
            autoComplete="none"
            placeholder="e.g. VEMCO"
            label="Acoustic Release Brand"
            maxLength={255}
            defaultValue={this.state.payload.deployment.acousticReleaseBrand}
            onBlur={handleReleaseBrandBlur}
          />
          <Form.Input
            label="Acoustic Release ID"
            maxLength={255}
            defaultValue={this.state.payload.deployment.acousticReleaseId}
            autoComplete="none"
            onBlur={handleAcousticReleaseIdBlur}
          />
          <Form.Input
            label="Mooring Descriptor"
            maxLength={255}
            autoComplete="none"
            placeholder="eg. Star Picket, Float Mooring, Fish Aggregating Device, Permanent mooring (e.g. navigation aid), Other (please specify)"
            defaultValue={this.state.payload.deployment.mooringDescriptor}
            onBlur={handleMooringDescriptorBlur}
          />
          <Form.Input
            required
            label="Bottom (Sea Floor) Depth (m)"
            type="number"
            min={0}
            step={0.001}
            autoComplete="none"
            defaultValue={this.state.payload.deployment.bottomDepthMeters}
            error={this.state.bottomDepthMetersError}
            onChange={handleBottomDepthChange}
          />
          <Form.Input
            required
            type="number"
            min={0}
            step={0.001}
            autoComplete="none"
            label="Receiver Depth Below Surface (m)"
            value={this.state.payload.deployment.depthBelowSurface}
            error={this.state.depthBelowSurfaceError}
            onChange={handleReceiverDepthBelowSurfaceChange}
          />
          <Form.Dropdown
            required
            fluid
            selection
            search
            label="Receiver Orientation"
            options={receiverOrientations.map((v) => ({text: v, value: v}))}
            value={this.state.payload.deployment.receiverOrientation}
            onChange={handleReceiverOrientationChange}
          />
          <Form.TextArea
            label="Deployment Comments"
            defaultValue={this.state.payload.deployment.comments}
            onBlur={handleDeploymentCommentsChange}
          />
        </Segment>
      </>
    );

    const recoverInputs = (
      <>
        <Segment>
          <Label attached="top left">Recovery</Label>
          <Form.Dropdown
            required
            fluid
            selection
            search
            label="Recovery Status"
            options={receiverRecoveryStatus.map((v) => ({text: v, value: v}))}
            value={recovery.status}
            onChange={handleRecoveryStatusChange}
          />
          <Form.Dropdown
            required
            fluid
            selection
            search
            label="Recovered By"
            options={recoveredByOptions}
            value={recovery.recoveredByUserId}
            onChange={handleRecoveredByChange}
          />
          <Form.Field
            control={DateSelector}
            error={this.state.recoveryDateNotValid}
            required
            dontShowFieldsRequired
            label="Recovery Date and Timezone"
            date={this.state.payload.recoveryDate}
            timezone={this.state.payload.recovery.recoveryTimezone}
            onChange={handleRecoveryDateChange}
          />
          <Form.Field
            disabled={recovery.status === "LOST"}
            control={Location}
            label={
              recovery.status === "LOST"
                ? "Location - will not be saved when receiver is LOST"
                : "Location"
            }
            required={recovery.status !== "LOST"}
            warn={false}
            radiusWarning="Coordinates are outside a 500m radius from the station's registered location"
            warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
            value={{
              longitude: recovery.longitude,
              latitude: recovery.latitude
            }}
            onChange={handleRecoveryLocationChange}
          />
          <Form.TextArea
            label="Recovery Comments"
            defaultValue={this.state.payload.recovery.comments}
            onBlur={handleRecoveryCommentsBlur}
          />
        </Segment>
      </>
    );

    let modalAttrs = showSearch
      ? {formInputs: {...searchInputs}}
      : {
          headerRow: {...headerRow},
          passiveColumn: 1,
          passiveElements: {...deployInputs},
          formInputs: {...recoverInputs}
        };

    return (
      <FormModal
        open
        {...modalAttrs}
        formSubmitAPI={historicDeploymentRecoveryCreate}
        formData={this.state.payload}
        checkFormValid={checkFormValid}
        testId="historic-deployment-form"
        successHeader="Success"
        onSuccess={successAction}
        success={success}
        headerIcon="microphone"
        trigger={trigger}
        successContent={successContent}
        headerContent={createLabel}
        submitContent="Enter historic deployment/recovery"
        onClose={onClose}
      />
    );
  }
}

ReceiverHistoricDeploymentRecovery.propTypes = {
  deployReceiverId: PropTypes.string,
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles,
    userId: user.userId,
    username: user.username,
    name: user.name
  };
}

export default connect(mapStateToProps)(ReceiverHistoricDeploymentRecovery);
