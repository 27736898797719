import React from "react";
import {Form} from "semantic-ui-react";
import {preventSpaces} from "./helpers";
import PropTypes from "prop-types";

// Check password
const checkPasswordMatchError = {
  content: "The two passwords must be the same",
  pointing: "above"
};

class Password extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      fieldLabel: props.fieldLabel ?? "Password",
      checkPassword: "",
      passwordValid: false,
      checkPasswordValid: false,
      passwordErrorMessage: false,
      checkPasswordErrorMessage: false
    };

    this.showCheckPassword = this.showCheckPassword.bind(this);
    this.handlePasswordBlur = this.handlePasswordBlur.bind(this);
    this.handleCheckPasswordBlur = this.handleCheckPasswordBlur.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleCheckPasswordBlur = this.handleCheckPasswordBlur.bind(this);
    this.handleCheckPasswordChange = this.handleCheckPasswordChange.bind(this);
    this.handleCheckPasswordFocus = this.handleCheckPasswordFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCheckPasswordKeyDown = this.handleCheckPasswordKeyDown.bind(
      this
    );
  }

  setFormState() {
    if (this.state.passwordValid && this.state.checkPasswordValid) {
      this.props.onValidate(this.state.password, true);
    } else {
      this.props.onValidate(this.state.password, false);
    }
  }

  handlePasswordBlur(e) {
    let newValue = e.target.value;
    let newState = {};

    if (this.props.createPassword) {
      // Regex test
      if (this.props.passwordRegex.test(newValue)) {
        newState = {
          passwordValid: true,
          password: newValue,
          passwordErrorMessage: false
        };
      } else {
        newState = {
          passwordValid: false,
          password: newValue,
          passwordErrorMessage: this.props.passwordRegexMessage
        };
      }
      // Compare test
      if (this.state.checkPassword !== "") {
        if (newValue === this.state.checkPassword) {
          newState.checkPasswordValid = true;
          newState.checkPasswordErrorMessage = false;
        } else {
          newState.checkPasswordValid = false;
          newState.checkPasswordErrorMessage = checkPasswordMatchError;
        }
      } else {
        newState.checkPasswordValid = false;
        newState.checkPasswordErrorMessage = checkPasswordMatchError;
      }
      this.setState({...newState}, this.setFormState);
    } else {
      newState = {password: newValue};
      this.setState({...newState}, this.setFormState);
    }
  }

  handleCheckPasswordFocus(e) {
    this.setState(
      {checkPasswordValid: true, checkPasswordErrorMessage: false},
      this.setFormState
    );
  }

  handlePasswordChange(e) {
    this.setState(
      {
        password: preventSpaces(e.target.value)
      },
      this.setFormState
    );
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handlePasswordBlur(e);
    }
  }

  handleCheckPasswordKeyDown(e) {
    if (e.key === "Enter") {
      this.handleCheckPasswordBlur(e);
    }
  }

  handleCheckPasswordBlur(e) {
    let newValue = e.target.value;
    let newState = {};

    // Compare test
    if (newValue === this.state.password) {
      newState.checkPasswordValid = true;
      newState.checkPasswordErrorMessage = false;
    } else {
      newState.checkPasswordValid = false;
      newState.checkPasswordErrorMessage = checkPasswordMatchError;
    }

    this.setState({...newState}, this.setFormState);
  }

  handleCheckPasswordChange(e) {
    this.setState({checkPassword: preventSpaces(e.target.value)});
  }

  showCheckPassword() {
    const {required} = this.props;
    const {
      showPassword,
      checkPasswordErrorMessage,
      checkPassword,
      fieldLabel
    } = this.state;
    const {
      handleCheckPasswordBlur,
      handleCheckPasswordChange,
      handleCheckPasswordFocus,
      handleCheckPasswordKeyDown
    } = this;

    if (this.props.createPassword) {
      return (
        <Form.Input
          fluid
          required={required}
          label={`Confirm ${fieldLabel}`}
          placeholder={`Confirm ${fieldLabel}`}
          type={showPassword ? "text" : "password"}
          error={checkPasswordErrorMessage}
          onBlur={handleCheckPasswordBlur}
          onChange={handleCheckPasswordChange}
          onFocus={handleCheckPasswordFocus}
          onKeyDown={handleCheckPasswordKeyDown}
          value={checkPassword}
          autoComplete="true"
          data-testid="checkpassword"
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const {required, autoComplete} = this.props;
    const {
      showPassword,
      passwordErrorMessage,
      password,
      fieldLabel
    } = this.state;
    const {
      showCheckPassword,
      handlePasswordBlur,
      handlePasswordChange,
      handleKeyDown
    } = this;

    return (
      <Form.Group widths="equal">
        <Form.Input
          fluid
          required={required}
          label={fieldLabel}
          placeholder={fieldLabel}
          type={showPassword ? "text" : "password"}
          error={passwordErrorMessage}
          onBlur={handlePasswordBlur}
          onChange={handlePasswordChange}
          onKeyDown={handleKeyDown}
          value={password}
          autoComplete={autoComplete ? "true" : "new-password"}
          data-testid="password"
        />
        {showCheckPassword()}
      </Form.Group>
    );
  }
}

Password.propTypes = {
  onValidate: PropTypes.func,
  createPassword: PropTypes.bool,
  passwordRegex: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  showCheckPassword: PropTypes.bool,
  handlePasswordBlur: PropTypes.func,
  handlePasswordChange: PropTypes.func,
  autoComplete: PropTypes.bool
};

export default Password;
