import React from "react";
import PropTypes from "prop-types";
import {Table} from "semantic-ui-react";
import {toDisplayUtc, locationToString} from "./helpers";

class ReceiverHistoryList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tableData: props.data?.reduce((accumulator, d) => {
        return accumulator.concat({
          deploymentDate: toDisplayUtc(
            d.deployment.deploymentDate,
            d.deployment.deploymentTimezone
          ),
          deploymentLocation: locationToString(d.deployment.location),
          recoveryDate: d.recovery
            ? toDisplayUtc(d.recovery.recoveryDate, d.recovery.recoveryTimezone)
            : "",
          recoveredBy: d.recovery ? d.recovery.recoverer.name : "",
          recoveryLocation: locationToString(d.recovery?.location),
          comments: d.recovery?.comments ?? d.deployment.comments,
          project: d.deployment.projectName,
          installation: d.deployment.installationName,
          station: d.deployment.stationName,
            depthBelowSurface: d.deployment.depthBelowSurface
        });
      }, [])
    };
  }

  row(r, index) {
    return {
      key: index,
      cells: [
        {key: 0, content: r.deploymentDate},
        {key: 1, content: r.project},
        {key: 2, content: r.installation},
        {key: 3, content: r.station},
        {key: 4, content: r.depthBelowSurface},
        {key: 5, content: r.deploymentLocation},
        {key: 6, content: r.recoveryDate},
        {key: 7, content: r.recoveredBy},
        {key: 8, content: r.recoveryLocation},
          {key: 9, content: r.comments}
      ]
    };
  }

  render() {
    const headerRow = (
      <Table.Row
        cells={[
          {
            key: 0,
            as: () => <Table.HeaderCell content="Deployment Date (UTC)" />
          },
          {
            key: 1,
            as: () => <Table.HeaderCell content="Project" />
          },
          {
            key: 2,
            as: () => <Table.HeaderCell content="Installation" />
          },
          {
            key: 3,
            as: () => <Table.HeaderCell content="Station" />
          },
          {
            key: 4,
            as: () => <Table.HeaderCell content="Depth Below Surface (m)" />
          },
          {
            key: 5,
            as: () => <Table.HeaderCell content="Deployment Location" />
          },
          {
            key: 6,
            as: () => <Table.HeaderCell content="Recovery Date (UTC)" />
          },
          {key: 7, as: () => <Table.HeaderCell content="Recovered By" />},
          {key: 8, as: () => <Table.HeaderCell content="Recovery Location" />},
          {key: 9, as: () => <Table.HeaderCell content="Comments" />}
        ]}
      />
    );

    return (
      <>
        <strong>Receiver Deployment History</strong>
        <Table
          celled
          tableData={this.state.tableData}
          renderBodyRow={this.row}
          headerRow={headerRow}
          className="at-modal-content"
        />
      </>
    );
  }
}

ReceiverHistoryList.propTypes = {
  data: PropTypes.array.isRequired
};

export default ReceiverHistoryList;
