import {NAVIGATE, USER_LOGOUT} from "../constants/action-types";

const initialState = {returnToPage: "/"};

function routeReducer(state = initialState, action) {
  if (action.type === NAVIGATE) {
    return {...state, ...action.payload};
  }

  if (action.type === USER_LOGOUT) {
    return {...state, ...initialState};
  }

  return state;
}

export default routeReducer;
