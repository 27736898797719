import React from "react";
import {Form, Grid, Message, Segment} from "semantic-ui-react";
import PropTypes from "prop-types";

const defaultState = {
  valid: false,
  warning: "",
  longitudeWarning: "",
  latitudeWarning: "",
  longitude: "",
  latitude: "",
  latitudeError: false,
  longitudeError: false
};

class Location extends React.Component {
  rangePreferred = {
    minLat: -90,
    maxLat: 0,
    minLong: -180,
    maxLong: 180
  };
  rangeWarnings = {
    minLat: "",
    maxLat:
      "The entered location is in the NORTHERN hemisphere. Change the latitude to a negative value if this is not correct.",
    minLong: "",
    maxLong: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      longitude: props.value?.longitude?.toString() ?? "",
      latitude: props.value?.latitude?.toString() ?? "",
      valid: this.checkValid(props.value?.longitude, props.value?.latitude)
    };
    this.handleLongitudeChange = this.handleLongitudeChange.bind(this);
    this.handleLatitudeChange = this.handleLatitudeChange.bind(this);
    this.checkValid = this.checkValid.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.warn !== this.props.warn) {
      this.setState({
        warning: this.props.warn ? this.props.warning : ""
      });
    }
    if (
      this.props.value?.longitude &&
      prevProps.value?.longitude !== this.props.value?.longitude
    ) {
      this.setState({
        longitude: this.props.value.longitude.toString()
      });
    }
    if (
      this.props.value?.latitude &&
      prevProps.value?.latitude !== this.props.value?.latitude
    ) {
      this.setState({
        latitude: this.props.value.latitude.toString()
      });
    }
  }

  calculateHaversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6378.137; // Radius of earth in kilometers
    const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  smallNumbersToFixed(x, readOnly) {
    if (readOnly && x === "") {
      x = "0";
    } else if (Math.abs(x) < 1.0) {
      let parts = x.toString().split("e-");
      let n = parseInt(parts[1]);
      if (n) {
        let m = parts[0].replace(".", "").replace("-", "");
        let sign = parts[0].charAt(0) === "-" ? "-" : "";
        x = sign + "0." + "0".repeat(n - 1) + m;
      }
    }
    return x;
  }

  handleLongitudeChange(e) {
    const newValue = e.target.value;
    let warning = "";
    let radiusWarning = "";
    let errors = {valid: true};

    if (newValue < this.rangePreferred.minLong)
      warning = this.rangeWarnings.minLong;
    if (newValue > this.rangePreferred.maxLong)
      warning = this.rangeWarnings.maxLong;
    errors = this.checkValid(
      parseFloat(newValue),
      parseFloat(this.state.latitude)
    );
    if (
      !errors.latitude &&
      !errors.longitude &&
      this.props.station &&
      this.calculateHaversineDistance(
        this.props.station.latitude,
        this.props.station.longitude,
        this.state.latitude,
        newValue
      ) > this.props.station.radius
    ) {
      radiusWarning = this.props.radiusWarning;
    }

    this.setState(
      {
        longitudeError: errors.longitude,
        valid: errors.valid,
        longitudeWarning: warning,
        longitude: newValue,
        warning: radiusWarning
      },
      () => this.props.onChange(e, this.state)
    );
  }

  handleLatitudeChange(e) {
    const newValue = e.target.value;
    let warning = "";
    let radiusWarning = "";

    if (newValue < this.rangePreferred.minLat)
      warning = this.rangeWarnings.minLat;
    if (newValue > this.rangePreferred.maxLat)
      warning = this.rangeWarnings.maxLat;
    const errors = this.checkValid(
      parseFloat(this.state.longitude),
      parseFloat(newValue)
    );
    if (
      !errors.latitude &&
      !errors.longitude &&
      this.props.station &&
      this.calculateHaversineDistance(
        this.props.station.latitude,
        this.props.station.longitude,
        newValue,
        this.state.longitude
      ) > this.props.station.radius
    ) {
      radiusWarning = this.props.radiusWarning;
    }

    this.setState(
      {
        latitudeError: errors.latitude,
        valid: errors.valid,
        latitudeWarning: warning,
        latitude: newValue,
        warning: radiusWarning
      },
      () => this.props.onChange(e, this.state)
    );
  }

  checkValid(longitude, latitude) {
    const errors = {};
    errors.valid =
      longitude >= -180 &&
      longitude <= 180 &&
      latitude >= -90 &&
      latitude <= 90;
    errors.latitude =
      latitude >= -90 && latitude <= 90
        ? false
        : "Latitude must be between -90 and 90";
    errors.longitude =
      longitude >= -180 && longitude <= 180
        ? false
        : "Longitude must be between -180 and 180";

    return errors;
  }

  render() {
    const {
      handleLongitudeChange,
      handleLatitudeChange,
      smallNumbersToFixed
    } = this;
    const {
      longitude,
      latitude,
      longitudeWarning,
      latitudeWarning,
      warning,
      longitudeError,
      latitudeError
    } = this.state;
    const {required, readOnly} = this.props;

    const resolution = "any";

    const warnings = [];
    if (longitudeWarning.length > 0) warnings.push(longitudeWarning);
    if (latitudeWarning.length > 0) warnings.push(latitudeWarning);
    if (warning.length > 0) warnings.push(warning);

    return (
      <>
        <div>Decimal degrees (positive east, positive north)</div>
        <Segment>
          <Grid columns={3}>
            <Grid.Column key={0}>
              <Form.Input
                error={longitudeError}
                label="Longitude"
                placeholder="Decimal degree, positive east"
                required={required ? true : undefined}
                value={smallNumbersToFixed(longitude, readOnly)}
                onChange={handleLongitudeChange}
                type="number"
                min={-180}
                max={180}
                autoComplete="none"
                step={resolution}
                readOnly={readOnly}
                transparent={readOnly}
              />
            </Grid.Column>
            <Grid.Column key={1}>
              <Form.Input
                error={latitudeError}
                label="Latitude"
                placeholder="Decimal degree, positive north"
                required={required ? true : undefined}
                value={smallNumbersToFixed(latitude, readOnly)}
                onChange={handleLatitudeChange}
                type="number"
                min={-90}
                max={90}
                autoComplete="none"
                step={resolution}
                readOnly={readOnly}
                transparent={readOnly}
              />
            </Grid.Column>
            <Grid.Column key={2}>
              <Form.Input
                fluid
                readOnly
                transparent
                label="Datum"
                value="WGS84"
              />
            </Grid.Column>
          </Grid>
          <Message
            color="orange"
            hidden={warnings.length === 0}
            list={warnings}
          />
        </Segment>
      </>
    );
  }
}

Location.propTypes = {
  required: PropTypes.bool,
  warning: PropTypes.string,
  warn: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
};

export default Location;
