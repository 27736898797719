import React from "react";
import config from "react-global-configuration";
import {
    mysteryTags
} from "../axios/api";
import {Form, Message} from "semantic-ui-react";
import FormModal from "./FormModal";


class MysteryTagsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      success: false,
      responseErrors: [],
      redirect: null,
      mysteryTagsDetails: {transmitterIds: "", delimiter: 'COMMA'},
      delimiterOptions: config.get("mystery_tags_delimiter"),
      successReport: {
        registeredWithValidDetections: [],
        registeredWithoutDetections: [],
        unregisteredWithDetections: [],
        unregisteredWithoutDetections: []
      },
      createSuccess: false
    };

    this.handleTransmitterIdChange = this.handleTransmitterIdChange.bind(this);
    this.handleDelimiterSelectChange = this.handleDelimiterSelectChange.bind(this);
    this.successAction = this.successAction.bind(this);
    this.onClose = this.onClose.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
  }

  componentDidMount() {
  }

  handleTransmitterIdChange(e) {

    let newValue = e.target.value;
    this.setState(() => ({
      mysteryTagsDetails: {
        ...this.state.mysteryTagsDetails,
        transmitterIds: newValue
      }
    }));
  }

  handleDelimiterSelectChange(e, {value}) {
    this.setState(() => ({
      mysteryTagsDetails: {
        ...this.state.mysteryTagsDetails,
        delimiter: value
      }
    }));
  }

  successAction(responseData) {

    this.setState({
      success: true,
      loading: false,
      createSuccess: true,
      successReport: {
        ...this.state.successReport,
        registeredWithValidDetections: responseData.registeredWithValidDetections,
        registeredWithoutDetections: responseData.registeredWithoutDetections,
        unregisteredWithDetections: responseData.unregisteredWithDetections,
        unregisteredWithoutDetections: responseData.unregisteredWithoutDetections
      }})
  }

  onClose() {
    this.props.onClose();
  }

  checkFormValid() {
    return this.state.mysteryTagsDetails.transmitterIds.length === 0 || !this.state.delimiterOptions.includes(this.state.mysteryTagsDetails.delimiter);
  }

  render() {

    const {
      checkFormValid,
      handleTransmitterIdChange,
      handleDelimiterSelectChange,
      successAction,
      onClose,
    } = this;

    const {mysteryTagsDetails, success} = this.state;
    const {open} = this.props;
    const delimiterSelectOptions = this.state.delimiterOptions.map(opt => (
        {
          key: opt,
          value: opt,
          text: opt.toLowerCase()
        }
    ));

    const formInputs =
        (
            <>
              <Form.TextArea
                  label="Mystery Tag Lookup"
                  placeholder="Enter Transmitter IDs"
                  onChange={handleTransmitterIdChange}
                  value={mysteryTagsDetails.transmitterIds}
                  rows={10}
              />
              <Form.Select
                  label="delimiter"
                  options={delimiterSelectOptions}
                  onChange={handleDelimiterSelectChange}
                  value={mysteryTagsDetails.delimiter}
              />
            </>
        )

    let formSubmitAPI = mysteryTags;

    const successMessage = (
        <Message success>
          <Message.Header style={{marginTop: '5px'}}>Registered transmitters with detections:</Message.Header>
          {this.state.successReport.registeredWithValidDetections.length > 0 ?
              this.state.successReport.registeredWithValidDetections.map(transmitterId =>
                  <p style={{margin: '1px'}}>{transmitterId}</p>) :
              <p>-</p> }

          <Message.Header style={{marginTop: '5px'}}>Registered transmitters without detections:</Message.Header>
          {this.state.successReport.registeredWithoutDetections.length > 0 ?
              this.state.successReport.registeredWithoutDetections.map(transmitterId =>
                  <p style={{margin: '1px'}}>{transmitterId}</p>) :
              <p>-</p> }

          <Message.Header style={{marginTop: '5px'}}>Unregistered transmitters without detections </Message.Header>
            {this.state.successReport.unregisteredWithoutDetections.length > 0 ?
                this.state.successReport.unregisteredWithoutDetections.map(transmitterId =>
                    <p style={{margin: '1px'}}>{transmitterId}</p>) :
                <p>-</p> }

          <Message.Header style={{marginTop: '5px'}}>Unregistered transmitters with detections </Message.Header>
          {this.state.successReport.unregisteredWithDetections.length > 0 ?
              this.state.successReport.unregisteredWithDetections.map(transmitterId =>
                  <p style={{margin: '1px'}}>{transmitterId}</p>
                ) :
              <p>-</p> }
          {this.state.successReport.unregisteredWithDetections.length > 0 ? <p>To access available detections, these transmitters will need to be deployed in the database (refer to the User Manual in the Resources tab for instructions).</p> : null }
        </Message>
    )

    return (
      <FormModal
        formSubmitAPI={formSubmitAPI}
        formData={mysteryTagsDetails}
        checkFormValid={checkFormValid}
        successMessage={successMessage}
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerContent="Mystery Tag Lookup"
        subheaderContent="Type or copy/paste a list of transmitter IDs (e.g. A69-9008-12345) to check whether detections for these tags are currently available in the IMOS Australian Animal Acoustic Telemetry Database"
        headerIcon="tag"
        submitContent="Check"
        onClose={onClose}
        open={open}
      />
    );
  }
}

export default MysteryTagsForm;
