import React from "react";
import PropTypes from "prop-types";
import {Form, Grid, Table} from "semantic-ui-react";
import {dataSensorTypes, getTransmitterId} from "./helpers";

class TransmitterSummary extends React.Component {

  render() {

    const { value: transmitter } = this.props;

    const showSlopeInterceptUnit = dataSensorTypes.includes(transmitter.sensors[0].sensorTypeName);

    const tableBody = transmitter.sensors.map((s, i) => {
      return <Table.Row key={i}>
        <Table.Cell content={s.sensorTypeName} />
        <Table.Cell content={s.idCode} />
        { showSlopeInterceptUnit ?
          <>
            <Table.Cell content={s.slope} />
            <Table.Cell content={s.intercept} />
            <Table.Cell content={s.unit} />
          </> :
          null
        }
      </Table.Row>
    });

    return (<>
      <Grid columns={2}>
        <Grid.Column key={0}>
          <Form.Input
            fluid
            transparent
            readOnly
            label="Serial Number"
            value={transmitter.serialNumber}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Project"
            value={transmitter.project.name}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Codespace"
            value={transmitter.codeSpaceName}
          />
        </Grid.Column>
        <Grid.Column key={1}>
          <Form.Input
            fluid
            transparent
            readOnly
            label="Transmitter ID"
            value={getTransmitterId(transmitter)}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Transmitter Type"
            value={transmitter.deviceModelName}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label={
              <>
                <strong>Expected Lifetime (Days)</strong><br />
                <small>(This information is provided by the manufacturer upon purchase)</small>
              </>
            }
            value={
              transmitter.expectedLifeTimeDays ?
              transmitter.expectedLifeTimeDays :
              "-"
            }
          />
        </Grid.Column>
      </Grid>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Sensor Type</Table.HeaderCell>
            <Table.HeaderCell>Sensor ID Code</Table.HeaderCell>
            {showSlopeInterceptUnit ?
              <><Table.HeaderCell>Sensor Slope</Table.HeaderCell>
                <Table.HeaderCell>Sensor Intercept</Table.HeaderCell>
                <Table.HeaderCell>Sensor Unit</Table.HeaderCell></> :
              null
            }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableBody}
        </Table.Body>
      </Table>
    </>);
  }
}

TransmitterSummary.propTypes = {
  value: PropTypes.any
};

export default TransmitterSummary;
