import {COUNTRY_OPTIONS} from "./countriesData";
import moment from "moment-timezone";
import React, {Fragment} from "react";
import {
  checkDeploymentCanBeDeleted,
  checkHistoricalDeployment,
  checkReceiverCanBeDeleted,
  checkRecoveryCanBeDeleted
} from "../axios/api";

export const DataPolicyUrl =
  "https://s3-ap-southeast-2.amazonaws.com/content.aodn.org.au/Documents/IMOS/Facilities/animal_tracking/LATEST/IMOS_Animal_Tracking_Facility_Data_Policy_LATEST.pdf";
export const ProtectedStatusFormUrl =
  "https://s3-ap-southeast-2.amazonaws.com/content.aodn.org.au/Documents/IMOS/Facilities/animal_tracking/LATEST/IMOS-Animal_Tracking_Acoustic_Telemetry_Application_Form_Protected_Data_Status_LATEST.docx";
export const CurrentReceiverLocationsUrl =
  "https://portal.aodn.org.au/search?uuid=4a97bd11-e821-4682-8b20-cb69201f3223";
export const DeploymentRecordsTemplateUrl =
  "https://s3-ap-southeast-2.amazonaws.com/content.aodn.org.au/Documents/IMOS/Facilities/animal_tracking/LATEST/IMOS-Animal_Tracking_Acoustic_Telemetry_Deployment_records_TEMPLATE_LATEST.xlsx";
export const CitationsTemplateUrl =
  "https://s3-ap-southeast-2.amazonaws.com/content.aodn.org.au/Documents/IMOS/Facilities/animal_tracking/LATEST/READ_ME_citation-template-and-use-of-data.txt";
export const IntroductionToAcousticTelemetryUrl =
  "https://s3-ap-southeast-2.amazonaws.com/content.aodn.org.au/Documents/IMOS/Facilities/animal_tracking/LATEST/introduction-to-acoustic-telemetry/docs/IMOS_Intro-to-Animal-Tracking.html";
export const WebInterfaceUserManual =
  "https://s3-ap-southeast-2.amazonaws.com/content.aodn.org.au/Documents/IMOS/Facilities/animal_tracking/LATEST/IMOS_Acoustic_Web-Interface_User_Manual.pdf";

export const preventSpaces = (value) => {
  return value.replace(/ /g, "");
};

// Password
export const passwordValidate = {
  regex: new RegExp(/^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,20}$/),
  regexMessage:
    "Must be 8-20 characters, containing at least 1 letter, 1 number and 1 special character"
};

export const FormMode = {
  EDIT: "edit",
  CREATE: "create",
  VIEW: "view"
};

export const Roles = {
  PROJECT_LEADER: "PROJECT_LEADER",
  PROJECT_MEMBER: "PROJECT_MEMBER",
  ROLE_USER: "ROLE_USER",
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_ATF_ADMIN: "ROLE_ATF_ADMIN",
  HAS_ANY_PROJECT: "HAS_ANY_PROJECT"
};

export const isAdmin = (roles) => {
  return roles.some(
    (r) => [Roles.ROLE_ADMIN, Roles.ROLE_ATF_ADMIN].indexOf(r) >= 0
  );
};

export const isAdminNotATFAdmin = (roles) => {
  return roles.some((r) => [Roles.ROLE_ADMIN].indexOf(r) >= 0);
};

export const isProjectMember = (projectIds, projectId) => {
  return projectIds.includes(projectId);
};

export const canRecoveryBeDeleted = async (recoveryId) => {
  return (await checkRecoveryCanBeDeleted(recoveryId)).data;
};

export const canReceiverBeDeleted = async (receiverId) => {
  return (await checkReceiverCanBeDeleted(receiverId)).data;
};

export const canDeploymentBeDeleted = async (deploymentId) => {
  const {canDeploymentBeDeleted} = (await checkDeploymentCanBeDeleted(deploymentId)).data;
  return canDeploymentBeDeleted;
};

export const isHistoricalDeployment = async (deploymentId) => {
  const {isHistoricalDeployment} = (await checkHistoricalDeployment(deploymentId)).data;
  return isHistoricalDeployment;
};

export const formatOrganisationDescription = (city, country) => {
  let countryStr = COUNTRY_OPTIONS.find((o) => o.value === country);
  countryStr = typeof countryStr === "undefined" ? "" : countryStr.text;
  countryStr =
    city !== "" && countryStr !== "" ? ` - ${countryStr}` : countryStr;
  return `${city}${countryStr}`;
};

export const countryFullName = (country) => {
  let countryDetail = COUNTRY_OPTIONS.find((o) => o.value === country);
  return typeof countryDetail === "undefined" ? "" : countryDetail.text;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const getOptions = async (apiCall, template, filter, query) => {
  let last = false;
  let options = [];
  let page = 0;

  while (!last) {
    let result = await apiCall({page: page, size: 50, ...query});
    let content = result.data.content;
    last = result.data.last;
    if (!content) {
      content = result.data;
      last = true;
    }
    let newOptions = content.reduce((allowedOptions, option) => {
      if (
        typeof filter === "undefined" ||
        option[Object.keys(filter)[0]] === Object.values(filter)[0]
      ) {
        allowedOptions.push({
          text: option[template.text],
          value: option[template.value],
          object: option
        });
      }
      return allowedOptions;
    }, options);
    options = newOptions;
    page++;
  }
  return options;
};

export const getSearchParams = (location) => {
  return new URLSearchParams(location.search);
};

export const toDisplayUtc = (ts, tz) => {
  const d = moment.tz(ts, tz);
  return d.isValid() ? d.utc().format("YYYY-MM-DD HH:mm") : "-";
};

export const csvAndZipTimestamp = () => {
  const d = moment.utc().local();
  return d.isValid() ? d.format("YYYY-MM-DD_HH-mm-ss") : "";
};

export const toUTC = (dateTime, timezone) => {
  // Format for sending data to API: 2020-07-18T01:00:00.000+0000
  const original = moment.tz(dateTime, timezone);
  const utc = original.clone().tz("UTC");
  return utc.format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
};

export const toLocaltime = (dateTime, timezone, format) => {
  const original = moment.tz(dateTime, "UTC");
  const localTime = original.tz(timezone);
  if (!dateTime || !timezone) {
    return dateTime;
  }
  if (!format) format = "YYYY-MM-DDTHH:mm:ss.SSSZZ";
  return localTime.format(format);
};

export const fromUTC = (utc, format) => {
  // For display of data from API
  if (!utc || !utc.timestamp || !utc.timezone) return "-";
  const original = moment(utc.timestamp);
  const dateTime = original.clone().tz(utc.timezone);
  if (!format) format = "YYYY-MM-DD, HH:mm";
  return dateTime.format(format);
};

export const getTransmitterId = (transmitter) => {
  const ids = transmitter.sensors.map((s) => {
    return `${transmitter.codeSpaceName}-${s.idCode ? s.idCode : ""}`;
  });
  return ids.length > 0 ? ids.join(", ") : `${transmitter.codeSpaceName}-`;
};

export const dataSensorTypes = [
  "PRESSURE",
  "TEMPERATURE",
  "ACCELEROMETER",
  "DISSOLVED_OXYGEN"
];

export const unit = {
  PRESSURE: "m",
  TEMPERATURE: "Degrees Celsius",
  ACCELEROMETER: "m.sec-2",
  DISSOLVED_OXYGEN: "mg/L"
};

export const noReleaseSensorTypes = [
  "RANGE_TEST",
  "REFERENCE_TAG",
  "SYNCHRONISED_TAG"
];

export const locationToString = (l) => {
  return l?.longitude && l?.latitude
    ? `${l.longitude.toString()} ${l.latitude.toString()}`
    : "";
};

export const getSelectedTransmitterDeployment = (transmitter, deploymentId) => {
  // Returned deployment has {tagPostDto: { tagId: ..., }, releasePostDto: { id: ..., }, recoveryPostDto: { id: ..., } }
  // Recovery can be null
  // If no deployment id is provided then use the first deployment in history

  const selectedDeployment = {};

  if (transmitter.history !== null) {
    const idx = deploymentId
      ? transmitter.history.findIndex((e) => e.release.id === deploymentId)
      : -1;
    const release =
      idx === -1
        ? transmitter.history[0].release
        : transmitter.history[idx].release;
    const recovery =
      idx === -1
        ? transmitter.history[0].recovery
        : transmitter.history[idx].recovery;

    if (noReleaseSensorTypes.includes(transmitter.sensors[0]?.sensorTypeName)) {
      release.measurements = null;
      release.animal = null;
    }

    selectedDeployment.tagPostDto = {
      ...transmitter,
      tagId: transmitter.tagId ?? release.tagId,
      speciesScientificName:
        transmitter.speciesScientificName ??
        release.animal?.species.scientificName,
      speciesName: transmitter.speciesName ?? release.animal?.species.name
    };

    selectedDeployment.releasePostDto = {
      id: release.id,
      tagId: transmitter.tagId ?? release.tagId,
      projectId: release.projectId,
      speciesId: release.animal?.species.id,
      sex: release.animal?.sex,
      surgeryType: release.surgeryType,
      measurements: {...release.measurements},
      releaseLocality: release.releaseLocality,
      longitude: release.releaseLongitude,
      latitude: release.releaseLatitude,
      releaseDate: release.releaseDate,
      releaseTimezone: release.releaseTimezone,
      comments: release.comments,
      embargoThisTag: release.embargoDate !== null,
      version: release.version
    };
    selectedDeployment.recoveryPostDto = recovery
      ? {
          id: recovery.id,
          version: recovery.version,
          recoveryDate: recovery.recoveryDate,
          recoveryTimezone: recovery.recoveryTimezone,
          status: recovery.status,
          comments: recovery.comments,
          longitude: recovery.location.longitude,
          latitude: recovery.location.latitude
        }
      : null;
  } else {
    selectedDeployment.tagPostDto = {
      ...transmitter,
      tagId: transmitter.tagId,
      speciesScientificName: "",
      speciesName: ""
    };

    selectedDeployment.releasePostDto = {
      tagId: transmitter.id,
      projectId: null,
      speciesId: null,
      sex: null,
      surgeryType: null,
      measurements: [],
      releaseLocality: "",
      longitude: null,
      latitude: null,
      releaseDate: moment(),
      releaseTimezone: "",
      comments: "",
      embargoThisTag: false
    };
  }

  return selectedDeployment;
};

export const getEntityDetailLists = (data, detailColumns) => {
  let details = {
    header: "",
    data: []
  };
  Object.keys(data).map((key) => {
    let detailCol = detailColumns.find((item) => {
      return item.dataSource === key;
    });
    if (detailCol) {
      let detail = {};
      if (detailCol.renderAs) {
        detail.renderAs = (
          <detailCol.renderAs data={data[key]} {...detailCol} />
        );
      } else {
        detail.data = data[key].map((name) => {
          return name[detailCol.field];
        });
      }
      detail.icon = detailCol.icon;
      detail.title = detailCol.icon;
      details.data.push(detail);
    }
    return true;
  });
  details.header = data.name;
  return details;
};

export const doesReceiverHaveId = (receiver, id) => {
  return (
    receiver?.deviceModelName &&
    receiver?.serialNumber &&
    id.toUpperCase() ===
      `${receiver.deviceModelName}-${receiver.serialNumber}`.toUpperCase()
  );
};

export const getEmbargoUntilDate = (releaseDate, protectedProject) => {
  return moment(releaseDate).add(protectedProject ? 99 : 5, "years");
};

export const containsActiveProject = (projects) => {
  return (
    projects.filter((p) => {
      return p.status === "ACTIVE";
    }).length > 0
  );
};

export const receiverDeploymentEventIsBeforeLast = (
  receiver,
  newStatus,
  dateTime,
  message
) => {
  const date =
    newStatus === "DEPLOYED"
      ? receiver.history.find((h) => {
          return h.recovery;
        }).recovery.recoveryDate
      : newStatus === "RECOVERED"
      ? receiver.history[0].deployment.deploymentDate
      : null;
  let isBefore = false;
  if (date) {
    isBefore =
      dateTime.timezone === "" ||
      moment(dateTime.utc).isAfter(moment(date), "minute")
        ? false
        : {
            content: `${message} at ${toLocaltime(
              date,
              dateTime.timezone,
              `YYYY-MM-DD HH:mm [${dateTime.timezone}]`
            )}`,
            pointing: "below"
          };
  }
  return isBefore;
};

const receiverDeploymentDateMessage = (phrase, date, timezone) => {
  if (
    moment(date).isSame(moment(0)) ||
    moment(date).isSame(moment(2147483646000))
  ) {
    return null;
  } else {
    return (
      <p>
        Must be {phrase} at{" "}
        {toLocaltime(date, timezone, `YYYY-MM-DD HH:mm [${timezone}]`)}.
      </p>
    );
  }
};

export const receiverDeploymentEventDatesAreNotInRange = (
  receiverHistory,
  dateTimes,
  createHistorical,
  deploymentId
) => {
  // Rules:
  //  Create (id === 0):
  //   Deployment:
  //    deployment date is AFTER last recovery date
  //   Historical (deployment and recovery):
  //    deployment date is BETWEEN previous recovery date and this recovery date
  //    recovery date is BETWEEN deployment date and next deployment date
  //  Edit (id !== 0):
  //   Deployment:
  //    deployment date is AFTER last recovery date
  //   Deployment which has a recovery:
  //    deployment date is BETWEEN previous recovery date and recovery date
  //   Recovery (includes adding a recovery to a deployment):
  //    recovery date is BETWEEN deployment date and next deployment date

  const id = deploymentId ?? 0;

  let history = [...receiverHistory];
  history.push({
    deployment: {
      id: null,
      deploymentDate: moment(0).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
      deploymentTimezone: null
    },
    recovery: {
      recoveryDate: moment(0).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
      recoveryTimezone: null
    }
  });

  const newDeployment = {
    deployment: {
      id: id,
      deploymentDate: dateTimes.deploymentDate.utc ?? null,
      deploymentTimezone: dateTimes.deploymentDate.timezone ?? null
    },
    recovery: {
      recoveryDate: dateTimes.recoveryDate?.utc ?? null,
      recoveryTimezone: dateTimes.recoveryDate?.timezone ?? null
    }
  };
  const comparator = (a, b) => {
    return (
      moment(a.deployment.deploymentDate) - moment(b.deployment.deploymentDate)
    );
  };
  if (createHistorical) {
    history.push(newDeployment);
    history.sort(comparator);
  } else {
    history.sort(comparator);
    if (newDeployment.deployment.id === 0) {
      history.push(newDeployment);
    } else {
      const i = history.findIndex((h) => {
        return h.deployment.id === newDeployment.deployment.id;
      });
      history[i] = newDeployment;
    }
  }

  history.push({
    deployment: {
      id: null,
      deploymentDate: moment(2147483646000).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
      deploymentTimezone: null
    },
    recovery: {
      recoveryDate: moment(2147483646000).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
      recoveryTimezone: null
    }
  });

  const index = history.findIndex((h) => {
    return h.deployment.id === newDeployment.deployment.id;
  });

  let deploymentDateInRange;
  let deploymentDateNotInRangeContent;
  let recoveryDateInRange;
  let recoveryDateNotInRangeContent;

  if (createHistorical && index === history.length - 2) {
    deploymentDateNotInRangeContent = receiverDeploymentDateMessage(
      "before the last deployment",
      history[index - 1].deployment.deploymentDate,
      dateTimes.deploymentDate.timezone
    );
    recoveryDateNotInRangeContent = deploymentDateNotInRangeContent;
  } else if (history[index].recovery?.recoveryDate || createHistorical) {
    deploymentDateInRange = moment(dateTimes.deploymentDate.utc).isBetween(
      history[index - 1].recovery.recoveryDate,
      history[index].recovery?.recoveryDate,
      "minute"
    );
    deploymentDateNotInRangeContent = deploymentDateInRange ? (
      false
    ) : (
      <Fragment>
        {receiverDeploymentDateMessage(
          "after the previous recovery",
          history[index - 1].recovery?.recoveryDate,
          dateTimes.deploymentDate.timezone
        )}
        {receiverDeploymentDateMessage(
          "before this recovery",
          history[index].recovery?.recoveryDate,
          dateTimes.deploymentDate.timezone
        )}
      </Fragment>
    );
    recoveryDateInRange = moment(dateTimes.recoveryDate.utc).isBetween(
      moment(history[index].deployment.deploymentDate),
      moment(history[index + 1].deployment.deploymentDate),
      "minute"
    );
    recoveryDateNotInRangeContent = recoveryDateInRange ? (
      false
    ) : (
      <Fragment>
        {receiverDeploymentDateMessage(
          "after this deployment",
          history[index].deployment.deploymentDate,
          dateTimes.recoveryDate?.timezone
        )}
        {receiverDeploymentDateMessage(
          "before the next deployment",
          history[index + 1].deployment.deploymentDate,
          dateTimes.recoveryDate?.timezone
        )}
      </Fragment>
    );
  } else {
    deploymentDateInRange = moment(dateTimes.deploymentDate.utc).isBetween(
      history[index - 1].recovery?.recoveryDate,
      history[index + 1].deployment.deploymentDate,
      "minute"
    );
    deploymentDateNotInRangeContent = deploymentDateInRange
      ? false
      : receiverDeploymentDateMessage(
          "after the last recovery",
          history[index - 1].recovery.recoveryDate,
          dateTimes.deploymentDate.timezone
        );
  }

  const isNotInRange = {
    deploymentDateNotInRange: deploymentDateInRange
      ? false
      : {
          content: deploymentDateNotInRangeContent,
          pointing: "below"
        },
    recoveryDateNotInRange: recoveryDateInRange
      ? false
      : {
          content: recoveryDateNotInRangeContent,
          pointing: "below"
        }
  };

  return isNotInRange;
};
