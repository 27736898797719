import React from "react";
import {Form, Message} from "semantic-ui-react";
import {connect} from "react-redux";
import FormModal from "./FormModal";
import {installationDelete} from "../axios/api";
import {isAdminNotATFAdmin} from "./helpers";

class InstallationDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open
    };
    this.onClose = this.onClose.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
  }

  componentDidMount() {
    this.setState({
      canBeDeleted: this.props.installation.stationCount === 0,
      userCanDelete: isAdminNotATFAdmin(this.props.roles)
    });
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    const isValid = this.state.canBeDeleted && this.state.userCanDelete;
    return !isValid;
  }

  successAction() {
    this.setState(
      {
        success: true,
        failed: false,
        deletedInstallation: this.props.installation.name
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.props.installation.id);
        }
      }
    );
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  render() {
    const {onClose, checkFormValid, successAction, failedAction} = this;
    const {trigger, installation} = this.props;
    const {success, loading, canBeDeleted, userCanDelete} = this.state;

    const successContent = `Installation ${installation.name} has been deleted.`;

    const canBeDeletedMessage = canBeDeleted ? null : (
      <p>
        The installation cannot be deleted because it has stations associated
        with it.
      </p>
    );
    const userCanDeleteMessage = userCanDelete ? null : (
      <p>You do not have permission to delete the installation.</p>
    );

    const messageContent = (
      <>
        {canBeDeletedMessage}
        {userCanDeleteMessage}
      </>
    );

    const formInputs = !success ? (
      <>
        <Message info header="Deleting installation" content={messageContent} />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Installation Name"
          value={installation.name}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Configuration"
          value={installation.configurationType}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Project"
          value={installation.projectName}
        />
      </>
    ) : null;

    return (
      <FormModal
        formSubmitAPI={!success ? installationDelete : false}
        submitContent={"Delete Installation"}
        formData={installation}
        checkFormValid={checkFormValid}
        onSuccess={successAction}
        onFail={failedAction}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        headerContent="Delete Installation"
        successHeader="Installation Deleted"
        successContent={successContent}
        onClose={onClose}
        open
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles
  };
}

export default connect(mapStateToProps)(InstallationDelete);
