import React from "react";
import PropTypes from "prop-types";
import {receiverByReceiverId, stationById} from "../axios/api";
import {Form, Grid, Label} from "semantic-ui-react";
import config from "react-global-configuration";

const serialNumberLength = 6;

class ReceiverFind extends React.Component {
  constructor(props) {
    super(props);
    const modelOrdering = [
      "VR2W",
      "VR2Tx",
      "VR2AR",
      "VR2AR-X",
      "VR4-UWM",
      "VR3-UWM",
      "aquaHub",
      "VR4G",
      "VR2C69"
    ];
    let receiverTypes = [
      ...modelOrdering,
      ...config
        .get("receiver_device_model_names")
        .filter((v) => !modelOrdering.includes(v))
    ];
    receiverTypes = receiverTypes.map((v) => {
      return {text: v, value: v, key: v.toLowerCase()};
    });

    this.state = {
      loading: false,
      error: null,
      receiverTypes: receiverTypes,
      id: ""
    };

    this.getValue = this.parseValue.bind(this);
    this.receiverIdLookup = this.receiverIdLookup.bind(this);
    this.handleReceiverIdChange = this.handleReceiverIdChange.bind(this);
    this.handleReceiverModelChange = this.handleReceiverModelChange.bind(this);
  }

  componentDidMount() {
    if (this.props.value) {
      this.receiverIdLookup(this.props.value);
    }
  }

  hasMissingRecoveryInformation(receiver) {
    const hist = receiver.history;
    if (hist.length > 0 && hist[0].recovery) {
      return false;
    } else {
      return "Error: Receiver status is Recovered but is missing recovery information";
    }
  }

  hasInValidDeploymentStatus(status, validReceiverDeploymentStatus) {
    if (validReceiverDeploymentStatus.includes(status)) {
      return false;
    } else {
      const message =
        status === "LOST"
          ? "If the receiver has been found, please update the recovery details for its previous deployment, " +
            "including receiver status, before attempting to redeploy it."
          : "";
      return `Error: Receiver status is ${status}. ${message}`;
    }
  }

  hasNoDeploymentToRecover(receiver) {
    const found = receiver.history.find((h) => {
      return h.deployment && (!h.recovery || h.recovery?.status === "LOST");
    });
    if (found) {
      receiver.deployment = found;
      return false;
    }
    return `Error: The receiver has no deployment to recover`;
  }

  validateFoundReceiver(receiver) {
    let error = false;
    if (this.props.deploy) {
      if (receiver.status === "RECOVERED") {
        error = this.hasMissingRecoveryInformation(receiver);
      } else {
        error = this.hasInValidDeploymentStatus(receiver.status, [
          "NEW",
          "RECOVERED"
        ]);
      }
    } else if (this.props.recover) {
      if (receiver.status === "DEPLOYED" || receiver.status === "LOST") {
        error = this.hasNoDeploymentToRecover(receiver);
      } else {
        error = this.hasInValidDeploymentStatus(receiver.status, [
          "DEPLOYED",
          "LOST"
        ]);
      }
    }
    return error;
  }

  getMatchingReceiver(receiverId) {
    return receiverByReceiverId(receiverId)
      .then((result) => {
        let data = {};
        if (result !== null) {
          data = result.receiver;
          data.error = this.validateFoundReceiver(data);
        } else {
          data.error = "Error: No such receiver";
        }
        return data;
      })
      .catch(() => {
        return {error: "Error: No such receiver"};
      });
  }

  findReceiver(receiverId) {
    return new Promise((resolve) => {
      this.setState(
        {
          loading: true,
          error: null
        },
        () => {
          resolve(this.getMatchingReceiver(receiverId));
        }
      );
    });
  }

  getDeploymentInstallation(stationId) {
    return stationById(stationId).then((s) => {
      return s.data.installation;
    });
  }

  handleReceiverIdChange(e) {
    const id = `${this.parseValue().model}-${e.target.value}`;
    this.props.onChange(id);
    this.receiverIdLookup(id);
  }

  handleReceiverModelChange(e, {value}) {
    const id = `${value}-${this.parseValue().serial}`;
    this.props.onChange(id);
    this.receiverIdLookup(id);
  }

  parseValue() {
    const id = this.props.value;
    const idx = id.lastIndexOf("-");
    return idx > 0
      ? {model: id.slice(0, idx), serial: id.slice(idx + 1)}
      : {model: "", serial: id};
  }

  getValidatedId(id) {
    const idx = id.lastIndexOf("-");
    const d =
      0 < idx && idx < id.length - 1
        ? {model: id.slice(0, idx), serial: id.slice(idx + 1)}
        : null;
    return {
      valid:
        d &&
        (this.props.noValidate ||
          (d?.serial.length === serialNumberLength &&
            this.state.receiverTypes.find(
              (t) => t.key === d.model.toLowerCase()
            ))),
      d: d
    };
  }

  receiverIdLookup(id) {
    const validatedId = this.getValidatedId(id);
    if (validatedId.valid) {
      const receiverId = `${
        this.state.receiverTypes.find(
          (t) => t.key === validatedId.d.model.toLowerCase()
        ).value
      }-${validatedId.d.serial}`;
      this.findReceiver(receiverId).then(async (receiver) => {
        if (receiver.error) {
          this.setState({
            loading: false,
            error: receiver.error,
            id: receiverId
          });
        } else {
          if (this.props.recover) {
            await this.getDeploymentInstallation(
              receiver.deployment.deployment.stationId
            ).then((p) => {
              receiver.installation = p;
            });
          }
          this.setState(
            {
              loading: false,
              error: null,
              id: receiverId
            },
            () => this.props.onFound(receiver)
          );
        }
      });
    } else {
      this.setState({
        error: null,
        loading: false,
        id: id
      });
    }
  }

  render() {
    const error = this.state.error ?? this.props.error;
    return (
      <div style={{marginBottom: "20px"}}>
        <h5>Receiver ID (e.g. VR4-UWM-555555)</h5>
        <Grid columns={3}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={4}>
              <Form.Dropdown
                fluid
                selection
                search
                options={this.state.receiverTypes}
                placeholder="VR4-UWM"
                value={this.parseValue().model}
                onChange={this.handleReceiverModelChange}
                data-testid="test-receiver-type-dropdown"
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Form.Input
                placeholder="555555"
                loading={this.state.loading}
                disabled={this.state.loading}
                value={this.parseValue().serial}
                onChange={this.handleReceiverIdChange}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              {error ? (
                <Label basic color="red" pointing="left">
                  {error}
                </Label>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

ReceiverFind.propTypes = {
  noValidate: PropTypes.bool,
  id: PropTypes.string,
  recover: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default ReceiverFind;
