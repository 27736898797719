import React from "react";
import { Table, TableCell, Visibility } from "semantic-ui-react";
import PropTypes from "prop-types";

class FilteredTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { onClick, index } = this.props;
    onClick(index, e);
  }

  handleUpdate(e) {
    const { onUpdate } = this.props;
    onUpdate();
  }

  render() {
    const { dataLoadTrigger, index, active, cells, className } = this.props;
    const { handleClick, handleUpdate } = this;

    if (dataLoadTrigger) {
      return (
        <Visibility
          as="tr"
          onUpdate={handleUpdate}
          className={className}
          cells={cells}
          id="table-row-middle"
          onClick={handleClick}
          active={index === active ? "true" : undefined}
        />
      );
    } else {
      return (
        <Table.Row
          className={className}
          cells={cells}
          onClick={handleClick}
          active={index === active}
        />
      );
    }
  }
}

FilteredTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  dataLoadTrigger: PropTypes.bool,
  active: PropTypes.number,
  cells: PropTypes.arrayOf(TableCell),
  onClick: PropTypes.func,
  onUpdate: PropTypes.func
};

export default FilteredTableRow;
