import React from "react";
import PropTypes from "prop-types";
import {Form} from "semantic-ui-react";
import {stationById} from "../axios/api";

class StationSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.showAll,
      stations: [],
      options: []
    };
  }

  render() {
    const {loading} = this.state;
    const {value, required, onChange} = this.props;

    return (
      <Form.Dropdown
        fluid
        selection
        search
        loading={loading}
        required={required ?? false}
        label="Station"
        text={loading ? this.props.loadingText ?? "" : null}
        options={this.props.options.sort(function(a,b){return a.text > b.text ? 1 : -1})}
        placeholder="Select Station"
        value={value}
        onChange={(e, {value}) => {
          stationById(value).then((result) => {
            onChange(result.data);
          });
        }}
        disabled={this.props.disabled}
        data-testid="project-dropdown"
        noResultsMessage="No stations exist for this project"
      />
    );
  }
}

StationSelect.propTypes = {
  loadingText: PropTypes.string,
  value: PropTypes.number,
  required: PropTypes.bool,
  showAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default StationSelect;
