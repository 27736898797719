import React from "react";
import {Button, Header, Icon, Modal} from "semantic-ui-react";
import {properties} from "../properties";

export const ErrorPage = (props) => {

  return (
    <Modal basic open size="small">
      <Header icon>
        <Icon name="plug" />
        {properties.title}
      </Header>
      <Modal.Content>
        <p style={{textAlign: "center"}}>
          The server may be experiencing problems. Please wait a moment and try
          again.
            {props.uuid ? <div><span>If this problem persists, please contact info@aodn.org.au with this issue code: { props.uuid }</span></div>
                : ""}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          inverted
          onClick={() => {
            window.history.back();
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorPage;
