import React from "react";
import Dropzone from "react-dropzone";
import axios from "axios";
import {
  Button,
  Form,
  Header,
  Table,
  Message,
  Modal,
  Progress,
  Segment
} from "semantic-ui-react";
import {containsActiveProject, isAdmin} from "./helpers";
import Alert from "./Alert";
import {
  beginFathom,
  postFathomDetections,
  cancelFathom,
  uploadFathomFile
} from "../axios/api";
import {connect} from "react-redux";
import LoginForm from "./LoginForm";
import JSZip from "jszip";
import {modelOrdering} from "../constants/receiver-model-ordering";

const stages = Object.freeze({
  error: -1,
  initial: 0,
  summary: 1,
  upload: 2,
  warning: 3
});
const invalidDetectionsPayloadErrMsg = "Invalid detections detected. Please check the file and try again. If the issue persists, please contact us at info@aodn.org.au.";
const fathomZipEnabled = true;

class FathomFileUpload extends React.PureComponent {
  constructor(props) {
    super(props);

    this.detections = [];
    this.events = [];
    this.receivers = [];
    this.cancelTokenSource = null;

    this.state = {
      loading: false,
      isZip: false,
      counter: 0,
      stage: stages.initial,
      open: true,
      uploadComplete: false,
      upload: null,
      refreshTriggered: null,
      fileId: null
    };
  }

  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
      );

    return bytes.toFixed(dp) + " " + units[u];
  }

  render() {
    // FUTURE: move to a HoC
    {
      const hasActiveProject = containsActiveProject(this.props.projects);
      const hasAccessToken = typeof this.props.accessToken === "string";
      const canUpload = hasActiveProject || isAdmin(this.props.roles);
      if (!hasAccessToken)
        return (
          <LoginForm
            open={this.state.open}
            onClose={(success) => {
              if (success) return;

              if (!this.props.noRedirectOnClose) {
                this.props.history.goBack();
              } else {
                this.props.onClose();
              }
            }}
          />
        );

      if (!canUpload)
        return (
          <Alert
            content="You must be part of a project to be able to upload receiver detection data to the project"
            icon="exclamation triangle"
            headerContent="Access Restricted"
            hideCancel
            closeButtonContent="OK"
            closeAction={() => {
              if (!this.props.noRedirectOnClose) {
                this.props.history.push("/");
              } else {
                this.setState({open: false});
                if (this.props.onClose) {
                  this.props.onClose();
                }
              }
            }}
            open={this.state.open}
          />
        );
    }

    const doUpload = async () => {
      this.setState((s) => ({...s, stage: stages.upload}));
      const fileName = this.state.upload.name;
      const detections = this.detections;
      const events = this.events;
      this.cancelTokenSource = axios.CancelToken.source();
      try {
        const beginResult = await beginFathom(
          {fileName, receivers: this.receivers},
          this.cancelTokenSource.token
        );

        if (beginResult.data === "locked") {
          this.setState({
            stage: stages.warning,
            message:
              "You might have another Fathom upload in progress, please wait for it to complete before uploading more files. If a previous upload has been disrupted, please logout and login again. Any other issues please contact info@aodn.org.au"
          });
        } else {

          const file = this.state.upload;
          const fileId = beginResult.data.fileId;

          // upload to S3
          const s3Upload = await uploadFathomFile({
            file, fileId
          }, this.cancelTokenSource.token);

          if (s3Upload.status === 200 && s3Upload.data["fileUploadId"] === fileId) {
            // send all to backend for processing
            await postFathomDetections(
              {
                fileName,
                fileId,
                detections: detections,
                events: events
              },
              this.cancelTokenSource.token
            );

            this.cancelTokenSource = null;
            this.setState((s) => ({
              ...s,
              refreshTriggered: true,
              uploadComplete: true
            }));
          } else {
            this.setState({
              stage: stages.error,
              message: "File upload failed, please try again."
            });
          }
        }
      } catch (ex) {
        this.setState({
          stage: stages.error,
          message: ex.response?.data?.errors[0] || ex.message
        });
      }
    };

    const parseReceiver = (csvModel, csvSerial) => {
      const modelSplit = csvModel.split("-");
      return `${
        /\d/.test(modelSplit[1]) ? modelSplit[0] : csvModel
      }-${csvSerial}`;
    };

    function findCsvHeadersRow(csvRow, targetCsvColumns) {
      const processedInput = csvRow.map(element => element.toLowerCase()); // Convert all elements to lowercase
      if (targetCsvColumns.every(value => processedInput.includes(value.toLowerCase()))) {
        return processedInput;
      } else {
        return null;
      }
    }

    const parseCsv = async (file) => {
      let parseCsvStatus = false;
      // CSV IDs taken from:
      // https://www.oceans-research.com/wp-content/uploads/2016/09/hr2-receiver-fathom-sw-manual-1.pdf
      const detectionCsvIds = ["DET"];
      const text = (await file.text()).split("\n");

      const targetCsvColumns = ["sensor value", "sensor unit", "model", "serial number", "full id"];

      // Find the row with the headers
      let csvHeaders = null;
      text.some(row => {
        const l = row.split(",");
        csvHeaders = findCsvHeadersRow(l, targetCsvColumns);
        return csvHeaders !== null;
      });

      // process detections and events payload
      if (csvHeaders !== null) {

        const indexesMap = targetCsvColumns.reduce((map, targetValue) => {
          const idx = csvHeaders.findIndex(header => header.startsWith(targetValue));
          map.set(targetValue, idx);
          return map;
        }, new Map());

        for (let i = 0; i < text.length; i++) {
          const row = text[i];
          const l = row.split(",");
          if (detectionCsvIds.includes(l[0])) {
            const record = {
              timestamp: l[1]?.includes(".") ? l[1] : `${l[1]}.000`,
              timestamp_corrected: l[2]?.includes(".") ? l[2] : `${l[2]}.000`,
              receiverName: parseReceiver(l[indexesMap.get("model")], l[indexesMap.get("serial number")]),
              transmitterId: l[indexesMap.get("full id")],
              sensorValue: l[indexesMap.get("sensor value")] !== undefined ? l[indexesMap.get("sensor value")] : "",
              sensorUnit: l[indexesMap.get("sensor unit")] !== undefined ? l[indexesMap.get("sensor unit")] : ""
            };
            if (validateRecord(record)) {
              this.detections.push(record);
            } else {
              parseCsvStatus = false;
            }
          } else if (l[0].includes("DIAG") && !l[0].includes("_DESC")) {
            const record = {
              eventDetail: row
            };
            this.events.push(record);
          }
        }
        parseCsvStatus = true;
      }
      return parseCsvStatus;
    };

    function validateRecord(record) {
      // Validate receiverName
      const receiverNameRegex = new RegExp(`^(${modelOrdering.join('|')})`, 'i');
      if (!record.receiverName || !receiverNameRegex.test(record.receiverName.toLowerCase())) {
        return false;
      }
      // Validate transmitterId
      if (!record.transmitterId || typeof record.transmitterId !== 'string' || record.transmitterId.length < 4 || !record.transmitterId.includes("-")) {
        return false;
      }
      // Validate sensorValue
      if (record.sensorValue !== "" && isNaN(parseFloat(record.sensorValue))) {
        return false;
      }
      // All validations passed
      return true;
    }

    const readCSV = async (file) => {
      const readCsvStatus = await parseCsv(file);
      if (!readCsvStatus) {
        this.setState({
          stage: stages.error,
          message: invalidDetectionsPayloadErrMsg
        });
      } else {
        this.receivers = Array.from(
          new Set(this.detections.map((d) => d.receiverName))
        );
        this.setState((s) => {
          return {
            loading: false,
            isZip: false,
            upload: file,
            stage: stages.summary,
            message: `${file.name} - ${file.size} bytes`
          };
        });
      }
    };

    const readZip = async (file) => {
      let counter = 0;
      let promises = [];
      let CSVs = [];
      let readZipStatus = true;
      JSZip.loadAsync(file)
        .then((zip) => {
          zip.forEach((relativePath, zipEntry) => {
            if (zipEntry.name.endsWith(".csv")) {
              promises.push(
                zip
                  .file(zipEntry.name)
                  .async("blob")
                  .then(async (csvData) => {
                    const csvFile = new File([csvData], zipEntry.name, {
                      type: "text/csv"
                    });
                    counter++;
                    CSVs.push(csvFile);
                  })
              );
            }
          });
          return Promise.all(promises);
        })
        .then(async () => {
          for (const csvFile of CSVs) {
            const parseCsvStatus = await parseCsv(csvFile);
            if (!parseCsvStatus) {
              readZipStatus = false;
              break;
            }
          }
          if (!readZipStatus) {
            this.setState({
              stage: stages.error,
              message: invalidDetectionsPayloadErrMsg
            });
          } else {
            this.receivers = Array.from(
              new Set(this.detections.map((d) => d.receiverName))
            );
            this.setState((s) => {
              return {
                loading: false,
                isZip: true,
                upload: file,
                counter: counter,
                stage: stages.summary,
                message: `${file.name} - ${file.size} bytes`
              };
            });
          }
        });
    };

    const onDrop = async (files) => {
      const file = files[0];
      const success =
        file?.type === "text/csv" ||
        (fathomZipEnabled && file?.type === "application/zip") ||
        (fathomZipEnabled && file?.type === "application/x-zip-compressed") ||
        (fathomZipEnabled && file?.type.includes("zip"));
      if (!success) {
        this.setState((s) => {
          return {
            ...s,
            upload: file,
            stage: stages.error,
            message: "Not a recognised file type"
          };
        });
        return;
      }

      this.detections = [];
      this.events = [];
      this.receivers = [];
      this.setState({
        loading: true
      });

      if ((file?.type === "application/zip" || file?.type === "application/x-zip-compressed" || file?.type.includes("zip")) && fathomZipEnabled) {
        await readZip(file);
      } else {
        await readCSV(file);
      }
    };

    const uploadSegment = (
      <Dropzone
        onDropAccepted={onDrop}
        multiple={false}
        height="300"
        accept={
          fathomZipEnabled
            ? "text/csv,.csv,application/zip,application/x-zip-compressed,.zip"
            : "text/csv,.csv"
        }
      >
        {({getRootProps, getInputProps, isDragAccept, isDragReject}) => {
          return (
            <div
              {...getRootProps({
                className: `at-dropzone ${
                  isDragAccept
                    ? "at-dropzone-accept"
                    : isDragReject
                      ? "at-dropzone-reject"
                      : ""
                }`
              })}
            >
              <input {...getInputProps()} />
              <p className={"at-dropzone-default"}>Drop Fathom file with extension .csv or .zip here.</p>
              {isDragReject && (
                <p className={this.rejectedClassName}>
                  Some files will be rejected
                </p>
              )}
              <Button primary>
                {!this.state.loading
                  ? "Browse for Fathom file ..."
                  : "Loading..."}
              </Button>
            </div>
          );
        }}
      </Dropzone>
    );

    const errorMessage = (
      <Segment>
        {this.state.message && (
          <Message negative>
            <Message.Header>Upload Failed</Message.Header>
            <p>{this.state.message}</p>
          </Message>
        )}
      </Segment>
    );

    const warningMessage = (
      <Segment>
        {this.state.message && (
          <Message warning>
            <Message.Header>Warning</Message.Header>
            <p>{this.state.message}</p>
          </Message>
        )}
      </Segment>
    );

    const summaryMessage = (
      <Segment>
        <Message
          onDismiss={() => {
            this.setState((s) => ({...s, stage: stages.initial}));
          }}
        >
          <Message.Header>
            File Selected: {this.state.upload?.name}
          </Message.Header>
          <Message.List>
            <Message.Item>
              File Size: {this.humanFileSize(this.state.upload?.size, true, 1)}
            </Message.Item>
            {this.state.isZip ? (
              <Message.Item>
                Number of CSV files: {this.state.counter}
              </Message.Item>
            ) : null}
            <Message.Item>Detections: {this.detections.length}</Message.Item>
            <Message.Item>Events: {this.events.length}</Message.Item>
          </Message.List>
        </Message>
      </Segment>
    );

    const uploadComplete = this.state.uploadComplete;
    const progressSegment = (
      <Message positive={uploadComplete}>
        {!uploadComplete ? (
          <Message warning>
            Please do not close this browser tab until uploads are complete.
          </Message>
        ) : null}
        <Message.Header>
          {uploadComplete ? (
            <Header>Upload Complete</Header>
          ) : (
            <Progress active percent={100}/>
          )}
        </Message.Header>
        <Message.Content>
          <Table>
            <Table.Body>
              {!uploadComplete && (
                <Table.Header fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan="3">
                      Uploading {this.state.upload?.name} ...
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              )}
            </Table.Body>
          </Table>
        </Message.Content>
        {this.state.refreshTriggered ? (
          <>
            <Message warning>
              Files were successfully uploaded. You will receive and email to notify you when the detections have been ingested into the database. If the email does not appear in your inbox, please check your spam or junk mail.
            </Message>
            <Message warning>
              Please be aware that detections for un-recovered receivers will
              not be available to download until a receiver recovery is
              registered.
            </Message>
          </>
        ) : null}
      </Message>
    );

    // const maintenanceMessage = (
    //   <Segment>
    //     <Message warning>
    //       <Message.Header>Maintenance</Message.Header>
    //       <p>Fathom upload tool is temporarily unavailable due to system maintenance. We apologise for any inconvenience.</p>
    //     </Message>
    //   </Segment>
    // );

    return (
      <Modal
        as={Form}
        onClose={this.props.onClose}
        open={this.state.open}
        size="medium"
        trigger={this.state.trigger}
        warning
      >
        <Header icon="upload" content="Upload Fathom files" />
        <Modal.Content inverted="true">
          {/*{maintenanceMessage}*/}
          {this.state.stage === stages.warning && warningMessage}
          {this.state.stage === stages.error && errorMessage}
          {this.state.stage === stages.initial && uploadSegment}
          {this.state.stage === stages.summary && summaryMessage}
          {this.state.stage === stages.upload && progressSegment}
        </Modal.Content>
        <Modal.Actions>
          {!uploadComplete ? (
            <>
              <Button
                onClick={async () => {
                  if (!uploadComplete) {
                    await cancelFathom({fileId: this.state.fileId}, this.cancelTokenSource);
                  }
                  if (this.cancelTokenSource) this.cancelTokenSource.cancel();
                  this.props.onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={doUpload}
                disabled={this.state.stage !== stages.summary}
                primary
              >
                Upload
              </Button>
            </>
          ) : (
            <Button
              onClick={() => {
                this.props.onClose();
              }}
              primary
            >
              Close
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    roles: user.roles,
    userOrganisation: user.organisationId,
    userId: user.userId,
    username: user.username,
    projects: user.projects,
    accessToken: user.accessToken
  };
}

export default connect(mapStateToProps)(FathomFileUpload);
