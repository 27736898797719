import React from "react";
import {Label, Form, Input, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";
import AwesomeDebouncePromise from "awesome-debounce-promise";

class FilterSearchable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: props.value?.description ?? "",
      value: props.multiple ? [] : {},
      codes: props.multiple ? {name: this.props.name, value: ""} : "",
      options: [],
      autoFocus: false,
      moreResults: false,
      noResults: false,
      replaceNonAlphanumericCharsWithHyphen: this.props
        .replaceNonAlphanumericCharsWithHyphen,
      debouncedSearchApi: AwesomeDebouncePromise(this.props.searchApi, 300)
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value && this.props.value.length === 0) {
      this.setState({
        options: [],
        moreResults: false,
        noResults: false,
        narrowed: false,
        value: this.props.multiple ? [] : {},
        codes: this.props.multiple ? {name: this.props.name, value: ""} : "",
        inputValue: ""
      });
    }
    if (this.props.options !== prevProps.options) {
      if (this.props.options?.length > 0) {
        this.setState({
          options: this.props.options,
          narrowed: true,
          moreResults: false,
          noResults: false
        });
      } else {
        this.setState({
          options: [],
          narrowed: false,
          moreResults: false,
          noResults: false
        });
      }
    }

    if (this.props.resetInputValueOnSubmit &&
      this.props.defaultInputValue === "" &&
      prevState.inputValue === this.state.inputValue &&
      this.state.inputValue !== "" &&
      this.props.filterApplyButtonClicked
    ) {
        this.setState({
          inputValue: this.props.defaultInputValue,
          noResults: false
        });
    }
  }

  setValue(idx) {
    const result = this.state.options[idx];
    const entity = {
      name: this.props.name,
      value: {description: result[0], code: result[1]}
    };
    this.setState(
      (s) => {
        return {
          autoFocus: true,
          inputValue: this.props.multiple ? "" : entity.value.description,
          value: this.props.multiple ? [...s.value, entity.value] : entity,
          codes: this.props.multiple
            ? {
                name: this.props.name,
                value: [...s.codes.value, entity.value.code]
              }
            : entity,
          noResults: false,
          moreResults:
            entity.value?.length > 2 && this.state.options.length === 20
        };
      },
      () => {
        this.props.onChange(this, this.state.codes);
      }
    );
  }

  handleOnChange(e) {
    let inputValue = e.target.value;
    if (this.state.replaceNonAlphanumericCharsWithHyphen) {
      inputValue = inputValue.replace(/[\W_]+/g, "-");
    }
    this.setState(
      {
        inputValue: inputValue,
      },
      () => this.getSearchResults()
    );
  }

  getSearchResults() {
    const {inputValue, debouncedSearchApi} = this.state;
    const idx = this.state.options?.findIndex((e) => e[0] === inputValue);
    if (idx >= 0) {
      this.setValue(idx);
    } else if (inputValue?.length > 2 && !this.state.narrowed) {
      debouncedSearchApi(inputValue).then((result) => {
        this.setState(
          {
            options: result.data,
            moreResults: inputValue?.length > 2 && result.data.length === 20,
            noResults: inputValue?.length > 2 && result.data.length === 0,
            autoFocus: true
          },
          () => {
            const idx2 = this.state.options?.findIndex(
              (e) => e[0] === inputValue
            );
            if (idx2 >= 0) {
              this.setValue(idx2);
            }
          }
        );
      });
    } else {
      this.setState((s) => {
        return {
          options: s.narrowed ? s.options : [],
          moreResults: false,
          noResults: inputValue?.length > 2,
          autoFocus: true
        };
      });
    }
  }

  removeSelected(e, item) {
    e.preventDefault();
    this.setState(
      (s) => {
        return {
          codes: {
            name: this.props.name,
            value: s.codes.value.filter((v) => {
              return v !== item;
            })
          },
          value: s.value.filter((v) => {
            return v.code !== item;
          })
        };
      },
      () => {
        this.props.onChange(this, this.state.codes);
      }
    );
  }

  render() {
    return (
      <>
        <div hidden>
          <Form.Input
            name={this.props.name}
            value={this.props.value?.code ?? this.state.codes.value}
          />
        </div>
        <div className="at-filter-searchable-container">
          {this.state.moreResults ? (
            <Label pointing="below" className="at-search-label">
              There may be more options. <br /> Continue typing to refine the
              search.
            </Label>
          ) : null}
          {this.props.multiple
            ? this.state.value.map((v) => {
                return (
                  <Label
                    as="a"
                    key={v.code}
                    className="at-filter-searchable-multiple"
                  >
                    {v.description}
                    <Icon
                      size="mini"
                      name="delete"
                      floated="right"
                      onClick={(e) => {
                        this.removeSelected(e, v.code);
                      }}
                    />
                  </Label>
                );
              })
            : null}
          <Input
            className="at-filter-searchable-input"
            autoFocus={this.state.autoFocus}
            spellCheck={false}
            autoComplete="off"
            list={this.props.name}
            onChange={this.handleOnChange}
            value={this.state.inputValue}
          />
          <datalist id={this.props.name}>
            {this.state.options.map((o, i) => {
              let disabled = this.props.multiple
                ? this.state.value.find((v) => {
                    return v.code === o[1];
                  })
                : false;
              return (
                <option disabled={disabled} label={o[0]} key={i}>
                  {o[0]}
                </option>
              );
            })}
          </datalist>
          {this.state.options.length === 0 &&
          this.props.value?.code === "-1" ? (
            <Label role="alert" pointing="above" className="prompt">
              No results found
            </Label>
          ) : null}
        </div>
        {this.state.noResults ? (
          <Label basic color="red" pointing>
            {this.props.noResultsMessage
              ? this.state.inputValue + this.props.noResultsMessage
              : "No results found."}
          </Label>
        ) : null}
      </>
    );
  }
}

FilterSearchable.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  searchApi: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.array,
  replaceNonAlphanumericCharsWithHyphen: PropTypes.bool,
  multiple: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  defaultInputValue: PropTypes.string,
  filterApplyButtonClicked: PropTypes.bool,
  resetInputValueOnSubmit: PropTypes.bool
};

export default FilterSearchable;
