const localdev_host = "http://localhost:5000";

export const properties = {
  APIBaseUrl: process.env.NODE_ENV === "development" ? localdev_host : "",
  errorCheckIntervalMs: 30000,
  imosLogo:
    "https://static.emii.org.au/images/logo/IMOS_logo-stacked_transparent.png",
  ncrisLogo: "https://static.emii.org.au/images/logo/NCRIS_2017_200.png",
  title: "Australian Animal Acoustic Telemetry Database",
  homepage: {
    map:
      "https://s3-ap-southeast-2.amazonaws.com/content.aodn.org.au/Documents/IMOS/Facilities/animal_tracking/Landing_page_map/Landing_page_static_map.png",
    videos: {
      left: {
        url: "https://vimeo.com/510466622",
        title: "Overview of broad-scale species movements (public data)"
      },
      right: {
        url: "https://www.youtube.com/watch?v=HpH_xeFZv48",
        title: "In the field with the Animal Tracking Facility"
      }
    },
    twitterHandles: ["IMOS_AUS", "IMOSAnimalTrack"]
  }
};
