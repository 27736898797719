import React from "react";
import FormModal from "./FormModal";
import {
  me,
  userConfirmLatestEmail,
  userLogin,
  pendingDeletionRequestCount
} from "../axios/api";
import {Form} from "semantic-ui-react";
import Password from "./Password";
import ActionsMenu from "./ActionsMenu";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {
  updatePendingRequestCount,
  updateToken,
  updateUser
} from "../actions/index";
import Alert from "./Alert";
import jwt_decode from "jwt-decode";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      loading: false,
      isLogInAttempt: false,
      credentials: {
        username: "",
        password: "",
        latestEmail: ""
      },
      email_update_required: false,
      email_verification_required: false,
      ...this.getParams(this.props.location)
    };

    this.validPassword = this.validPassword.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.successAction = this.successAction.bind(this);
    this.successConfirmEmailAction = this.successConfirmEmailAction.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.checkEmailFormValid = this.checkEmailFormValid.bind(this);

    this.usernameInput = React.createRef();
    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();
  }

  componentDidMount() {
    // http://avernet.blogspot.com/2010/11/autocomplete-and-javascript-change.html
    setTimeout(() => {
      this.handleAutoFill(
        this.usernameInput.current.value,
        this.passwordInput.current.state.password
      );
    }, 300);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (
      prevState.credentials.latestEmail !==
        this.state.credentials.latestEmail &&
      this.emailInput.current !== null
    ) {
      setTimeout(() => {
        this.handleUpdateLatestEmailAutoFill(
          this.usernameInput.current.value,
          this.passwordInput.current.state.password,
          this.emailInput.current.value
        );
      }, 300);
    }
  }

  getParams(location) {
    let params = {};
    if (location) {
      const searchParams = new URLSearchParams(location.search);
      params = {
        redirect: searchParams.get("redirect") || ""
      };
    }
    return params;
  }

  checkFormValid() {
    const valid =
      this.state.credentials.username.length > 0 &&
      this.state.credentials.password.length > 0;
    return !valid;
  }

  checkEmailFormValid() {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const valid =
      this.state.credentials.username.length > 0 &&
      this.state.credentials.password.length > 0 &&
      regex.test(this.state.credentials.latestEmail);
    return !valid;
  }

  validPassword(password) {
    this.setState((s) => {
      return {
        credentials: {
          ...s.credentials,
          password: password
        }
      };
    });
  }

  handleUsernameChange(e) {
    let newValue = e.target.value;
    this.setState((s) => {
      return {
        credentials: {
          ...s.credentials,
          username: newValue
        }
      };
    });
  }

  handleEmailChange(e) {
    let newValue = e.target.value;
    this.setState((s) => {
      return {
        credentials: {
          ...s.credentials,
          latestEmail: newValue
        }
      };
    });
  }

  handleAutoFill(username, password) {
    this.setState(
      (s) => {
        return {
          credentials: {
            password: password,
            username: username
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleUpdateLatestEmailAutoFill(username, password, latestEmail) {
    this.setState(
      (s) => {
        return {
          credentials: {
            password: password,
            username: username,
            latestEmail: latestEmail
          }
        };
      },
      () => this.checkEmailFormValid()
    );
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleUsernameChange(e);
    }
  }

  successAction(responseData) {
    if (responseData.warning === "email_update_required") {
      this.setState({
        email_update_required: true
      });
    } else {
      this.handleSuccess(responseData);
    }
  }

  successConfirmEmailAction() {
    this.setState({
      email_verification_required: true,
      loading: false,
      success: true
    });
  }

  handleSuccess(responseData) {
    this.setState({loading: true, isLogInAttempt: true}, () => {
      this.props.userLoggedIn(
        this.state.credentials.username,
        responseData.accessToken,
        responseData.tokenType,
        jwt_decode(responseData.accessToken).exp,
        responseData.tokenType
      );

      pendingDeletionRequestCount().then((s) => {
        this.props.pendingRequestCount(s.data);
      });

      me().then((result) => {
        this.props.user(
          result.data.roles,
          result.data.id,
          result.data.organisationId,
          result.data.projects,
          result.data.name
        );
        this.setState({loading: false});
        if (this.props.onClose)
          this.props.onClose(true, this.props.location?.state?.referrer);
      });
    });
  }

  onClose() {
    if (typeof this.props.onClose !== "undefined") {
      this.props.onClose();
    } else {
      window.history.back();
    }
  }

  render() {
    const {open, noActions} = this.props;
    const {
      loading,
      credentials,
      success,
      redirect,
      email_update_required,
      email_verification_required
    } = this.state;
    const {
      checkFormValid,
      checkEmailFormValid,
      validPassword,
      handleUsernameChange,
      handleEmailChange,
      successAction,
      successConfirmEmailAction,
      handleKeyDown,
      onClose
    } = this;

    const actions = noActions ? null : (
      <ActionsMenu
        horizontal
        menuItems={[
          {
            name: "Forgot Password",
            onClick: () =>
              this.setState({success: true, redirect: "/reset-password"})
          },
          {
            name: "Create User",
            onClick: () => this.setState({success: true, redirect: "/register"})
          }
        ]}
      />
    );

    const formInputs = !email_verification_required ? (
      <div>
        <Form.Group widths="equal">
          <Form.Field fluid="true" required placeholder="Username">
            <label>Username</label>
            <input
              ref={this.usernameInput}
              onChange={handleUsernameChange}
              onKeyDown={handleKeyDown}
              placeholder="Username"
              data-testid="username"
            />
          </Form.Field>
        </Form.Group>
        <Password
          autoComplete
          onValidate={validPassword}
          ref={this.passwordInput}
          required
        />
        {email_update_required ? (
          <Form.Field fluid="true" required placeholder="Email">
            <label>Email</label>
            <input
              ref={this.emailInput}
              onChange={handleEmailChange}
              placeholder="Email"
              data-testid="email"
            />
          </Form.Field>
        ) : null}
        {email_update_required ? null : actions}
      </div>
    ) : null;

    if (!this.state.loading && success && redirect) {
      return <Redirect push to={{pathname: redirect}} />;
    }

    if (
      !this.state.loading &&
      this.props.accessToken &&
      !this.state.isLogInAttempt
    ) {
      return (
        <Alert
          icon="user"
          headerContent="Sign In"
          content="You are already signed in."
          closeButtonContent="OK"
          hideCancel
        />
      );
    }

    const successContent = email_verification_required
      ? `A verification email has been sent to ${credentials.latestEmail}. Please check your email and click the link to verify your email address.`
      : null;

    if (email_update_required) {
      return (
        <FormModal
          formSubmitAPI={
            !email_verification_required ? userConfirmLatestEmail : null
          }
          formData={credentials}
          successContent={successContent}
          checkFormValid={checkEmailFormValid}
          loading={loading}
          onSuccess={successConfirmEmailAction}
          success={success}
          formInputs={formInputs}
          headerContent="Update your current email address"
          headerIcon="user"
          submitContent="Update"
          redirectOnClose="/"
          onClose={onClose}
          open={open}
        />
      );
    }

    return (
      <FormModal
        formSubmitAPI={userLogin}
        formData={credentials}
        checkFormValid={checkFormValid}
        testId="loginuser"
        loading={loading}
        onSuccess={successAction}
        formInputs={formInputs}
        headerContent="Sign In"
        headerIcon="user"
        submitContent="Sign In"
        redirectOnClose="/"
        onClose={onClose}
        open={open}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user, pendingRequestCount} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects,
    pendingRequestCount: pendingRequestCount.pendingRequestCount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLoggedIn: (username, accessToken, tokenType, tokenExp) =>
      dispatch(updateToken(username, accessToken, tokenType, tokenExp)),
    user: (roles, userId, organisationId, projects, name) =>
      dispatch(updateUser(roles, userId, organisationId, projects, name)),
    pendingRequestCount: (pendingRequestCount) =>
      dispatch(updatePendingRequestCount(pendingRequestCount))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
