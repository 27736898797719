import React from "react";
import {
  Button,
  Form,
  Grid,
  Message,
  Modal,
  Header,
  Dimmer,
  Loader,
  Icon,
  Segment
} from "semantic-ui-react";
import axios from "axios";
import {getAPIErrorMessages} from "../axios/api-helpers";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

class FormModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      loading: props.loading ?? false,
      error: false,
      success: props.success ?? false,
      responseErrors: {
        messages: [],
        header: ""
      }
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      modalOpen: this.props.open,
      trigger: this.props.trigger
        ? React.cloneElement(this.props.trigger, {
            onClick: () => this.handleOpen()
          })
        : undefined
    });
  }

  componentWillUnmount() {
    if (this.ct) this.ct.cancel();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.success !== prevProps.success ||
      this.props.failed !== prevProps.failed
    ) {
      this.setState({
        success: this.props.success,
        error: this.props.failed
      });
    } else if (this.props.open !== prevProps.open) {
      if (this.props.open && this.props.onOpen) {
        this.props.onOpen();
      }
      this.setState({
        modalOpen: this.props.open,
        success: false,
        error: false
      });
    } else if (this.props.loading !== prevProps.loading) {
      this.setState({loading: this.props.loading});
    }
  }

  handleSubmit(e, {value}) {
    if (!this.props.formSubmitAPI) return;

    this.setState({loading: true});
    this.ct = axios.CancelToken.source();
    this.props
      .formSubmitAPI(this.props.formData, this.ct.token)
      .then((response) => {
        this.ct = null;
        this.setState(
          {
            loading: false,
            error: false,
            success: true
          },
          () => {
            if (this.props.onSuccess) this.props.onSuccess(response.data);
          }
        );
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const retryHeader = error?.response?.headers['x-rate-limit-retry-after-seconds'];
          var errors = [];
          if (retryHeader) {
            const seconds = parseInt(retryHeader);
            const delay = seconds < 60 ? `${seconds} seconds.` : `${Math.ceil(seconds / 60)} minutes.`;
            errors = {header: `Too many authentication requests from your IP.`, messages: [`Try again in ${delay}`]};
          } else {
            errors = getAPIErrorMessages(
              error.response.data,
              error.response.status
            );
          }
          this.setState(
            {
              loading: false,
              error: true,
              success: false,
              responseErrors: errors
            },
            () => {
              if (this.props.onFail) this.props.onFail();
            }
          );
          }
      });
  }

  handleClose(e) {
    this.setState({modalOpen: false});
    e.preventDefault();
    if (typeof this.props.onClose !== "undefined") this.props.onClose();
  }

  handleOpen() {
    // This is only called when triggered (ie user input). When opening by setting "open" prop it will not
    // be called. https://github.com/Semantic-Org/Semantic-UI-React/issues/901. Instead we execute
    // this from componentDidUpdate for the untriggered case
    this.setState({modalOpen: true, success: false, error: false}, () => {
      if (this.props.onOpen) this.props.onOpen();
    });
  }

  hasTrigger() {
    return typeof this.props.trigger !== "undefined";
  }

  render() {
    const {
      loading,
      error,
      success,
      responseErrors,
      modalOpen,
      redirectOnClose
    } = this.state;
    const {
      checkFormValid,
      testId,
      successContent,
      successMessage,
      successHeader,
      formInputs,
      headerContent,
      subheaderContent,
      submitContent,
      headerIcon,
      formSubmitAPI
    } = this.props;
    const {handleSubmit, handleClose} = this;

    if (!modalOpen && !this.hasTrigger() && redirectOnClose) {
      return <Redirect to={{pathname: redirectOnClose}} />;
    }

    const submitButton = formSubmitAPI ? (
      <Button
        primary
        type="submit"
        content={submitContent}
        disabled={checkFormValid ? checkFormValid() : true}
        data-testid={"submit-" + testId}
      />
    ) : null;

    let modalContent = null;
    if (this.props.passiveColumn && this.props.passiveElements) {
      const firstCol =
        this.props.passiveColumn === 1
          ? this.props.passiveElements
          : formInputs;
      const secondCol =
        this.props.passiveColumn === 1
          ? formInputs
          : this.props.passiveElements;
      modalContent = (
        <>
          <Dimmer active={loading} inverted>
            <Loader>Please wait</Loader>
          </Dimmer>
          {this.props.headerRow}
          <Grid columns={2}>
            <Grid.Row stretched>
              <Grid.Column>{firstCol}</Grid.Column>
              <Grid.Column>{secondCol}</Grid.Column>
            </Grid.Row>
          </Grid>
          <Message
            error
            header={responseErrors.header}
            list={responseErrors.messages}
            data-testid={"errormessage-" + testId}
          />
          {successMessage ? successMessage :
              <Message
                  success
                  header={successHeader}
                  content={successContent}
                  data-testid={"successmessage-" + testId}
              />
          }
        </>
      );
    } else {
      const content = this.props.headerRow ? (
        <>
          {this.props.headerRow}
          {formInputs}
        </>
      ) : formInputs ? (
        <Segment>{formInputs}</Segment>
      ) : null;
      modalContent = (
        <>
          <Dimmer active={loading} inverted>
            <Loader>Please wait</Loader>
          </Dimmer>
          {content}
          <Message
            error
            header={responseErrors.header}
            list={responseErrors.messages}
            data-testid={"errormessage-" + testId}
          />
          {successMessage ? successMessage :
              <Message
                  success
                  header={successHeader}
                  content={successContent}
                  data-testid={"successmessage-" + testId}
              />
          }
        </>
      );
    }

    const modalSize =
      this.props.modalSize ??
      (this.props.passiveElements ? "fullscreen" : null);
    const extraButtons = this.props.optionalButtons?.map((m, i) => {
      return (
        <Button
          primary
          type="button"
          content={m.name}
          onClick={m.onClick}
          key={i}
        />
      );
    });
    return (
      <Modal
        as={Form}
        onSubmit={handleSubmit}
        error={error}
        warning
        success={success}
        open={modalOpen}
        onClose={handleClose}
        size={modalSize}
        trigger={this.state.trigger}
      >
        <Header icon={headerIcon} content={headerContent} />
        { subheaderContent ? <Modal.Content>{subheaderContent}</Modal.Content> : null }
        <Modal.Content inverted="true">{modalContent}</Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Close"
            onClick={handleClose}
            data-testid={"close-" + testId}
          />
          {extraButtons?.length > 0 ? extraButtons : submitButton}
        </Modal.Actions>
      </Modal>
    );
  }
}

FormModal.propTypes = {
  successContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  passiveColumn: PropTypes.number,
  passiveElements: PropTypes.node,
  headerRow: PropTypes.node,
  successHeader: PropTypes.string,
  formInputs: PropTypes.node,
  formData: PropTypes.object,
  headerContent: PropTypes.string.isRequired,
  subheaderContent: PropTypes.string,
  headerIcon: Icon.propTypes.className,
  submitContent: PropTypes.node,
  checkFormValid: PropTypes.func,
  handleSubmit: PropTypes.func,
  formSubmitAPI: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  redirectOnClose: PropTypes.string,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  modalSize: PropTypes.string
};

export default FormModal;
