import React from "react";
import {connect} from "react-redux";
import {Form, Segment} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";
import axios from "axios";

import {
  deploymentUpdate,
  receiverById,
  receiverSummary,
  station
} from "../axios/api";

import FormModal from "./FormModal";
import DateSelector from "./DateSelector";
import ReceiverSummary from "./ReceiverSummary";
import StationSelect from "./StationSelect";
import Location from "./Location";
import {fromUTC, receiverDeploymentEventDatesAreNotInRange} from "./helpers";

const receiverOrientations = ["UP", "DOWN"];

class ReceiverDeploymentEdit extends React.PureComponent {
  originalState = {};

  constructor(props) {
    super(props);

    this.state = {
      loadingReceiver: false,
      loadingStations: false,
      valid: false,
      stations: [],
      projectOrganisationIds: [],
      receiverTypes: config.get("receiver_device_model_names"),
      createLabel: "Edit Receiver Deployment",
      viewLabel: "Deployment Details",
      success: false,
      deployment: {
        ...props.value,
        latitude: props.value?.location?.latitude,
        longitude: props.value?.location?.longitude
      },
      receiverIds: []
    };

    this.originalState = {...this.state};

    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    this.ct = axios.CancelToken.source();
    receiverById(this.state.deployment.receiverId).then((receiverResult) => {
      this.setState(
        (s) => {
          return {
            deployment: {...s.deployment, receiver: receiverResult.receiver}
          };
        },
        () =>
          station(
            {projectId: this.state.deployment.projectId, size: 1000},
            this.ct.token
          )
            .then((result) => {
              const stations = result.data.content;
              const station = stations.find(
                (s) => s.id === this.state.deployment.stationId
              );
              this.setState((s) => {
                return {
                  loadingStations: false,
                  stations: stations,
                  stationLocation: station?.lastDeployedLocation
                };
              }, this.checkFormValid);
            })
            .catch((err) => {
              if (!axios.isCancel(err)) console.debug(err);
            })
      );
    });

    receiverSummary().then((result) => {
      this.setState((s) => {
        const receiverIds = result.data;
        return {receiverIds: receiverIds};
      });
    });
  }

  onClose() {
    if (this.ct) this.ct.cancel();
    this.setState({...this.originalState});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    const deployment = this.state.deployment;
    const valid =
      deployment.stationId &&
      deployment.deploymentDate &&
      deployment.deploymentTimezone &&
      !this.state.dateNotValid &&
      deployment.latitude &&
      deployment.longitude &&
      deployment.bottomDepthMeters > 0 &&
      deployment.depthBelowSurface > 0 &&
      deployment.receiverOrientation &&
      deployment.receiverId;

    return !valid;
  }

  successAction(response) {
    if (this.props.onUpdate) {
      this.props.onUpdate({
        ...response,
        ...response.location
      });
    }
  }

  handleLocationChange(e, value) {
    this.setState(
      (s) => ({
        deployment: {
          ...s.deployment,
          latitude: parseFloat(value.latitude),
          longitude: parseFloat(value.longitude)
        }
      }),
      this.checkFormValid
    );
  }

  render() {
    const {success, createLabel, deployment} = this.state;
    const {trigger} = this.props;
    const {checkFormValid, successAction, onClose} = this;
    const formInputs = (
      <>
        <Segment>
          <ReceiverSummary value={this.state.deployment.receiver} />
        </Segment>
        <Form.Dropdown
          required
          fluid
          selection
          search
          label="Receiver Id"
          options={this.state.receiverIds.map((v) => ({
            text: v.receiverId,
            value: v.id
          }))}
          value={this.state.deployment.receiverId}
          onChange={(e, {value}) => {
            this.setState(
              {deployment: {...deployment, receiverId: value}},
              checkFormValid
            );
            receiverById(value).then((receiverResult) => {
              this.setState((s) => {
                return {
                  deployment: {
                    ...s.deployment,
                    receiver: receiverResult.receiver
                  }
                };
              });
            });
          }}
        />
        <Form.Field
          control={DateSelector}
          required
          error={this.state.dateNotValid}
          dontShowFieldsRequired
          timezone={this.state.deployment.deploymentTimezone}
          date={fromUTC(
            {
              timestamp: this.state.deployment.deploymentDate,
              timezone: this.state.deployment.deploymentTimezone
            },
            "YYYY-MM-DDTHH:mm:ss.SSS"
          )}
          minDate={
            this.state.receiverStatus === "RECOVERED"
              ? this.state.receiver.recoveryDate
              : null
          }
          onChange={(v) => {
            this.setState(
              (s) => ({
                dateNotValid:
                  v.timezone !== "" &&
                  this.props.value.receiverId ===
                    this.state.deployment.receiverId
                    ? receiverDeploymentEventDatesAreNotInRange(
                        s.deployment.receiver.history,
                        {deploymentDate: v, recoveryDate: null},
                        false,
                        s.deployment.id
                      ).deploymentDateNotInRange
                    : false,
                deployment: {
                  ...s.deployment,
                  deploymentDate: v.utc,
                  deploymentTimezone: v.timezone
                }
              }),
              checkFormValid
            );
          }}
          label="Deployment Date and Timezone"
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Project Name"
          value={deployment.projectName}
          data-testid="test-project-id-ro"
        />
        <StationSelect
          required
          loading={this.state.loadingStations}
          disabled={this.state.stations.length === 0}
          stations={this.state.stations}
          options={this.state.stations.map((s) => {
            return {text: s.name, value: s.id};
          })}
          value={this.state.deployment.stationId}
          onChange={(station) => {
            this.setState(
              (s) => ({
                stationLocation: station.location,
                deployment: {
                  ...s.deployment,
                  stationId: station.id,
                  longitude: station.location.longitude,
                  latitude: station.location.latitude
                }
              }),
              checkFormValid
            );
          }}
        />
        {this.state.stationLocation ? (
          <Form.Field
            control={Location}
            label="Location"
            required
            warn={false}
            station={{
              longitude: this.state.stationLocation.longitude,
              latitude: this.state.stationLocation.latitude,
              radius: 500
            }}
            radiusWarning="Coordinates are outside a 500m radius from the station's  location"
            warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
            value={{
              longitude: deployment.longitude,
              latitude: deployment.latitude
            }}
            onChange={this.handleLocationChange}
          />
        ) : null}
        <Form.Input
          placeholder="e.g. VEMCO"
          label="Acoustic Release Brand"
          maxLength={255}
          autoComplete="none"
          defaultValue={this.state.deployment.acousticReleaseBrand}
          onBlur={(e) => {
            const value = e.target.value;
            this.setState((s) => ({
              deployment: {
                ...s.deployment,
                acousticReleaseBrand: value
              }
            }));
          }}
        />
        <Form.Input
          label="Acoustic Release ID"
          maxLength={255}
          autoComplete="none"
          defaultValue={this.state.deployment.acousticReleaseId}
          onBlur={(e) => {
            const value = e.target.value;
            this.setState((s) => ({
              deployment: {
                ...s.deployment,
                acousticReleaseId: value
              }
            }));
          }}
        />
        <Form.Input
          label="Mooring Descriptor"
          maxLength={255}
          autoComplete="none"
          placeholder="eg. Star Picket, Float Mooring, Fish Aggregating Device, Permanent mooring (e.g. navigation aid), Other (please specify)"
          defaultValue={this.state.deployment.mooringDescriptor}
          onBlur={(e) => {
            this.setState({
              deployment: {...deployment, mooringDescriptor: e.target.value}
            });
          }}
        />
        <Form.Input
          required
          label="Bottom (Sea Floor) Depth (m)"
          type="number"
          min={0}
          step={0.00001}
          autoComplete="none"
          defaultValue={this.state.deployment.bottomDepthMeters}
          error={this.state.bottomDepthMetersError}
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            this.setState(
              {
                bottomDepthMetersError:
                  value <= 0
                    ? "Depth below surface must be greater than zero"
                    : null,
                deployment: {...deployment, bottomDepthMeters: value}
              },
              checkFormValid
            );
          }}
        />
        <Form.Input
          required
          label="Receiver Depth Below Surface (m)"
          type="number"
          min={0}
          step={0.00001}
          autoComplete="none"
          value={this.state.deployment.depthBelowSurface}
          error={this.state.depthBelowSurfaceError}
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            this.setState(
              {
                depthBelowSurfaceError:
                  value <= 0
                    ? "Depth below surface must be greater than zero"
                    : null,
                deployment: {...deployment, depthBelowSurface: value}
              },
              checkFormValid
            );
          }}
        />
        <Form.Dropdown
          required
          fluid
          selection
          search
          label="Receiver Orientation"
          options={receiverOrientations.map((v) => ({text: v, value: v}))}
          value={this.state.deployment.receiverOrientation}
          onChange={(e, {value}) => {
            this.setState(
              {deployment: {...deployment, receiverOrientation: value}},
              checkFormValid
            );
          }}
        />
        <Form.TextArea
          label="Comments"
          defaultValue={this.state.deployment.comments}
          onBlur={(e) => {
            const value = e.target.value;
            this.setState((s) => ({
              deployment: {...s.deployment, comments: value}
            }));
          }}
        />
      </>
    );

    return (
      <FormModal
        open
        formSubmitAPI={deploymentUpdate}
        formData={deployment}
        checkFormValid={checkFormValid}
        testId="receiver-deployment-form"
        successHeader="Success"
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        successContent={`Deployment Updated`}
        headerContent={createLabel}
        submitContent={`Update Deployment`}
        onClose={onClose}
      />
    );
  }
}

ReceiverDeploymentEdit.propTypes = {
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles
  };
}

export default connect(mapStateToProps)(ReceiverDeploymentEdit);
