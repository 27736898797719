import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MainMenu from "./MainMenu";
import {Container, Segment} from "semantic-ui-react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Home from "./Home";
import About from "./About";
import ErrorPage from "./ErrorPage";
import {Resources} from "./Resources";
import UserProfile from "./UserProfile";
import {
  DataPolicyUrl,
  DeploymentRecordsTemplateUrl,
  FormMode,
  getSearchParams,
  getSelectedTransmitterDeployment,
  ProtectedStatusFormUrl
} from "./helpers";
import LoginForm from "./LoginForm";
import Organisation from "./Organisation";
import UserConfirmation from "./UserConfirmation";
import PasswordReset from "./PasswordReset";
import PasswordChange from "./PasswordChange";
import {properties} from "../properties";
import ProjectTable from "./ProjectTable";
import InstallationTable from "./InstallationTable";
import ReceiverTable from "./ReceiverTable";
import UserTable from "./UserTable";
import OrganisationTable from "./OrganisationTable";
import Project from "./ProjectCreate";
import StationTable from "./StationTable";
import ModalContainer from "./ModalContainer";
import ProjectView from "./ProjectView";
import {
  installationById,
  organisationById,
  projectById,
  receiverById,
  receiverDeploymentById,
  receiverRecoveryById,
  transmitterById,
  transmitterByReleaseId,
  transmitterBySensorId
} from "../axios/api";
import Alert from "./Alert";
import Receiver from "./Receiver";
import ReceiverDeploymentTable from "./ReceiverDeploymentTable";
import {connect} from "react-redux";
import DetectionFileUpload from "./DetectionFileUpload";
import FathomFileUpload from "./FathomFileUpload";
import TransmitterTable from "./TransmitterTable";
import DetectionTable from "./DetectionTable";
import TransmitterView from "./TransmitterView";
import InstallationView from "./InstallationView";
import StationView from "./StationView";
import ReceiverDeploymentView from "./ReceiverDeploymentView";
import ReceiverRecoveryView from "./ReceiverRecoveryView";
import MysteryTags from "./MysteryTags";
import config from "react-global-configuration";
import {isMobile} from "react-device-detect";
import MobileDetection from "./MobileDetection";
import DeletionRequestTable from "./DeletionRequestTable";

class AnimalTracking extends React.Component {
  render() {
    const {title, imosLogo} = properties;
    return (
      <Router>
        <Container>
          {isMobile ? <MobileDetection /> : null}
          <Header
            title={title}
            logo={imosLogo}
            messageOfTheDay={config.get("message_of_the_day")}
          />
          <MainMenu />
          <Segment className="at-content-container">
            <Switch>
              <Route exact path="/error" render={() => <ErrorPage />} />
              <Route exact path="/" render={() => <Home />} />
              <Route exact path="/about" render={() => <About />} />
              <Route
                exact
                path="/register"
                render={(props) => (
                  <>
                    {this.props.accessToken ? (
                      <Home />
                    ) : (
                      <UserProfile
                        {...props}
                        mode={FormMode.CREATE}
                        open
                        onClose={() => props.history.goBack()}
                      />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/login"
                render={(props) => (
                  <LoginForm
                    {...props}
                    open
                    onClose={(success, referrer) => {
                      if (
                        referrer !== "/passwordreset" &&
                        referrer !== "/confirmation"
                      ) {
                        props.history.goBack();
                      } else {
                        props.history.replace("/");
                      }
                    }}
                  />
                )}
              />
              <Route
                exact
                path="/edit-user-profile"
                render={(props) => (
                  <UserProfile
                    {...props}
                    open
                    mode={FormMode.EDIT}
                    onClose={() => {
                      props.history.goBack();
                    }}
                  />
                )}
              />
              <Route
                exact
                path="/user-profile"
                render={(props) => (
                  <UserProfile
                    {...props}
                    open
                    mode={FormMode.VIEW}
                    onClose={() => {
                      props.history.goBack();
                    }}
                  />
                )}
              />
              <Route
                path="/person/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={UserProfile}
                    model={{
                      id: parseInt(props.match.params.id),
                      mode: FormMode.VIEW
                    }}
                    noRedirectOnClose
                    onClose={() => {
                      if (window.location.pathname !== this.props.returnTo)
                        props.history.replace(this.props.returnTo);
                    }}
                  />
                )}
              />
              <Route path="/confirmation" component={UserConfirmation} />
              <Route
                path="/reset-password"
                component={(props) => (
                  <PasswordReset
                    {...props}
                    onClose={() => {
                      props.history.goBack();
                    }}
                  />
                )}
              />
              <Route
                path="/passwordreset"
                component={(props) => (
                  <PasswordChange
                    {...props}
                    onClose={() => {
                      props.history.goBack();
                    }}
                  />
                )}
              />
              <Route
                path="/projects"
                render={(props) => (
                  <ProjectTable {...props} key={props.location.key} />
                )}
              />
              <Route
                path="/installations"
                render={(props) => (
                  <InstallationTable {...props} key={props.location.key} />
                )}
              />
              <Route
                path="/installation/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={InstallationView}
                    fetcher={installationById}
                    noRedirectOnClose
                    onClose={() => props.history.goBack()}
                  />
                )}
              />
              <Route
                path="/stations/register/:installationId"
                component={StationTable}
              />
              <Route
                path="/stations"
                render={(props) => (
                  <StationTable {...props} key={props.location.key} />
                )}
              />
              <Route
                path="/station/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={StationView}
                    model={{station: parseInt(props.match.params.id)}}
                    noRedirectOnClose
                    onClose={() => props.history.goBack()}
                  />
                )}
              />
              <Route
                path="/project/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={ProjectView}
                    fetcher={projectById}
                    noRedirectOnClose
                    onClose={() => {
                      if (window.location.pathname !== this.props.returnTo)
                        props.history.replace(this.props.returnTo);
                    }}
                  />
                )}
              />
              <Route
                path="/tag/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={TransmitterView}
                    fetcher={(id) => {
                      return transmitterById(id).then((transmitter) => {
                        return {
                          transmitter: getSelectedTransmitterDeployment(
                            transmitter.transmitter
                          )
                        };
                      });
                    }}
                    noRedirectOnClose
                    onClose={() =>
                      (window.location = "/transmitters/transmitter")
                    }
                  />
                )}
              />
              <Route
                path="/sensor/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={TransmitterView}
                    fetcher={(id) => {
                      return transmitterBySensorId(id).then((transmitter) => {
                        return {
                          transmitter: getSelectedTransmitterDeployment(
                            transmitter.transmitter
                          )
                        };
                      });
                    }}
                    noRedirectOnClose
                    onClose={() =>
                      (window.location = "/transmitters/transmitter")
                    }
                  />
                )}
              />
              <Route
                path="/release/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={TransmitterView}
                    fetcher={(id) => {
                      return transmitterByReleaseId(id).then((transmitter) => {
                        return {
                          transmitter: getSelectedTransmitterDeployment(
                            transmitter.transmitter
                          )
                        };
                      });
                    }}
                    noRedirectOnClose
                    onClose={() => props.history.goBack()}
                  />
                )}
              />
              <Route
                path="/create-organisation"
                render={(props) => (
                  <Organisation {...props} mode={FormMode.CREATE} />
                )}
              />
              <Route
                path="/organisations"
                render={(props) => (
                  <OrganisationTable {...props} key={props.location.key} />
                )}
              />
              <Route
                path="/edit-organisation/:id/"
                render={(props) => (
                  <Organisation {...props} mode={FormMode.EDIT} />
                )}
              />
              <Route
                path="/organisation/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={Organisation}
                    fetcher={organisationById}
                    noRedirectOnClose
                    onClose={() => {
                      if (window.location.pathname !== this.props.returnTo)
                        props.history.replace(this.props.returnTo);
                    }}
                  />
                )}
              />
              <Route
                path="/receivers/deployment/:modal"
                component={ReceiverDeploymentTable}
              />
              <Route path="/receivers/deployment/deploy">
                <Redirect to="/receivers/receiver/deploy" />
              </Route>
              <Route path="/transmitters/deployment/deployment">
                <Redirect to="/receivers/receiver/recover" />
              </Route>
              <Route
                path="/receivers/deployment"
                render={(props) => (
                  <ReceiverDeploymentTable
                    {...props}
                    key={props.location.key}
                  />
                )}
              />
              <Route
                path="/receiverRecovery/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={ReceiverRecoveryView}
                    fetcher={receiverRecoveryById}
                    noRedirectOnClose
                    onClose={() => props.history.goBack()}
                    extraProps={{showEditButton: true}}
                  />
                )}
              />
              <Route
                path="/receiverDeployment/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={ReceiverDeploymentView}
                    fetcher={receiverDeploymentById}
                    noRedirectOnClose
                    onClose={() => props.history.goBack()}
                    extraProps={{showEditButton: true}}
                  />
                )}
              />
              <Route
                path="/receivers/receiver"
                render={(props) => (
                  <ReceiverTable {...props} key={props.location.key} />
                )}
              />
              <Route path="/receivers/register/:id" component={ReceiverTable} />
              <Route
                path="/receiver/:id"
                render={(props) => (
                  <ModalContainer
                    {...props}
                    modal={Receiver}
                    fetcher={receiverById}
                    noRedirectOnClose
                    onClose={() => (window.location = "/receivers/receiver")}
                  />
                )}
              />
              <Route
                path="/receivers/upload"
                render={(props) => (
                  <DetectionFileUpload
                    {...props}
                    onClose={() => props.history.goBack()}
                  />
                )}
              />
              <Route
                path="/receivers/fathom/upload"
                render={(props) => (
                  <FathomFileUpload
                    {...props}
                    onClose={() => props.history.goBack()}
                  />
                )}
              />
              <Route
                path="/transmitters/transmitter/:modal"
                component={TransmitterTable}
              />
              <Route
                path="/transmitters/transmitter"
                render={(props) => (
                  <TransmitterTable {...props} key={props.location.key} />
                )}
              />
              <Route path="/transmitters/recover">
                <Redirect to="/transmitters/transmitter/recover" />
              </Route>
              <Route path="/transmitters/deployment">
                <Redirect to="/transmitters/transmitter/deploy" />
              </Route>
              <Route
                keyProp={this.props.pageKey}
                path="/detection"
                render={(props) => (
                  <DetectionTable {...props} key={props.location.key} />
                )}
              />
              <Route
                exact
                path="/project-create"
                render={(props) => (
                  <Project {...props} mode={FormMode.CREATE} />
                )}
              />
              <Route
                exact
                path="/datapolicy"
                render={() => {
                  window.location = DataPolicyUrl;
                }}
              />
              <Route
                exact
                path="/protected-status-form"
                render={() => {
                  window.location = ProtectedStatusFormUrl;
                }}
              />
              <Route
                exact
                path="/deployment-records-template"
                render={() => {
                  window.location = DeploymentRecordsTemplateUrl;
                }}
              />
              <Route exact path="/resources" render={() => <Resources />} />
              <Route
                exact
                path="/user"
                render={(props) => (
                  <UserTable {...props} key={props.location.key} />
                )}
              />
              <Route
                exact
                path="/deletion-request"
                render={(props) => (
                  <DeletionRequestTable {...props} key={props.location.key} />
                )}
              />
              <Route
                path="/notfound"
                render={(props) => (
                  <Alert
                    {...props}
                    icon="exclamation triangle"
                    headerContent="Resource Not Found"
                    content={`The requested resource ${getSearchParams(
                      props.location
                    ).get("resource")} is not available`}
                    hideCancel
                    closeButtonContent="OK"
                  />
                )}
              />
              <Route
                path="/notpermitted"
                render={(props) => (
                  <Alert
                    {...props}
                    icon="exclamation triangle"
                    headerContent="Access Restricted"
                    content={[
                      <span key={0}>
                        The logged in user does not have permission to access
                        the requested resource.
                      </span>,
                      <br key={1} />,
                      <span key={2}>
                        {" "}
                        If you feel this is in error, please contact{" "}
                        <a href="mailto:info@aodn.org.au">info@aodn.org.au</a>
                      </span>
                    ]}
                    closeButtonContent="OK"
                    hideCancel
                  />
                )}
              />
              <Route
                exact
                path="/tools/mysterytags"
                render={() => <MysteryTags />}
              />
              <Redirect to="/" />
            </Switch>
          </Segment>
          <Footer />
        </Container>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const {user, route, pendingRequestCount} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects,
    returnTo: route.returnToPage,
    pendingRequestCount: pendingRequestCount.pendingRequestCount
  };
}

export default connect(mapStateToProps)(AnimalTracking);
