import React from "react";
import {connect} from "react-redux";
import {Button, Form, Message, Segment} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";
import {projectById, recoveryCreate} from "../axios/api";
import {
  doesReceiverHaveId,
  receiverDeploymentEventDatesAreNotInRange
} from "./helpers";

import FormModal from "./FormModal";
import DateSelector from "./DateSelector";
import ReceiverSummary from "./ReceiverSummary";
import Location from "./Location";
import ReceiverFind from "./ReceiverFind";
import ActionsMenu from "./ActionsMenu";
import {ModalType} from "./ReceiverDeploymentTable";

class ReceiverRecoveryCreate extends React.Component {
  initialState = {
    deploymentId: null,
    status: "RECOVERED",
    recoveredByUserId: "",
    recoveryTimezone: "",
    recoveryDate: "",
    latitude: "",
    longitude: "",
    comments: "",
    receiverFindValue: ""
  };

  constructor(props) {
    super(props);

    this.state = {
      valid: false,
      locationValid: true,
      recoveredByOptions: [],
      receiverTypes: config.get("receiver_device_model_names"),
      receiverRecoveryStatus: config.get("receiver_recovery_status"),
      receiver: null,
      success: false,
      recovery: this.initialState,
      receiverFindValue: this.props.recoverReceiverId ?? "",
      noValidate: this.props.recoverReceiverId ? true : false
    };

    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleReceiverFindChange = this.handleReceiverFindChange.bind(this);
    this.handleReceiverFindConfirm = this.handleReceiverFindConfirm.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleRecoveredByChange = this.handleRecoveredByChange.bind(this);
    this.handleRecoveryDateChange = this.handleRecoveryDateChange.bind(this);
    this.handleCommentsBlur = this.handleCommentsBlur.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  onClose() {
    this.setState({...this.initialState});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    const {recovery, receiver, locationValid} = this.state;

    const valid =
      receiver &&
      recovery.status &&
      recovery.recoveredByUserId &&
      recovery.recoveryDate &&
      !this.state.dateNotValid &&
      recovery.recoveryTimezone &&
      (locationValid || recovery.status === "LOST");

    return !valid;
  }

  successAction(response) {
    this.setState({success: true});
    if (this.props.onUpdate) {
      const recovery = {
        ...response,
        deployment: this.state.receiver.deployment.deployment
      };
      this.props.onUpdate(recovery);
    }
  }

  resetForm() {
    this.setState({
      success: false,
      locationValid: true,
      recoveredByOptions: [],
      receiver: null,
      receiverFindValue: "",
      recovery: this.initialState
    });
  }

  handleLocationChange(e, value) {
    this.setState(
      {
        locationValid: value.valid,
        recovery: {
          ...this.state.recovery,
          latitude: parseFloat(value.latitude),
          longitude: parseFloat(value.longitude)
        }
      },
      () => this.checkFormValid()
    );
  }

  handleReceiverFindConfirm(e) {
    e.preventDefault();
    const receiver = this.state.receiverFound;
    if (!receiver.error) {
      receiver.deploymentStation = receiver.installation.stations.find(
        (s) => s.id === receiver.deployment.deployment.stationId
      );
      projectById(receiver.installation.project.id).then((result) => {
        const members = result.project.projectRoles.filter((r) => {
          return r.access !== "REVOKED";
        });
        const recoveredByOptions = members.map((m) => {
          return {text: m.user.name, value: m.user.id, object: m.user};
        });
        this.setState({
          receiver: receiver,
          recoveredByOptions: recoveredByOptions,
          recovery: {
            ...this.state.recovery,
            recoveredByUserId: recoveredByOptions.find(
              (o) => o.value === this.props.userId
            )
              ? this.props.userId
              : "",
            deploymentId: receiver.deployment.deployment.id,
            latitude: receiver.deployment.deployment.location.latitude.toFixed(
              6
            ),
            longitude: receiver.deployment.deployment.location.longitude.toFixed(
              6
            )
          }
        });
      });
    }
  }

  handleReceiverFindChange(receiver) {
    this.setState({receiverFound: receiver});
  }

  handleStatusChange(e, {value}) {
    this.setState({recovery: {...this.state.recovery, status: value}}, () =>
      this.checkFormValid()
    );
  }

  handleRecoveredByChange(e, {value}) {
    this.setState(
      {recovery: {...this.state.recovery, recoveredByUserId: value}},
      () => this.checkFormValid()
    );
  }

  handleRecoveryDateChange(value) {
    this.setState(
      (s) => ({
        dateNotValid:
          value.timezone !== ""
            ? receiverDeploymentEventDatesAreNotInRange(
                s.receiver.history,
                {
                  deploymentDate: {
                    utc: s.receiver.deployment.deployment.deploymentDate,
                    timezone:
                      s.receiver.deployment.deployment.deploymentTimezone
                  },
                  recoveryDate: value
                },
                false,
                s.recovery.deploymentId
              ).recoveryDateNotInRange
            : false,
        recovery: {
          ...this.state.recovery,
          recoveryDate: value.utc,
          recoveryTimezone: value.timezone
        }
      }),
      () => this.checkFormValid()
    );
  }

  handleCommentsBlur(e) {
    this.setState({
      recovery: {...this.state.recovery, comments: e.target.value}
    });
  }

  render() {
    const {
      success,
      recovery,
      receiver,
      receiverFound,
      receiverFindValue,
      recoveredByOptions,
      receiverRecoveryStatus
    } = this.state;
    const {
      checkFormValid,
      successAction,
      onClose,
      handleReceiverFindChange,
      handleReceiverFindConfirm,
      handleStatusChange,
      handleRecoveredByChange,
      handleRecoveryDateChange,
      handleLocationChange,
      handleCommentsBlur
    } = this;

    const canContinue = doesReceiverHaveId(receiverFound, receiverFindValue);
    const findDeployment = (
      <>
        <ReceiverFind
          noValidate={this.state.noValidate}
          onChange={(value) => this.setState({receiverFindValue: value})}
          onFound={handleReceiverFindChange}
          value={receiverFindValue}
          recover
        />
        <Button
          primary
          onClick={handleReceiverFindConfirm}
          disabled={!canContinue || receiverFound.status === "LOST"}
        >
          Next
        </Button>
      </>
    );

    const deploymentDetails = receiver ? (
      <>
        <strong>Receiver Summary</strong>
        <Segment>
          <ReceiverSummary value={receiver} />
        </Segment>
        <strong>Deployment Summary</strong>
        <Segment>
          <DateSelector
            readOnly
            date={receiver.deployment.deployment.deploymentDate}
            timezone={receiver.deployment.deployment.deploymentTimezone}
            label="Deployment Date and Timezone"
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Project Name"
            value={receiver.installation.project.name}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Installation Name"
            value={receiver.installation.name}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Station Name"
            value={receiver.deploymentStation.name}
          />
          <Form.Field
            control={Location}
            label="Deployment Location"
            readOnly
            value={receiver.deployment.deployment.location}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Acoustic Release ID"
            value={receiver.deployment.deployment.acousticReleaseId ?? ""}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Acoustic Release Brand"
            value={receiver.deployment.deployment.acousticReleaseBrand ?? ""}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Mooring Descriptor"
            value={receiver.deployment.deployment.mooringDescriptor ?? ""}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Depth Below Surface (metres)"
            value={receiver.deployment.deployment.depthBelowSurface}
          />
        </Segment>
      </>
    ) : null;

    const recoveryDetails =
      receiver && !success ? (
        <>
          <Form.Dropdown
            required
            fluid
            selection
            search
            label="Recovery Status"
            options={receiverRecoveryStatus.map((v) => ({text: v, value: v}))}
            value={recovery.status}
            onChange={handleStatusChange}
          />
          <Form.Dropdown
            required
            fluid
            selection
            search
            label="Recovered By"
            options={recoveredByOptions}
            value={recovery.recoveredByUserId}
            onChange={handleRecoveredByChange}
          />
          <Form.Field
            control={DateSelector}
            error={this.state.dateNotValid}
            required
            dontShowFieldsRequired
            label="Recovery Date and Timezone"
            timezone={this.state.recovery.recoveryTimezone}
            minDate={receiver.deployment.deployment.deploymentDate}
            onChange={handleRecoveryDateChange}
          />
          <Form.Field
            disabled={recovery.status === "LOST"}
            control={Location}
            label={
              recovery.status === "LOST"
                ? "Location - will not be saved when receiver is LOST"
                : "Location"
            }
            required={recovery.status !== "LOST"}
            warn={false}
            radiusWarning="Coordinates are outside a 500m radius from the station's registered location"
            warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
            value={{longitude: recovery.longitude, latitude: recovery.latitude}}
            onChange={handleLocationChange}
          />
          <Form.TextArea
            label="Comments"
            defaultValue={this.state.recovery.comments}
            onBlur={handleCommentsBlur}
          />
        </>
      ) : null;

    const recoveryDetailsReadOnly = success ? (
      <>
        <Form.Input
          fluid
          label="Recovery Status"
          value={recovery.status}
          readOnly
          transparent
        />
        <Form.Input
          fluid
          label="Recovered By"
          value={
            recoveredByOptions.find((o) => {
              return o.value === recovery.recoveredByUserId;
            }).object.name
          }
          readOnly
          transparent
        />
        <DateSelector
          label="Recovery Date and Timezone"
          readOnly
          date={recovery.recoveryDate}
          timezone={recovery.recoveryTimezone}
        />
        <Form.Field
          control={Location}
          label="Location"
          readOnly
          value={{longitude: recovery.longitude, latitude: recovery.latitude}}
        />
        <Form.TextArea
          readOnly
          label="Comments"
          value={this.state.recovery.comments}
        />
      </>
    ) : null;

    let actionMenuItems = [];
    if (success) {
      actionMenuItems.push(
        {
          name: "Recover Another Receiver",
          onClick: (e) => {
            e.preventDefault();
            this.resetForm();
          }
        },
        {
          name: "Upload VUE files",
          goto: "/receivers/upload"
        },
        {
          name: "Upload Fathom files",
          goto: "/receivers/fathom/upload"
        }
      );
    }

    if (receiverFound?.status === "LOST") {
      actionMenuItems.push({
        name: "Edit receiver recovery",
        onClick: (e) => {
          this.props.modalOpener(
            ModalType.EditDeploymentRecovery,
            {
              ...receiverFound.deployment.deployment,
              recovery: receiverFound.deployment.recovery
            },
            {editRecovery: true}
          );
        }
      });
    }

    const actions =
      actionMenuItems.length > 0 ? (
        <ActionsMenu horizontal menuItems={actionMenuItems} />
      ) : null;

    const message =
      receiverFound?.status === "LOST" ? (
        <Message
          warning
          header="Edit recovery details for lost receiver."
          content={`Receiver ${receiverFound.deviceModelName}-${receiverFound.serialNumber} status is currently LOST. 
          You can edit the recovery now or search for another receiver instead.`}
        />
      ) : null;

    const formInputs = (
      <>
        {!receiver ? findDeployment : null}
        {deploymentDetails}
        {recoveryDetails}
        {recoveryDetailsReadOnly}
        {actions}
        {message}
      </>
    );

    const submitContent = receiver
      ? `Recover ${receiver.deviceModelName}-${receiver.serialNumber}`
      : "Recover";

    return (
      <FormModal
        open
        formSubmitAPI={success ? false : recoveryCreate}
        formData={recovery}
        checkFormValid={checkFormValid}
        successHeader="Success"
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerIcon="microphone"
        successContent="Receiver Recovered"
        headerContent="Recover Receiver"
        submitContent={submitContent}
        onClose={onClose}
      />
    );
  }
}

ReceiverRecoveryCreate.propTypes = {
  onClose: PropTypes.func,
  onUpdate: PropTypes.func
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles,
    userId: user.userId,
    username: user.username,
    name: user.name
  };
}

export default connect(mapStateToProps)(ReceiverRecoveryCreate);
