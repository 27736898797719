export const modelOrdering = [
  "VR2W",
  "VR2Tx",
  "VR2AR",
  "VR2AR-X",
  "VR4-UWM",
  "VR3-UWM",
  "aquaHub",
  "VR4G",
  "VR2C69"
];
