import React from "react";
import {List, Form, Segment} from "semantic-ui-react";
import SelectListItem from "./SelectListItem";

class SelectList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      items: this.getItems(), // Items in the list
      value: props.defaultValue, // Value to return to parent
      options: props.options // All available options
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      // Set default text value
      let textValues = this.getItems();
      // Remove excluded
      let values = this.props.defaultValue;
      if (typeof this.props.excludeItem !== "undefined") {
        textValues.filter((o) => o.text !== this.props.excludeItem.text);
        values =
          typeof this.props.allowDefaultStrikeThrough !== "undefined"
            ? this.props.defaultValue.filter(
                (o) => o.value !== this.props.excludeItem.value
              )
            : this.props.defaultValue.filter(
                (o) => o !== this.props.excludeItem.value
              );
      }
      this.setState({
        options: this.props.options,
        items: textValues,
        value: values
      });
    }
  }

  getItems() {
    return this.props.defaultValue.reduce((result, i) => {
      let value =
        typeof this.props.allowDefaultStrikeThrough !== "undefined"
          ? this.props.options.find((item) => item.value === i.value)
          : this.props.options.find((item) => item.value === i);
      if (typeof value !== "undefined") {
        result.push({
          text: value.text,
          required: this.props.requireDefault,
          allowStrikeThrough: this.props.allowDefaultStrikeThrough,
          strikeThrough: i.strikeThrough,
          strikeThroughLabel: this.props.strikeThroughLabel,
          strikeThroughButtonVisible: this.props.strikeThroughButtonVisible
        });
      }
      return result;
    }, []);
  }

  handleSelectChange(e, {value}) {
    const textValue = this.state.options.find((item) => item.value === value)
      .text;
    value =
      typeof this.props.allowDefaultStrikeThrough !== "undefined"
        ? {value: value, strikeThrough: false}
        : value;
    this.setState(
      {
        selected: value,
        items: [...this.state.items, {text: textValue, required: false}],
        value: [...this.state.value, value]
      },
      () => {
        this.props.onChange(this.state.value);
      }
    );
  }

  handleRemoveClick(key, strikeThrough) {
    let items = this.state.items;
    if (typeof strikeThrough !== "undefined") {
      items[key].strikeThrough = strikeThrough;
    } else {
      items.splice(key, 1);
    }
    const values = items.map((i) => {
      if (typeof this.props.allowDefaultStrikeThrough !== "undefined") {
        let value = {};
        value.value = this.state.options.find(
          (item) => item.text === i.text
        ).value;
        value.strikeThrough =
          typeof i.strikeThrough === "undefined" ? false : i.strikeThrough;
        return value;
      } else {
        return this.state.options.find((item) => item.text === i.text).value;
      }
    });
    this.setState(
      {
        selected: null,
        items: items,
        value: values
      },
      () => {
        this.props.onChange(this.state.value);
      }
    );
  }

  render() {
    const {label, required} = this.props;
    const {items, options, selected} = this.state;
    const {handleSelectChange, handleRemoveClick} = this;

    const availableOptions = options.filter((o) => {
      let f = items.find((i) => {
        return o.text === i.text;
      });
      return typeof f === "undefined";
    });

    const listItems = items.map((item, index) => {
      return (
        <SelectListItem
          onClick={handleRemoveClick}
          content={item}
          index={index}
          key={index}
        />
      );
    });

    const requiredMessage = required ? " At least one is required." : "";

    const list =
      items.length === 0 ? (
        <p>
          No {label} have been selected.{requiredMessage}
        </p>
      ) : (
        <List celled verticalAlign="middle" items={listItems} />
      );

    return (
      <div className={`${required ? "required" : ""} field`}>
        <label>{label}</label>
        <Segment>
          {list}
          <Form.Group>
            <Form.Dropdown
              width="16"
              placeholder="Search for one or more"
              fluid
              search
              selection
              options={availableOptions}
              onChange={handleSelectChange}
              value={selected}
            />
          </Form.Group>
        </Segment>
      </div>
    );
  }
}

export default SelectList;
