import {Button, Header, Modal} from "semantic-ui-react";
import React from "react";
import PropTypes from "prop-types";

class Alert extends React.Component {
  constructor(props) {
    super(props);
    if (this.hasTrigger()) {
      this.state = {modalOpen: false};
    } else {
      this.state = {modalOpen: true};
    }

    this.handleClose = this.handleClose.bind(this);
    this.setTrigger = this.setTrigger.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.hasTrigger = this.hasTrigger.bind(this);
  }

  handleOpen() {
    this.setState({modalOpen: true});
  }

  handleCancel() {
    if (typeof this.props.cancelAction !== "undefined") {
      this.props.cancelAction();
    }
    this.setState({modalOpen: false});
  }

  handleClose() {
    if (typeof this.props.closeAction !== "undefined") {
      this.props.closeAction();
    }
    this.setState({modalOpen: false});
  }

  setTrigger() {
    if (typeof this.props.trigger !== "undefined") {
      return React.cloneElement(this.props.trigger, {
        onClick: () => this.handleOpen()
      });
    } else {
      return false;
    }
  }

  hasTrigger() {
    return typeof this.props.trigger !== "undefined";
  }

  render() {
    const {handleClose, setTrigger, handleCancel, hasTrigger} = this;
    const {
      icon,
      headerContent,
      content,
      closeButtonContent,
      hideCancel,
      cancelAction
    } = this.props;
    const {modalOpen} = this.state;

    if (!modalOpen && !hasTrigger() && !cancelAction) {
      window.history.back();
    }

    let cancel = hideCancel ? null : (
      <Button content="Cancel" onClick={handleCancel} />
    );

    return (
      <Modal
        open={modalOpen}
        onClose={handleCancel}
        size="small"
        trigger={setTrigger()}
      >
        <Header icon={icon} content={headerContent} />
        <Modal.Content>
          <p>{content}</p>
        </Modal.Content>
        <Modal.Actions>
          {cancel}
          <Button
            primary
            content={closeButtonContent}
            onClick={handleClose}
            data-testid="closealert"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

Alert.propTypes = {
  icon: PropTypes.string.isRequired,
  headerContent: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  closeButtonContent: PropTypes.string.isRequired,
  hideCancel: PropTypes.bool
};

export default Alert;
