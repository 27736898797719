import React from "react";
import {connect} from "react-redux";
import config from "react-global-configuration";
import FormModal from "./FormModal";
import {projectById, tagUpdate, transmitter} from "../axios/api";
import {Form} from "semantic-ui-react";
import ProjectSelect from "./ProjectSelect";
import {getTransmitterId, Roles} from "./helpers";
import SensorSelect from "./SensorSelect";

const defaultState = {
  success: false,
  failed: false,
  loading: false,
  sensorsValid: true,
  project: null,
  transmitterStatus: null,
  sensors: [
    {
      sensorTypeName: null,
      idCode: "",
      slope: "",
      intercept: "",
      unit: ""
    }
  ],
  // Payload for API
  transmitter: {
    projectId: null,
    deviceModelName: "",
    serialNumber: "",
    codeSpaceName: "",
    expectedLifeTimeDays: "",
    sensors: [],
    version: null
  }
};
class TransmitterEdit extends React.Component {
  constructor(props) {
    super(props);

    const {tagPostDto: transmitter} = props.transmitter;
    const {releasePostDto: release} = props.transmitter;

    this.state = {
      transmitter: {
        id: release.tagId,
        projectId: transmitter.project.id,
        deviceModelName: transmitter.deviceModelName,
        serialNumber: transmitter.serialNumber,
        codeSpaceName: transmitter.codeSpaceName,
        expectedLifeTimeDays: transmitter.expectedLifeTimeDays,
        sensors: transmitter.sensors,
        version: transmitter.version,
        status: transmitter.status
      },
      sensors: transmitter.sensors,
      sensorsValid: true,
      originalSerialNumber: transmitter.serialNumber
    };

    this.codeSpaceNames = config.get("code_space_names").map((c) => {
      return {text: c, value: c};
    });
    this.tagDeviceModelNames = config.get("tag_device_model_names").map((m) => {
      return {text: m, value: m};
    });

    this.successAction = this.successAction.bind(this);
    this.failedAction = this.failedAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleTransmitterTypeChange = this.handleTransmitterTypeChange.bind(
      this
    );
    this.handleSerialNumberChange = this.handleSerialNumberChange.bind(this);
    this.handleCodeSpaceNameChange = this.handleCodeSpaceNameChange.bind(this);
    this.handleExpectedLifetimeChange = this.handleExpectedLifetimeChange.bind(
      this
    );
    this.handleSensorsChange = this.handleSensorsChange.bind(this);
  }

  checkFormValid() {
    const {transmitter, sensorsValid} = this.state;

    transmitter.sensors = transmitter.sensors.filter((s) => {
      return s.sensorTypeName;
    });

    let isValid =
      transmitter.projectId !== null &&
      transmitter.deviceModelName !== "" &&
      transmitter.serialNumber.length > 0 &&
      !this.state.serialNumberError &&
      transmitter.codeSpaceName !== "" &&
      parseInt(transmitter.expectedLifeTimeDays) > 0 &&
      sensorsValid;

    return !isValid;
  }

  successAction(transmitter) {
    this.setState(
      {
        transmitter: {...this.state.transmitter, version: transmitter.version},
        success: true
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate({
            tag: transmitter,
            id: this.props.transmitter.releasePostDto.id
          });
        }
      }
    );
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  onClose() {
    if (this.props.onClose) {
      this.setState(defaultState);
      this.props.onClose(this.state);
    }
  }

  handleProjectChange(e, {value}) {
    projectById(value).then((result) => {
      this.setState({
        project: result.project,
        transmitter: {
          ...this.state.transmitter,
          projectId: value
        }
      });
    });
  }

  handleTransmitterTypeChange(e, {value}) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        deviceModelName: value
      }
    });
  }

  handleSerialNumberChange(e, {value}) {
    const serialNumber =
      value === ""
        ? ""
        : /^\d+$/.test(value)
        ? value
        : this.state.transmitter?.tagPostDto?.serialNumber ?? "";

    if (
      serialNumber !== this.state.originalSerialNumber &&
      serialNumber.length > 0
    )
      transmitter({serialNumber: serialNumber}).then((result) => {
        if (result.data.content.length > 0) {
          this.setState({
            transmitter: {
              ...this.state.transmitter,
              serialNumber: serialNumber
            },
            serialNumberError: {
              content: "Serial number must be unique",
              pointing: "above"
            }
          });
        } else {
          this.setState({
            transmitter: {
              ...this.state.transmitter,
              serialNumber: serialNumber
            },
            serialNumberError: false
          });
        }
      });
    else
      this.setState({
        transmitter: {
          ...this.state.transmitter,
          serialNumber: serialNumber
        },
        serialNumberError: true
      });
  }

  handleCodeSpaceNameChange(e, {value}) {
    this.setState({
      transmitter: {
        ...this.state.transmitter,
        codeSpaceName: value
      }
    });
  }

  handleExpectedLifetimeChange(e, {value}) {
    value = value !== "" ? parseInt(value) : "";
    if ((!isNaN(value) && value > 0) || value === "") {
      this.setState({
        transmitter: {
          ...this.state.transmitter,
          expectedLifeTimeDays: value
        }
      });
    }
  }

  handleSensorsChange(valid, value) {
    const newState = valid
      ? {
          sensorsValid: valid,
          sensors: value,
          transmitter: {
            ...this.state.transmitter,
            sensors: value.map((s) => {
              return {
                ...s,
                slope: s.slope === "" ? null : s.slope,
                intercept: s.intercept === "" ? null : s.intercept,
                unit: s.unit === "" ? null : s.unit
              };
            })
          }
        }
      : {sensorsValid: valid};

    this.setState(newState);
  }

  render() {
    const {
      transmitter,
      success,
      failed,
      loading,
      serialNumberError,
      sensors,
      project
    } = this.state;
    const {
      checkFormValid,
      successAction,
      failedAction,
      onClose,
      handleProjectChange,
      handleTransmitterTypeChange,
      handleSerialNumberChange,
      handleCodeSpaceNameChange,
      handleSensorsChange,
      handleExpectedLifetimeChange,
      codeSpaceNames,
      tagDeviceModelNames
    } = this;
    const {trigger, roles} = this.props;

    const formInputs = (
      <>
        <Form.Input
          fluid
          minLength={1}
          required
          autoComplete="none"
          label="Serial Number"
          onChange={handleSerialNumberChange}
          placeholder={`Enter serial number`}
          value={transmitter.serialNumber}
          error={serialNumberError}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Transmitter ID"
          value={getTransmitterId(transmitter)}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Status"
          value={transmitter.status}
        />
        <ProjectSelect
          required
          value={transmitter.projectId}
          onChange={handleProjectChange}
          showAll={roles.includes(Roles.ROLE_ADMIN)}
          loadingText="Loading"
        />
        <Form.Dropdown
          fluid
          selection
          search
          required
          label="Transmitter Type"
          options={tagDeviceModelNames}
          placeholder="Select Transmitter Type"
          value={transmitter.deviceModelName}
          onChange={handleTransmitterTypeChange}
        />
        <Form.Dropdown
          fluid
          selection
          search
          required
          label="Codespace"
          options={codeSpaceNames}
          placeholder="Select Codespace"
          value={transmitter.codeSpaceName}
          onChange={handleCodeSpaceNameChange}
        />
        <Form.Input
          fluid
          required
          label="Expected Lifetime (days)"
          placeholder="Expected Lifetime (days)"
          onChange={handleExpectedLifetimeChange}
          value={transmitter.expectedLifeTimeDays ?? ""}
        />
        <Form.Field
          control={SensorSelect}
          disabled={transmitter.projectId === null}
          label="Sensors"
          required
          fluid
          value={sensors}
          onChange={handleSensorsChange}
          projectPositioning={project?.isPositionSystem}
        />
      </>
    );

    const headerContent = `Update Transmitter ${transmitter.serialNumber}`;

    return (
      <FormModal
        formSubmitAPI={tagUpdate}
        formData={transmitter}
        checkFormValid={checkFormValid}
        successHeader="Confirmation"
        onSuccess={successAction}
        onFail={failedAction}
        success={success}
        failed={failed}
        formInputs={formInputs}
        headerIcon="tag"
        trigger={trigger}
        successContent="Transmitter Updated"
        headerContent={headerContent}
        submitContent="Update transmitter"
        open
        loading={loading}
        onClose={onClose}
        modalSize={"large"}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(TransmitterEdit);
