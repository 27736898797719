import React from "react";
import {Button, Form, Message} from "semantic-ui-react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import config from "react-global-configuration";
import PropTypes from "prop-types";
import {updateUser} from "../actions/index";

import {
  activeProjectSummary,
  installationCreate,
  installationUpdate,
  me
} from "../axios/api";

import FormModal from "./FormModal";
import ProjectSelect from "./ProjectSelect";
import ActionsMenu from "./ActionsMenu";
import ProjectCreate from "./ProjectCreate";
import {isAdmin} from "./helpers";

const createLabels = {
  successHeader: "Confirmation",
  success: "Installation Created",
  header: "Create Installation",
  submit: "Create Installation"
};

const updateLabels = {
  successHeader: "Confirmation",
  success: "Installation Updated",
  header: "Edit Installation",
  submit: "Update Installation"
};

const emptyInstallation = {
  id: null,
  version: 0,
  name: "",
  configurationType: "",
  projectName: "",
  projectId: null
};

class Installation extends React.Component {
  constructor(props) {
    super(props);

    const configurations = config
      .get("installation_configuration_type")
      .map((c) => {
        return {text: c, value: c};
      });

    this.state = {
      open: props.open,
      success: false,
      configurations: configurations,
      installation: props.installation ?? {...emptyInstallation},
      originalInstallation: props.installation ?? {...emptyInstallation},
      labels: props.installation ? updateLabels : createLabels,
      loading: true
    };

    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.isMemberOfActiveProject = this.isMemberOfActiveProject.bind(this);

    this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.isMemberOfActiveProject();
  }

  onClose() {
    if (this.props.onClose) {
      this.setState({
        success: false,
        installation: {...emptyInstallation}
      });
      this.props.onClose(this.state);
    }
  }

  handleNameChange(e) {
    let newValue = e.target.value;
    this.setState(() => ({
      installation: {
        ...this.state.installation,
        name: newValue
      }
    }));
  }

  checkFormValid() {
    const installation = this.state.installation;
    const original = this.state.originalInstallation;

    let isValid =
      installation.name.length > 0 &&
      installation.configurationType.length > 0 &&
      Number.isInteger(installation.projectId);

    // If there are initial values, additional checked
    // to ensure the form has been changed
    if (isValid && installation.id) {
      isValid =
        installation.name !== original.name ||
        installation.configurationType !== original.configurationType ||
        installation.projectId !== original.projectId;
    }

    return !isValid;
  }

  handleProjectChange(e, {value}) {
    this.setState(() => ({
      installation: {
        ...this.state.installation,
        projectId: value
      }
    }));
    this.checkFormValid();
  }

  handleConfigurationChange(e, {value}) {
    this.setState(() => ({
      installation: {
        ...this.state.installation,
        configurationType: value
      }
    }));
    this.checkFormValid();
  }

  successAction(installation) {
    this.setState({
      success: true,
      installation: {...installation}
    });
    this.checkFormValid();
  }

  isMemberOfActiveProject() {
    const getUserActiveProjects = (s) => {
      return this.props.projects.filter((project) =>
        s.find((active) => active.id === project.id)
      );
    };

    if (
      this.props.roles.includes("ROLE_ADMIN") ||
      this.props.roles.includes("ROLE_ATF_ADMIN")
    ) {
      this.setState({isMemberOfActiveProject: true, loading: false});
    } else {
      activeProjectSummary().then((result) => {
        const activeProjects = result.data;
        if (getUserActiveProjects(activeProjects) < 1) {
          me().then((result) => {
            this.props.user(
              result.data.roles,
              result.data.id,
              result.data.organisationId,
              result.data.projects,
              result.data.name
            );
            const isMemberOfActiveProject =
              result.data.projects.filter((p) =>
                activeProjects.find((a) => a.id === p.id)
              ).length > 0;
            this.setState({
              isMemberOfActiveProject: isMemberOfActiveProject,
              loading: false
            });
          });
        } else {
          this.setState({isMemberOfActiveProject: true, loading: false});
        }
      });
    }
  }

  render() {
    const {
      installation,
      configurations,
      labels,
      open,
      loading,
      isMemberOfActiveProject
    } = this.state;
    const {roles, trigger} = this.props;
    const {
      handleNameChange,
      handleProjectChange,
      handleConfigurationChange,
      checkFormValid,
      successAction,
      success,
      onClose
    } = this;

    const menuItems = [];

    if (!isMemberOfActiveProject) {
      menuItems.push({
        name: "Create Project",
        triggered: (
          <ProjectCreate
            open={false}
            onClose={({success}) => {
              this.setState({open: !success});
            }}
          />
        )
      });
    }

    const actions =
      menuItems.length > 0 ? <ActionsMenu menuItems={menuItems} /> : null;

    const formInputs = this.state.success ? (
      <>
        <Form.Input
          fluid
          transparent
          readOnly
          label="Installation Name"
          value={installation.name}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Configuration"
          value={installation.configurationType}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Project"
          value={installation.project.name}
        />
        {this.state.installation !== null ? (
          <Link to={`/stations/register/${installation.id}`}>
            <Button primary>Create a new station</Button>
          </Link>
        ) : null}
      </>
    ) : isMemberOfActiveProject ? (
      <>
        <Form.Input
          fluid
          minLength={3}
          maxLength={200}
          autoComplete="none"
          label="Installation Name"
          onChange={handleNameChange}
          value={installation.name}
          data-testid="name-input"
        />
        <Form.Dropdown
          fluid
          selection
          search
          label="Configuration"
          options={configurations}
          placeholder="Select configuration"
          value={installation.configurationType}
          onChange={handleConfigurationChange}
          data-testid="configuration-dropdown"
        />
        <ProjectSelect
          loadingText={installation.projectName}
          value={installation.projectId}
          showAll={isAdmin(roles)}
          onChange={handleProjectChange}
        />
      </>
    ) : (
      <>
        <Message warning>
          You must be a member of an active project to create a new installation.
        </Message>
        {actions}
      </>
    );

    let formSubmitAPI = isMemberOfActiveProject
      ? installation.id
        ? installationUpdate
        : installationCreate
      : null;

    return loading ? (
      <></>
    ) : (
      <FormModal
        formSubmitAPI={formSubmitAPI}
        formData={installation}
        checkFormValid={checkFormValid}
        enableSubmit={false}
        testId="installation-form"
        successHeader={labels.successHeader}
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        successContent={labels.success}
        headerContent={labels.header}
        submitContent={labels.submit}
        onClose={onClose}
        open={open}
      />
    );
  }
}

Installation.propTypes = {
  onClose: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    user: (roles, userId, organisationId, projects, name) =>
      dispatch(updateUser(roles, userId, organisationId, projects, name))
  };
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Installation);
