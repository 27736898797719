import React from "react";
import {
  Button,
  Table,
  Message,
  Grid,
  Icon,
  Header,
  Progress,
  Segment,
  Container
} from "semantic-ui-react";
import axios from "axios";
import {getAPIErrorMessages} from "../axios/api-helpers";
import ActionsMenu, {menuItemsType} from "./ActionsMenu";

import DetailPanel from "./DetailPanel";
import Filter from "./Filter";
import FilteredTableRow from "./FilterTableRow";
import PropTypes from "prop-types";
import {asyncForEach} from "./helpers";

class FilteredTable extends React.Component {
  middleRowPosition = 0;
  filteredData = [];
  currentFilters = null;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      detailData: [],
      page: 0,
      lastPage: false,
      filters: [],
      headers: [],
      responseErrors: false,
      active: 0,
      panelVisible: false,
      fetchingData: false,
      loadingMore: false,
      recordCount: 0
    };

    this.handleRowClick = this.handleRowClick.bind(this);
    this.getCellContent = this.getCellContent.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleApplyFilters = this.handleApplyFilters.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.recordCount = this.recordCount.bind(this);
    this.getFilterOptions = this.getFilterOptions.bind(this);
    this.focusRow = this.focusRow.bind(this);
    this.narrowFilters = this.narrowFilters.bind(this);
    this.resetNarrowing = this.resetNarrowing.bind(this);
  }

  componentDidMount() {
    const {fetcher, data, columns} = this.props;

    const headers = columns.reduce((accumulator, col) => {
      if (!col.detail && !col.hide) {
        accumulator.push(
          <Table.HeaderCell
            style={{width: col.width ?? "auto"}}
            key={col.title}
          >
            {col.title}
          </Table.HeaderCell>
        );
      }
      return accumulator;
    }, []);

    const filters = this.props.columns.filter((item) => {
      if (typeof item.filter !== "undefined") {
        this[`${item.dataSource}Filter`] = item.filter.bind(this);
        return true;
      }
      return false;
    });

    if (this.props.filterOptionsFetcher) {
      this.getFilterOptions().then((options) => {
        this.setState(() => ({
          headers: headers,
          filterOptions: options,
          filters: filters.map((v) => {
            if (
              v.component &&
              options[v.filterOptionsKey]
              // v.filterOptionsKey &&
              // !v.component?.props?.isSearchable
            )
              v.component.props = {
                options: options[v.filterOptionsKey],
                ...v.component.props
              };
            return v;
          })
        }));
      });
    } else {
      this.setState(() => ({
        headers: headers,
        filters: filters
      }));
    }

    if (typeof fetcher !== "undefined") {
      this.applyFilters(null, [], false, 0, false, true);
    } else {
      this.setState(() => ({
        data: data
      }));
    }
  }

  componentDidUpdate(prevProps) {
    const {data} = this.state;
    const {detailFetcher} = this.props;

    if (
      this.props.refreshFilterOptions &&
      this.props.refreshFilterOptions !== prevProps.refreshFilterOptions
    ) {
      this.getFilterOptions().then((options) => {
        this.setState({
          filterOptions: options,
          filters: this.state.filters.map((v) => {
            if (v.component)
              v.component.props = options[v.filterOptionsKey]
                ? {options: options[v.filterOptionsKey]}
                : null;
            return v;
          })
        });
      });
    }

    if (this.props.focus !== prevProps.focus) {
      if (Array.isArray(this.props.focus)) {
        this.props.focus.forEach((f) => {
          const key = Object.keys(f)[0];
          const focusRowIndex = data.findIndex((i) => i[key] === f[key]);
          this.focusRow(f, key, focusRowIndex);
        });
      } else if (this.props.focus) {
        const key = Object.keys(this.props.focus)[0];
        const focusRowIndex = data.findIndex(
          (i) => i[key] === this.props.focus[key]
        );

        if (focusRowIndex === -1) {
          // Load new data
          if (typeof this.props.newData !== "undefined") {
            // Load multiple added rows. Focus last newly created row
            let newState = {data: [], active: 0, detailData: []};
            this.props.newData
              .reduce(async (row, id) => {
                newState = await row;
                return this.getSingleRow(newState, id, key);
              }, this.getSingleRow(newState))
              .then(() => {
                newState.active = newState.data.findIndex((i) => {
                  return i.id === this.props.focus[key];
                });
                newState.data = [...newState.data, ...this.state.data];
                this.setState(newState);
              });
          } else {
            // Load and focus newly created row
            detailFetcher(this.props.focus[key]).then((result) => {
              this.setState({
                data: [result.row, ...this.state.data],
                active: 0,
                detailData: result.data,
                detailHeader: result.header,
                detailKeyValue: this.props.focus[key]
              });
            });
          }
        } else {
          // Reload and focus the existing row
          this.focusRow(this.props.focus, key, focusRowIndex);
        }
      } else {
        this.getMoreData(this.state.page);
      }
    }
  }

  componentWillUnmount() {
    if (this.filterOptionsFetcherCt) this.filterOptionsFetcherCt.cancel();
    if (this.searchableFilterOptionsFetcherCt)
      this.searchableFilterOptionsFetcherCt.cancel();
  }

  applyNarrowing(options, fieldName, value) {
    let valueArray = [];
    if (Array.isArray(value)) {
      valueArray = value;
    } else {
      valueArray[0] = value;
    }

    return options.filter((o) => {
      if (Array.isArray(o.narrowing[fieldName])) {
        return valueArray.some((v) => o.narrowing[fieldName].includes(v));
      } else {
        return valueArray.some((v) => v === o.narrowing[fieldName]);
      }
    });
  }

  reapplyNarrowing(narrowed, filterToNarrow) {
    let newNarrowing = {...narrowed};
    filterToNarrow.narrowedBy.forEach((nb) => {
      newNarrowing[filterToNarrow.filterOptionsKey] = this.applyNarrowing(
        newNarrowing[filterToNarrow.filterOptionsKey],
        nb.fieldName,
        nb.value
      );
    });
    return newNarrowing;
  }

  disableNarrowed(filter) {
    if (filter.component.props.options?.length < 1) {
      filter.disabled = true;
      filter.originalPlaceholder = filter.placeholder;
      filter.placeholder = "No options found";
    } else {
      filter.disabled = false;
      filter.placeholder = filter.originalPlaceholder;
    }
  }

  async narrow(narrowedFilters, narrowedBy) {
    let narrowed = {...this.state.filterOptions}; // Start with all the filter options
    let filtersWithNarrowedOptions = [...this.state.filters]; // Filters after narrowing applied
    await asyncForEach(narrowedFilters, async (nf) => {
      const key = this.state.filters.findIndex((f) => {
        return f.filterOptionsKey === nf.field;
      });
      const filterToNarrow = this.state.filters[key];
      let isSearchable = filterToNarrow.component?.props.isSearchable;
      filterToNarrow.narrowedBy = filterToNarrow.narrowedBy ?? [];
      const i = filterToNarrow.narrowedBy.findIndex(
        (n) => n.fieldName === nf.by
      );

      // Track applied narrowings
      if (i === -1) {
        // new narrowing
        filterToNarrow.narrowedBy.push({
          fieldName: nf.by,
          value: narrowedBy.value
        });
      } else if (narrowedBy.value.length !== 0) {
        // change narrowing value
        filterToNarrow.narrowedBy[i] = {
          fieldName: nf.by,
          value: narrowedBy.value
        };
      } else {
        // remove the narrowing
        filterToNarrow.narrowedBy = filterToNarrow.narrowedBy.filter((nb) => {
          return nb.fieldName !== nf.by;
        });
      }

      // Apply narrowings
      if (isSearchable) {
        let query = {};
        filterToNarrow.narrowedBy.forEach((n) => {
          const value = Array.isArray(n.value) ? n.value.join() : n.value;
          query[n.fieldName] = value;
        });
        if (Object.keys(query).length !== 0) {
          query.size = 1000000;
          narrowed[
            nf.field
          ] = await filterToNarrow.component.props.optionsFetcher(query);
          filterToNarrow.component.props.options = narrowed[nf.field];
        } else {
          filterToNarrow.component.props.options = null;
        }
      } else {
        if (i === -1) {
          // new narrowing
          narrowed[filterToNarrow.filterOptionsKey] = this.applyNarrowing(
            filterToNarrow.component.props.options,
            nf.by,
            narrowedBy.value
          );
        } else if (narrowedBy.value !== "") {
          // change narrowing value
          narrowed = this.reapplyNarrowing(narrowed, filterToNarrow);
        } else {
          // remove the narrowing (re-apply all narrowings except this one)
          narrowed = this.reapplyNarrowing(narrowed, filterToNarrow);
        }
        filterToNarrow.component.props.options = narrowed[
          filterToNarrow.filterOptionsKey
        ]
          ? narrowed[filterToNarrow.filterOptionsKey]
          : filterToNarrow.component.props.options;
      }
      this.disableNarrowed(filterToNarrow);
      filtersWithNarrowedOptions[key] = filterToNarrow;
    });
    return filtersWithNarrowedOptions;
  }

  async narrowFilters(narrowedFilters, narrowedBy) {
    let filtersWithNarrowedOptions = await this.narrow(
      narrowedFilters,
      narrowedBy
    );

    this.setState((s) => {
      return {
        filters: filtersWithNarrowedOptions
      };
    });
  }

  resetNarrowing() {
    const filters = this.state.filters.map((f) => {
      f.narrowedBy = [];
      if (f.component?.props?.options) {
        if (f.filterOptionsKey)
          f.component.props.options = this.state.filterOptions[
            f.filterOptionsKey
          ];
        this.disableNarrowed(f);
      }
      return f;
    });

    this.setState((s) => {
      return {
        filters: filters
      };
    });
  }

  focusRow(focus, key, focusRowIndex) {
    if (focus.eventType === "delete") {
      const existing = this.state.data.filter((i) => i[key] !== focus[key]);
      this.setState({
        data: existing,
        active: null
      });
    } else {
      this.props.detailFetcher(focus[key]).then((result) => {
        const newData = this.state.data;
        newData[focusRowIndex] = result.row;
        this.setState({
          data: newData,
          active: focusRowIndex,
          detailData: result.data,
          detailHeader: result.header,
          detailKeyValue: focus[key]
        });
      });
    }
  }

  getFilterOptions() {
    this.filterOptionsFetcherCt = axios.CancelToken.source();
    return this.props
      .filterOptionsFetcher(this.filterOptionsFetcherCt.token)
      .then((response) => {
        this.filterOptionsFetcherCt = null;
        return response.data;
      })
      .catch((err) => {
        if (axios.isCancel(err))
          console.debug("filterOptionsFetcher cancelled");
      });
  }

  getSingleRow(newState, id, key) {
    return new Promise(async (resolve, reject) => {
      if (typeof id !== "undefined") {
        let row = await this.props.detailFetcher(id);
        if (id === this.props.focus[key]) {
          newState.detailData = row.data;
          newState.detailHeader = row.header;
          newState.detailKeyValue = id;
        }
        newState.data.push(row.row);
      }
      resolve(newState);
    });
  }

  getMoreData(page) {
    const {fetcher, pageSize} = this.props;

    fetcher(pageSize, page, this.apiFilters)
      .then((result) => {
        if (
          typeof result.response !== "undefined" &&
          result.response.status >= 400
        ) {
          this.setState(() => ({
            page: page,
            responseErrors: getAPIErrorMessages(
              result.response.data,
              result.response.status
            ),
            fetchingData: false,
            loadingMore: false,
            data: []
          }));
        } else {
          this.applyFilters(
            this.currentFilters,
            result.content,
            result.last,
            result.page,
            false,
            false,
            result.totalElements,
            result.totalPages,
            result.recordCount
          );
        }
      })
      .catch((result) => {
        if (!axios.isCancel(result)) {
          this.setState(() => ({
            responseErrors: getAPIErrorMessages(
              result.response.data,
              result.response.status
            )
          }));
        }
      });
  }

  getDetailData(key, row, visible) {
    // Expects a single record identified by an single key
    const {detailFetcher} = this.props;

    if (detailFetcher)
      detailFetcher(key).then((result) => {
        this.setState(() => ({
          detailKeyValue: key,
          detailData: result.data,
          detailHeader: result.header,
          active: row,
          panelVisible: visible
        }));
      });
  }

  handleUpdate() {
    const tableBody = this.elementOffset(document.getElementById("table-body"));
    const tableRowMiddle = this.elementOffset(
      document.getElementById("table-row-middle")
    );
    const directionIsUp =
      this.middleRowPosition !== 0 &&
      this.middleRowPosition > tableRowMiddle.top;
    const loadNext =
      directionIsUp &&
      tableRowMiddle.top < tableBody.top &&
      !this.state.lastPage;

    if (!this.state.fetchingData && loadNext) {
      this.setState({fetchingData: true, loadingMore: true}, () =>
        this.getMoreData(this.state.page + 1)
      );
    }

    this.middleRowPosition = tableRowMiddle.top;
  }

  handleRowClick(index) {
    const {detailKey, disableDetailView} = this.props;
    const {data} = this.state;

    this.setState({active: index});

    if (disableDetailView) {
      if (typeof disableDetailView === "function") {
        disableDetailView(index);
      }
      return;
    }

    let visible;
    if (this.state.active === index) {
      visible = !this.state.panelVisible;
    } else {
      visible = true;
    }

    if (detailKey === "row") {
      this.getDetailData(data[index], index, visible);
    } else {
      this.getDetailData(data[index][detailKey], index, visible);
    }
  }

  elementOffset(el) {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft,
      bottom: rect.bottom + scrollTop,
      right: rect.right + scrollLeft
    };
  }

  getCellContent(dataSource, item) {
    let keys = dataSource.split(".");
    let content =
      item && typeof item[keys[0]] != "boolean" ? item[keys[0]] : "";

    if (dataSource === "rowActions") {
      let rowActions = [];

      if (item["edit"] === true) {
        rowActions.push({
          name: "Edit",
          onClick: () => this.props.editRow(item)
        });
      }
      if (item["delete"] === true) {
        rowActions.push({
          name: "Delete",
          destructive: true,
          onClick: () => this.props.deleteRow(item)
        });
      }

      if (item["requestDelete"] === true) {
        rowActions.push({
          name: "Request Delete",
          destructive: true,
          onClick: () => this.props.requestDeleteRow(item)
        });
      }

      return <ActionsMenu menuItems={rowActions} />;
    }

    if (keys.length > 1) {
      return this.getCellContent(
        dataSource.substr(dataSource.indexOf(".") + 1),
        content
      );
    } else {
      return content;
    }
  }

  applyFilters(
    filterValues,
    currentPageData,
    lastPage,
    page,
    focusFirstRow,
    removeFocus,
    totalElements,
    totalPages,
    recordCount
  ) {
    const {fetcher, pageSize, detailKey, detailFetcher} = this.props;
    const {filters} = this.state;

    this.currentFilters = filterValues;
    if (page === 0) {
      this.filteredData = [];
    }

    this.apiFilters = filters.reduce((accumulator, currentFilter) => {
      if (
        currentFilter.isAPIFilter &&
        filterValues !== null &&
        filterValues[currentFilter.dataSource].value !== ""
      ) {
        accumulator[this[`${currentFilter.dataSource}Filter`]()] =
          filterValues[currentFilter.dataSource].value;
      }
      return accumulator;
    }, {});

    let filteredPage = currentPageData;
    if (filterValues !== null) {
      filteredPage = filters.reduce((accumulator, currentFilter) => {
        let filterResult = false;
        if (!currentFilter.isAPIFilter && !currentFilter.hideFilter) {
          let value = filterValues[currentFilter.dataSource].value;
          if (value === "") {
            value = /[\s\S]*/;
          }
          filterResult = accumulator.filter((data) => {
            return this[`${currentFilter.dataSource}Filter`](data, value);
          });
        }
        return !filterResult ? accumulator : filterResult;
      }, currentPageData);
    }
    this.filteredData = this.filteredData.concat(filteredPage);

    if (!lastPage && this.filteredData.length < 1) {
      this.setState({fetchingData: true});

      fetcher(pageSize, page, this.apiFilters).then(
        (result) => {
          if (!result) {
            this.setState(() => ({
              responseErrors: {
                header: "Problem fetching data",
                messages: ["Please check filter values and try again."]
              },
              fetchingData: false,
              loadingMore: false
            }));
            return;
          }
          let responseErrors = false;
          let data = result.content;
          if (
            typeof result.response !== "undefined" &&
            result.response.status >= 400
          ) {
            responseErrors = getAPIErrorMessages(
              result.response.data,
              result.response.status
            );
            data = [];
          }
          if (!responseErrors) {
            this.applyFilters(
              filterValues,
              data,
              result.last,
              result.page,
              focusFirstRow,
              removeFocus,
              result.totalElements,
              result.totalPages,
              result.recordCount
            );
          } else {
            this.setState(() => ({
              responseErrors: responseErrors,
              data: data,
              fetchingData: false,
              loadingMore: false
            }));
          }
        },
        (error) => {
          if (!error.response) return;
          const errors = getAPIErrorMessages(
            error.response.data,
            error.response.status
          );
          this.setState((s) => {
            return {
              responseErrors: errors,
              data: s.data,
              fetchingData: false,
              loadingMore: false
            };
          });
        }
      );
    } else {
      this.setState(
        {
          panelVisible: removeFocus ? false : this.state.panelVisible,
          active: removeFocus ? -1 : this.state.active,
          responseErrors: false,
          data: this.filteredData,
          page: page,
          lastPage: lastPage,
          fetchingData: false,
          loadingMore: false,
          totalElements: totalElements,
          totalPages: totalPages,
          recordCount: recordCount
            ? this.state.recordCount + recordCount
            : false
        },
        () => {
          if (
            focusFirstRow &&
            detailKey &&
            typeof detailFetcher !== "undefined" &&
            this.state.data.length > 0
          ) {
            if (detailKey === "row") {
              this.getDetailData(this.state.data[0], 0, true);
            } else {
              this.getDetailData(this.state.data[0][detailKey], 0, true);
            }
          }
        }
      );
    }
  }

  handleApplyFilters(e) {
    this.setState(
      {recordCount: 0},
      this.applyFilters(e.target, [], false, 0, false, true)
    );
  }

  recordCount() {
    return this.state.recordCount
      ? this.state.recordCount
      : this.state.data.length;
  }

  render() {
    const {
      columns,
      fetcher,
      icon,
      actions,
      pageSize,
      tableActions,
      header,
      headerIcon,
      detailKey,
      errorMessage
    } = this.props;
    const {
      data,
      responseErrors,
      detailData,
      detailHeader,
      headers,
      filters,
      filterOptions,
      active,
      detailKeyValue,
      fetchingData,
      loadingMore,
      totalElements
    } = this.state;
    const {
      handleUpdate,
      handleRowClick,
      getCellContent,
      handleApplyFilters,
      narrowFilters,
      resetNarrowing
    } = this;

    const body = data.map((item, index) => {
      let cells = columns.reduce((accumulator, col) => {
        if (!col.detail && !col.hide) {
          let content = getCellContent(col.dataSource, item);
          if (col.format) {
            content = col.format(content);
          }
          let icon =
            col.icons && col.icons[item[col.dataSource]]?.name
              ? {
                  name: col.icons[item[col.dataSource]].name,
                  color: col.icons[item[col.dataSource]].color
                }
              : null;
          accumulator.push(
            <Table.Cell
              key={col.dataSource}
              icon={icon}
              content={content || col.default}
              style={{width: col.width ?? "auto"}}
            />
          );
        }
        return accumulator;
      }, []);

      return (
        <FilteredTableRow
          dataLoadTrigger={
            typeof fetcher !== "undefined" &&
            index === Math.floor(data.length - pageSize / 2) &&
            !this.state.lastPage
          }
          key={index}
          index={index}
          active={active}
          cells={cells}
          onClick={handleRowClick}
          onUpdate={handleUpdate}
          className="at-table-row"
        />
      );
    });

    const loading = (
      <>
        <Progress percent={100} active={fetchingData} />
        <Container textAlign="right">
          {data.length > 0 && totalElements > 0
            ? `${this.recordCount()} records of ${totalElements} retrieved`
            : ""}
        </Container>
      </>
    );

    const tableHeader = (
      <Table.Header className="at-table-row">
        <Table.Row>{headers}</Table.Row>
      </Table.Header>
    );

    const tableBody = (
      <Table.Body id="table-body" className="at-table-body">
        {body}
      </Table.Body>
    );

    const table = (
      <>
        <Table selectable className="at-table">
          {tableHeader}
          {data.length > 0 ? tableBody : null}
        </Table>
        {!this.state.fetchingData && data.length === 0 ? (
          <Message
            warning
            header="Filter result."
            list={[
              this.props.noDataMessage
                ? this.props.noDataMessage
                : "There is no data matching the filters"
            ]}
            data-testid={"nodata"}
          />
        ) : null}
      </>
    );

    if (actions) {
      actions.map((action) => {
        if (action.triggered && detailKey !== "row") {
          action.triggered = React.cloneElement(action.triggered, {
            [detailKey]: detailKeyValue
          });
        }
        return action;
      });
    }

    const panelColumn =
      actions && this.state.panelVisible && data.length > 0 ? (
        <Grid.Column width={3} color="grey" className="at-panel-column">
          {data.length > 0 ? (
            <>
              <Button
                icon="close"
                size="mini"
                floated="right"
                compact
                circular
                onClick={() => handleRowClick(active)}
              />
              <DetailPanel
                header={detailHeader}
                data={detailData}
                icon={icon}
                actions={actions}
              />
            </>
          ) : null}
        </Grid.Column>
      ) : null;

    const tableActionsCol =
      typeof tableActions !== "undefined" ? (
        <Grid.Column width={6}>
          <ActionsMenu horizontal menuItems={tableActions} />
        </Grid.Column>
      ) : null;

    return (
      <>
        <Header as="h3">
          <Icon name={headerIcon} />
          <Header.Content>{header}</Header.Content>
        </Header>
        <Grid>
          {tableActionsCol}
          <Grid.Column width={16} className="at-filter-padding">
            <Filter
              narrow={narrowFilters}
              columns={4}
              filters={filters}
              filterOptions={filterOptions}
              applyFilters={handleApplyFilters}
              resetNarrowing={resetNarrowing}
            />
          </Grid.Column>
          {errorMessage ? (
            <Grid.Column width={16}>
              <Message error body={errorMessage} data-testid={"errormessage"} />
            </Grid.Column>
          ) : null}
          {responseErrors ? (
            <Grid.Column width={16}>
              <Message
                error
                header={responseErrors.header}
                list={responseErrors.messages}
                data-testid={"responsemessage"}
              />
            </Grid.Column>
          ) : null}
          <Grid.Row className="at-table-padding">
            <Grid.Column
              width={this.state.panelVisible && data.length > 0 ? 13 : 16}
            >
              <Segment
                attached
                compact
                loading={fetchingData && !loadingMore}
                className="at-loading"
              >
                {table}
              </Segment>
              <Segment attached="bottom" className="at-table-border">
                {loading}
              </Segment>
            </Grid.Column>
            {panelColumn}
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

FilteredTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataSource: PropTypes.string,
      default: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      filter: PropTypes.func
    })
  ),
  detailFetcher: PropTypes.func,
  narrowFilters: PropTypes.func,
  detailKey: PropTypes.string,
  pageSize: PropTypes.number,
  fetcher: PropTypes.func,
  icon: Icon.propTypes.className,
  actions: menuItemsType,
  newData: PropTypes.array,
  editRow: PropTypes.func,
  disableDetailView: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  refreshFilterOptions: PropTypes.bool,
  noDataMessage: PropTypes.string
};

export default FilteredTable;
