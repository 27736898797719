import React from "react";
import {List} from "semantic-ui-react";
import {Link} from "react-router-dom";

class UserList extends React.Component {
  render() {
    const {data, title, showEmail, showOrganisation} = this.props;

    const list = data.reduce((accum, user, key) => {
      if (user.access !== "REVOKED") {
        accum.push(
          <List.Item key={key}>
            <Link to={`/person/${user.id}`}>{user.name}</Link>
            {showOrganisation
              ? `, ${user.organisation.name ?? user.organisation}`
              : ""}
            {showEmail ? `, ${user.emailAddress}` : ""}
          </List.Item>
        );
      }
      return accum;
    }, []);

    const header = list.length > 1 ? `${title} (${list.length})` : `${title}`;

    return (
      <>
        <strong>{header}</strong>
        <List>{list}</List>
      </>
    );
  }
}

export default UserList;
