import React from "react";
import {List} from "semantic-ui-react";
import {Link} from "react-router-dom";

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    if (this.props.onClick) this.props.onClick();
  }

  render() {
    const {onClickHandler} = this;
    const {data} = this.props;
    const header = data.length > 1 ? `Projects (${data.length})` : `Projects`;

    return (
      <>
        <strong>{header}</strong>
        <List>
          {data.map((project, key) => {
            return (
              <List.Item key={key}>
                <Link to={`/project/${project.id}`} onClick={onClickHandler}>
                  {project.name}
                </Link>
              </List.Item>
            );
          })}
        </List>
      </>
    );
  }
}

export default ProjectList;
