import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import {receiver, receiverCsv, receiverListConfig} from "../axios/api";
import FileDownload from "js-file-download";
import FilterDropDown from "./FilterDropDown";
import FilteredTable from "./FilteredTable";
import LoginForm from "./LoginForm";
import Receiver from "./Receiver";
import ReceiverDeploymentCreate from "./ReceiverDeploymentCreate";
import ReceiverDeploymentView from "./ReceiverDeploymentView";
import {
  Roles,
  csvAndZipTimestamp,
  canReceiverBeDeleted,
  isAdminNotATFAdmin
} from "./helpers";
import RequestDelete from "./RequestDelete";
import ReceiverDelete from "./ReceiverDelete";

class ReceiverTable extends React.Component {
  tableColumns = [
    {
      title: "Receiver ID",
      dataSource: "receiverId",
      default: "",
      isAPIFilter: true,
      filter: () => "id",
      component: {
        control: FilterDropDown,
        props: {
          multiple: true
        }
      },
      filterOptionsKey: "receiver",
      placeholder: "Filter Receiver ID"
    },
    {
      title: "Purchasing Organisation",
      dataSource: "organisationName",
      default: "",
      width: "30%",
      isAPIFilter: true,
      filter: () => "organisationId",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [{field: "receiver", by: "receiverOwningOrganisationId"}]
        }
      },
      filterOptionsKey: "organisation",
      placeholder: "Filter Purchasing Organisation"
    },
    {
      title: "Current Receiver Status",
      dataSource: "status",
      default: "",
      width: "10%",
      isAPIFilter: true,
      filter: () => "status",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "receiver", by: "deviceStatus"},
            {field: "organisation", by: "receiverStatus"}
          ]
        }
      },
      filterOptionsKey: "status"
    },
    {
      title: "Modem ID",
      dataSource: "modemId"
    },
    {
      title: "Transmitter ID",
      dataSource: "internalTag.transmitterId"
    },
    {
      title: "Serial Number",
      dataSource: "internalTag.serialNumber"
    },
    {
      title: "Sensor Type",
      dataSource: "internalTag.sensorType"
    },
    {
      title: "Transmitter Type",
      dataSource: "internalTag.deviceModelName"
    }
  ];

  constructor(props) {
    super(props);
    this.actions = [];
    this.state = {
      refreshFilterOptions: false,
      focusQuery: -1,
      tableFilters: null,
      showCreateModal: props.match.params.id ? true : false,
      showViewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      showRequestDeleteModal: false,
      dataSource: [],
      projects: props.projects ? props.projects.map((v, i) => v.id) : [],
      downloadDataEnabled: true
    };
    this.getReceiver = this.getReceiver.bind(this);
    this.getReceivers = this.getReceivers.bind(this);
    this.onUpdateNew = this.onUpdateNew.bind(this);
    this.onUpdateEdit = this.onUpdateEdit.bind(this);
    this.onUpdateDelete = this.onUpdateDelete.bind(this);
    this.onUpdateDeployment = this.onUpdateDeployment.bind(this);
  }

  componentDidMount() {
    if (this.props.accessToken) {
      this.setState({
        projects: this.props.projects.map((p) => p.id)
      });
    }
  }

  componentWillUnmount() {
    if (this.receiverCt) this.receiverCt.cancel();
  }

  getReceiver(id) {
    return new Promise(async (resolve, reject) => {
      const idx = this.state.dataSource.findIndex((e) => e.id === id);
      const selectedReceiver = this.state.dataSource[idx];
      if (selectedReceiver) {
        this.actions.length = 0;
        this.actions.push({
          name: "View Receiver",
          onClick: () => {
            this.setState({showViewModal: selectedReceiver});
          }
        });

        if (
          this.props.roles.includes("ROLE_ADMIN") ||
          this.props.roles.includes("ROLE_ATF_ADMIN") ||
          this.props.organisationId === selectedReceiver.organisationId ||
          this.state.projects.includes(selectedReceiver.projectId)
        ) {
          this.actions.push({
            name: "Edit Receiver",
            onClick: () => {
              this.setState({showEditModal: selectedReceiver});
            }
          });
        }
        if (
          !isAdminNotATFAdmin(this.props.roles) &&
          (await canReceiverBeDeleted(selectedReceiver.id))
        ) {
          this.actions.push({
            name: "Request Delete Receiver",
            destructive: true,
            onClick: () => {
              this.setState({showRequestDeleteModal: selectedReceiver});
            }
          });
        }
        if (
          isAdminNotATFAdmin(this.props.roles) &&
          (await canReceiverBeDeleted(selectedReceiver.id))
        ) {
          this.actions.push({
            name: "Delete Receiver",
            destructive: true,
            onClick: () => {
              this.setState({showDeleteModal: selectedReceiver});
            }
          });
        }
        resolve({
          header: "Receiver",
          data: [],
          row: selectedReceiver
        });
      } else {
        reject();
      }
    });
  }

  getReceivers(pageSize, page, filter) {
    if (this.receiverCt) this.receiverCt.cancel();
    this.receiverCt = axios.CancelToken.source();
    return receiver(
      {size: pageSize, page: page, ...filter},
      this.receiverCt.token
    ).then((result) => {
      this.setState({downloadDataEnabled: result.data.totalElements >= 1});
      this.receiverCt = null;
      const content = result.data.content.reduce((accum, r) => {
        const data = {
          id: r.id,
          receiverId: `${r.deviceModelName}-${r.serialNumber}`,
          version: r.version,
          status: r.status,
          deviceModelName: r.deviceModelName,
          projectName: r.project?.name,
          projectId: r.project?.id,
          organisationName: r.organisation.name,
          organisationId: r.organisation.id,
          serialNumber: r.serialNumber,
          modemId: r.modemId,
          internalTag: {...r.internalTag},
          history: r.history
        };
        if (r.internalTag?.sensor) {
          data.internalTag.transmitterId = r.internalTag.sensor.transmitterId;
          data.internalTag.serialNumber = r.internalTag.serialNumber;
          data.internalTag.idCode = r.internalTag.sensor.idCode;
          data.internalTag.sensorType = r.internalTag.sensor.sensorTypeName;
          data.internalTag.codeSpaceName = r.internalTag.codeSpaceName;
          data.internalTag.deviceModelName = r.internalTag.deviceModelName;
        }
        accum.push(data);
        return accum;
      }, []);
      result.data.content = content;
      // Update dataSource
      this.setState((state) => {
        return {
          dataSource: [...state.dataSource, ...content]
        };
      });
      return result.data;
    });
  }

  onUpdateNew(receiver) {
    this.setState(
      (state) => {
        return {
          dataSource: [...state.dataSource, receiver],
          refreshFilterOptions: true
        };
      },
      () => {
        this.setState({
          focusQuery: {id: receiver.id},
          refreshFilterOptions: false
        });
      }
    );
  }

  onUpdateDelete(receiverId) {
    this.setState(
      () => {
        return {
          refreshFilterOptions: true
        };
      },
      () => {
        this.setState({
          focusQuery: {
            id: receiverId,
            eventType: "delete"
          },
          refreshFilterOptions: false
        });
      }
    );
  }

  onUpdateEdit(receiver) {
    this.setState(
      (state) => {
        const dataSource = [...state.dataSource];
        const idx = dataSource.findIndex((e) => e.id === receiver.id);
        dataSource[idx] = receiver;
        return {
          dataSource: dataSource,
          refreshFilterOptions: true
        };
      },
      () => {
        this.setState({
          focusQuery: {id: receiver.id},
          refreshFilterOptions: false
        });
      }
    );
  }

  onUpdateDeployment(deployment) {
    // Update the receiver in the table to show that it has been deployed
    this.setState(
      (state) => {
        const dataSource = [...state.dataSource];
        if (deployment) {
          const idx = dataSource.findIndex(
            (e) => e.id === deployment.receiverId
          );
          const updatedReceiver = {
            ...dataSource[idx],
            status: "DEPLOYED"
          };
          dataSource[idx] = updatedReceiver;
        }
        return {
          showDeployModal: false,
          dataSource: dataSource,
          showDeploymentViewModal: deployment ? deployment : false
        };
      },
      () => {
        this.setState({focusQuery: {id: deployment.receiverId}});
      }
    );
  }

  getTableActions() {
    return [
      {
        name: "Create Receiver",
        onClick: () => {
          this.setState({showCreateModal: true});
        }
      },
      {
        name: "Download Data",
        disableOnClick: true,
        disabledNoData: !this.state.downloadDataEnabled,
        onClick: () => {
          return receiverCsv(this.state.tableFilters).then((response) => {
            FileDownload(
              response.data,
              `IMOS_receiver_metadata_${csvAndZipTimestamp()}.csv`
            );
          });
        }
      }
    ];
  }

  render() {
    const {
      getReceivers,
      getReceiver,
      editReceiver,
      tableColumns,
      actions,
      onUpdateNew,
      onUpdateEdit,
      onUpdateDelete,
      onUpdateDeployment
    } = this;
    const {
      focusQuery,
      showViewModal,
      showEditModal,
      showDeleteModal,
      showRequestDeleteModal,
      showCreateModal,
      showDeployModal,
      showDeploymentViewModal,
      refreshFilterOptions
    } = this.state;

    const modal =
      showCreateModal && !this.props.accessToken ? (
        <LoginForm
          open
          onClose={(success) => {
            if (!success) this.setState({showCreateModal: false});
          }}
        />
      ) : showCreateModal && this.props.roles?.length > 0 ? (
        <Receiver
          newId={this.props.match.params.id}
          isAdmin={this.props.roles.some(
            (r) => [Roles.ROLE_ADMIN, Roles.ROLE_ATF_ADMIN].indexOf(r) >= 0
          )}
          onUpdate={onUpdateNew}
          onClose={(deployReceiverId) => {
            this.setState({
              showCreateModal: null,
              showDeployModal: deployReceiverId
            });
          }}
        />
      ) : showViewModal ? (
        <Receiver
          isAdmin={this.props.roles.includes("ROLE_ADMIN")}
          id={showViewModal.id}
          onUpdate={onUpdateEdit}
          onClose={() => {
            this.setState({showViewModal: null});
          }}
        />
      ) : showEditModal ? (
        <Receiver
          edit
          isAdmin={this.props.roles.includes("ROLE_ADMIN")}
          id={showEditModal.id}
          onUpdate={onUpdateEdit}
          onClose={() => {
            this.setState({showEditModal: null});
          }}
        />
      ) : showDeployModal ? (
        <ReceiverDeploymentCreate
          deployReceiverId={showDeployModal}
          onUpdate={onUpdateDeployment}
          onClose={() => {
            this.setState({showDeployModal: null});
          }}
        />
      ) : showDeploymentViewModal ? (
        <ReceiverDeploymentView
          success={true}
          successContent={`Receiver created and deployed`}
          value={this.state.showDeploymentViewModal}
          showEditButton={false}
          showCreateButton={false}
          onClose={() => {
            this.setState({
              showDeploymentViewModal: false
            });
          }}
        />
      ) : showRequestDeleteModal ? (
        <RequestDelete
          entity="receiver"
          name={
            "OrganisationName: " +
            showRequestDeleteModal.organisationName +
            ", ReceiverID: " +
            showRequestDeleteModal.receiverId +
            (showRequestDeleteModal.projectName !== undefined
              ? ", Project: " + showRequestDeleteModal.projectName
              : "")
          }
          id={showRequestDeleteModal.id}
          onClose={() => {
            this.setState({showRequestDeleteModal: null});
          }}
          onUpdate={() => {
            this.setState({
              focusQuery: null
            });
          }}
        />
      ) : showDeleteModal ? (
        <ReceiverDelete
          isAdmin={this.props.roles.includes("ROLE_ADMIN")}
          id={showDeleteModal.id}
          onClose={() => {
            this.setState({
              showDeleteModal: null
            });
          }}
          onUpdate={onUpdateDelete}
        />
      ) : null;

    return (
      <>
        <FilteredTable
          fetchingData={true}
          filterOptionsFetcher={receiverListConfig}
          actions={actions}
          header="Receivers"
          headerIcon="microphone"
          columns={tableColumns}
          detailKey="id"
          detailFetcher={getReceiver}
          fetcher={(pageSize, page, apiFilters) => {
            this.setState({tableFilters: {...apiFilters}});
            return getReceivers(pageSize, page, apiFilters);
          }}
          pageSize={50}
          icon="microphone"
          tableActions={this.getTableActions()}
          focus={focusQuery}
          editRow={editReceiver}
          refreshFilterOptions={refreshFilterOptions}
        />
        {modal}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects,
    organisationId: user.organisationId
  };
}

export default connect(mapStateToProps)(ReceiverTable);
