import React from "react";
import {connect} from "react-redux";
import FileDownload from "js-file-download";
import axios from "axios";
import {
  receiverDeployment,
  receiverDeploymentListConfig,
  deploymentRecoveryZip
} from "../axios/api";

import FilteredTable from "./FilteredTable";
import FilterDropDown from "./FilterDropDown";
import FilterDateSelector from "./FilterDateSelector";

import Receiver from "./Receiver";
import ReceiverDeploymentEdit from "./ReceiverDeploymentEdit";
import ReceiverDeploymentDelete from "./ReceiverDeploymentDelete";
import ReceiverDeploymentCreate from "./ReceiverDeploymentCreate";
import ReceiverRecoveryCreate from "./ReceiverRecoveryCreate";
import ReceiverDeploymentRecoveryEdit from "./ReceiverDeploymentRecoveryEdit";

import {
  containsActiveProject,
  isAdmin,
  toDisplayUtc,
  csvAndZipTimestamp,
  isAdminNotATFAdmin,
  canRecoveryBeDeleted,
  canDeploymentBeDeleted,
  isHistoricalDeployment
} from "./helpers";
import ReceiverHistoricDeploymentRecovery from "./ReceiverHistoricDeploymentRecovery";
import ReceiverDeploymentRecoveryDelete from "./ReceiverDeploymentRecoveryDelete";
import LoginForm from "./LoginForm";
import DetectionFileUpload from "./DetectionFileUpload";
import FathomFileUpload from "./FathomFileUpload";
import Alert from "./Alert";
import RequestDelete from "./RequestDelete";

export const ModalType = Object.freeze({
  NewDeploy: 1,
  ViewDeploy: 2,
  NewRecover: 3,
  ViewRecover: 4,
  ViewReceiver: 5,
  EditDeployment: 6,
  EditDeploymentRecovery: 7,
  EditRecovery: 8,
  AddRecovery: 9,
  DeleteRecovery: 10,
  RequestDeleteRecovery: 11,
  HistoricDeploymentRecovery: 12,
  Login: 13,
  DetectionFileUpload: 14,
  Alert: 15,
  EditReceiver: 16,
  DeleteDeployment: 17,
  RequestDeleteDeployment: 18,
  FathomFileUpload: 19,
  RequestDeleteHistoricalDeployment: 20
});

class ReceiverDeploymentTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.actions = [];
    const modalParams = props.match?.params?.modal;
    let modal = null;
    if (modalParams) {
      if (modalParams === "deploy") {
        modal = {type: ModalType.NewDeploy};
      }
      if (modalParams === "recover") {
        modal = {type: ModalType.NewRecover};
      }
    }
    this.state = {
      refreshFilterOptions: false,
      focusQuery: -1,
      tableFilters: null,
      modal: modal,
      dataSource: [],
      downloadDataEnabled: true
    };
    this.resetModal = this.resetModal.bind(this);
    this.getReceiverDeployment = this.getReceiverDeployment.bind(this);
    this.getReceiverDeployments = this.getReceiverDeployments.bind(this);
    this.modalOpener = this.modalOpener.bind(this);
    this.updateRowAfterDeploymentDeleted =
      this.updateRowAfterDeploymentDeleted.bind(this);
  }

  componentWillUnmount() {
    if (this.deploymentCt) this.deploymentCt.cancel();
  }

  resetModal() {
    this.setState({
      modal: null
    });
  }

  updateRow(id, deployment, recovery) {
    const dataSource = [...this.state.dataSource];
    const idx = dataSource.findIndex((e) => e.id === id);
    if (idx >= 0) {
      const existing = {...dataSource[idx]};
      dataSource[idx] = recovery
        ? {...existing, deviceStatus: recovery.status, recovery: recovery}
        : {...existing, ...deployment, recovery: existing.recovery};
    } else {
      const newRow = recovery
        ? {
            ...deployment,
            deviceStatus: recovery.status,
            recovery: recovery
          }
        : deployment;
      dataSource.push(newRow);
    }

    this.setState({
      dataSource: dataSource,
      focusQuery: {id: id}
    });
  }

  updateRowAfterRecoveryDeleted(id, deployment) {
    const dataSource = [...this.state.dataSource];
    const idx = dataSource.findIndex((e) => e.id === id);
    if (idx >= 0) {
      const existing = {...dataSource[idx]};
      dataSource[idx] = {...existing, ...deployment};
    }
    this.setState({
      dataSource: dataSource,
      focusQuery: {id: id}
    });
  }

  updateRowAfterDeploymentDeleted(deploymentId) {
    this.setState(
      () => {
        return {
          refreshFilterOptions: true
        };
      },
      () => {
        this.setState({
          focusQuery: {
            id: deploymentId,
            eventType: "delete"
          },
          refreshFilterOptions: false
        });
      }
    );
  }

  dataToRow(deployment) {
    return {
      id: deployment.id,
      receiverId: deployment.receiverIdString,
      organisationName: deployment.receiverOrganisationName,
      projectName: deployment.projectName,
      status: deployment.deviceStatus,
      deploymentDate: toDisplayUtc(
        deployment.deploymentDate,
        deployment.deploymentTimezone
      ),
      deploymentTimezone: deployment.deploymentTimezone,
      installationName: deployment.installationName,
      stationName: deployment.stationName,
      deploymentLocation:
        deployment.location.longitude + " " + deployment.location.latitude,
      recoveryDate: deployment.recovery
        ? toDisplayUtc(
            deployment.recovery.recoveryDate,
            deployment.recovery.recoveryTimezone
          )
        : "",
      recoveryLocation:
        deployment.recovery?.location?.longitude &&
        deployment.recovery?.location?.latitude
          ? deployment.recovery.location.longitude +
            " " +
            deployment.recovery.location.latitude
          : "",
      depthBelowSurface: deployment.depthBelowSurface
    };
  }

  tableColumns = [
    {
      title: "Receiver ID",
      dataSource: "receiverId",
      isAPIFilter: true,
      default: null,
      filter: () => "receiverId",
      component: {
        control: FilterDropDown,
        props: {
          multiple: true
        }
      },
      filterOptionsKey: "receiver"
    },
    {
      title: "Purchasing Organisation",
      dataSource: "organisationName",
      isAPIFilter: true,
      filter: () => "organisationId",
      default: null,
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "receiver", by: "receiverOwningOrganisationId"},
            {field: "project", by: "receiverOwningOrganisationIds"},
            {field: "installation", by: "receiverOwningOrganisationIds"},
            {field: "station", by: "receiverOwningOrganisationId"}
          ]
        }
      },
      filterOptionsKey: "organisation",
      placeholder: "Filter Purchasing Organisation"
    },
    {
      title: "Project",
      dataSource: "projectName",
      isAPIFilter: true,
      default: null,
      filter: () => "deploymentProjectId",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "receiver", by: "deploymentProjectId"},
            {field: "organisation", by: "deploymentProjectId"},
            {field: "installation", by: "projectId"},
            {field: "station", by: "projectId"}
          ]
        }
      },
      filterOptionsKey: "project",
      placeholder: "Filter Project"
    },
    {
      title: "Current Receiver Status",
      dataSource: "status",
      hide: true,
      isAPIFilter: true,
      filter: () => "receiverStatus",
      default: null,
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "receiver", by: "status"},
            {field: "organisation", by: "status"},
            {field: "project", by: "status"},
            {field: "installation", by: "status"},
            {field: "station", by: "status"}
          ]
        }
      },
      filterOptionsKey: "status",
      placeholder: "Filter Status"
    },
    {
      title: "Deployment Date (UTC)",
      dataSource: "deploymentDate",
      isAPIFilter: true
    },
    {
      title: "Installation Name",
      dataSource: "installationName",
      isAPIFilter: true,
      default: null,
      filter: () => "installationId",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "receiver", by: "deploymentInstallationId"},
            {field: "organisation", by: "deploymentInstallationId"},
            {field: "project", by: "installationIds"},
            {field: "station", by: "installationId"}
          ]
        }
      },
      filterOptionsKey: "installation",
      placeholder: "Filter Installation Name"
    },
    {
      title: "Station Name",
      dataSource: "stationName",
      isAPIFilter: true,
      default: null,
      filter: () => "stationId",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "receiver", by: "deploymentStationIds"},
            {field: "organisation", by: "deploymentStationId"},
            {field: "project", by: "stationIds"},
            {field: "installation", by: "stationIds"}
          ]
        }
      },
      filterOptionsKey: "station",
      placeholder: "Filter Station Name"
    },
    {
      title: "Depth Below Surface (m)",
      dataSource: "depthBelowSurface",
      default: ""
    },
    {
      title: "Deployment Location",
      dataSource: "deploymentLocation",
      isAPIFilter: true
    },
    {
      title: "Recovery Date (UTC)",
      dataSource: "recoveryDate",
      isAPIFilter: true
    },
    {
      title: "Recovery Location",
      dataSource: "recoveryLocation",
      isAPIFilter: true
    },
    {
      title: "Time Range (Start)",
      hide: true,
      isAPIFilter: true,
      default: null,
      filter: () => "timestampStart",
      dataSource: "timestampStart",
      component: {
        control: FilterDateSelector
      }
    },
    {
      title: "Time Range (End)",
      isAPIFilter: true,
      hide: true,
      filter: () => "timestampEnd",
      dataSource: "timestampEnd",
      default: null,
      component: {
        control: FilterDateSelector,
        props: {end: true}
      }
    },
    {
      title: "Bounding Box (N,S,W,E) - WGS84 datum",
      dataSource: "boundingBox",
      hide: true,
      isAPIFilter: true,
      default: "",
      filter: () => "boundingBox",
      pattern: "([-]?[0-9]+(.[0-9]+)?,){3}[-]?[0-9]+(.[0-9]+)?$"
    }
  ];

  getReceiverDeployments(pageSize, page, filter) {
    if (this.deploymentCt) this.deploymentCt.cancel();
    this.deploymentCt = axios.CancelToken.source();
    return receiverDeployment(
      {size: pageSize, page: page, ...filter},
      this.deploymentCt.token
    ).then((result) => {
      this.setState({downloadDataEnabled: result.data.totalElements >= 1});
      this.deploymentCt = null;
      const content = result.data.content.reduce((accum, r) => {
        accum.push(r);
        return accum;
      }, []);
      result.data.content = content.map((r) => this.dataToRow(r));
      this.setState((state) => {
        return {
          dataSource: [...state.dataSource, ...content]
        };
      });
      return result.data;
    });
  }

  getReceiverDeployment(id) {
    return new Promise(async (resolve, reject) => {
      const idx = this.state.dataSource.findIndex((e) => e.id === id);
      const selected = this.state.dataSource[idx];
      if (!selected) return reject();

      this.actions.length = 0;
      this.actions.push({
        name: "View Receiver",
        onClick: () => {
          this.setState({
            modal: {
              type: ModalType.ViewReceiver,
              id: selected.receiverId
            }
          });
        }
      });

      if (
        this.props.accessToken &&
        (isAdmin(this.props.roles) ||
          this.props.organisationId === selected.receiverOrganisationId ||
          this.props.projects.find((p) => p.id === selected.receiverProjectId))
      ) {
        this.actions.push({
          name: "Edit Receiver",
          onClick: () => {
            this.setState({
              modal: {
                type: ModalType.EditReceiver,
                id: selected.receiverId
              }
            });
          }
        });
      }

      if (
        this.props.accessToken &&
        (isAdmin(this.props.roles) ||
          this.props.projects.find((p) => p.id === selected.projectId))
      ) {
        if (selected.recovery) {
          this.actions.push({
            name: "Edit Receiver Deployment",
            onClick: () => {
              this.setState({
                modal: {
                  type: ModalType.EditDeploymentRecovery,
                  value: selected
                }
              });
            }
          });
          this.actions.push({
            name: "Edit Receiver Recovery",
            onClick: () => {
              this.setState({
                modal: {
                  type: ModalType.EditRecovery,
                  value: selected
                }
              });
            }
          });
          if (
            isAdminNotATFAdmin(this.props.roles) &&
            (await canRecoveryBeDeleted(selected.recovery.id))
          ) {
            this.actions.push({
              name: "Delete Receiver Recovery",
              destructive: true,
              onClick: () => {
                this.setState({
                  modal: {
                    type: ModalType.DeleteRecovery,
                    value: selected
                  }
                });
              }
            });
          }
          if (
            !isAdminNotATFAdmin(this.props.roles) &&
            (await canRecoveryBeDeleted(selected.recovery.id))
          ) {
            this.actions.push({
              name: "Request Delete Receiver Recovery",
              destructive: true,
              onClick: () => {
                this.setState({
                  modal: {
                    type: ModalType.RequestDeleteRecovery,
                    value: selected
                  }
                });
              }
            });
          }
        } else {
          this.actions.push({
            name: "Edit Receiver Deployment",
            onClick: () => {
              this.setState({
                modal: {
                  type: ModalType.EditDeployment,
                  value: selected
                }
              });
            }
          });
          this.actions.push({
            name: "Recover a receiver",
            onClick: () => {
              this.setState({
                modal: {
                  type: ModalType.AddRecovery,
                  value: selected.receiverIdString
                }
              });
            }
          });
          if (
            (await canDeploymentBeDeleted(selected.id)) &&
            !(await isHistoricalDeployment(selected.id))
          ) {
            if (isAdminNotATFAdmin(this.props.roles)) {
              this.actions.push({
                name: "Delete Receiver Deployment",
                destructive: true,
                onClick: () => {
                  this.setState({
                    modal: {
                      type: ModalType.DeleteDeployment,
                      value: selected
                    }
                  });
                }
              });
            } else {
              this.actions.push({
                name: "Request Delete Receiver Deployment",
                destructive: true,
                onClick: () => {
                  this.setState({
                    modal: {
                      type: ModalType.RequestDeleteDeployment,
                      value: selected
                    }
                  });
                }
              });
            }
          }
        }
        if (
          !isAdminNotATFAdmin(this.props.roles) &&
          (await isHistoricalDeployment(selected.id))
        ) {
          this.actions.push({
            name: "Request Delete Historical Deployment",
            destructive: true,
            onClick: () => {
              this.setState({
                modal: {
                  type: ModalType.RequestDeleteHistoricalDeployment,
                  value: selected
                }
              });
            }
          });
        }
      }
      resolve({
        header: "Receiver Deployment",
        data: [],
        row: this.dataToRow(selected)
      });
    });
  }

  modalOpener(type, value, props) {
    this.setState({
      modal: {
        type: type,
        value: value,
        recoverReceiverId: value,
        ...props
      }
    });
  }

  getTableActions() {
    return [
      {
        name: "Deploy Receiver",
        onClick: () => {
          !this.props.accessToken
            ? this.setState({
                modal: {type: ModalType.Login},
                nextModal: {type: ModalType.NewDeploy}
              })
            : this.setState({modal: {type: ModalType.NewDeploy}});
        }
      },
      {
        name: "Recover Receiver",
        onClick: () => {
          !this.props.accessToken
            ? this.setState({
                modal: {type: ModalType.Login},
                nextModal: {type: ModalType.NewRecover}
              })
            : this.setState({modal: {type: ModalType.NewRecover}});
        }
      },
      {
        name: "Upload VUE files",
        onClick: () => {
          this.setState({modal: {type: ModalType.DetectionFileUpload}});
        }
      },
      {
        name: "Upload Fathom files",
        onClick: () => {
          this.setState({modal: {type: ModalType.FathomFileUpload}});
        }
      },
      {
        name: "Download Data",
        disableOnClick: true,
        disabledNoData: !this.state.downloadDataEnabled,
        onClick: () => {
          return deploymentRecoveryZip(this.state.tableFilters).then(
            (response) => {
              FileDownload(
                response.data,
                `IMOS_receiver_deployment_metadata_${csvAndZipTimestamp()}.zip`
              );
            }
          );
        }
      },
      {
        name: "Create Historic Deployment/Recovery",
        onClick: () => {
          !this.props.accessToken
            ? this.setState({
                modal: {type: ModalType.Login},
                nextModal: {type: ModalType.HistoricDeploymentRecovery}
              })
            : containsActiveProject(this.props.projects) ||
              isAdmin(this.props.roles)
            ? this.setState({
                modal: {type: ModalType.HistoricDeploymentRecovery}
              })
            : this.setState({
                modal: {
                  type: ModalType.Alert
                }
              });
        },
        className: "at-secondary-button"
      }
    ];
  }

  render() {
    const {modalOpener, updateRowAfterDeploymentDeleted} = this;
    const {refreshFilterOptions} = this.state;
    let modal = null;
    switch (this.state.modal?.type) {
      case ModalType.NewDeploy:
        modal = (
          <ReceiverDeploymentCreate
            onClose={this.resetModal}
            modalOpener={modalOpener}
            onUpdate={(deployment) => {
              this.updateRow(deployment.id, deployment);
            }}
          />
        );
        break;
      case ModalType.ViewDeploy:
        modal = null;
        break;
      case ModalType.NewRecover:
        modal = (
          <ReceiverRecoveryCreate
            onClose={this.resetModal}
            modalOpener={modalOpener}
            onUpdate={(recovery) => {
              this.updateRow(
                recovery.deploymentId,
                recovery.deployment,
                recovery
              );
            }}
          />
        );
        break;
      case ModalType.ViewReceiver:
        modal = (
          <Receiver
            onClose={this.resetModal}
            id={this.state.modal.id}
            isAdmin={this.props.roles.includes("ROLE_ADMIN")}
            onUpdate={(receiver) => {
              const dataSource = [...this.state.dataSource];
              const idx = dataSource.findIndex(
                (e) => e.receiverId === receiver.id
              );
              if (idx >= 0) {
                const row = dataSource[idx];
                row.receiverIdString = `${receiver.deviceModelName}-${receiver.serialNumber}`;
                row.organisationName = receiver.organisationName;
                row.projectName = receiver.projectName;
                this.updateRow(row.id, row);
              }
            }}
          />
        );
        break;
      case ModalType.EditReceiver:
        modal = (
          <Receiver
            edit
            onClose={this.resetModal}
            id={this.state.modal.id}
            isAdmin={this.props.roles.includes("ROLE_ADMIN")}
            onUpdate={(receiver) => {
              const dataSource = [...this.state.dataSource];
              const idx = dataSource.findIndex(
                (e) => e.receiverId === receiver.id
              );
              if (idx >= 0) {
                const row = dataSource[idx];
                row.receiverIdString = `${receiver.deviceModelName}-${receiver.serialNumber}`;
                row.receiverOrganisationName = receiver.organisationName;
                this.updateRow(row.id, row);
              }
            }}
          />
        );
        break;
      case ModalType.EditDeployment:
        modal = (
          <ReceiverDeploymentEdit
            value={{
              ...this.state.modal.value,
              receiver: {
                deviceModelName:
                  this.state.modal.value.receiverIdString.split("-")[0],
                serialNumber:
                  this.state.modal.value.receiverIdString.split("-")[1],
                organisation: {name: this.state.modal.value.organisationName}
              }
            }}
            onUpdate={(deployment) => {
              this.updateRow(deployment.id, deployment);
            }}
            onClose={this.resetModal}
          />
        );
        break;
      case ModalType.EditDeploymentRecovery:
        modal = (
          <ReceiverDeploymentRecoveryEdit
            editDeployment
            value={{
              ...this.state.modal.value,
              receiver: {
                deviceModelName:
                  this.state.modal.value.receiverIdString.split("-")[0],
                serialNumber:
                  this.state.modal.value.receiverIdString.split("-")[1],
                organisation: {name: this.state.modal.value.organisationName}
              }
            }}
            onUpdate={(deployment) => {
              this.updateRow(deployment.id, deployment);
            }}
            onClose={this.resetModal}
          />
        );
        break;
      case ModalType.EditRecovery:
        modal = (
          <ReceiverDeploymentRecoveryEdit
            editRecovery
            value={{
              ...this.state.modal.value,
              receiver: {
                deviceModelName:
                  this.state.modal.value.receiverIdString.split("-")[0],
                serialNumber:
                  this.state.modal.value.receiverIdString.split("-")[1],
                organisation: {name: this.state.modal.value.organisationName}
              }
            }}
            onUpdate={(recovery) => {
              this.updateRow(recovery.deploymentId, null, recovery);
            }}
            onClose={this.resetModal}
          />
        );
        break;
      case ModalType.DeleteRecovery:
        modal = (
          <ReceiverDeploymentRecoveryDelete
            value={{
              ...this.state.modal.value,
              receiver: {
                deviceModelName:
                  this.state.modal.value.receiverIdString.split("-")[0],
                serialNumber:
                  this.state.modal.value.receiverIdString.split("-")[1],
                organisation: {name: this.state.modal.value.organisationName}
              }
            }}
            onUpdate={(deployment) => {
              this.updateRowAfterRecoveryDeleted(deployment.id, deployment);
            }}
            onClose={this.resetModal}
          />
        );
        break;
      case ModalType.RequestDeleteRecovery:
        modal = (
          <RequestDelete
            entity="recovery"
            name={
              "ReceiverID: " +
              this.state.modal.value.receiverIdString +
              ", Installation: " +
              this.state.modal.value.installationName +
              ", Project: " +
              this.state.modal.value.projectName +
              ", Station: " +
              this.state.modal.value.stationName +
              ", RecoveryDate: " +
              this.state.modal.value.recovery.recoveryDate +
              ", RecoveryLocation: (" +
              this.state.modal.value.recovery.location.longitude +
              "; " +
              this.state.modal.value.recovery.location.latitude +
              "), Recoverer: " +
              this.state.modal.value.recovery.recoverer.name
            }
            id={this.state.modal.value.recovery.id}
            onUpdate={(recovery) => {
              this.updateRow(recovery.deploymentId, null, recovery);
            }}
            onClose={this.resetModal}
          />
        );
        break;
      case ModalType.DeleteDeployment:
        modal = (
          <ReceiverDeploymentDelete
            open
            value={{
              ...this.state.modal.value,
              receiver: {
                deviceModelName:
                  this.state.modal.value.receiverIdString.split("-")[0],
                serialNumber:
                  this.state.modal.value.receiverIdString.split("-")[1],
                organisation: {name: this.state.modal.value.organisationName}
              }
            }}
            onClose={this.resetModal}
            onUpdate={updateRowAfterDeploymentDeleted}
          />
        );
        break;
      case ModalType.RequestDeleteDeployment:
        modal = (
          <RequestDelete
            entity="deployment"
            name={
              "ReceiverID: " +
              this.state.modal.value.receiverIdString +
              ", Installation: " +
              this.state.modal.value.installationName +
              ", Station: " +
              this.state.modal.value.stationName +
              ", Project: " +
              this.state.modal.value.projectName
            }
            id={this.state.modal.value.id}
            onUpdate={(deployment) => {
              this.updateRow(deployment.id, deployment);
            }}
            onClose={this.resetModal}
          />
        );
        break;
      case ModalType.RequestDeleteHistoricalDeployment:
        modal = (
          <RequestDelete
            entity="historical_deployment"
            name={
              "ReceiverID: " +
              this.state.modal.value.receiverIdString +
              ", Installation: " +
              this.state.modal.value.installationName +
              ", Station: " +
              this.state.modal.value.stationName +
              ", Project: " +
              this.state.modal.value.projectName
            }
            id={this.state.modal.value.id}
            onUpdate={(deployment) => {
              this.updateRow(deployment.id, deployment);
            }}
            onClose={this.resetModal}
          />
        );
        break;
      case ModalType.AddRecovery:
        modal = (
          <ReceiverRecoveryCreate
            recoverReceiverId={this.state.modal.value}
            onUpdate={(recovery) => {
              this.updateRow(recovery.deploymentId, null, recovery);
            }}
            onClose={this.resetModal}
          />
        );
        break;
      case ModalType.HistoricDeploymentRecovery:
        modal = (
          <ReceiverHistoricDeploymentRecovery
            onClose={this.resetModal}
            onUpdate={(combinedPayload) => {
              const {deployment, recovery} = combinedPayload;
              this.updateRow(deployment.id, deployment, recovery);
            }}
          />
        );
        break;
      case ModalType.Login:
        modal = (
          <LoginForm
            open
            onClose={(success) => {
              if (!success) {
                this.resetModal();
              } else {
                this.setState({
                  modal: containsActiveProject(this.props.projects)
                    ? this.state.nextModal
                    : {type: ModalType.Alert}
                });
              }
            }}
          />
        );
        break;
      case ModalType.DetectionFileUpload:
        modal = (
          <DetectionFileUpload onClose={this.resetModal} noRedirectOnClose />
        );
        break;
      case ModalType.FathomFileUpload:
        modal = (
          <FathomFileUpload onClose={this.resetModal} noRedirectOnClose />
        );
        break;
      case ModalType.Alert:
        modal = (
          <Alert
            icon="exclamation triangle"
            headerContent="Cannot create historical receiver deployments"
            content="You must be a member of an active project to create historical receiver deployments and recoveries."
            closeButtonContent="OK"
            hideCancel
            closeAction={this.resetModal}
          />
        );
        break;
      default:
        break;
    }

    return (
      <>
        <FilteredTable
          header="Receiver Deployments & Recoveries"
          headerIcon="microphone"
          filterOptionsFetcher={receiverDeploymentListConfig}
          columns={this.tableColumns}
          detailKey="id"
          detailFetcher={this.getReceiverDeployment}
          fetcher={(pageSize, page, apiFilters) => {
            this.setState({tableFilters: {...apiFilters}});
            return this.getReceiverDeployments(pageSize, page, apiFilters);
          }}
          pageSize={50}
          icon="microphone"
          actions={this.actions}
          focus={this.state.focusQuery}
          tableActions={this.getTableActions()}
          refreshFilterOptions={refreshFilterOptions}
        />
        {modal}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    organisationId: user.organisationId,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(ReceiverDeploymentTable);
