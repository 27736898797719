import React from "react";
import FilteredTable from "./FilteredTable";

import {project, speciesSearch, projectListConfig, me} from "../axios/api";
import axios from "axios";
import {connect} from "react-redux";
import {getEntityDetailLists, Roles} from "./helpers";
import ProjectCreate from "./ProjectCreate";
import ProjectView from "./ProjectView";
import ProjectEdit from "./ProjectEdit";
import FilterDropDown from "./FilterDropDown";
import FilterBoundingBox from "./FilterBoundingBox";
import FilterSearchable from "./FilterSearchable";
import OrganisationList from "./OrganisationList";
import UserList from "./UserList";
import {updateUser} from "../actions";
import LoginForm from "./LoginForm";

class ProjectTable extends React.Component {
  tableActions = [
    {
      name: "Create Project",
      onClick: () => {
        this.setState({showCreateModal: true});
      }
    }
  ];

  tableColumns = [
    {
      title: "",
      dataSource: "protected",
      key: "protected",
      hide: !(
        this.props.roles.includes("ROLE_ADMIN") ||
        this.props.roles.includes("ROLE_ATF_ADMIN")
      ),
      width: "3%",
      icons: {
        true: {color: "red", name: "lock"},
        false: null
      }
    },
    {
      title: "Project Name",
      dataSource: "name",
      default: "",
      isAPIFilter: true,
      filter: () => "id",
      component: {
        control: FilterDropDown,
        props: {narrows: [{field: "organisation", by: "projectIds"}]}
      },
      filterOptionsKey: "project",
      placeholder: "Filter Project Name",
      width: "60%"
    },
    {
      title: "Project Leader",
      dataSource: "leaders",
      field: "name",
      key: "name",
      icon: "user",
      detail: true,
      renderAs: UserList
    },
    {
      title: "Project Leader Email Address",
      dataSource: "leader.user.emailAddress",
      default: "",
      width: "30%"
    },
    {
      title: "Project Members",
      dataSource: "members",
      field: "name",
      icon: "users",
      default: [],
      detail: true,
      renderAs: UserList
    },
    {
      title: "Organisation",
      dataSource: "organisations",
      field: "name",
      icon: "sitemap",
      default: "",
      detail: true,
      renderAs: OrganisationList,
      isAPIFilter: true,
      filter: () => "organisationId",
      component: {
        control: FilterDropDown,
        props: {narrows: [{field: "project", by: "organisationIds"}]}
      },
      filterOptionsKey: "organisation",
      placeholder: "Filter Organisation"
    },
    {
      title: "Species Tagged",
      dataSource: "species",
      icon: "",
      default: "",
      hide: true,
      isAPIFilter: true,
      filter: () => "speciesId",
      component: {
        control: FilterSearchable,
        props: {
          searchApi: speciesSearch,
          isSearchable: true,
          multiple: true,
          resetInputValueOnSubmit: true
        }
      }
    },
    {
      title: "Status",
      dataSource: "status",
      width: "10%",
      icons: {
        PENDING: {color: "yellow", name: "circle"},
        ACTIVE: {color: "green", name: "circle"},
        DEACTIVATED: {color: "red", name: "circle"}
      },
      hide: !this.props.roles.includes("ROLE_ADMIN"),
      hideFilter: !this.props.roles.includes("ROLE_ADMIN"),
      isAPIFilter: this.props.roles.includes("ROLE_ADMIN"),
      filter: () => "status",
      default: "",
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "status"
    },
    {
      title: "Station Location Bounding Box (N,S,W,E) - WGS84 datum",
      dataSource: "location",
      icon: "",
      default: "",
      hide: true,
      isAPIFilter: true,
      filter: () => "stationBbox",
      component: {
        control: FilterBoundingBox
      }
    }
  ];

  constructor(props) {
    super(props);

    this.actions = [];

    this.state = {
      focusQuery: undefined,
      dataSource: []
    };
    this.getProjectDetail = this.getProjectDetail.bind(this);
    this.getProject = this.getProject.bind(this);
    this.modalOpener = this.modalOpener.bind(this);
  }

  componentDidMount() {
    if (this.props.accessToken !== null) {
      this.setState({
        userProjectIds: this.props.projects.map((v, i) => {
          return v.id;
        })
      });
    }
  }

  componentWillUnmount() {
    if (this.projectCt) this.projectCt.cancel();
  }

  getProjectDetail(id) {
    let detailColumns = this.tableColumns.filter((item) => {
      return item.detail;
    });
    return new Promise(async (resolve, reject) => {
      const idx = this.state.dataSource.findIndex((e) => e?.id === id);
      const selectedProject = this.state.dataSource[idx];
      if (selectedProject) {
        const members = selectedProject.projectRoles.reduce((accum, role) => {
          if (role.roleType === Roles.PROJECT_MEMBER) {
            role.user.access = role.access;
            accum.push(role.user);
          }
          return accum;
        }, []);
        const leader = selectedProject.projectRoles.find(
          (role) => role.roleType === "PROJECT_LEADER"
        ) ?? {user: {name: ""}};
        let data = {
          ...selectedProject,
          leaders: [leader.user],
          members: members
        };
        let details = getEntityDetailLists(data, detailColumns);
        // let details ={};
        details.row = {...selectedProject, leader: leader};
        this.actions.length = 0;
        this.actions.push({
          name: "View Project",
          onClick: () => {
            this.setState({showViewModal: selectedProject});
          }
        });
        if (
          (this.props.accessToken !== null &&
            details.row.projectRoles.some(
              (r) => r.user.id === this.props.userId && r.access !== "REVOKED"
            )) ||
          this.props.roles.includes("ROLE_ADMIN") ||
          this.props.roles.includes("ROLE_ATF_ADMIN")
        ) {
          this.actions.push({
            name: "Edit Project",
            onClick: () => {
              this.setState({showEditModal: selectedProject});
            }
          });
        }
        resolve(details);
      }
    });
  }

  getProject(pageSize, page, filter) {
    this.projectCt = axios.CancelToken.source();
    return project(
      {size: pageSize, page: page, ...filter},
      this.projectCt.token
    ).then((result) => {
      this.projectCt = null;
      const content = result.data.content.reduce((accum, data) => {
        if (
          data.status === "ACTIVE" ||
          this.state.userProjectIds.includes(data.id) ||
          this.props.roles.includes(Roles.ROLE_ADMIN) ||
          this.props.roles.includes("ROLE_ATF_ADMIN")
        ) {
          const leader = data.projectRoles.find(
            (role) => role.roleType === Roles.PROJECT_LEADER
          ) ?? {user: {name: ""}};
          accum.push({...data, leader: leader, leaderName: leader.user.name});
        }
        return accum;
      }, []);

      result.data.content = content;

      // Update dataSource
      this.setState({
        dataSource: [...this.state.dataSource, ...content]
      });
      return result.data;
    });
  }

  modalOpener(modalName, id) {
    const idx = this.state.dataSource.findIndex((e) => e?.id === id);
    const selectedProject = this.state.dataSource[idx];
    switch (modalName) {
      case "edit":
        this.setState({
          showEditModal: selectedProject,
          showViewModal: false,
          showCreateModal: false
        });
        break;
      case "create":
        this.setState({
          showEditModal: false,
          showViewModal: false,
          showCreateModal: selectedProject
        });
        break;
      case "view":
        this.setState({
          showEditModal: false,
          showViewModal: selectedProject,
          showCreateModal: false
        });
        break;
      default:
        this.setState({
          showEditModal: false,
          showViewModal: false,
          showCreateModal: false
        });
    }
  }

  render() {
    const {
      getProject,
      tableColumns,
      getProjectDetail,
      actions,
      tableActions,
      modalOpener
    } = this;
    const {focusQuery, newData} = this.state;

    const modal =
      this.state.showCreateModal && !this.props.accessToken ? (
        <LoginForm
          open
          onClose={(success) => {
            if (!success) this.setState({showCreateModal: false});
          }}
        />
      ) : this.state.showViewModal ? (
        <ProjectView
          open
          isWorkflow={this.state.showViewModal?.isWorkflow}
          project={
            this.state.showViewModal?.project ?? this.state.showViewModal
          }
          onClose={(createAnother) => {
            this.setState({
              showViewModal: null,
              showCreateModal: createAnother
            });
          }}
          modalOpener={modalOpener}
        />
      ) : this.state.showEditModal ? (
        <ProjectEdit
          project={this.state.showEditModal}
          onUpdate={(project) => {
            this.setState((state) => {
              const dataSource = [...state.dataSource];
              const idx = dataSource.findIndex((e) => e.id === project.id);
              dataSource[idx] = project;
              return {
                dataSource: dataSource,
                focusQuery: {id: project.id}
              };
            });
          }}
          onClose={() => {
            this.setState({showEditModal: null});
          }}
        />
      ) : this.state.showCreateModal ? (
        <ProjectCreate
          onUpdate={(project) => {
            this.setState(
              (state) => {
                const dataSource = [...state.dataSource];
                const idx = dataSource.findIndex((e) => e.id === project.id);
                dataSource[idx] = project;
                return {
                  dataSource: dataSource,
                  focusQuery: {id: project.id},
                  showCreateModal: false,
                  showViewModal: {project: project, isWorkflow: true}
                };
              },
              () =>
                me().then((result) => {
                  this.props.user(
                    result.data.roles,
                    result.data.id,
                    result.data.organisationId,
                    result.data.projects,
                    result.data.name
                  );
                })
            );
          }}
          onClose={() => {
            this.setState({showCreateModal: null});
          }}
        />
      ) : null;

    return (
      <>
        <FilteredTable
          header="Projects"
          headerIcon="tasks"
          columns={tableColumns}
          fetcher={getProject}
          detailFetcher={getProjectDetail}
          filterOptionsFetcher={projectListConfig}
          detailKey="id"
          pageSize={50}
          icon="tasks"
          actions={actions}
          tableActions={tableActions}
          focus={focusQuery}
          newData={newData}
        />
        {modal}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    user: (roles, userId, organisationId, projects, name) =>
      dispatch(updateUser(roles, userId, organisationId, projects, name))
  };
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    userId: user.userId,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTable);
