import React from "react";
import PropTypes from "prop-types";
import {Popup, Table, TableRow} from "semantic-ui-react";
import {locationToString, toDisplayUtc} from "./helpers";
import moment from "moment";

class TransmitterHistoryList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      tableData: this.getTableData()
    };

    this.row = this.row.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.getTableData = this.getTableData.bind(this);

    if (props.onRowClick) props.onRowClick(props.data?.history[0]?.release.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.data.history[0].release.tagId !==
      prevProps.data.history[0].release.tagId
    ) {
      this.setState({tableData: this.getTableData()}, () => {
        if (this.props.onRowClick)
          this.props.onRowClick(this.props.data?.history[0]?.release.id);
      });
    }
  }

  getTableData() {
    return this.props.data?.history?.reduce((accumulator, d) => {
      accumulator.push({
        id: d.release.id,
        deploymentDate: `${toDisplayUtc(
          d.release.releaseDate,
          d.release.releaseTimezone
        )}`,
        deploymentLocation: locationToString({
          longitude: d.release.releaseLongitude,
          latitude: d.release.releaseLatitude
        }),
        species: d.release.animal?.species.commonName,
        sex: d.release.animal?.sex,
        surgeryType: d.release.surgeryType,
        releaseLocality: d.release.releaseLocality,
        recoveryDate: d.recovery
          ? `${toDisplayUtc(
              d.recovery.recoveryDate,
              d.recovery.recoveryTimezone
            )}`
          : "-",
        recoveryLocation: d.recovery
          ? locationToString(d.recovery.location)
          : "-",
        embargoDate: `${
          moment(d.release.embargoDate).isAfter(moment.utc())
            ? toDisplayUtc(d.release.embargoDate, d.release.releaseTimezone)
            : "-"
        }`,
        releaseProject: d.release.projectName,
        releaseComments: d.release.comments,
        measurements: d.release.measurements
      });
      return accumulator;
    }, []);
  }

  rowClick(id, index) {
    this.setState({active: index}, () => {
      if (this.props.onRowClick) this.props.onRowClick(id);
    });
  }

  measurementRow(r, index) {
    return {
      key: index,
      cells: [
        {key: 1, content: r.measurementType},
        {key: 2, content: r.value},
        {key: 3, content: r.unit},
        {key: 4, content: r.comments}
      ]
    };
  }

  row(r, index) {
    return (
      <Popup
        disabled={!r.measurements || r.measurements?.length < 1}
        pinned
        position="bottom left"
        key={index}
        content={
          <Table
            className="at-modal-content"
            tableData={r.measurements}
            renderBodyRow={this.measurementRow}
            headerRows={[
              {
                key: 1,
                cells: [
                  {
                    singleLine: true,
                    key: 1,
                    colSpan: 4,
                    content: `Measurements for deployment ${r.deploymentDate}`
                  }
                ]
              },
              {
                key: 2,
                cells: [
                  {key: 1, content: "Type"},
                  {key: 2, content: "Value"},
                  {key: 3, content: "Unit"},
                  {key: 4, content: "Comment"}
                ]
              }
            ]}
          />
        }
        trigger={
          <TableRow
            cells={[
              {key: 0, content: r.deploymentDate},
              {key: 1, content: r.deploymentLocation},
              {key: 2, content: r.releaseLocality},
              {key: 3, content: r.releaseProject},
              {key: 4, content: r.species},
              {key: 5, content: r.sex},
              {key: 6, content: r.surgeryType},
              {key: 7, content: r.recoveryDate},
              {key: 8, content: r.recoveryLocation},
              {key: 9, content: r.embargoDate},
              {key: 10, content: r.releaseComments}
            ]}
            active={index === this.state.active}
            onClick={() => this.rowClick(r.id, index)}
          />
        }
      />
    );
  }

  render() {
    const headerRow = (
      <Table.Row
        cells={[
          {
            key: 0,
            as: () => <Table.HeaderCell content="Deployment Date (UTC)" />
          },
          {
            key: 1,
            as: () => <Table.HeaderCell content="Deployment Location" />
          },
          {
            key: 2,
            as: () => <Table.HeaderCell content="Deployment Locality" />
          },
          {key: 3, as: () => <Table.HeaderCell content="Deployment Project" />},
          {key: 4, as: () => <Table.HeaderCell content="Species" />},
          {key: 5, as: () => <Table.HeaderCell content="Sex" />},
          {key: 6, as: () => <Table.HeaderCell content="Surgery Type" />},
          {
            key: 7,
            as: () => <Table.HeaderCell content="Recovery Date (UTC)" />
          },
          {key: 8, as: () => <Table.HeaderCell content="Recovery Location" />},
          {key: 9, as: () => <Table.HeaderCell content="Embargo Date (UTC)" />},
          {key: 10, as: () => <Table.HeaderCell content="Comments" />}
        ]}
      />
    );

    return (
      <>
        <strong>
          Transmitter Deployment History - Current Status is{" "}
          {this.props.data?.status}
        </strong>
        <Table
          selectable
          tableData={this.state.tableData}
          renderBodyRow={this.row}
          headerRow={headerRow}
          className="at-modal-content"
        />
      </>
    );
  }
}

TransmitterHistoryList.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string,
    history: PropTypes.array
  }).isRequired,
  onRowClick: PropTypes.func
};

export default TransmitterHistoryList;
