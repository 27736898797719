import React from "react";
import {connect} from "react-redux";
import {Form, Label, Message, Segment} from "semantic-ui-react";
import ReceiverSummary from "./ReceiverSummary";
import {recoveryDelete, receiverById} from "../axios/api";
import FormModal from "./FormModal";
import DateSelector from "./DateSelector";
import Location from "./Location";
import {fromUTC, isAdminNotATFAdmin, canRecoveryBeDeleted} from "./helpers";

class ReceiverDeploymentRecoveryDelete extends React.Component {
  constructor(props) {
    super(props);
    const recovery = {
      ...props.value.recovery,
      ...props.value.recovery?.location
    };
    const deployment = {
      ...props.value,
      latitude: props.value?.location?.latitude,
      longitude: props.value?.location?.longitude
    };
    this.state = {
      valid: false,
      createLabel: `Delete Receiver Recovery`,
      viewLabel: `Recovery Details`,
      success: false,
      deployment: deployment,
      recovery: recovery
    };
    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.failedAction = this.failedAction.bind(this);
  }

  componentDidMount() {
    receiverById(this.state.deployment.receiverId).then((receiverResult) => {
      this.setState((s) => {
        return {
          loading: false,
          canBeDeleted: canRecoveryBeDeleted(this.state.recovery.id),
          userCanDelete: isAdminNotATFAdmin(this.props.roles),
          deployment: {...s.deployment, receiver: receiverResult.receiver}
        };
      });
    });
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    const isValid = this.state.userCanDelete && this.state.canBeDeleted;
    return !isValid;
  }

  successAction(response) {
    this.setState({
      success: true,
      failed: false
    });
    if (this.props.onUpdate) {
      this.props.onUpdate({
        ...response
      });
    }
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  render() {
    const {
      success,
      createLabel,
      deployment,
      recovery,
      loading,
      userCanDelete
    } = this.state;
    const {trigger} = this.props;
    const {checkFormValid, successAction, onClose, failedAction} = this;
    let endpoint = recoveryDelete;
    let successContent = `Recovery Deleted`;
    let submitContent = `Delete Recovery`;

    const userCanDeleteMessage = userCanDelete ? null : (
      <p>You do not have permission to delete the station.</p>
    );
    const messageContent = <>{userCanDeleteMessage}</>;

    const headerRow = !success ? (
      <Segment>
        <Message
          info
          header="Deleting Receiver Recovery"
          content={messageContent}
        />
        <ReceiverSummary
          value={{...deployment.receiver, status: deployment.deviceStatus}}
        />
      </Segment>
    ) : null;

    const formInputs = !success ? (
      <>
        <Segment>
          <Label attached="top left">Deployment</Label>
          <DateSelector
            readOnly
            date={fromUTC(
              {
                timestamp: deployment.deploymentDate,
                timezone: deployment.deploymentTimezone
              },
              "YYYY-MM-DD HH:mm"
            )}
            timezone={deployment.deploymentTimezone}
            label="Deployment Date and Timezone"
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Station Name"
            value={deployment.stationName}
          />
          <Form.Field
            control={Location}
            label="Deployment Location"
            readOnly
            value={deployment.location}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Acoustic Release Brand"
            value={deployment.acousticReleaseBrand ?? ""}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Acoustic Release ID"
            value={deployment.acousticReleaseId ?? ""}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Mooring Descriptor"
            value={deployment.mooringDescriptor ?? ""}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Depth Below Surface (metres)"
            value={deployment.depthBelowSurface ?? ""}
          />
          <Form.Input
            fluid
            readOnly
            transparent
            label="Comments"
            value={deployment.comments ?? ""}
          />
        </Segment>
      </>
    ) : null;

    const passiveElements = !success ? (
      <>
        <Segment>
          <Label attached="top left">Recovery</Label>
          <Form.Input
            fluid
            readOnly
            transparent
            label="Recovery Status"
            value={recovery.status}
          />
          <Form.Input
            fluid
            label="Recovered By"
            value={recovery.recoverer.name}
            readOnly
            transparent
          />
          <DateSelector
            label="Recovery Date and Timezone"
            readOnly
            date={recovery.recoveryDate}
            timezone={recovery.recoveryTimezone}
          />
          <Form.Field
            control={Location}
            label="Location"
            readOnly
            value={{longitude: recovery.longitude, latitude: recovery.latitude}}
          />
          <Form.Input
            fluid
            readOnly
            transparent
            label="Comments"
            value={recovery.comments ?? ""}
          />
        </Segment>
      </>
    ) : null;

    return (
      <FormModal
        open
        headerRow={headerRow}
        formSubmitAPI={!success ? endpoint : false}
        formData={recovery.id}
        formInputs={formInputs}
        checkFormValid={checkFormValid}
        testId="receiver-deployment-recovery-delete"
        successHeader="Success"
        onSuccess={successAction}
        success={success}
        headerIcon="dot circle"
        trigger={trigger}
        successContent={successContent}
        headerContent={createLabel}
        submitContent={submitContent}
        onClose={onClose}
        passiveElements={passiveElements}
        passiveColumn={2}
        onFail={failedAction}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles,
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    userId: user.userId
  };
}

export default connect(mapStateToProps)(ReceiverDeploymentRecoveryDelete);
