import React from "react";
import FormModal from "./FormModal";
import {requestDelete} from "../axios/api";

class RequestDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      failed: false,
      formData: {
        entity: this.props.entity,
        id: this.props.id,
        details: this.props.name
      }
    };
    this.successAction = this.successAction.bind(this);
    this.failedAction = this.failedAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
  }

  successAction() {
    this.setState({
      success: true,
      failed: false
    });
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  checkFormValid() {
    return (
      typeof this.state.formData.entity === "undefined" ||
      typeof this.state.formData.id === "undefined"
    );
  }

  render() {
    const {onOpen, successAction, failedAction, checkFormValid} = this;
    const {loading, success, formData} = this.state;
    const {trigger, onClose} = this.props;

    let subheaderContent = "";
    if (this.props.entity !== "historical_deployment") {
      subheaderContent = `This ${this.props.entity} does not have any dependant resources and can be deleted. Would you like to notify the site administrator to delete ${this.props.entity}: ${this.props.name}?`;
    } else {
      subheaderContent = `This ${this.props.entity} deletion request has to be manually checked, we contact you if it cannot be deleted. Would you like to notify the site administrator to delete ${this.props.entity}: ${this.props.name}?`;
    }

    return (
      <FormModal
        formSubmitAPI={!success ? requestDelete : false}
        headerContent="Request Delete"
        subheaderContent={subheaderContent}
        submitContent="Request Delete"
        checkFormValid={checkFormValid}
        formData={formData}
        onSuccess={successAction}
        onFail={failedAction}
        trigger={trigger}
        successHeader="Notification to delete this resource has been sent to the admin"
        onClose={onClose}
        onOpen={onOpen}
        open
        loading={loading}
      />
    );
  }
}

export default RequestDelete;
