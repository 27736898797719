import React from "react";
import {Form, Icon, Table} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";

const lengthUnits = [
  {text: "cm", value: "cm"},
  {text: "mm", value: "mm"},
  {text: "m", value: "m"}
];

const weightUnits = [
  {text: "g", value: "g"},
  {text: "kg", value: "kg"}
];

let units = lengthUnits.concat(weightUnits);

const newMeasurement = {
  measurementType: null,
  value: "",
  unit: null,
  comments: "",
  unitOptions: units
};

const lengthRegex = /(length|width)/i;
const weightRegex = /weight/i;
const otherRegex = /other/i;

class Measurements extends React.Component {
  constructor(props) {
    super(props);

    let measurementTypes = config.get("animal_measurement_types").map((m) => {
      return {text: m, value: m};
    });
    measurementTypes.push({text: "N/A", value: "N/A"});

    const initialMeasurements = props.value
      ? props.value.map((m, i) => {
          let unitOptions = [];
          if (lengthRegex.test(m.measurementType)) {
            unitOptions = lengthUnits;
          } else if (weightRegex.test(m.measurementType)) {
            unitOptions = weightUnits;
          }
          m.comments = m.comments ?? "";
          return {...m, unitOptions: unitOptions};
        })
      : [];

    this.state = {
      measurementTypes: measurementTypes,
      measurements: props.value
        ? [...initialMeasurements, newMeasurement]
        : [newMeasurement]
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.checkValid = this.checkValid.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAddUnit = this.handleAddUnit.bind(this);
  }

  handleTypeChange(e, {value, index}) {
    let measurements = this.state.measurements;
    if (value === "N/A") {
      measurements = [];
      measurements[0] = {
        measurementType: value,
        value: "",
        unit: null,
        comments: "",
        unitOptions: units
      };
    } else {
      measurements[index] = {...newMeasurement};
      measurements[index].measurementType = value;
      if (lengthRegex.test(value)) {
        measurements[index].unitOptions = lengthUnits;
        measurements[index].unit = "cm";
      } else if (weightRegex.test(value)) {
        measurements[index].unitOptions = weightUnits;
        measurements[index].unit = "g";
      }
      if (measurements[measurements.length - 1].measurementType) {
        measurements.push({...newMeasurement});
      }
    }

    this.setState(
      {
        measurements: measurements
      },
      () => this.checkValid()
    );
  }

  handleValueChange(e, {value, index}) {
    const measurements = this.state.measurements;
    measurements[index].value = value;

    this.setState(
      {
        measurements: measurements
      },
      () => this.checkValid()
    );
  }

  handleUnitChange(e, {value, index}) {
    const measurements = this.state.measurements;
    measurements[index].unit = value;

    this.setState(
      {
        measurements: measurements
      },
      () => this.checkValid()
    );
  }

  handleCommentChange(e, {value, index}) {
    const measurements = this.state.measurements;
    measurements[index].comments = value;

    this.setState(
      {
        measurements: measurements
      },
      () => this.checkValid()
    );
  }

  validMeasurement(measurement) {
    if (measurement.measurementType === "N/A" || !measurement.measurementType) {
      return true;
    } else {
      return (
        (measurement.value > 0 || measurement.value.length > 0) &&
        measurement.unit
      );
    }
  }

  checkValid() {
    const isValid =
      this.state.measurements[0] &&
      this.state.measurements.reduce((accum, s) => {
        return s.measurementType ? accum && this.validMeasurement(s) : accum;
      }, true);

    if (isValid) {
      let measurements = [...this.state.measurements];
      if (!measurements[measurements.length - 1].measurementType) {
        measurements.pop();
      }
      this.props.onChange(true, measurements);
    } else {
      this.props.onChange(false);
    }
  }

  handleRemove(e, {index}) {
    let measurements = this.state.measurements;

    if (index === measurements.length - 1) {
      measurements[index] = {...newMeasurement};
    } else {
      measurements.splice(index, 1);
      if (measurements[measurements.length - 1].measurementType)
        measurements.push({...newMeasurement});
    }

    this.setState(
      {
        measurements: measurements
      },
      () => this.checkValid()
    );
  }

  handleAddUnit(e, {value, index}) {
    const newOptions = this.state.measurements[index].unitOptions;
    newOptions.push({text: value, value: value});

    const newMeasurement = this.state.measurements[index];
    newMeasurement.unitOptions = newOptions;

    this.setState({
      measurements: Object.values({
        ...this.state.measurements,
        [index]: newMeasurement
      })
    });
  }

  render() {
    const {
      handleTypeChange,
      handleValueChange,
      handleUnitChange,
      handleCommentChange,
      handleRemove,
      handleAddUnit
    } = this;
    const {measurements, measurementTypes} = this.state;
    const {readOnly} = this.props;

    const tableBody = readOnly
      ? measurements.map((m, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell>
                <Form.Input
                  fluid
                  transparent
                  readOnly
                  value={m.measurementType ?? "N/A"}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Input fluid transparent readOnly value={m.value ?? ""} />
              </Table.Cell>
              <Table.Cell>
                <Form.Input fluid transparent readOnly value={m.unit ?? ""} />
              </Table.Cell>
              <Table.Cell>
                <Form.TextArea disabled value={m.comments ?? ""} rows={1} />
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          );
        })
      : measurements.map((m, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell>
                <Form.Dropdown
                  fluid
                  selection
                  required={i === 0}
                  options={measurementTypes}
                  placeholder="Select Type"
                  value={m.measurementType}
                  index={i}
                  onChange={handleTypeChange}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Input
                  fluid
                  type="number"
                  step="0.000001"
                  required={
                    measurements[i].measurementType &&
                    measurements[i].measurementType !== "N/A"
                  }
                  disabled={
                    !measurements[i].measurementType ||
                    measurements[0].measurementType === "N/A"
                  }
                  placeholder="Value"
                  onChange={handleValueChange}
                  value={m.value}
                  index={i}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Dropdown
                  fluid
                  selection
                  required={
                    measurements[i].measurementType &&
                    measurements[i].measurementType !== "N/A"
                  }
                  disabled={
                    !measurements[i].measurementType ||
                    measurements[0].measurementType === "N/A"
                  }
                  options={m.unitOptions}
                  placeholder="Select Unit"
                  value={m.unit}
                  index={i}
                  onChange={handleUnitChange}
                  allowAdditions={otherRegex.test(m.measurementType)}
                  search={otherRegex.test(m.measurementType)}
                  onAddItem={handleAddUnit}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.TextArea
                  disabled={
                    !measurements[i].measurementType ||
                    measurements[0].measurementType === "N/A"
                  }
                  value={m.comments}
                  index={i}
                  onChange={handleCommentChange}
                  rows={1}
                />
              </Table.Cell>
              <Table.Cell>
                {i !== 0 && measurements[i].measurementType ? (
                  <Icon
                    name="remove"
                    color="red"
                    onClick={handleRemove}
                    size="large"
                    index={i}
                    className=".at-list-icon at-list-icon"
                  />
                ) : null}
              </Table.Cell>
            </Table.Row>
          );
        });

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>Type</Table.HeaderCell>
              <Table.HeaderCell width={4}>Value</Table.HeaderCell>
              <Table.HeaderCell width={4}>Unit</Table.HeaderCell>
              <Table.HeaderCell width={4}>Comments</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableBody}</Table.Body>
        </Table>
      </>
    );
  }
}

Measurements.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func
};

export default Measurements;
