import isPlainObject from "react-redux/lib/utils/isPlainObject";

const errorHeaders = {
  errorHeader500: "Something went wrong.",
  errorHeader504: "Timeout.",
  errorHeader400: "You have errors in your submission.",
  errorHeader401: "Unauthorised.",
  errorHeader404: "The data was not found.",
  errorHeader409: "Conflict."
};

let responseErrors = {
  messages: [],
  header: ""
};

const stripHTML = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  let msg = doc.body.innerText.trim();
  return !html || msg === "" ? "No error message" : msg;
};

export const getAPIErrorMessages = (responseData, errorCode) => {
  responseErrors.messages = [];

  if (Array.isArray(responseData)) {
    responseErrors.messages = [...responseErrors.messages, ...responseData];
  } else if (isPlainObject(responseData)) {
    if (typeof responseData.message !== "undefined") {
      responseErrors.messages.push(responseData.message);
    }
    if (typeof responseData.errors !== "undefined") {
      responseErrors.messages = [
        ...responseErrors.messages,
        ...responseData.errors
      ];
    }
  } else {
    // This may not be from the API but a proxy for example
    responseErrors.messages.push(stripHTML(responseData));
  }

  responseErrors.header = errorHeaders["errorHeader" + errorCode];

  return responseErrors;
};

export const prepareReceiverRecovery = (recoveryDetails) => {
  const status = recoveryDetails.recovery
    ? recoveryDetails.recovery.status
    : recoveryDetails.status;

  if (status === "LOST") {
    if (recoveryDetails.recovery) {
      const recovery = recoveryDetails.recovery;
      delete recovery.latitude;
      delete recovery.longitude;
      recoveryDetails.recovery = {...recovery};
    } else {
      delete recoveryDetails.latitude;
      delete recoveryDetails.longitude;
    }
  }

  return recoveryDetails;
};
