import React from "react";
import FilteredTable from "./FilteredTable";
import axios from "axios";
import {
  organisationById,
  user,
  userById,
  userListConfig
} from "../axios/api";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {FormMode, getEntityDetailLists} from "./helpers";
import UserProfile from "./UserProfile";
import PasswordReset from "./PasswordReset";
import FilterDropDown from "./FilterDropDown";
import ProjectList from "./ProjectList";

class UserTable extends React.Component {
  actions = [
    {name: "Edit User", onClick: () => this.setState({showModal: "edit"})},
    {name: "View User", onClick: () => this.setState({showModal: "view"})}
  ];

  tableColumns = [
    {
      title: "Full Name",
      dataSource: "name",
      default: "",
      width: "15%",
      isAPIFilter: true,
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "user",
      filter: () => "id"
    },
    {
      title: "Username",
      width: "10%",
      dataSource: "username"
    },
    {
      title: "Email address",
      dataSource: "emailAddress",
      default: ""
    },
    {
      title: "Organisation",
      dataSource: "organisationName",
      default: "",
      component: {
        control: FilterDropDown
      },
      isAPIFilter: true,
      filterOptionsKey: "organisation",
      filter: () => "organisationId"
    },
    {
      title: "Projects",
      dataSource: "projects",
      field: "name",
      icon: "tasks",
      default: [],
      detail: true,
      renderAs: ProjectList
    },
    {
      title: "Status",
      dataSource: "status",
      width: "10%",
      icons: {
        PENDING: {color: "yellow", name: "circle"},
        ACTIVE: {color: "green", name: "circle"},
        DEACTIVATED: {color: "red", name: "circle"}
      }
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      showModal: "none"
    };
    this.getUserDetail = this.getUserDetail.bind(this);
    this.getUser = this.getUser.bind(this);
    this.modalOpener = this.modalOpener.bind(this);
  }

  componentWillUnmount() {
    if (this.userCt) this.userCt.cancel();
  }

  getUserDetail(id) {
    let detailColumns = this.tableColumns.filter((item) => {
      return item.detail;
    });

    return userById(id)
      .then((result) => {
        this.setState({userData: id});
        const detail = getEntityDetailLists(result.data, detailColumns);
        return organisationById(result.data.organisationId)
          .then((o) => {
            return {
              row: {...result.data, organisationName: o.value.name},
              ...detail
            };
          })
          .catch((result) => {
            return result;
          });
      })
      .catch((result) => {
        return result;
      });
  }

  getUser(pageSize, page, filter) {
    if (this.userCt) this.userCt.cancel();
    this.userCt = axios.CancelToken.source();
    return user(
      {size: pageSize, page: page, ...filter},
      this.userCt.token
    ).then((result) => {
      this.userCt = null;
      let promises = result.data.content.map((currentUser) => {
        return {...currentUser};
      });
      return Promise.all(promises).then((users) => {
        return {...result.data};
      });
    });
  }

  modalOpener(modalName) {
    this.setState({showModal: modalName});
  }

  render() {
    const {getUser, tableColumns, getUserDetail, actions, modalOpener} = this;
    const {roles, accessToken} = this.props;
    const {focusQuery, showModal, userData} = this.state;

    if (accessToken === null || !roles.includes("ROLE_ADMIN")) {
      return <Redirect to={{pathname: "/"}} />;
    }

    return (
      <>
        {showModal === "resetPassword" ? (
          <PasswordReset
            open
            onClose={() => {
              this.setState({showModal: "none"});
            }}
          />
        ) : null}

        {showModal === "edit" ? (
          <UserProfile
            open
            modalOpener={modalOpener}
            onClose={() => {
              this.setState({showModal: "none"});
            }}
            onSuccess={(s) => {
              this.setState({focusQuery: {id: s.id}});
            }}
            mode={FormMode.EDIT}
            id={userData}
          />
        ) : null}

        {showModal === "view" ? (
          <UserProfile
            open
            modalOpener={modalOpener}
            onClose={() => {
              this.setState({showModal: "none", focusQuery: {id: userData}});
            }}
            mode={FormMode.VIEW}
            id={userData}
          />
        ) : null}

        <FilteredTable
          header="Users"
          headerIcon="user"
          columns={tableColumns}
          filterOptionsFetcher={userListConfig}
          fetcher={getUser}
          detailFetcher={getUserDetail}
          detailKey="id"
          pageSize={50}
          icon="user"
          actions={actions}
          focus={focusQuery}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles
  };
}

export default connect(mapStateToProps)(UserTable);
