import React from "react";
import {connect} from "react-redux";
import Logout from "./Logout";
import {Link} from "react-router-dom";
import {Icon, Segment} from "semantic-ui-react";
import {userLogout} from "../actions";

class UserLoginState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loggedIn: false};
    this.checkTokenExpiry = this.checkTokenExpiry.bind(this);
    this.checkToken = this.checkToken.bind(this);

    window.addEventListener("storage", (e) => {
      if (e.key === "persist:root") {
        const newToken = JSON.parse(JSON.parse(e.newValue).user).accessToken;
        const oldToken = JSON.parse(JSON.parse(e.oldValue).user).accessToken;
        if (newToken !== oldToken) {
          if (!newToken) {
            this.props.userLogout();
            window.location = "/";
          } else {
            window.location.reload();
          }
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.accessToken !== prevProps.user.accessToken) {
      this.checkToken();
    }
  }

  componentDidMount() {
    this.checkTokenExpiry();
    this.checkToken();
  }

  componentWillUnmount() {
    if (this.state.intervalId) clearInterval(this.state.intervalId);
  }

  checkTokenExpiry() {
    if (this.props.user.tokenExp < Math.floor(Date.now() / 1000)) {
      this.props.userLogout();
    }
  }

  checkToken() {
    if (this.props.user.accessToken) {
      const intervalId = setInterval(this.checkTokenExpiry, 1000);
      this.setState({intervalId: intervalId, loggedIn: true});
    } else {
      if (this.state.intervalId) clearInterval(this.state.intervalId);
      this.setState({intervalId: null, loggedIn: false});
    }
  }

  render() {
    const {username} = this.props.user;
    return (
      <Segment
        compact
        textAlign="center"
        className="at-user-login-state"
        raised
      >
        {!this.state.loggedIn ? (
          <>
            <p>
              <Icon name="user" size="large" /> Log in to use database
            </p>
            <Link to="/register" data-testid="register">
              Register
            </Link>
            &nbsp;|&nbsp;
            <Link to="/login" data-testid="login">
              Log in
            </Link>
          </>
        ) : (
          <>
            <p>
              <Icon name="user" size="large" /> Logged in as&nbsp;
              <Link to="/user-profile" data-testid="me">
                {username}
              </Link>
            </p>
            <Logout />
          </>
        )}
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    user: user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogout: () => dispatch(userLogout())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLoginState);
