import React from "react";
import {connect} from "react-redux";
import FormModal from "./FormModal";
import {
  installation,
  stationById,
  stationUpdate,
  stationExists
} from "../axios/api";
import {Form, Message} from "semantic-ui-react";
import Location from "./Location";
import {getOptions} from "./helpers";

const defaultState = {
  station: {
    id: null,
    name: "",
    installationId: null,
    longitude: "",
    latitude: "",
    version: 0
  },
  projectName: "",
  installationOptions: [],
  locationValid: true,
  locationExists: false,
  success: false,
  failed: false
};

class StationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
    this.failedAction = this.failedAction.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleInstallationChange = this.handleInstallationChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.updateLocationState = this.updateLocationState.bind(this);
    this.getStation = this.getStation.bind(this);
    this.initialiseForm = this.initialiseForm.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
  }

  componentDidMount() {
    this.getStation();
  }

  checkFormValid() {
    const {station, locationValid} = this.state;
    const isValid = Number.isInteger(station.installationId) && locationValid;
    return !isValid;
  }

  successAction(station) {
    this.setState(
      {
        success: true,
        failed: false,
        station: {
          ...this.state.station,
          version: station.version
        }
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.station);
        }
      }
    );
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose(this.state);
      this.setState(defaultState);
    }
  }

  async getStation() {
    this.setState({loading: true});

    let query = {activeProjectSummary: true};

    const projectQuery = (this.props.roles.includes("ROLE_ADMIN") || this.props.roles.includes("ROLE_ATF_ADMIN"))
      ? {}
      : this.props.projects.length > 0
      ? {projectIdList: this.props.projects.map((p) => p.id).join(",")}
      : null;

    stationById(this.props.station).then((s) => {
      if (projectQuery) {
        query = {...query, ...projectQuery};
        getOptions(
          installation,
          {text: "name", value: "id"},
          undefined,
          query
        ).then((allInstallations) => {
          this.initialiseForm(s.data, allInstallations);
        });
      } else {
        this.initialiseForm(s.data, []);
      }
    });
  }

  initialiseForm(station, installations) {
    this.setState({
      station: {
        id: station.id,
        name: station.name,
        installationId: station.installation.id,
        longitude: station.location.longitude,
        latitude: station.location.latitude,
        version: station.version
      },
      projectName: station.installation.project.name,
      installationOptions: installations,
      loading: false
    });
  }

  handleInstallationChange(e, {value, options}) {
    const projectName = options.find((o) => o.value === value).object
      .projectName;

    this.setState({
      projectName: projectName,
      station: {
        ...this.state.station,
        installationId: value
      }
    });
  }

  handleLocationChange(e, value) {
    if (value.latitude && value.longitude)
      stationExists(`${value.latitude},${value.longitude}`).then((res) => {
        const exists = res.data.exists;
        this.updateLocationState(exists, value);
      });
    else this.updateLocationState(false, value);
  }

  updateLocationState(exists, value) {
    this.setState({
      locationExists: exists,
      locationValid: value.valid,
      station: {
        ...this.state.station,
        longitude: parseFloat(value.longitude),
        latitude: parseFloat(value.latitude)
      }
    });
  }

  handleNameChange(e) {
    this.setState({
      station: {
        ...this.state.station,
        name: e.target.value
      }
    });
  }

  render() {
    const {
      checkFormValid,
      successAction,
      onClose,
      handleInstallationChange,
      handleLocationChange,
      handleNameChange,
      failedAction,
      onOpen
    } = this;
    const {
      station,
      success,
      failed,
      installationOptions,
      projectName,
      locationExists,
      loading
    } = this.state;

    const formInputs = (
      <>
        <Form.Input
          fluid
          autoComplete="none"
          placeholder="Station Name"
          label="Station Name"
          value={station.name}
          onChange={handleNameChange}
        />
        <Form.Dropdown
          placeholder="Installation Name"
          label="Installation Name"
          required
          search
          selection
          options={installationOptions.sort(function(a,b){return a.text > b.text ? 1 : -1})}
          onChange={handleInstallationChange}
          value={station.installationId}
        />
        <Form.Input
          fluid
          readOnly
          transparent
          label="Project Name"
          value={projectName}
        />
        <Form.Field
          control={Location}
          label="Location"
          required
          warn={locationExists}
          warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
          value={{longitude: station.longitude, latitude: station.latitude}}
          onChange={handleLocationChange}
        />
        <Message
            color="orange"
            content="Warning: Updating station coordinates after initial creation will automatically update coordinates of all receiver deployments and recoveries that are associated with, and identical to, the station."
        />
      </>
    );

    const successContent = <p>Station {station.name} saved.</p>;

    return (
      <FormModal
        formSubmitAPI={stationUpdate}
        formData={station}
        checkFormValid={checkFormValid}
        testId="station-form"
        successHeader="Confirmation"
        onSuccess={successAction}
        onFail={failedAction}
        success={success}
        failed={failed}
        formInputs={formInputs}
        headerIcon="dot circle"
        successContent={successContent}
        headerContent="Edit Station"
        submitContent="Save Changes"
        onClose={onClose}
        onOpen={onOpen}
        open
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    roles: user.roles,
    userOrganisation: user.organisationId,
    userId: user.userId,
    username: user.username,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(StationEdit);
