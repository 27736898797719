import React from "react";
import {List} from "semantic-ui-react";
import {Link} from "react-router-dom";

class OrganisationList extends React.Component {

  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler(e) {
    if (this.props.onClick) this.props.onClick();
  }

  render() {

    const { onClickHandler } = this;
    const { data } = this.props;
    const header = data.length > 1 ? `Organisations (${data.length})` : `Organisations`

    return <>
      <strong>{header}</strong>
      <List>
        {data.map((organisation, key) => {
          return <List.Item key={key}><Link to={`/organisation/${organisation.id}`} onClick={onClickHandler} >{organisation.name}</Link></List.Item>
        })}
      </List>
    </>

  }

}

export default OrganisationList;
