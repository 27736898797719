import React from "react";
import {Form, Grid, Label} from "semantic-ui-react";
import PhoneCode from "react-phone-code";
import parsePhoneNumberFromString from "libphonenumber-js";
import PropTypes from "prop-types";

// Phone number
const phoneMessage = "Phone number is invalid";
const phoneError = {
  content: phoneMessage,
  pointing: "below"
};

class PhoneNumber extends React.Component {
  constructor(props) {
    super(props);

    // If a value is passed in, display this value initially
    if (this.props.value) {
      this.state = {
        phoneNotValid: false,
        nationalNumber: this.props.value,
        country: null,
        countryCallingCode: ""
      };
    } else {
      this.state = {
        phoneNotValid: false,
        nationalNumber: "",
        country: "AU",
        countryCallingCode: "+61"
      };
    }

    this.handlePhoneBlur = this.handlePhoneBlur.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  componentDidMount() {
    this.setPhoneNumberState(this.props.value);
  }

  parsePhoneNumberFromString(number) {
    // parsePhoneNumberFromString may throw unhandled errors
    let phoneNumber;
    try {
      phoneNumber = parsePhoneNumberFromString(number, "AU");
    } catch (error) {
      phoneNumber = undefined;
      console.log(error);
      console.log(`The value to parse was: ${number}`);
    } finally {
      return phoneNumber;
    }
  }

  setPhoneNumberState(number) {
    const phoneNumber = this.parsePhoneNumberFromString(number);
    if (typeof phoneNumber !== "undefined") {
      const country = phoneNumber.country;
      this.setState({
        phoneNotValid: false,
        nationalNumber: phoneNumber.nationalNumber,
        country: country,
        countryCallingCode: "+" + phoneNumber.countryCallingCode
      });
    }
  }

  validatePhoneNumber() {
    const phoneNumber = this.parsePhoneNumberFromString(
      this.state.countryCallingCode + this.state.nationalNumber
    );
    if (phoneNumber && phoneNumber.isPossible()) {
      this.setState(
        {phoneNotValid: phoneNumber.isValid() ? false : phoneError},
        () =>
          this.props.onValidate(
            this.state.countryCallingCode + this.state.nationalNumber,
            phoneNumber.isValid()
          )
      );
    } else {
      this.setState({phoneNotValid: false}, () =>
        this.props.onValidate(
          this.state.countryCallingCode + this.state.nationalNumber,
          true
        )
      );
    }
  }

  handlePhoneBlur(e) {
    this.validatePhoneNumber();
  }

  handlePhoneChange(e, {value}) {
    const info = isNaN(value)
      ? `You entered "${value}". Must be numbers only.`
      : false;
    value = value.replace(/\D/g, "");
    this.setState({nationalNumber: value, information: info}, () => {
      this.props.onValidate(
        this.state.countryCallingCode + this.state.nationalNumber
      );
    });
  }

  handleCountryChange(value) {
    const countryCode = value.split("-")[0];
    this.setState({countryCallingCode: countryCode}, () =>
      this.props.onValidate(
        this.state.countryCallingCode + this.state.nationalNumber
      )
    );
  }

  handleCountryCallingCodeClick(e) {
    e.preventDefault();
  }

  render() {
    const {readOnly} = this.props;
    const {
      handlePhoneBlur,
      handlePhoneChange,
      handleCountryCallingCodeClick,
      handleCountryChange
    } = this;
    const {
      country,
      nationalNumber,
      phoneNotValid,
      countryCallingCode,
      information
    } = this.state;

    const countryField =
      readOnly || !country ? null : (
        <Form.Field
          required
          label="Country Calling Code"
          control={PhoneCode}
          onSelect={handleCountryChange}
          showFirst={[country]}
          defaultValue="Select Country Calling Code"
          data-testid="country"
        />
      );

    const action = readOnly
      ? null
      : {content: countryCallingCode, onClick: handleCountryCallingCodeClick};

    const info = information ? (
      <Label pointing="above" content={information} />
    ) : null;

    return (
      <>
        <Form.Group widths="equal">
          {countryField}
          <Form.Input
            autoComplete="none"
            readOnly={readOnly}
            transparent={readOnly}
            value={
              readOnly ? countryCallingCode + nationalNumber : nationalNumber
            }
            fluid
            required={!readOnly}
            label="Phone number"
            placeholder="Phone number"
            error={phoneNotValid}
            onBlur={handlePhoneBlur}
            onChange={handlePhoneChange}
            action={action}
            actionPosition="left"
            data-testid="phonenumber"
          />
        </Form.Group>
        <Grid columns={2}>
          <Grid.Row className="info">
            <Grid.Column></Grid.Column>
            <Grid.Column className="phone">{info}</Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

PhoneNumber.propTypes = {
  onValidate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
};

export default PhoneNumber;
