import React from "react";
import {connect} from "react-redux";
import {
  Button,
  Form,
  Grid,
  GridColumn,
  Label,
  Message,
  Segment
} from "semantic-ui-react";
import PropTypes from "prop-types";
import TransmitterSummary from "./TransmitterSummary";
import FormModal from "./FormModal";
import Location from "./Location";
import DateSelector from "./DateSelector";
import config from "react-global-configuration";
import {
  tagRecoveryDelete,
  tagDeploymentDelete,
  transmitterDeleteByTagId
} from "../axios/api";
import Measurements from "./Measurements";
import {Link} from "react-router-dom";
import {fromUTC, isAdmin, noReleaseSensorTypes} from "./helpers";
import ProjectSelect from "./ProjectSelect";

class TransmitterReleaseRecoveryDelete extends React.PureComponent {
  originalState = {};

  constructor(props) {
    super(props);

    const existingMeasurements = props.transmitter.releasePostDto.measurements;
    const measurements = existingMeasurements
      ? Object.keys(existingMeasurements).map((m, i) => {
          return {
            measurementType: existingMeasurements[i].measurementType,
            value: existingMeasurements[i].value,
            unit: existingMeasurements[i].unit,
            comments: existingMeasurements[i].comments
          };
        })
      : [];
    this.state = {
      viewLabel: `Transmitter Details`,
      success: false,
      transmitter: props.transmitter,
      release: {
        ...props.transmitter.releasePostDto,
        id: props.transmitter.releasePostDto.id,
        tagId: props.transmitter.releasePostDto.tagId,
        projectId: props.transmitter.releasePostDto.projectId,
        measurements: measurements,
        description: `${props.transmitter.tagPostDto.speciesScientificName} ${props.transmitter.tagPostDto.speciesName}`,
        comments: props.transmitter.releasePostDto.comments
      },
      recovery: {...props.transmitter.recoveryPostDto}
    };
    this.originalState = {...this.state};
    this.sexes = config.get("animal_sex").map((s) => {
      return {text: s, value: s};
    });
    this.surgeryTypes = config.get("surgery_type").map((s) => {
      return {text: s, value: s};
    });

    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.failedAction = this.failedAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
  }

  onClose() {
    this.setState({...this.originalState});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    let valid = true;
    return !valid;
  }

  successAction(response) {
    this.setState({
      success: true,
      failed: false
    });
    if (this.props.onUpdate) {
      this.props.onUpdate({
        ...response
      });
    }
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  handleProjectChange(e, {value}) {
    this.setState((s) => {
      return {
        release: {
          ...s.release,
          projectId: value
        }
      };
    });
  }

  render() {
    const {
      successAction,
      failedAction,
      checkFormValid,
      onClose,
      handleProjectChange
    } = this;
    const {success, release, recovery, transmitter} = this.state;
    const {
      roles,
      openViewForTag,
      deleteTransmitter,
      deleteRecovery,
      deleteDeployment
    } = this.props;

    let formInputs = null;
    let passiveElements = null;
    let endpoint = tagRecoveryDelete;
    let formData = release;
    let successContent = `Recovery Deleted`;
    let submitContent = `Delete Recovery`;
    let modalLabel = "Delete Transmitter Recovery";

    const headerRow = (
      <Segment>
        {deleteTransmitter ? (
          <Message
            info
            header="Notes"
            content="This will also delete the below deployment"
          />
        ) : null}
        <TransmitterSummary value={this.props.transmitter.tagPostDto} />
        {this.props.openViewForTag ? (
          <Button primary onClick={openViewForTag}>
            View Transmitter
          </Button>
        ) : null}
      </Segment>
    );

    const hideAnimalRelease = noReleaseSensorTypes.includes(
      this.props.transmitter?.tagPostDto.sensors[0].sensorTypeName
    );

    if (deleteDeployment) {
      endpoint = tagDeploymentDelete;
      formData = release;
      successContent = `Deployment Deleted`;
      submitContent = `Delete Deployment`;
      modalLabel = "Delete Transmitter Deployment";

      formInputs = !success ? (
        <Segment>
          <Label attached="top left">Release</Label>
          {hideAnimalRelease ? null : (
            <>
              <ProjectSelect
                value={release.projectId}
                onChange={handleProjectChange}
                showAll={isAdmin(roles)}
                loadingText="Loading"
                readOnly
              />
              <Form.Input
                fluid
                transparent
                readOnly
                label="Species Name"
                value={release.description}
              />
              <Form.Input
                fluid
                transparent
                readOnly
                label="Sex"
                value={release.sex}
              />
              <Form.Input
                fluid
                readOnly
                transparent
                label="Placement"
                value={release.surgeryType}
              />
              <Form.Field
                control={Measurements}
                label="Measurements"
                readOnly
                fluid
                value={release.measurements}
              />
            </>
          )}
          <Form.Input
            fluid
            readOnly
            label="Deployment locality"
            placeholder="Deployment locality"
            value={release.releaseLocality}
          />
          <Form.Field
            control={Location}
            label="Deployment Location"
            readOnly
            value={{
              longitude: release.longitude,
              latitude: release.latitude
            }}
          />
          <DateSelector
            readOnly
            timezone={release.releaseTimezone}
            date={fromUTC(
              {
                timestamp: release.releaseDate,
                timezone: release.releaseTimezone
              },
              "YYYY-MM-DDTHH:mm:ss.SSS"
            )}
            label="Deployment Date and Timezone"
          />
          <Form.TextArea
            readOnly
            label="Comments"
            defaultValue={transmitter.releasePostDto.comments}
          />
          {hideAnimalRelease ? null : (
            <Grid columns={1}>
              <GridColumn>
                <Link target="_blank" to="/datapolicy">
                  Data policy document
                </Link>
              </GridColumn>
            </Grid>
          )}
        </Segment>
      ) : null;
      passiveElements =
        !success && transmitter.recoveryPostDto?.recoveryDate ? (
          <Segment>
            <Label attached="top left">Recovery</Label>
            <Form.Input
              fluid
              transparent
              readOnly
              label="Project"
              value={transmitter.tagPostDto.project.name}
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Serial Number"
              value={transmitter.tagPostDto.serialNumber}
            />
            <Form.Field
              control={Location}
              label="Recovery Location"
              readOnly
              value={{
                longitude: transmitter.recoveryPostDto.longitude,
                latitude: transmitter.recoveryPostDto.latitude
              }}
            />
            <DateSelector
              readOnly
              date={fromUTC(
                {
                  timestamp: transmitter.recoveryPostDto.recoveryDate,
                  timezone: transmitter.recoveryPostDto.recoveryTimezone
                },
                "YYYY-MM-DDTHH:mm:ss.SSS"
              )}
              timezone={transmitter.recoveryPostDto.recoveryTimezone}
              label="Recovery Date and Timezone"
            />
          </Segment>
        ) : null;
    }

    if (deleteTransmitter) {
      endpoint = transmitterDeleteByTagId;
      formData = transmitter.tagPostDto;
      successContent = `Transmitter Deleted`;
      submitContent = `Delete Transmitter`;
      modalLabel = "Delete Transmitter";

      formInputs = !success ? (
        <Segment>
          <Label attached="top left">Release</Label>
          {hideAnimalRelease ? null : (
            <>
              <ProjectSelect
                value={release.projectId}
                onChange={handleProjectChange}
                showAll={isAdmin(roles)}
                loadingText="Loading"
                readOnly
              />
              <Form.Input
                fluid
                transparent
                readOnly
                label="Species Name"
                value={release.description}
              />
              <Form.Input
                fluid
                transparent
                readOnly
                label="Sex"
                value={release.sex}
              />
              <Form.Input
                fluid
                readOnly
                transparent
                label="Placement"
                value={release.surgeryType}
              />
              <Form.Field
                control={Measurements}
                label="Measurements"
                readOnly
                fluid
                value={release.measurements}
              />
            </>
          )}
          <Form.Input
            fluid
            readOnly
            label="Deployment locality"
            placeholder="Deployment locality"
            value={release.releaseLocality}
          />
          <Form.Field
            control={Location}
            label="Deployment Location"
            readOnly
            value={{
              longitude: release.longitude,
              latitude: release.latitude
            }}
          />
          <DateSelector
            readOnly
            timezone={release.releaseTimezone}
            date={fromUTC(
              {
                timestamp: release.releaseDate,
                timezone: release.releaseTimezone
              },
              "YYYY-MM-DDTHH:mm:ss.SSS"
            )}
            label="Deployment Date and Timezone"
          />
          <Form.TextArea
            readOnly
            label="Comments"
            defaultValue={transmitter.releasePostDto.comments}
          />
          {hideAnimalRelease ? null : (
            <Grid columns={1}>
              <GridColumn>
                <Link target="_blank" to="/datapolicy">
                  Data policy document
                </Link>
              </GridColumn>
            </Grid>
          )}
        </Segment>
      ) : null;
      passiveElements =
        !success && transmitter.recoveryPostDto?.recoveryDate ? (
          <Segment>
            <Label attached="top left">Recovery</Label>
            <Form.Input
              fluid
              transparent
              readOnly
              label="Project"
              value={transmitter.tagPostDto.project.name}
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Serial Number"
              value={transmitter.tagPostDto.serialNumber}
            />
            <Form.Field
              control={Location}
              label="Recovery Location"
              readOnly
              value={{
                longitude: transmitter.recoveryPostDto.longitude,
                latitude: transmitter.recoveryPostDto.latitude
              }}
            />
            <DateSelector
              readOnly
              date={fromUTC(
                {
                  timestamp: transmitter.recoveryPostDto.recoveryDate,
                  timezone: transmitter.recoveryPostDto.recoveryTimezone
                },
                "YYYY-MM-DDTHH:mm:ss.SSS"
              )}
              timezone={transmitter.recoveryPostDto.recoveryTimezone}
              label="Recovery Date and Timezone"
            />
          </Segment>
        ) : null;
    }

    if (this.props.deleteRecovery) {
      formInputs = !success ? (
        <Segment>
          <Label attached="top left">Recovery</Label>
          <>
            <Form.Field
              control={Location}
              label="Recovery Location"
              readOnly
              value={{
                longitude: recovery.longitude,
                latitude: recovery.latitude
              }}
            />
            <DateSelector
              readOnly
              label="Recovery Date and Timezone"
              timezone={recovery.recoveryTimezone}
              date={fromUTC(
                {
                  timestamp: transmitter.recoveryPostDto.recoveryDate,
                  timezone: transmitter.recoveryPostDto.recoveryTimezone
                },
                "YYYY-MM-DDTHH:mm:ss.SSS"
              )}
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Status"
              value={recovery.status}
            />
            <Form.TextArea
              label="Comments"
              defaultValue={recovery.comments}
              readOnly
            />
          </>
        </Segment>
      ) : null;

      passiveElements = !success ? (
        <>
          <Segment>
            <Label attached="top left">Release</Label>
            {hideAnimalRelease ? null : (
              <>
                <ProjectSelect
                  readOnly
                  value={release.projectId}
                  onChange={handleProjectChange}
                  showAll={isAdmin(roles)}
                  loadingText="Loading"
                />
                <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Species"
                  value={release.description}
                />
                <Form.Input fluid transparent readOnly value={release.sex} />
                <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Placement"
                  value={release.surgeryType}
                />
                <Form.Field
                  control={Measurements}
                  label="Measurements"
                  readOnly
                  fluid
                  value={release.measurements}
                />
              </>
            )}

            <Form.Input
              fluid
              transparent
              readOnly
              label="Deployment locality"
              value={release.releaseLocality}
            />
            <Form.Field
              control={Location}
              label="Deployment Location"
              readOnly
              value={{
                longitude: release.longitude,
                latitude: release.latitude
              }}
            />
            <DateSelector
              required
              readOnly
              timezone={release.releaseTimezone}
              date={fromUTC(
                {
                  timestamp: release.releaseDate,
                  timezone: release.releaseTimezone
                },
                "YYYY-MM-DDTHH:mm:ss.SSS"
              )}
              label="Deployment Date and Timezone"
            />
            <Form.TextArea
              label="Comments"
              readOnly
              defaultValue={transmitter.releasePostDto.comments}
            />
            {hideAnimalRelease ? null : (
              <Grid columns={1}>
                <GridColumn>
                  <Link target="_blank" to="/datapolicy">
                    Data policy document
                  </Link>
                </GridColumn>
              </Grid>
            )}
          </Segment>
        </>
      ) : null;
    }

    return (
      <FormModal
        open
        headerRow={!success ? headerRow : null}
        passiveColumn={deleteRecovery ? 1 : 2}
        passiveElements={passiveElements}
        formSubmitAPI={!success ? endpoint : false}
        formData={formData}
        checkFormValid={checkFormValid}
        testId="transmitter-release-recovery-delete"
        successHeader="Success"
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerIcon="trash"
        successContent={successContent}
        headerContent={modalLabel}
        submitContent={submitContent}
        onClose={onClose}
        onFail={failedAction}
      />
    );
  }
}

TransmitterReleaseRecoveryDelete.propTypes = {
  onClose: PropTypes.func.isRequired,
  openViewForTag: PropTypes.func,
  onUpdate: PropTypes.func,
  transmitter: PropTypes.any.isRequired,
  deleteDeployment: PropTypes.bool,
  deleteRecovery: PropTypes.bool,
  deleteTransmitter: PropTypes.bool
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(TransmitterReleaseRecoveryDelete);
