import { call, put, takeEvery } from "redux-saga/effects";
import { userLogin } from "../axios/api";
import {
  USER_DETAIL,
} from "../constants/action-types";

function doUserLogin(username, password) {
  return userLogin(username, password);
}

function* userLoginWorker(action) {
  try {
    let resp = yield call(
      doUserLogin,
      action.username,
      action.password
    );
    yield put({
      type: USER_DETAIL,
      payload: { username: action.username, response: resp.data }
    });
  } catch (e) {
  }
}

function* mySaga() {
  yield takeEvery(USER_DETAIL, userLoginWorker);
}

export default mySaga;
