import React from "react";
import {Image, Segment} from "semantic-ui-react";

export const ImageButton = (props) => {
  const size = props.primary ? null : "medium";
  return (
    <Segment basic className="at-image-button-container">
      <Image
        src={props.image}
        size={size}
        centered
        className={props.primary ? "at-image-button-shadow" : null}
        onClick={props.onClick}
      />
      <div
        primary={props.primary}
        className={`at-image-button-label ${props.size ??
          "large"} ${props.position ?? "center"}`}
        onClick={props.onClick}
      >
        {props.label}
      </div>
    </Segment>
  );
};

export default ImageButton;
