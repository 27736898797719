import React from "react";
import {connect} from "react-redux";
import {Form, Message} from "semantic-ui-react";
import {receiverById, receiverDelete} from "../axios/api";
import FormModal from "./FormModal";
import {canReceiverBeDeleted, isAdminNotATFAdmin} from "./helpers";

class ReceiverDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !!this.props.id
    };
    this.onClose = this.onClose.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      receiverById(this.props.id).then((res) => {
        const r = res.receiver;
        this.setState((s) => ({
          receiver: r,
          loading: false,
          canBeDeleted: canReceiverBeDeleted(r.id),
          userCanDelete: isAdminNotATFAdmin(this.props.roles)
        }));
      });
    }
  }

  checkFormValid() {
    const isValid = this.state.canBeDeleted && this.state.userCanDelete;
    return !isValid;
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  successAction() {
    this.setState(
      {
        success: true,
        failed: false
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.receiver.id);
        }
      }
    );
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  render() {
    const {
      receiver,
      success,
      loading,
      canBeDeleted,
      userCanDelete
    } = this.state;
    const {trigger} = this.props;
    const {checkFormValid, successAction, onClose, failedAction} = this;

    const successContent = `The receiver has been deleted.`;

    const canBeDeletedMessage = canBeDeleted ? null : (
      <p>
        The receiver cannot be deleted because it has either deployment or
        detection associated with it.
      </p>
    );
    const userCanDeleteMessage = userCanDelete ? null : (
      <p>You do not have permission to delete the installation.</p>
    );

    const messageContent = (
      <>
        {canBeDeletedMessage}
        {userCanDeleteMessage}
      </>
    );

    const formInputs =
      !loading && !success ? (
        <>
          <Message info header="Deleting receiver" content={messageContent} />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Receiver ID"
            value={`${receiver.deviceModelName}-${receiver.serialNumber}`}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Receiver Type"
            value={receiver.deviceModelName}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Serial Number"
            value={receiver.serialNumber}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Purchasing Organisation"
            value={receiver.organisationName}
          />
          {receiver.projectId ? (
            <Form.Input
              fluid
              transparent
              readOnly
              label="Project Name"
              value={receiver.projectName}
            />
          ) : null}
        </>
      ) : null;

    return (
      <FormModal
        formSubmitAPI={!success ? receiverDelete : false}
        submitContent={"Delete Receiver"}
        formData={receiver}
        checkFormValid={checkFormValid}
        onSuccess={successAction}
        onFail={failedAction}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        headerContent="Delete Receiver"
        successHeader="Receiver Deleted"
        successContent={successContent}
        onClose={onClose}
        open
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles,
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    userId: user.userId
  };
}

export default connect(mapStateToProps)(ReceiverDelete);
