import React from "react";
import {
  userRegistration,
  me,
  userById,
  userUpdate,
  meUpdate
} from "../axios/api";
import {Form, Label} from "semantic-ui-react";
import isPlainObject from "react-redux/lib/utils/isPlainObject";
import FormModal from "./FormModal";
import {Redirect} from "react-router-dom";
import {preventSpaces, FormMode, passwordValidate} from "./helpers";
import Password from "./Password";
import PhoneNumber from "./PhoneNumber";
import {connect} from "react-redux";
import OrganisationSelect from "./OrganisationSelect";
import ActionsMenu from "./ActionsMenu";
import ProjectList from "./ProjectList";
import {updateUser} from "../actions/index";

// Username
const usernameRegex = new RegExp(/[\W_]+/g, "");

// Password
const passwordRegex = passwordValidate.regex;
const passwordRegexMessage = passwordValidate.regexMessage;

// Email
const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const emailRegexMessage = "Please enter a valid email address";
const emailError = {
  content: emailRegexMessage,
  pointing: "below"
};

// Form headers
const formHeaders = {};
formHeaders[FormMode.CREATE] = "Create New User";
formHeaders[FormMode.EDIT] = "Edit User Profile";
formHeaders[FormMode.VIEW] = "User Profile";

// Save button
const saveButtonNames = {};
saveButtonNames[FormMode.CREATE] = "Create";
saveButtonNames[FormMode.EDIT] = "Save Changes";
saveButtonNames[FormMode.VIEW] = "Close";

const defaultUser = {
  username: "",
  name: "",
  emailAddress: "",
  password: "",
  phoneNumber: "",
  organisation: {
    name: "",
    type: "",
    state: "",
    suburb: "",
    country: ""
  },
  organisationId: -1,
  projects: [],
  unsubscribedToEmails: ""
};

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: props.open,
      loading: false,
      error: false,
      success: false,
      responseErrors: [],
      emailNotValid: false,
      phoneNotValid: false,
      passwordNotValid: false,
      showPassword: false,
      userDetails: defaultUser,
      mode: props.mode,
      redirect: null
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handleUnsubscribedToEmailsChange = this.handleUnsubscribedToEmailsChange.bind(
      this
    );
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleValidatedPassword = this.handleValidatedPassword.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleOrganisationChange = this.handleOrganisationChange.bind(this);
    this.initialiseForm = this.initialiseForm.bind(this);
    this.successAction = this.successAction.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.onClose = this.onClose.bind(this);
    this.initialise = this.initialise.bind(this);
  }

  componentDidMount() {
    this.initialise();
  }

  initialise() {
    if (
      this.state.mode === FormMode.VIEW ||
      this.state.mode === FormMode.EDIT
    ) {
      if (typeof this.props.id !== "undefined") {
        const {id} = this.props;
        userById(id).then((result) => {
          if (result) this.initialiseForm(result.data);
        });
      } else {
        me().then((result) => {
          if (result) this.initialiseForm(result.data);
        });
      }
    } else {
      this.initialiseForm(defaultUser);
    }
  }

  initialiseForm(userData) {
    userData.organisation = {value: userData.organisationId};
    this.setState(() => ({
      modalOpen: true,
      userDetails: {
        ...this.state.userDetails,
        ...userData
      }
    }));
  }

  handleFullNameChange(e) {
    let newValue = e.target.value;
    this.setState(() => ({
      userDetails: {
        ...this.state.userDetails,
        name: newValue
      }
    }));
  }

  handleUnsubscribedToEmailsChange(e, {checked}) {
    this.setState(() => ({
      userDetails: {
        ...this.state.userDetails,
        unsubscribedToEmails: checked
      }
    }));
  }

  handleUsernameChange(e) {
    let newValue = e.target.value.replace(usernameRegex, "");
    this.setState(() => ({
      userDetails: {
        ...this.state.userDetails,
        username: newValue
      }
    }));
  }

  handleEmailBlur(e) {
    let newValue = e.target.value;
    if (emailRegex.test(newValue)) {
      this.setState({
        emailNotValid: false,
        userDetails: {
          ...this.state.userDetails,
          emailAddress: newValue
        }
      });
    } else if (newValue !== "") {
      this.setState({emailNotValid: emailError});
    }
  }

  handleEmailChange(e) {
    this.setState({
      userDetails: {
        ...this.state.userDetails,
        emailAddress: preventSpaces(e.target.value)
      }
    });
  }

  handleValidatedPassword(password, valid) {
    this.setState({
      userDetails: {
        ...this.state.userDetails,
        password: password
      },
      passwordNotValid: !valid
    });
  }

  handlePhoneNumberChange(phoneNumber, valid) {
    this.setState({
      userDetails: {
        ...this.state.userDetails,
        phoneNumber: phoneNumber
      },
      phoneNumberNotValid: !valid
    });
  }

  handleOrganisationChange(value, orgErrors) {
    let newUserDetails = {};
    if (isNaN(value)) {
      newUserDetails = {
        ...this.state.userDetails,
        organisation: {
          name: value.name,
          type: value.type,
          state: value.state,
          suburb: value.suburb,
          country: value.country
        }
      };
      delete newUserDetails.organisationId;
    } else {
      orgErrors = false;
      newUserDetails = {
        ...this.state.userDetails,
        organisationId: value
      };
      delete newUserDetails.organisation;
    }

    this.setState({userDetails: newUserDetails, orgErrors: orgErrors});
  }

  checkFormValid() {
    return (
      isPlainObject(this.state.passwordNotValid) ||
      isPlainObject(this.state.emailNotValid) ||
      isPlainObject(this.state.phoneNumberNotValid) ||
      this.state.orgErrors
    );
  }

  successAction(responseData) {
    if (responseData.id === this.props.userId) {
      this.props.setLoggedInUser(responseData, this.props.user.projects);
    } else {
      if (this.props.onSuccess) this.props.onSuccess(responseData);
    }
    if (this.state.mode === FormMode.CREATE) {
      this.setState({
        success: true,
        loading: false,
        mode: FormMode.VIEW,
        createSuccess: true,
        userDetails: {
          ...this.state.userDetails,
          id: responseData.id
        }
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        userDetails: {
          ...this.state.userDetails,
          version: responseData.version
        }
      });
    }
  }

  toggleStatus() {
    this.setState(
      {
        loading: true,
        userDetails: {
          ...this.state.userDetails,
          status:
            this.state.userDetails.status === "ACTIVE"
              ? "DEACTIVATED"
              : "ACTIVE"
        }
      },
      () =>
        userUpdate(this.state.userDetails).then((result) => {
          console.log(result);
          this.setState({
            loading: false,
            userDetails: {
              ...this.state.userDetails,
              version: result.data.version
            }
          });
        })
    );
  }

  onClose() {
    this.setState({readOnly: false, success: false});
    if (this.props.onClose) this.props.onClose(this.state);
  }

  render() {
    const {roles, trigger, userId} = this.props;
    const {emailNotValid, userDetails, mode, redirect, modalOpen} = this.state;
    const {
      handleFullNameChange,
      handleUsernameChange,
      checkFormValid,
      handleValidatedPassword,
      handlePhoneNumberChange,
      handleUnsubscribedToEmailsChange,
      handleEmailBlur,
      handleEmailChange,
      handleOrganisationChange,
      successAction,
      success,
      toggleStatus,
      onClose
    } = this;

    if (redirect) return <Redirect to={redirect} />;

    const password =
      mode === FormMode.CREATE ? (
        <>
          <Password
            createPassword
            passwordRegex={passwordRegex}
            passwordRegexMessage={passwordRegexMessage}
            onValidate={handleValidatedPassword}
            required={mode === FormMode.CREATE}
          />
        </>
      ) : null;

    // Actions menu
    const menuItems = [];

    if (mode !== FormMode.CREATE) {
      if (roles.includes("ROLE_ADMIN") || userDetails.id === userId) {
        menuItems.push({
          name: "Reset Password",
          onClick: () => this.setState({redirect: "/passwordreset"})
        });
        menuItems.push({
          name: "Edit Profile",
          onClick: () => this.setState({mode: FormMode.EDIT}, this.initialise)
        });
      }
      if (roles.includes("ROLE_ADMIN") && userDetails.status === "ACTIVE")
        menuItems.push({name: "Deactivate User", onClick: toggleStatus});
      if (roles.includes("ROLE_ADMIN") && userDetails.status !== "ACTIVE")
        menuItems.push({name: "Activate User", onClick: toggleStatus});
    }
    const actions =
      mode === FormMode.VIEW && menuItems.length > 0 ? (
        <ActionsMenu menuItems={menuItems} />
      ) : null;

    let status = null;
    if (
      (userDetails.status === "ACTIVE" ||
        typeof userDetails.status === "undefined") &&
      roles.includes("ROLE_ADMIN")
    ) {
      status = (
        <>
          <Label color="green" attached="top" content="USER IS ACTIVE" />
          <br />
          <br />
        </>
      );
    } else if (userDetails.status === "DEACTIVATED") {
      status = (
        <>
          <Label color="red" attached="top" content="USER IS DEACTIVATED" />
          <br />
          <br />
        </>
      );
    }

    const organisationValue =
      typeof userDetails.organisationId !== "undefined"
        ? userDetails.organisationId
        : userDetails.organisation;

    const projects =
      mode === FormMode.VIEW ? (
        <>
          <ProjectList
            data={userDetails.projects}
            onClick={() => this.setState({modalOpen: false})}
          />
        </>
      ) : null;

    const isAdmin =
      this.props.roles.includes("ROLE_ADMIN") ||
      this.props.roles.includes("ROLE_ATF_ADMIN");

    const formInputs =
      userDetails.id || mode === FormMode.CREATE ? (
        <>
          {status}
          <Form.Input
            fluid
            required={mode === FormMode.EDIT || mode === FormMode.CREATE}
            readOnly={mode === FormMode.VIEW}
            transparent={mode === FormMode.VIEW}
            label="Full name"
            placeholder="Full name"
            onChange={handleFullNameChange}
            value={userDetails.name}
            data-testid="name"
          />
          <Form.Input
            readOnly={
              (mode === FormMode.EDIT && !roles.includes("ROLE_ADMIN")) ||
              mode === FormMode.VIEW
            }
            transparent={
              (mode === FormMode.EDIT && !roles.includes("ROLE_ADMIN")) ||
              mode === FormMode.VIEW
            }
            required={
              (mode === FormMode.EDIT && roles.includes("ROLE_ADMIN")) ||
              mode === FormMode.CREATE
            }
            fluid
            label="Username"
            placeholder="Username"
            onChange={handleUsernameChange}
            value={userDetails.username}
            data-testid="username"
          />
          {password}
          <Form.Input
            fluid
            required={mode === FormMode.EDIT || mode === FormMode.CREATE}
            readOnly={mode === FormMode.VIEW}
            transparent={mode === FormMode.VIEW}
            label="Email address"
            placeholder="Email address"
            error={emailNotValid}
            onBlur={handleEmailBlur}
            onChange={handleEmailChange}
            value={userDetails.emailAddress}
            data-testid="emailaddress"
          />
          <PhoneNumber
            onValidate={handlePhoneNumberChange}
            value={userDetails.phoneNumber ?? ""}
            readOnly={mode === FormMode.VIEW}
            required={mode === FormMode.EDIT || mode === FormMode.CREATE}
          />
          {isAdmin && mode === FormMode.VIEW ? (
            <Form.Input
              fluid
              readOnly
              transparent
              label="Email subscription status"
              value={
                userDetails.unsubscribedToEmails ? "Unsubscribed" : "Subscribed"
              }
            />
          ) : null}
          {projects}
          <OrganisationSelect
            isAdmin={isAdmin}
            includeNoAffiliation={mode === FormMode.CREATE}
            onValidate={handleOrganisationChange}
            allowCreate={mode === FormMode.CREATE}
            readOnlyName={mode === FormMode.VIEW}
            selectOnly={mode === FormMode.EDIT}
            value={organisationValue}
            required={mode === FormMode.EDIT || mode === FormMode.CREATE}
            asLink={mode === FormMode.VIEW}
          />
          {isAdmin && mode === FormMode.EDIT ? (
            <Form.Checkbox
              label="Unsubscribe this user from receiving notification emails"
              toggle
              onChange={handleUnsubscribedToEmailsChange}
              checked={userDetails.unsubscribedToEmails}
            />
          ) : null}
          {!this.state.createSuccess ? actions : null}
        </>
      ) : (
        <></>
      );

    let formSubmitAPI = userRegistration;
    if (mode === FormMode.EDIT) {
      formSubmitAPI = roles.includes("ROLE_ADMIN") ? userUpdate : meUpdate;
    } else if (mode === FormMode.VIEW) {
      formSubmitAPI = false;
    }

    const successContent = this.state.createSuccess
      ? `Thank you for creating your account with the IMOS Animal Tracking Facility. 
      An email has been sent to ${userDetails.emailAddress}. Please check your email 
      and follow the link included to complete your registration. If you cannot find 
      the confirmation email in your inbox, please check in your spam or junk mail.`
      : `Updated user profile saved`;

    const successHeader = this.state.createSuccess
      ? `Registration confirmation`
      : `Profile update confirmation`;

    return (
      <FormModal
        formSubmitAPI={formSubmitAPI}
        formData={userDetails}
        checkFormValid={checkFormValid}
        testId="registeruser"
        successContent={successContent}
        successHeader={successHeader}
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerContent={formHeaders[mode]}
        headerIcon="user"
        submitContent={saveButtonNames[mode]}
        trigger={trigger}
        onClose={onClose}
        open={modalOpen}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    user: user,
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    userId: user.userId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoggedInUser: (user, projects) =>
      dispatch(
        updateUser(
          user.roles,
          user.id,
          user.organisationId,
          projects,
          user.name
        )
      )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
