import React from "react";
import {connect} from "react-redux";
import {Form, Segment, Message} from "semantic-ui-react";
import axios from "axios";
import {deploymentDelete, receiverById, station} from "../axios/api";
import FormModal from "./FormModal";
import ReceiverSummary from "./ReceiverSummary";
import Location from "./Location";
import {fromUTC, isAdminNotATFAdmin, canDeploymentBeDeleted} from "./helpers";

class ReceiverDeploymentDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      deployment: {
        ...props.value,
        latitude: props.value?.location?.latitude,
        longitude: props.value?.location?.longitude
      }
    };
    this.onClose = this.onClose.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.successAction = this.successAction.bind(this);
  }

  componentDidMount() {
    this.ct = axios.CancelToken.source();
    receiverById(this.state.deployment.receiverId).then((receiverResult) => {
      this.setState(
        (s) => {
          return {
            deployment: {...s.deployment, receiver: receiverResult.receiver},
            canBeDeleted: canDeploymentBeDeleted(s.deployment.id),
            userCanDelete: isAdminNotATFAdmin(this.props.roles)
          };
        },
        () =>
          station(
            {projectId: this.state.deployment.projectId, size: 1000},
            this.ct.token
          )
            .then((result) => {
              const stations = result.data.content;
              const station = stations.find(
                (s) => s.id === this.state.deployment.stationId
              );
              this.setState((s) => {
                return {
                  stationLocation: station?.lastDeployedLocation
                };
              }, this.checkFormValid);
            })
            .catch((err) => {
              if (!axios.isCancel(err)) console.debug(err);
            })
      );
    });
  }

  checkFormValid() {
    const isValid = this.state.canBeDeleted && this.state.userCanDelete;
    return !isValid;
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  successAction() {
    this.setState(
      {
        success: true,
        failed: false
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.deployment.id);
        }
      }
    );
  }

  failedAction() {
    this.setState({
      success: false,
      failed: true
    });
  }

  render() {
    const {onClose, checkFormValid, successAction, failedAction} = this;
    const {trigger} = this.props;
    const {
      deployment,
      success,
      loading,
      canBeDeleted,
      userCanDelete,
      stationLocation
    } = this.state;

    const successContent = `The deployment has been deleted.`;

    const canBeDeletedMessage = canBeDeleted ? null : (
      <p>
        The deployment cannot be deleted because it has either recovery or
        detection associated with it.
      </p>
    );
    const userCanDeleteMessage = userCanDelete ? null : (
      <p>You do not have permission to delete the installation.</p>
    );

    const messageContent = (
      <>
        {canBeDeletedMessage}
        {userCanDeleteMessage}
      </>
    );

    const formInputs = !success ? (
      <>
        <Message info header="Deleting deployment" content={messageContent} />
        <Segment>
          <ReceiverSummary value={deployment.receiver} />
        </Segment>
        <Form.Input
          fluid
          readOnly
          label="Receiver Id"
          value={deployment.receiverId}
        />
        <Form.Field
          readOnly
          timezone={deployment.deploymentTimezone}
          date={fromUTC(
            {
              timestamp: deployment.deploymentDate,
              timezone: deployment.deploymentTimezone
            },
            "YYYY-MM-DDTHH:mm:ss.SSS"
          )}
          label="Deployment Date and Timezone"
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Project Name"
          value={deployment.projectName}
          data-testid="test-project-id-ro"
        />
        <Form.Input readOnly label="Station" value={deployment.stationName} />
        {stationLocation ? (
          <Form.Field
            control={Location}
            label="Location"
            readOnly
            value={{
              longitude: deployment.longitude,
              latitude: deployment.latitude
            }}
          />
        ) : null}
        <Form.Input
          readOnly
          label="Acoustic Release Brand"
          value={deployment.acousticReleaseBrand ?? ""}
        />
        <Form.Input
          readOnly
          label="Acoustic Release ID"
          value={deployment.acousticReleaseId ?? ""}
        />
        <Form.Input
          readOnly
          label="Mooring Descriptor"
          value={deployment.mooringDescriptor ?? ""}
        />
        <Form.Input
          readOnly
          label="Bottom (Sea Floor) Depth (m)"
          value={deployment.bottomDepthMeters ?? ""}
        />
        <Form.Input
          readOnly
          label="Receiver Depth Below Surface (m)"
          value={deployment.depthBelowSurface}
        />
        <Form.Input
          readOnly
          fluid
          label="Receiver Orientation"
          value={deployment.receiverOrientation}
        />
        <Form.TextArea
          readOnly
          label="Comments"
          value={deployment.comments ?? ""}
        />
      </>
    ) : null;

    return (
      <FormModal
        formSubmitAPI={!success ? deploymentDelete : false}
        submitContent={"Delete Receiver Deployment"}
        formData={deployment}
        checkFormValid={checkFormValid}
        onSuccess={successAction}
        onFail={failedAction}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        headerContent="Delete Receiver Deployment"
        successHeader="Receiver Deployment Deleted"
        successContent={successContent}
        onClose={onClose}
        open
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles
  };
}

export default connect(mapStateToProps)(ReceiverDeploymentDelete);
