import React from "react";
import {Form} from "semantic-ui-react";
import {connect} from "react-redux";
import FormModal from "./FormModal";

class InstallationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open
    };
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const {onClose} = this;
    const {trigger, installation} = this.props;
    const {open} = this.state;

    const formInputs = (
      <>
        <Form.Input
          fluid
          transparent
          readOnly
          label="Installation Name"
          value={installation.name}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Configuration"
          value={installation.configurationType}
        />
        <Form.Input
          fluid
          transparent
          readOnly
          label="Project"
          value={installation.project.name}
        />
      </>
    );

    return (
      <FormModal
        headerContent="View Installation"
        formData={installation}
        enableSubmit={false}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        open={open}
        onClose={onClose}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles
  };
}

export default connect(mapStateToProps)(InstallationView);
