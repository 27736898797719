import React from "react";
import {Header, Icon, Segment} from "semantic-ui-react";

export const About = () => {
  return (
    <Segment padded="very" basic>
      <Header as="h2">About the IMOS Animal Tracking Database</Header>
      <div className="at-about">
        <p>
          The IMOS Animal Tracking Database is a national, centralised passive
          acoustic telemetry repository maintained by the{" "}
          <a href="http://imos.org.au/facilities/animaltracking/">
            IMOS Animal Tracking Facility
          </a>{" "}
          and the{" "}
          <a href="https://portal.aodn.org.au/">
            Australian Ocean Data Network
          </a>{" "}
          (AODN). The data it contains were compiled from a nation-wide array of
          underwater hydrophones and document movements of marine species within
          Australian coastal waters between 2007-present.
        </p>
        <p>
          The IMOS Animal Tracking Facility is one of 13 facilities of the{" "}
          <a href="http://imos.org.au/">Integrated Marine Observing System</a>{" "}
          (IMOS). One of its main objectives is to oversee the development and
          maintenance of a national marine acoustic telemetry network and a
          central public database for the Australian research community, thereby
          facilitating regional- to national-scale collaborative research and
          helping the management of Australia’s marine resources.
        </p>
        <p>
          Researchers involved in the field of acoustic telemetry are encouraged
          to join the IMOS Animal Tracking community by contributing a copy of
          their tag and receiver data and metadata to this database, thereby
          contributing to this critical national infrastructure and benefitting
          from automated email notifications when their tagged animals are
          detected within the continent-wide network.
        </p>
        <p>
          All data submitted to the Database are licensed under a Creative
          Commons Attribution 4.0 International License{" "}
          <Icon name="creative commons" />
        </p>
        <strong>
          <i>
            All animal tagging procedures are conducted by participating
            research groups and organisations and are subject to Animal Ethics
            approval.
          </i>
        </strong>
      </div>
    </Segment>
  );
};

export default About;
