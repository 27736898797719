import React from "react";
import {Button, Form, Message} from "semantic-ui-react";
import {connect} from "react-redux";
import config from "react-global-configuration";
import PropTypes from "prop-types";
import ActionsMenu from "./ActionsMenu";

import {activeProjectSummary, me, receiverById, receiverCreate, receiverUpdate} from "../axios/api";

import FormModal from "./FormModal";
import InternalTag from "./InternalTag";
import OrganisationSelect from "./OrganisationSelect";
import ProjectSelect from "./ProjectSelect";
import ReceiverHistoryList from "./ReceiverHistoryList";
import {isAdmin} from "./helpers";
import {updateUser} from "../actions";
import {modelOrdering} from "../constants/receiver-model-ordering";
import ProjectCreate from "./ProjectCreate";

const modelsRequiringModemId = ["VR4-UWM", "VR3-UWM"];
const modelsRequiringInternalTag = ["VR2TX", "VR2AR", "VR2AR-X"];

const serialNumberLength = 6;

const emptyReceiver = {
  id: -1,
  deviceModelName: "",
  projectId: null,
  organisationId: -1,
  serialNumber: ""
};

const emptyInternalTag = {
  transmitterId: "",
  deviceModelName: "",
  serialNumber: "",
  sensorType: "RANGE_TEST",
  codeSpaceName: "A69-1601",
  idCode: ""
};

class Receiver extends React.Component {
  originalState = {};

  constructor(props) {
    super(props);

    const transmitterTypes = config.get("tag_device_model_names").map((c) => {
      return {text: c, value: c};
    });

    const receiverTypes = [
      ...modelOrdering,
      ...config
        .get("receiver_device_model_names")
        .filter((v) => !modelOrdering.includes(v))
    ].map((c) => {
      return {text: c, value: c};
    });

    const codeSpaceNames = config.get("code_space_names").map((c) => {
      return {text: c, value: c};
    });

    let initialReceiver = {...emptyReceiver};
    let requiresInternalTag = false;
    let requiresModemId = false;

    if (typeof props.receiver?.deviceModelName === "string") {
      const deviceModelName = props.receiver.deviceModelName;
      requiresModemId = modelsRequiringModemId.includes(
        deviceModelName.toUpperCase()
      );
      requiresInternalTag = modelsRequiringInternalTag.includes(
        deviceModelName.toUpperCase()
      );
    } else {
      initialReceiver.organisationId = props.organisationId;
      const idx = props.newId?.lastIndexOf("-");
      if (idx > 1) {
        const deviceModelName = props.newId.slice(0, idx);
        const serialNumber = props.newId.slice(idx + 1);
        requiresModemId = modelsRequiringModemId.includes(
          deviceModelName.toUpperCase()
        );
        requiresInternalTag = modelsRequiringInternalTag.includes(
          deviceModelName.toUpperCase()
        );

        initialReceiver = {
          ...emptyReceiver,
          transmitterId: this.props.newId,
          deviceModelName: deviceModelName,
          serialNumber: serialNumber,
          internalTag: requiresInternalTag ? {...emptyInternalTag} : null
        };
      }
    }

    this.state = {
      requireExactSerialNumberLength: true,
      loading: this.props.id ? true : false,
      createLabel: "Create Receiver",
      viewLabel: "Receiver Details",
      success: false,
      edit: this.props.edit ?? false,
      requiresModemId: requiresModemId,
      requiresInternalTag: requiresInternalTag,
      originalRequiresInternalTag: requiresInternalTag,
      validInternalTag: requiresInternalTag,
      projects: [],
      codeSpaceNames: codeSpaceNames,
      receiverTypes: receiverTypes,
      transmitterTypes: transmitterTypes,
      receiver: props.receiver ?? (!props.id ? {...initialReceiver} : null),
      projectMetadataResolved: false,
    };

    this.originalState = {...this.state};

    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleReceiverTypeChange = this.handleReceiverTypeChange.bind(this);
    this.handleSerialNumberChange = this.handleSerialNumberChange.bind(this);
    this.handleOrganisationChange = this.handleOrganisationChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleModemIdChange = this.handleModemIdChange.bind(this);
    this.handleInternalTagUpdate = this.handleInternalTagUpdate.bind(this);
    this.isMemberOfActiveProject = this.isMemberOfActiveProject.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      receiverById(this.props.id).then((res) => {
        const r = res.receiver;
        const requiresInternalTag = modelsRequiringInternalTag.includes(
          r.deviceModelName.toUpperCase()
        );
        let canEditInternalTag;
        if (requiresInternalTag) {
          canEditInternalTag = r.internalTag !== null;
          r.internalTag = r.internalTag ?? emptyInternalTag;
          r.internalTag.transmitterId =
            r.internalTag.sensor?.transmitterId ?? "";
          r.internalTag.idCode = r.internalTag.sensor?.idCode.toString() ?? "";
          r.internalTag.sensorType =
            r.internalTag.sensor?.sensorTypeName ?? r.internalTag.sensorType;
        }

        let hasInternalTag;
        if (requiresInternalTag) {
          hasInternalTag = r.internalTag !== emptyInternalTag;
        }

        /*
         * assign serial number of the internal tag to the payload with receiver's serial number
         * see requirements: https://github.com/aodn/animal-tracking/issues/1440
         * the above requirements have been previously implemented, but only for when creating a new receiver
         *
         * this is for adding missing internal tag to existing receiver
        */
        if (requiresInternalTag && !hasInternalTag) {
          emptyInternalTag.serialNumber = r.serialNumber;
        }

        this.setState((s) => ({
          receiverTypes: requiresInternalTag
            ? [
                {text: "VR2Tx", value: "VR2Tx"},
                {text: "VR2AR", value: "VR2AR"},
                {text: "VR2AR-X", value: "VR2AR-X"}
              ]
            : s.receiverTypes,
          requireExactSerialNumberLength:
            r.serialNumber.toString().length === serialNumberLength,
          receiver: r,
          requiresInternalTag: r.internalTag?.transmitterId !== undefined,
          canEditInternalTag: canEditInternalTag,
          hasInternalTag: hasInternalTag,
          requiresModemId: modelsRequiringModemId.includes(
            r.deviceModelName.toUpperCase()
          ),
          loading: false,
          originalProject: r.projectId ?? null
        }));
      });
    }
    this.isMemberOfActiveProject();
  }

  onClose() {
    this.setState({...this.originalState});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  isMemberOfActiveProject() {
    const getUserActiveProjects = (s) => {
      return this.props.projects.filter((project) =>
          s.find((active) => active.id === project.id)
      );
    };

    if (
        this.props.roles.includes("ROLE_ADMIN") ||
        this.props.roles.includes("ROLE_ATF_ADMIN")
    ) {
      this.setState({
        hasActiveProject: true,
        projectMetadataResolved: true,
        installationMetadataResolved: true
      });
    } else {
      activeProjectSummary().then((result) => {
        const activeProjects = result.data;
        if (getUserActiveProjects(activeProjects) < 1) {
          me().then((result) => {
            this.props.user(
                result.data.roles,
                result.data.id,
                result.data.organisationId,
                result.data.projects,
                result.data.name
            );
            const isMemberOfActiveProject =
                result.data.projects.filter((p) =>
                    activeProjects.find((a) => a.id === p.id)
                ).length > 0;
            this.setState({
              projectMetadataResolved: true,
              hasActiveProject: isMemberOfActiveProject
            });
          });
        } else {
          this.setState({
            hasActiveProject: true,
            projectMetadataResolved: true
          });
        }
      });
    }
  }

  checkFormValid() {
    const r = this.state.receiver;
    let valid =
      r &&
      r.deviceModelName &&
      r.organisationId >= 0 &&
      (this.state.originalProject ||
        r.projectId !== this.state.originalProject ||
        isAdmin(this.props.roles));

    const currentSerialNumberLength = (r?.serialNumber ?? "").toString().length;
    if (
      (this.state.edit && this.state.requireExactSerialNumberLength) ||
      !this.state.edit
    ) {
      valid = valid && currentSerialNumberLength === serialNumberLength;
    } else {
      valid =
        valid &&
        currentSerialNumberLength > 0 &&
        currentSerialNumberLength <= serialNumberLength;
    }

    if (this.state.requiresModemId) {
      valid = valid && r.modemId;
    }

    if (this.state.requiresInternalTag && this.state.canEditInternalTag) {
      valid = valid && this.state.validInternalTag;
    }

    //TODO: check form valid add internal tag too

    return !valid;
  }

  successAction(response) {
    this.setState(
      (s, p) => ({
        success: true,
        receiver: {
          ...s.receiver,
          id: response.id,
          receiverId: `${response.deviceModelName}-${response.serialNumber}`,
          organisationId: response.organisation.id,
          organisationName: response.organisation.name,
          projectId: response.project?.id,
          projectName: response.project?.name,
          status: response.status,
          version: response.version
        }
      }),
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.receiver);
        }
      }
    );
  }

  handleReceiverTypeChange(e, {value}) {
    const requiresModemId = modelsRequiringModemId.includes(
      value.toUpperCase()
    );
    const requiresInternalTag = modelsRequiringInternalTag.includes(
      value.toUpperCase()
    );
    this.setState(
      (s) => ({
        requiresModemId: requiresModemId,
        requiresInternalTag: requiresInternalTag,
        canEditInternalTag:
          (!s.originalRequiresInternalTag && requiresInternalTag) ||
          s.originalRequiresInternalTag,
        receiver: {
          ...s.receiver,
          deviceModelName: value,
          modemId:
            s.requiresModemId !== requiresModemId
              ? requiresModemId
                ? ""
                : undefined
              : s.modemId,
          internalTag: requiresInternalTag
            ? s.originalRequiresInternalTag
              ? {...s.receiver.internalTag, serialNumber: s.receiver.serialNumber.toString()}
              : {...emptyInternalTag, serialNumber: s.receiver.serialNumber.toString()}
            : undefined
        }
      }),
      () => {
        this.checkFormValid();
      }
    );
  }

  handleSerialNumberChange(e, {value}) {
    const serialNumber = isNaN(parseInt(value)) ? "" : parseInt(value);
    const requiresInternalTag = modelsRequiringInternalTag.includes(
      this.state.receiver.deviceModelName.toUpperCase()
    );
    this.setState(
      (s, p) => ({
        receiver: {
          ...s.receiver,
          serialNumber: serialNumber,
          internalTag: requiresInternalTag ?
            {...s.receiver.internalTag, serialNumber: value} : undefined
        }
      }),
      () => {
        this.checkFormValid();
      }
    );
  }

  handleOrganisationChange(orgId) {
    this.setState(
      (s, p) => ({
        receiver: {
          ...s.receiver,
          organisationId: orgId
        }
      }),
      () => {
        this.checkFormValid();
      }
    );
  }

  handleProjectChange(e, {value}) {
    this.setState(
      (s, p) => ({
        receiver: {
          ...s.receiver,
          projectId: value
        }
      }),
      () => {
        this.checkFormValid();
      }
    );
  }

  handleModemIdChange(e, {value}) {
    const modemId = isNaN(parseInt(value)) ? "" : parseInt(value);
    this.setState(
      (s, p) => ({
        receiver: {
          ...s.receiver,
          modemId: modemId
        }
      }),
      () => {
        this.checkFormValid();
      }
    );
  }

  handleInternalTagUpdate(tag, isValid) {
    this.setState(
      (s, p) => ({
        validInternalTag: isValid,
        receiver: {
          ...s.receiver,
          internalTag: tag
        }
      }),
      () => {
        this.checkFormValid();
      }
    );
  }

  handleFormReset() {
    this.setState({
      ...this.originalState,
      hasActiveProject: true,
      projectMetadataResolved: true,
      installationMetadataResolved: true
    });
  }

  metadataChecksAreResolved() {
    return this.state.projectMetadataResolved;
  }

  render() {

    const {
      success,
      edit,
      createLabel,
      viewLabel,
      receiver,
      receiverTypes,
      requiresInternalTag,
      hasInternalTag,
      requiresModemId,
      hasActiveProject
    } = this.state;
    const {roles, trigger} = this.props;
    const {
      handleReceiverTypeChange,
      handleSerialNumberChange,
      handleOrganisationChange,
      handleInternalTagUpdate,
      handleProjectChange,
      handleModemIdChange,
      handleFormReset,
      checkFormValid,
      successAction,
      onClose
    } = this;

    let menuItems = [];
    let formInputs = [];
    let optionalButtons = [];

    if (this.metadataChecksAreResolved()) {

      if (!hasActiveProject && !isAdmin(this.props.roles)) {

        const additionalActions = [{
          name: "Create Project",
          triggered: (
              <ProjectCreate
                  open={false}
                  onClose={() => {
                    this.onClose();
                  }}
              />
          )
        }];

        const actions = <ActionsMenu menuItems={additionalActions} />

        formInputs = (
            <>
              <Message warning>
                To create a receiver, you must be a member of an active project.
              </Message>
              {actions}
            </>
        );
      } else {
        if (receiver?.id >= 0 && !edit) {
          const isAdmin =
              this.props.roles.includes("ROLE_ADMIN") ||
              this.props.roles.includes("ROLE_ATF_ADMIN");
          if (
              isAdmin ||
              this.props.organisationId === receiver.organisationId ||
              this.state.projects.includes(receiver.projectId)
          )
            menuItems.push({
              name: "Edit Receiver",
              onClick: () => {
                this.setState({edit: true});
              }
            });
        }

        const actions =
            menuItems.length > 0 && this.props.accessToken ? (
                <ActionsMenu menuItems={menuItems}/>
            ) : null;
        const isNewReceiver = !this.state.edit;

        formInputs = this.state.loading ? (
            <>
              <Form.Input fluid transparent readOnly/>
            </>
        ) : receiver?.id < 0 || (edit && !success) ? (
            <>
              <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Receiver ID"
                  value={`${receiver.deviceModelName}-${receiver.serialNumber}`}
                  data-testid="test-receiver-id"
              />
              <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Current Receiver Status"
                  value={receiver.status}
              />
              <Form.Dropdown
                  fluid
                  selection
                  search
                  required={true}
                  label="Receiver Type"
                  options={receiverTypes}
                  placeholder="Select Receiver Type"
                  value={receiver.deviceModelName}
                  onChange={handleReceiverTypeChange}
                  data-testid="test-receiver-type-dropdown"
              />
              <Form.Input
                  fluid
                  minLength={
                    this.state.requireExactSerialNumberLength ? serialNumberLength : 1
                  }
                  maxLength={serialNumberLength}
                  required={true}
                  autoComplete="none"
                  label="Serial Number"
                  onChange={handleSerialNumberChange}
                  value={receiver.serialNumber}
                  data-testid="test-receiver-id"
              />
              {isNewReceiver || this.props.isAdmin ? (
                  <OrganisationSelect
                      loadingText={receiver.organisationName}
                      isAdmin={this.props.isAdmin}
                      includeNoAffiliation={false}
                      label="Purchasing Organisation"
                      onValidate={handleOrganisationChange}
                      value={receiver.organisationId}
                      allowCreate={false}
                      readOnlyName={true}
                      selectOnly={true}
                      required={true}
                  />
              ) : (
                  <Form.Input
                      fluid
                      transparent
                      readOnly
                      label="Purchasing Organisation"
                      value={receiver.organisationName}
                      data-testid="test-receiver-organisationName-ro"
                  />
              )}
              {isNewReceiver ||
              (receiver.projectId &&
                  this.props.projects.find((p) => p.id === receiver.projectId)) ||
              isAdmin(roles) ? (
                  <ProjectSelect
                      loadingText={receiver.projectName}
                      required={isNewReceiver}
                      value={receiver.projectId}
                      onChange={handleProjectChange}
                      showAll={isAdmin(roles)}
                  />
              ) : receiver?.projectId ? (
                  <Form.Input
                      fluid
                      transparent
                      readOnly
                      label="Project"
                      value={receiver.projectName}
                  />
              ) : null}

              {requiresModemId ? (
                  <Form.Input
                      fluid
                      minLength={1}
                      maxLength={16}
                      label="Modem ID"
                      required={true}
                      autoComplete="none"
                      onChange={handleModemIdChange}
                      value={receiver.modemId ?? ""}
                      data-testid="test-modem-id"
                  />
              ) : null}
              {requiresInternalTag ? (
                <>
                  <InternalTag
                      value={receiver.internalTag}
                      onUpdate={
                        !hasInternalTag ||  this.state.canEditInternalTag ? handleInternalTagUpdate : false
                      }
                  />
                </>
              ) : null}
            </>
        ) : (
            <>
              <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Receiver ID"
                  value={`${receiver.deviceModelName}-${receiver.serialNumber}`}
                  data-testid="test-receiver-id-ro"
              />
              <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Receiver Type"
                  value={receiver.deviceModelName}
                  data-testid="test-receiver-type-ro"
              />
              <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Serial Number"
                  value={receiver.serialNumber}
                  data-testid="test-receiver-id-ro"
              />
              <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Purchasing Organisation"
                  value={receiver.organisationName}
                  data-testid="test-receiver-organisationName-ro"
              />
              {receiver.projectId || this.props.isAdmin ? (
                  <Form.Input
                      fluid
                      transparent
                      readOnly
                      label="Project Name"
                      value={receiver.projectName}
                      data-testid="test-receiver-projectName-ro"
                  />
              ) : null}
              {requiresModemId ? (
                  <Form.Input
                      fluid
                      transparent
                      readOnly
                      label="Modem ID"
                      value={receiver.modemId ?? ""}
                      data-testid="test-receiver-modem-id-ro"
                  />
              ) : null}
              {receiver.internalTag ? (
                  <InternalTag value={receiver.internalTag}/>
              ) : null}
              <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Current Receiver Status"
                  value={receiver.status}
                  data-testid="test-receiver-status-ro"
              />
              {receiver.history?.length > 0 ? (
                  <ReceiverHistoryList data={receiver.history}/>
              ) : (
                  <Form.Input
                      fluid
                      transparent
                      readOnly
                      label="Receiver Deployment History"
                      value="No History"
                      data-testid="test-receiver-status-ro"
                  />
              )}
              {!edit && success ? (
                  <>
                    <Button
                        primary
                        onClick={() => {
                          this.props.onClose(receiver.receiverId);
                        }}
                        content="Deploy Receiver"
                    />
                    <Button
                        primary
                        onClick={() => {
                          handleFormReset();
                        }}
                        content="Create Another Receiver"
                    />
                  </>
              ) : (
                  actions
              )}
            </>
        );
      }
    }

    const hasReceiver = receiver?.id > 0;
    const formSubmitAPI = hasReceiver
        ? this.state.edit && !success
            ? receiverUpdate
            : null
        : receiverCreate;

    return (
        <FormModal
            open
            modalSize={
              !this.state.edit && receiver?.history?.length > 0
                  ? "fullscreen"
                  : null
            }
            optionalButtons={optionalButtons}
            loading={this.state.loading}
            formSubmitAPI={formSubmitAPI}
            formData={receiver}
            checkFormValid={checkFormValid}
            enableSubmit={false}
            testId="installation-form"
            successHeader="Success"
            onSuccess={successAction}
            success={success}
            formInputs={this.state.loading ? <></> : formInputs}
            headerIcon="microphone"
            trigger={trigger}
            successContent={`Receiver ${this.state.edit ? "Updated" : "Created"}`}
            headerContent={hasReceiver ? viewLabel : createLabel}
            submitContent={
              !this.state.loading
                  ? `${edit ? "Update" : "Create"} Receiver ${
                      receiver.deviceModelName
                  }-${receiver.serialNumber}`
                  : ""
            }
            onClose={onClose}
        />
    );
  }
}

Receiver.propTypes = {
  onClose: PropTypes.func.isRequired,
  receiver: PropTypes.shape({
    id: PropTypes.number.isRequired,
    deviceModelName: PropTypes.string.isRequired,
    project: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }),
    organisation: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }),
    serialNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    modelId: PropTypes.string,
    internalTag: PropTypes.shape(InternalTag.propTypes.value),
    edit: PropTypes.bool,
    isAdmin: PropTypes.bool,
    onUpdate: PropTypes.func
  })
};

function mapDispatchToProps(dispatch) {
  return {
    user: (roles, userId, organisationId, projects, name) =>
        dispatch(updateUser(roles, userId, organisationId, projects, name))
  };
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    organisationId: user.organisationId,
    projects: user.projects
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Receiver);
