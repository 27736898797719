import axiosInstance from "./index.js";
import axios from "axios";
import {prepareReceiverRecovery} from "./api-helpers";

// siteConfig loaded into react-global-configuration
export const siteConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/siteconfig", {cancelToken: cancelToken});
};

// Statistics endpoints
export const statistics = (cancelToken) => {
  return axiosInstance.get("/api/utils/statistics", {cancelToken: cancelToken});
};

// Search
export const speciesSearch = (searchString, cancelToken, params) => {
  return axiosInstance.get(
    `/api/species/search?query=${encodeURIComponent(searchString)}`,
    {cancelToken: cancelToken, params: params}
  );
};
export const tagSearch = (searchString, cancelToken, params) => {
  return axiosInstance.get(
    `/api/tag/search?query=${encodeURIComponent(searchString)}`,
    {cancelToken: cancelToken, params: params}
  );
};
export const search = (params, cancelToken) => {
  const config = {
    params: params,
    cancelToken: cancelToken
  };
  return axiosInstance.get(`/api/search`, config);
};

// List Configuration Endpoints
export const userListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/userListConfig", {
    cancelToken: cancelToken
  });
};

export const organisationListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/organisationListConfig", {
    cancelToken: cancelToken
  });
};

export const projectListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/projectListConfig", {
    cancelToken: cancelToken
  });
};

export const installationListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/installationListConfig", {
    cancelToken: cancelToken
  });
};

export const stationListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/stationListConfig", {
    cancelToken: cancelToken
  });
};

export const receiverListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/receiverListConfig", {
    cancelToken: cancelToken
  });
};

export const receiverDeploymentListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/receiverDeploymentListConfig", {
    cancelToken: cancelToken
  });
};

export const transmitterListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/tagListConfig", {
    cancelToken: cancelToken
  });
};

export const detectionListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/detectionListConfig", {
    cancelToken: cancelToken
  });
};

// Organisation
export const organisation = (params, cancelToken) => {
  return axiosInstance.get("/api/organisation", {
    params: params,
    cancelToken: cancelToken
  });
};
export const organisationSummary = (
  isAdmin,
  includeNoAffiliation,
  cancelToken
) => {
  const params = !isAdmin
    ? `&excludeNamesList=IMOS-ATF${
        !includeNoAffiliation ? ",No%20Affiliation" : ""
      }`
    : "";
  return axiosInstance.get(`/api/organisation?summary=true${params}`, {
    cancelToken: cancelToken
  });
};
export const organisationCreate = (organisationDetails) => {
  return axiosInstance.post("/api/organisation", organisationDetails);
};
export const organisationById = (id) => {
  return axiosInstance.get(`/api/organisation/${id}`).then((o) => {
    return {value: o.data};
  });
};
export const organisationUsersById = (id) => {
  return axiosInstance.get(`/api/organisation/${id}/user`);
};
export const editOrganisation = (org) => {
  return axiosInstance.put(`/api/organisation/${org.id}`, org);
};
export const organisationUsersAndProjectsById = (id) => {
  return axios.all([organisationById(id), organisationUsersById(id)]).then(
    axios.spread((...responses) => {
      const projects = responses[0].data.projects;
      const users = responses[1].data.content;
      return {
        organisation: responses[0].data,
        data: {
          users: users,
          projects: projects
        }
      };
    })
  );
};

// Authentication
export const activate = (params) => {
  return axiosInstance.post("/api/auth/activate", params);
};
export const resetPassword = (data, cancelToken) => {
  return axiosInstance.post("/api/auth/resetPassword", data, {
    cancelToken: cancelToken
  });
};
export const changePassword = (data, cancelToken) => {
  return axiosInstance.post("/api/auth/changePassword", data, {
    cancelToken: cancelToken
  });
};
export const userLogin = (params, cancelToken) => {
  return axiosInstance.post(
    "/api/auth/signin",
    {
      username: params.username,
      password: params.password
    },
    {
      cancelToken: cancelToken
    }
  );
};

export const userConfirmLatestEmail = (params, cancelToken) => {
  return axiosInstance.post(
    `/api/auth/confirmLatestEmail`,
    {
      username: params.username,
      password: params.password,
      latestEmail: params.latestEmail
    },
    {
      cancelToken: cancelToken
    }
  );
};

export const userLogout = (params) => {
  const headers = {Authorization: `${params.tokenType} ${params.accessToken}`};
  return axiosInstance.post("/api/auth/signout", {}, {headers});
};

// User
export const me = () => {
  return axiosInstance.get("/api/user/me");
};
export const meUpdate = (userDetails) => {
  delete userDetails.password;
  return axiosInstance.put("/api/user/me", userDetails);
};
export const userRegistration = (userDetails) => {
  return axiosInstance.post("/api/auth/signup", userDetails);
};

// Project
export const projectRegister = (project, cancelToken) => {
  return axiosInstance.post("/api/project", project, {
    cancelToken: cancelToken
  });
};
export const project = (params, cancelToken) => {
  const config = {params: params, cancelToken: cancelToken};
  return axiosInstance.get("/api/project", config);
};
export const activeProjectSummary = () => {
  return axiosInstance.get("/api/project?activeProjectSummary=true");
};
export const projectById = (id) => {
  return axiosInstance.get(`/api/project/${id}`).then((p) => {
    return {project: p.data};
  });
};
export const projectUpdate = (project, cancelToken) => {
  return axiosInstance.put(`/api/project/${project.id}`, project, {
    cancelToken: cancelToken
  });
};

// Installation
export const installationCreate = (installationDetails, cancelToken) => {
  return axiosInstance.post("/api/installation", installationDetails, {
    cancelToken: cancelToken
  });
};
export const installationUpdate = (details, cancelToken) => {
  return axiosInstance.put(`/api/installation/${details.id}`, details, {
    cancelToken: cancelToken
  });
};
export const installation = (params, cancelToken) => {
  const config = {params: params, cancelToken: cancelToken};
  return axiosInstance.get("/api/installation", config);
};
export const installationCsv = (params) => {
  const config = {params: params};
  return axiosInstance.get("/api/installation/installations.csv", config);
};
export const installationById = (id) => {
  return axiosInstance.get(`/api/installation/${id}`).then((i) => {
    return {installation: i.data};
  });
};
export const installationDelete = (installation, cancelToken) => {
  return axiosInstance.delete(`/api/installation/${installation.id}`, {
    cancelToken: cancelToken
  });
};

// Station
export const station = (params, cancelToken) => {
  return axiosInstance.get("/api/station", {
    params: params,
    cancelToken: cancelToken
  });
};
export const stationCreate = (station, cancelToken) => {
  return axiosInstance.post("/api/station", station, {
    cancelToken: cancelToken
  });
};
export const stationById = (id) => {
  return axiosInstance.get(`/api/station/${id}`);
};
export const stationUpdate = (details, cancelToken) => {
  return axiosInstance.put(`/api/station/${details.id}`, details, {
    cancelToken: cancelToken
  });
};
export const stationExists = (coords) => {
  return axiosInstance.get(`/api/station/stationExists?coordinates=${coords}`);
};
export const stationCsv = (params) => {
  const config = {params: params};
  return axiosInstance.get("/api/station/stations.csv", config);
};
export const stationDelete = (id, cancelToken) => {
  return axiosInstance.delete(`/api/station/${id}`, {
    cancelToken: cancelToken
  });
};

export const deploymentsCsv = (id, params) => {
  const config = {params: params};
  return axiosInstance.get(`/api/station/${id}/deployments.csv`, config);
};

// Receiver
export const receiver = (params, cancelToken) => {
  const config = {params: params, cancelToken: cancelToken};
  return axiosInstance.get("/api/receiver", config);
};
export const receiverById = (id) => {
  return axiosInstance.get(`/api/receiver/${id}`).then((r) => {
    return {
      receiver: {
        ...r.data,
        organisationId: r.data.organisation.id,
        organisationName: r.data.organisation.name,
        projectId: r.data.project?.id,
        projectName: r.data.project?.name
      }
    };
  });
};
export const receiverByReceiverId = (id) => {
  return axiosInstance
    .get(`/api/receiver/receiverId/${id}`)
    .then((r) => {
      return {
        receiver: {
          ...r.data,
          organisationId: r.data.organisation.id,
          organisationName: r.data.organisation.name,
          projectId: r.data.project?.id,
          projectName: r.data.project?.name
        }
      };
    })
    .catch(() => {
      return null;
    });
};
export const receiverCreate = (receiverDetails) => {
  const receiverPostCreate = Object.assign({}, receiverDetails);
  return axiosInstance.post("/api/receiver", receiverPostCreate);
};
export const receiverUpdate = (receiver) => {
  return axiosInstance.put(`/api/receiver/${receiver.id}`, receiver);
};
export const receiverCsv = (params) => {
  const config = {params: params};
  return axiosInstance.get("/api/receiver/receivers.csv", config);
};
export const receiverSummary = (params) => {
  const config = {params: params};
  return axiosInstance.get("/api/receiver?summary=true", config);
};
export const receiverDelete = (receiver, cancelToken) => {
  return axiosInstance.delete(`/api/receiver/${receiver.id}`, {
    cancelToken: cancelToken
  });
};

// Receiver Deployment
export const receiverDeployment = (params, cancelToken) => {
  const config = {params: params, cancelToken: cancelToken};
  return axiosInstance.get("/api/receiver/deployment", config);
};
export const deploymentCreate = (deploymentDetails, cancelToken) => {
  const deploymentPostCreate = Object.assign({}, deploymentDetails);
  return axiosInstance.post("/api/receiver/deployment", deploymentPostCreate, {
    cancelToken: cancelToken
  });
};
export const historicDeploymentRecoveryCreate = (
  deploymentRecoveryDetails,
  cancelToken
) => {
  deploymentRecoveryDetails = prepareReceiverRecovery(
    deploymentRecoveryDetails
  );
  const deploymentPostCreate = Object.assign({}, deploymentRecoveryDetails);
  return axiosInstance.post(
    "/api/receiver/deployment/createHistoricalReceiverDeployment",
    deploymentPostCreate,
    {cancelToken: cancelToken}
  );
};
export const deploymentUpdate = (deployment, cancelToken) => {
  return axiosInstance.put(
    `/api/receiver/deployment/${deployment.id}`,
    deployment,
    {cancelToken: cancelToken}
  );
};
export const deploymentDelete = (deployment, cancelToken) => {
  return axiosInstance.delete(`/api/receiver/deployment/${deployment.id}`, {
    cancelToken: cancelToken
  });
};
export const deploymentRecoveryZip = (params) => {
  const config = {params: params, responseType: "blob"};
  return axiosInstance.get("/api/receiver/deployment/deployments.zip", config);
};
export const receiverDeploymentById = (id) => {
  return axiosInstance.get(`/api/receiver/deployment/${id}`).then((r) => {
    return receiverById(r.data.receiverId).then((r2) => {
      return {
        value: {...r.data, receiver: r2.receiver}
      };
    });
  });
};

// Receiver Recovery
export const recoveryCreate = (recoveryDetails, cancelToken) => {
  recoveryDetails = prepareReceiverRecovery(recoveryDetails);
  const recoveryPostCreate = Object.assign({}, recoveryDetails);
  return axiosInstance.post("/api/receiver/recovery", recoveryPostCreate, {
    cancelToken: cancelToken
  });
};
export const recoveryUpdate = (recovery) => {
  recovery = prepareReceiverRecovery(recovery);
  return axiosInstance.put(`/api/receiver/recovery/${recovery.id}`, recovery);
};
export const recoveryDelete = (id, cancelToken) => {
  return axiosInstance.delete(`/api/receiver/recovery/${id}`, {
    cancelToken: cancelToken
  });
};
export const checkRecoveryCanBeDeleted = (id) => {
  return axiosInstance.get(`/api/receiver/recovery/check/${id}`);
};
export const checkReceiverCanBeDeleted = (id) => {
  return axiosInstance.get(`/api/receiver/check/${id}`);
};
export const checkDeploymentCanBeDeleted = (id) => {
  return axiosInstance.get(`/api/receiver/deployment/check/${id}`);
};

export const checkHistoricalDeployment = (id) => {
  return axiosInstance.get(`/api/receiver/deployment/historical/check/${id}`);
};

export const receiverRecoveryById = (id) => {
  return axiosInstance.get(`/api/receiver/recovery/${id}`).then((r) => {
    return receiverDeploymentById(r.data.deploymentId).then((d) => {
      return receiverById(d.value.receiver.id).then((r2) => {
        return {
          value: {...r.data, receiver: r2.receiver, deployment: d.value}
        };
      });
    });
  });
};

// Admin users only
export const userById = (id) => {
  return axiosInstance.get(`/api/user/${id}`);
};
export const allActiveUsers = () => {
  return axiosInstance.get(`/api/user?activeUserSummary=true`);
};
export const userUpdate = (userDetails) => {
  delete userDetails.password;
  return axiosInstance.put(`/api/user/${userDetails.id}`, userDetails);
};
export const user = (params, cancelToken) => {
  const config = {params: params, cancelToken: cancelToken};
  return axiosInstance.get(`/api/user`, config);
};

// Detections
export const uploadDetections = (upload, cancelToken) => {
  const data = new FormData();
  data.append("detection", upload.detection);
  data.append("event", upload.event);

  const headers = {
    "Content-Type": `multipart/form-data; boundary=${data._boundary}`
  };
  return axiosInstance.post("/api/upload/combined", data, {
    headers: headers,
    cancelToken: cancelToken
  });
};

// Fathom
export const beginFathom = (data, cancelToken) => {
  return axiosInstance.post(`/api/fathom/upload/begin`, data, {
    cancelToken: cancelToken
  });
};

export const uploadFathomFile = (data, cancelToken) => {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("receiverDownloadFileId", data.fileId);

  return axiosInstance.post(`/api/fathom/upload/s3`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    cancelToken: cancelToken
  });
};

export const cancelFathom = () => {
  return axiosInstance.get(`/api/fathom/upload/cancel`);
};

export const postFathomDetections = (data, cancelToken) => {
  return axiosInstance.post(`/api/fathom/upload/payload`, data, {
    cancelToken: cancelToken
  });
};

export const detection = (params, cancelToken) => {
  const config = {
    params: params,
    cancelToken: cancelToken
  };
  return axiosInstance.get("/api/detection", config);
};

export const areDownloadableDetectionsAvailable = (params, cancelToken) => {
  const config = {
    params: params,
    cancelToken: cancelToken
  };
  return axiosInstance.get("/api/detection/areDownloadableDetectionsAvailable", config);
};

export const detectionById = (id) => {
  return axiosInstance.get(`/api/detection/${id}`);
};
export const detectionToken = () => {
  return axiosInstance.get("/api/detection/downloadToken");
};

// Tags (Transmitters)
export const transmitter = (params, cancelToken) => {
  const config = {params: params, cancelToken: cancelToken};
  return axiosInstance.get("/api/tag", config);
};
export const transmitterById = (id) => {
  return axiosInstance.get(`/api/tag/${id}`).then((t) => {
    return {transmitter: t.data};
  });
};
export const transmitterBySerialNumber = (serialNumber, params) => {
  return axiosInstance
    .get(`/api/tag/serialNumber/${serialNumber}`, {
      params: params
    })
    .then((t) => {
      return {transmitter: t.data};
    })
    .catch((error) => {
      if (params.noRedirectOn401 && error.response.status === 401) {
        return Promise.reject(error);
      } else {
        return null;
      }
    });
};
export const transmitterByReleaseId = (id) => {
  return axiosInstance.get(`/api/tag/release/${id}`).then((r) => {
    return transmitterById(r.data.tagId);
  });
};
export const transmitterBySensorId = (id) => {
  return axiosInstance.get(`/api/tag/sensor/${id}`).then((r) => {
    return transmitterById(r.data.id);
  });
};

export const speciesCountByTransmitterId = (id) => {
  return axiosInstance.get(`/api/tag/transmitter/count_species/${id}`);
};

export const tagRegisterRelease = (transmitterDetails, cancelToken) => {
  const transmitterPostCreate = Object.assign({}, transmitterDetails);
  return axiosInstance.post("/api/tag/registerRelease", transmitterPostCreate, {
    cancelToken: cancelToken
  });
};
export const tagValidate = (transmitterDetails, cancelToken) => {
  const transmitterPostCreate = Object.assign({}, transmitterDetails);
  return axiosInstance.post("/api/tag/validateTag", transmitterPostCreate, {
    cancelToken: cancelToken
  });
};
export const tagRelease = (transmitterDetails, cancelToken) => {
  const release = Object.assign({}, transmitterDetails);
  return axiosInstance.post("/api/tag/release", release, {
    cancelToken: cancelToken
  });
};
export const tagRecover = (transmitterDetails, cancelToken) => {
  const release = Object.assign({}, transmitterDetails);
  return axiosInstance.post("/api/tag/recovery", release, {
    cancelToken: cancelToken
  });
};
export const tagReleaseUpdate = (releasePutDto, cancelToken) => {
  return axiosInstance.put(
    `/api/tag/release/${releasePutDto.id}`,
    releasePutDto,
    {cancelToken: cancelToken}
  );
};
export const tagRecoverUpdate = (tagRecoverUpdateDto, cancelToken) => {
  return axiosInstance.put(
    `/api/tag/recovery/${tagRecoverUpdateDto.id}`,
    tagRecoverUpdateDto,
    {cancelToken: cancelToken}
  );
};

export const tagRecoveryDelete = (transmitterDetails, cancelToken) => {
  const release = Object.assign({}, transmitterDetails);
  return axiosInstance.delete(`/api/tag/recovery/${release.id}`, {
    cancelToken: cancelToken
  });
};

export const tagDeploymentDelete = (transmitterDetails, cancelToken) => {
  const release = Object.assign({}, transmitterDetails);
  return axiosInstance.delete(`/api/tag/deployment/${release.id}`, {
    cancelToken: cancelToken
  });
};

export const transmitterDeleteByTagId = (transmitterDetails, cancelToken) => {
  const transmitter = Object.assign({}, transmitterDetails);
  return axiosInstance.delete(`/api/tag/${transmitter.id}`, {
    cancelToken: cancelToken
  });
};

export const tagUpdate = (transmitter, cancelToken) => {
  return axiosInstance.put(`/api/tag/${transmitter.id}`, transmitter, {
    cancelToken: cancelToken
  });
};
export const tagsZip = (params) => {
  const config = {params: params, responseType: "blob"};
  return axiosInstance.get("/api/tag/tags.zip", config);
};
export const mysteryTags = (mysteryTags) => {
  return axiosInstance.post("/api/tools/mysterytags", mysteryTags, {});
};
export const requestDelete = (requestDelete) => {
  return axiosInstance.post("/api/utils/requestDelete", requestDelete, {});
};

// Deletion request APIs
export const deletionRequestById = (id) => {
  return axiosInstance.get(`/api/deletion-request/${id}`);
};

export const pendingDeletionRequestCount = (cancelToken) => {
  return axiosInstance.get(`/api/deletion-request/pending-request-count`, {
    cancelToken: cancelToken
  });
};

export const getAllDeletionRequests = (params, cancelToken) => {
  const config = {params: params, cancelToken: cancelToken};
  return axiosInstance.get(`/api/deletion-request`, config);
};

export const deletionRequestUpdate = (requestDetails) => {
  return axiosInstance.put(
    `/api/deletion-request/${requestDetails.id}`,
    requestDetails
  );
};

export const deletionRequestListConfig = (cancelToken) => {
  return axiosInstance.get("/api/utils/deletionRequestListConfig", {
    cancelToken: cancelToken
  });
};
