import React from "react";
import {connect} from "react-redux";
import FormModal from "./FormModal";
import {
  deletionRequestUpdate,
  deletionRequestById,
  pendingDeletionRequestCount
} from "../axios/api";
import {Form, Label, List} from "semantic-ui-react";
import {updatePendingRequestCount} from "../actions";

const defaultState = {
  request: {
    id: null,
    email: "",
    username: "",
    entityId: null,
    entityType: "",
    entityDetails: "",
    status: "",
    updateStatus: ""
  },
  processed: false,
  success: false,
  deleteSelected: false,
  confirmDeletion: ""
};

class DeletionRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.successAction = this.successAction.bind(this);
    this.onClose = this.onClose.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.getDeletionRequest = this.getDeletionRequest.bind(this);
    this.initialiseForm = this.initialiseForm.bind(this);
  }

  componentDidMount() {
    this.getDeletionRequest().then((r) => r);
  }

  checkFormValid() {
    return !(
      (this.state.request.updateStatus === "APPROVED" &&
        this.state.confirmDeletion === "delete" && this.state.request.entityType !== "historical_deployment") || (this.state.request.entityType === "historical_deployment" && this.state.confirmDeletion === "yes") ||
      this.state.request.updateStatus === "CANCELLED"
    );
  }

  successAction() {
    this.setState(
      {
        success: true,
        request: {
          ...this.state.request,
          status: this.state.request.updateStatus,
          updateStatus: ""
        },
        processed: true
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.request);
        }
      }
    );
    pendingDeletionRequestCount().then((result) => {
      this.props.dispatch(updatePendingRequestCount(result.data));
    });
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose(this.state);
      this.setState(defaultState);
    }
  }

  async getDeletionRequest() {
    this.setState({loading: true});
    deletionRequestById(this.props.id).then((s) => {
      this.initialiseForm(s.data, []);
    });
  }

  initialiseForm(request) {
    this.setState({
      request: {
        id: request.id,
        username: request.username,
        email: request.email,
        entityId: request.entityId,
        entityType: request.entityType,
        entityDetails: request.entityDetails,
        status: request.status,
        updateStatus: ""
      },
      processed: false,
      loading: false,
      confirmDeletion: ""
    });
  }

  render() {
    const handleStatusChange = (e, {value}) => {
      this.setState({
        request: {
          ...this.state.request,
          updateStatus: value
        },
        deleteSelected: value === "APPROVED"
      });
    };

    const {successAction, onClose, checkFormValid} = this;
    const {request, success, loading, processed, deleteSelected} = this.state;

    const status = (
      <>
        <Label
          color={
            request.status === "APPROVED"
              ? "green"
              : request.status === "CANCELLED"
              ? "red"
              : "yellow"
          }
          attached="top"
          content={
            request.status === "APPROVED"
              ? "APPROVED"
              : request.status === "CANCELLED"
              ? "CANCELLED"
              : "PENDING"
          }
        />
        <br />
        <br />
      </>
    );

    const regex = new RegExp("(\\w+):\\s*(.*?)(?=\\,\\s*\\w+\\:|$)", "g");
    let match = regex.exec(request.entityDetails);
    let requestDetails = {};
    while (match != null) {
      requestDetails[match[1]] = match[2];
      match = regex.exec(request.entityDetails);
    }
    const detailsList = (requestDetails) => {
      return (
        <List.List as="ul">
          {Object.entries(requestDetails).map(([key, value]) => (
            <List.Item as="li">
              <strong>{key}: </strong> {value}
            </List.Item>
          ))}
        </List.List>
      );
    };

    const formInputs = (
      <>
        {status}
        <Form.Input
          label="Requesting username"
          readOnly
          transparent
          value={request.username}
        />
        <Form.Input
          label="Requesting user's email"
          readOnly
          transparent
          value={request.email}
        />
        <Form.Input
          label="Metadata record type"
          readOnly
          transparent
          value={request.entityType}
        />

        <Form.Group grouped>
          <label>Details</label>
          {detailsList(requestDetails)}
        </Form.Group>

        {processed || request.status !== "PENDING" ? null : (
          <>
            <Form.Group grouped>
              <label>Action</label>
              <Form.Radio
                label="Approve"
                name="status"
                value="APPROVED"
                checked={request.updateStatus === "APPROVED"}
                onChange={handleStatusChange}
              />
              <Form.Radio
                label="Cancel"
                name="status"
                value="CANCELLED"
                checked={request.updateStatus === "CANCELLED"}
                onChange={handleStatusChange}
              />
            </Form.Group>
            {deleteSelected ? (
              <Form.Input
                label={request.entityType === "historical_deployment" ? `Have you manually deleted this historical deployment record? Please type 'yes' in the box below to confirm.` : `To confirm the deletion of this record, please type 'delete' in the box below.`}
                placeholder={request.entityType === "historical_deployment" ? "yes" : "delete"}
                required
                onChange={(e, {value}) => {
                  this.setState({
                    confirmDeletion: value.toLowerCase()
                  });
                }}
              />
            ) : null}
          </>
        )}
      </>
    );

    const successContent = <p>Request {request.name} processed.</p>;

    return (
      <FormModal
        formSubmitAPI={deletionRequestUpdate}
        formData={request}
        checkFormValid={checkFormValid}
        successHeader="Confirmation"
        onSuccess={successAction}
        success={success}
        testId="deletion-request-update"
        formInputs={formInputs}
        headerIcon="trash"
        successContent={successContent}
        headerContent="Process request"
        submitContent="Apply"
        onClose={onClose}
        open
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles,
    userOrganisation: user.organisationId,
    userId: user.userId,
    username: user.username,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(DeletionRequest);
