import React, {Fragment} from "react";
import FilteredTable from "./FilteredTable";
import axios from "axios";
import {
  projectById,
  speciesSearch,
  transmitter,
  transmitterListConfig,
  tagsZip,
  transmitterById,
  tagSearch,
  receiverById
} from "../axios/api";
import FilterDropDown from "./FilterDropDown";
import {
  Roles,
  toDisplayUtc,
  getSelectedTransmitterDeployment,
  csvAndZipTimestamp,
  isAdminNotATFAdmin
} from "./helpers";
import moment from "moment";
import {connect} from "react-redux";
import FilterDateSelector from "./FilterDateSelector";
import TransmitterCreateDeploy from "./TransmitterCreateDeploy";
import FilterSearchable from "./FilterSearchable";
import LoginForm from "./LoginForm";
import ModalContainer from "./ModalContainer";
import ProjectView from "./ProjectView";
import TransmitterView from "./TransmitterView";
import TransmitterEdit from "./TransmitterEdit";
import TransmitterRecoveryCreate from "./TransmitterRecoveryCreate";
import TransmitterReleaseRecoveryEdit from "./TransmitterReleaseRecoveryEdit";
import FileDownload from "js-file-download";
import {Message} from "semantic-ui-react";
import Receiver from "./Receiver";
import TransmitterReleaseRecoveryDelete from "./TransmitterReleaseRecoveryDelete";
import RequestDelete from "./RequestDelete";

class TransmitterTable extends React.PureComponent {
  actions = [];

  tableColumns = [
    {
      title: "Transmitter ID",
      dataSource: "transmitterIds",
      default: "",
      isAPIFilter: true,
      filter: () => "transmitterId",
      component: {
        control: FilterSearchable,
        props: {
          searchApi: tagSearch,
          resetInputValueOnSubmit: true,
          replaceNonAlphanumericCharsWithHyphen: true,
          isSearchable: true,
          optionsFetcher: this.getTransmitterIdOptions,
          multiple: true,
          noResultsMessage:
            " does not currently exist in the database. If you are the owner, please create a first " +
            "deployment for this transmitter."
        }
      },
      filterOptionsKey: "transmitterId"
    },
    {
      title: "Transmitter Type",
      dataSource: "transmitterType",
      default: ""
    },
    {
      title: "Serial Number",
      dataSource: "serialNumber",
      default: ""
    },
    {
      title: "Project Name",
      dataSource: "projectName",
      default: "",
      filter: () => "projectId",
      isAPIFilter: true,
      component: {
        control: FilterDropDown,
        props: {
          narrows: [
            {field: "transmitterId", by: "projectId"},
            {field: "species", by: "projectId"}
          ]
        }
      },
      filterOptionsKey: "project"
    },
    {
      title: "Sensor Types",
      dataSource: "sensorTypes",
      default: ""
    },
    {
      title: "Species Common Name",
      dataSource: "speciesName",
      default: ""
    },
    {
      title: "Species Scientific Name",
      dataSource: "speciesScientificName",
      default: ""
    },
    {
      title: "Species Name",
      dataSource: "species",
      hide: true,
      icon: "",
      default: "",
      isAPIFilter: true,
      filter: () => "speciesId",
      component: {
        control: FilterSearchable,
        props: {
          searchApi: speciesSearch,
          narrows: [
            {
              field: "transmitterId",
              by: "speciesId"
            },
            {field: "project", by: "speciesIds"}
          ],
          isSearchable: true,
          optionsFetcher: this.getSpeciesOptions,
          multiple: true,
          resetInputValueOnSubmit: true
        }
      },
      filterOptionsKey: "species"
    },
    {
      title: "Deployment Date (UTC)",
      dataSource: "releaseDate",
      default: "-"
    },
    {
      title: "Deployment Start Date (UTC)",
      hide: true,
      isAPIFilter: true,
      default: null,
      filter: () => "releaseStartDate",
      dataSource: "releaseStartDateFilter",
      component: {
        control: FilterDateSelector
      }
    },
    {
      title: "Deployment End Date (UTC)",
      isAPIFilter: true,
      hide: true,
      filter: () => "releaseEndDate",
      dataSource: "releaseEndDateFilter",
      default: null,
      component: {
        control: FilterDateSelector,
        props: {end: true}
      }
    },
    {
      title: "Deployment Locality",
      dataSource: "releaseLocality",
      default: ""
    },
    {
      title: "Deployment Location (Long, Lat) WGS84",
      dataSource: "releaseLocation",
      default: ""
    },
    {
      title: "Deployment Location Bounding Box (N,S,W,E) WGS84",
      hide: true,
      dataSource: "releaseLocation",
      isAPIFilter: true,
      default: "",
      filter: () => "releaseBBox",
      pattern: "([-]?[0-9]+(.[0-9]+)?,){3}[-]?[0-9]+(.[0-9]+)?$"
    },
    {
      title: "Status",
      dataSource: "status",
      default: ""
    },
    {
      title: "Recovery Date (UTC)",
      dataSource: "recoveryDate",
      default: "-"
    },
    {
      title: "Recovery Location (Long, Lat) WGS84",
      dataSource: "recoveryLocation",
      default: "-"
    },
    {
      title: "Embargo Date (UTC)",
      dataSource: "releaseEmbargoDate",
      default: "-"
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      showViewModal: false,
      showEditModal: false,
      showDeleteDeployment: false,
      showDeleteRecovery: false,
      showDeleteTransmitter: false,
      showRequestDeleteRecovery: false,
      showRequestDeleteDeployment: false,
      showRequestDeleteTransmitter: false,
      showCreateModal: props.match?.params?.modal === "deploy" ? true : false,
      showRecoverModal: props.match?.params?.modal === "recover" ? true : false,
      activeRelease: null,
      downloadDataEnabled: true
    };
    this.getTransmitterReleases = this.getTransmitterReleases.bind(this);
    this.getTransmitter = this.getTransmitter.bind(this);
    this.update = this.update.bind(this);
    this.modalOpener = this.modalOpener.bind(this);
    this.updateRecovery = this.updateRecovery.bind(this);
    this.updateRelease = this.updateRelease.bind(this);
    this.updateInternalTag = this.updateInternalTag.bind(this);
  }

  componentWillUnmount() {
    if (this.transmitterCt) this.transmitterCt.cancel();
  }
  embargoCurrent(date) {
    return moment(date).isAfter(moment.utc());
  }

  getTransmitterIdOptions(query) {
    if (this.searchableFilterOptionsFetcherCt)
      this.searchableFilterOptionsFetcherCt.cancel();
    this.searchableFilterOptionsFetcherCt = axios.CancelToken.source();
    return tagSearch("", this.searchableFilterOptionsFetcherCt.token, query)
      .then((r) => {
        this.searchableFilterOptionsFetcherCt = null;
        return r.data;
      })
      .catch((err) => {
        if (axios.isCancel(err))
          console.debug("searchableFilterOptionsFetcher cancelled");
      });
  }

  getSpeciesOptions(query) {
    if (this.speciesFilterOptionsFetcherCt)
      this.speciesFilterOptionsFetcherCt.cancel();
    this.speciesFilterOptionsFetcherCt = axios.CancelToken.source();
    return speciesSearch("", this.speciesFilterOptionsFetcherCt.token, query)
      .then((r) => {
        this.speciesFilterOptionsFetcherCt = null;
        return r.data;
      })
      .catch((err) => {
        if (axios.isCancel(err))
          console.debug("searchableFilterOptionsFetcher cancelled");
      });
  }

  fetchTransmitter(id) {
    return transmitterById(id).then((t) => {
      return t;
    });
  }

  getRowFromTransmitter(transmitter, releaseId) {
    // Create data for a table row
    // Use this to update the transmitter details or add a new row in the table

    const {embargoCurrent} = this;
    const idx = transmitter.history
      ? transmitter.history.findIndex((e) => e.release.id === releaseId)
      : 0;

    let data;

    if (transmitter.history) {
      const h = transmitter.history[idx];
      data = {
        id: h.release.id,
        idType: "Release",
        projectName: h.release.projectName,
        recoveryDate: h.recovery
          ? toDisplayUtc(h.recovery.recoveryDatetime, "UTC")
          : null,
        releaseTimezone: h.release.releaseTimezone,
        recoveryLocation: h.recovery
          ? `${h.recovery.location.longitude}, ${h.recovery.location.latitude}`
          : null,
        releaseDate: toDisplayUtc(
          h.release.releaseDate,
          h.release.releaseTimezone
        ),
        releaseEmbargoDate: embargoCurrent(h.release.embargoDate)
          ? toDisplayUtc(h.release.embargoDate, "UTC")
          : "-",
        releaseId: h.release.id,
        releaseLocality: h.release.releaseLocality,
        releaseLocation: `${h.release.releaseLongitude}, ${h.release.releaseLatitude}`,
        sensorTypes: transmitter.sensors.map((s, i) => (
          <Fragment key={i}>
            {s.sensorTypeName}
            <br />
          </Fragment>
        )),
        serialNumber: transmitter.serialNumber,
        species: h.release.animal?.species.name,
        speciesName:
          h.release.animal !== null
            ? `${h.release.animal.species.name.match(/^[0-9]{8}/)[0]} - ${
                h.release.animal.species.commonName
              }`
            : "-",
        speciesScientificName:
          h.release.animal !== null
            ? `${h.release.animal?.species.name.match(/^[0-9]{8}/)[0]} - ${
                h.release.animal?.species.scientificName
              }`
            : "-",
        status: transmitter.status,
        tagId: transmitter.id,
        transmitterIds: transmitter.sensors.map((s, i) => (
          <Fragment key={i}>
            {s.transmitterId}
            <br />
          </Fragment>
        )),
        transmitterType: transmitter.deviceModelName
      };
      return data;
    } else {
      // no history so show record with missing deployment data
      data = {
        idType: "Transmitter",
        projectName: transmitter.project.name,
        sensorTypes: transmitter.sensors.map((s, i) => (
          <Fragment key={i}>
            {s.sensorTypeName}
            <br />
          </Fragment>
        )),
        serialNumber: transmitter.serialNumber,
        status: transmitter.status,
        tagId: transmitter.id,
        transmitterIds: transmitter.sensors.map((s, i) => (
          <Fragment key={i}>
            {s.transmitterId}
            <br />
          </Fragment>
        )),
        transmitterType: transmitter.deviceModelName
      };
    }

    return data;
  }

  convertDtoToRow(t) {
    const {embargoCurrent} = this;

    return {
      ...t,
      id: t.releaseId,
      idType: "Release",
      transmitterIds: t.transmitterIds.split(",").map((s, i) => (
        <Fragment key={i}>
          {s}
          <br />
        </Fragment>
      )),
      sensorTypes: t.sensorTypes.split(",").map((s, i) => (
        <Fragment key={i}>
          {s}
          <br />
        </Fragment>
      )),
      releaseDate: toDisplayUtc(t.releaseDate, t.releaseTimezone),
      releaseEmbargoDate: embargoCurrent(t.releaseEmbargoDate)
        ? toDisplayUtc(t.releaseEmbargoDate, "UTC")
        : "-",
      recoveryDate: t.recoveryDatetime
        ? toDisplayUtc(t.recoveryDatetime, "UTC")
        : t.recoveryDate
    };
  }

  rows(content) {
    return content.reduce((accum, t) => {
      const history = this.convertDtoToRow(t);
      return [...accum, history];
    }, []);
  }

  getTransmitterReleases(pageSize, page, filter) {
    this.setState({tableFilters: {...filter}});
    if (this.transmitterCt) this.transmitterCt.cancel();
    this.transmitterCt = axios.CancelToken.source();
    return transmitter(
      {size: pageSize, page: page, ...filter},
      this.transmitterCt.token
    )
      .then((result) => {
        this.setState({downloadDataEnabled: result.data.totalElements >= 1});
        this.transmitterCt = null;
        result.data.recordCount = result.data.content.length;
        const content = this.rows(result.data.content);
        result.data.content = content;
        // Update dataSource
        this.setState((state) => {
          return {
            dataSource:
              page > 0 ? [...state.dataSource, ...content] : [...content]
          };
        });
        return result.data;
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.debug("transmitterCt cancelled");
        } else {
          return err;
        }
      });
  }

  allowView(transmitter) {
    const {roles, projects} = this.props;

    const allow =
      roles.includes(Roles.ROLE_ADMIN) ||
      roles.includes(Roles.ROLE_ATF_ADMIN) ||
      projects.map((p) => p.id).includes(transmitter.project?.id) ||
      (!transmitter.isProtected &&
        (!transmitter.releaseEmbargoDate ||
          transmitter.releaseEmbargoDate === "-"));

    return allow;
  }

  allowEdit(transmitter) {
    const {roles, projects} = this.props;

    const userProjectIds = projects.map((p) => p.id);
    const releaseProjectIds = transmitter.history.map(
      (h) => h.release.projectId
    );

    const allow =
      roles.includes(Roles.ROLE_ADMIN) ||
      roles.includes(Roles.ROLE_ATF_ADMIN) ||
      userProjectIds.some((up) => releaseProjectIds.includes(up)) ||
      userProjectIds.includes(
        getSelectedTransmitterDeployment(transmitter).releasePostDto.projectId
      ) ||
      (transmitter.owningReceiverId &&
        projects.map((p) => p.id).includes(transmitter.project.id));

    return allow;
  }

  getTransmitter(id) {
    // For transmitter view and edit any row (release) for the transmitter will do
    return new Promise((resolve, reject) => {
      const {dataSource} = this.state;
      let idx = dataSource.findIndex((e) => e.id === id);
      if (idx === -1) {
        // Status is NEW and there will only be one
        idx = dataSource.findIndex((e) => e.tagId === id);
      }
      const selectedRelease = dataSource[idx];
      this.setState({
        activeRelease: selectedRelease
      });

      this.fetchTransmitter(selectedRelease?.tagId).then(async (payload) => {
        const {activeRelease} = this.state;
        const selectedTransmitter = payload.transmitter;
        selectedTransmitter.owningReceiverId = activeRelease.owningReceiverId;

        if (selectedTransmitter) {
          this.actions.length = 0;
          if (this.allowView(selectedTransmitter)) {
            this.actions.push({
              name: "View Transmitter",
              onClick: () => {
                this.setState({
                  showViewModal: getSelectedTransmitterDeployment(
                    selectedTransmitter
                  )
                });
              }
            });
            this.actions.push({
              name: "View Transmitter Project",
              disableOnClick: true,
              onClick: () => {
                return projectById(selectedTransmitter.project.id).then(
                  (model) => {
                    const modal = (
                      <ModalContainer
                        {...this.props}
                        modal={ProjectView}
                        model={model}
                        noRedirectOnClose
                        onClose={() => {
                          this.setState({showViewProjectModal: null});
                        }}
                      />
                    );
                    this.setState({showViewProjectModal: modal});
                  }
                );
              }
            });
          }
          if (this.allowEdit(selectedTransmitter)) {
            if (selectedTransmitter.owningReceiverId) {
              this.actions.push({
                name: "Edit Internal Tag and Receiver",
                disableOnClick: true,
                onClick: () => {
                  return receiverById(
                    selectedTransmitter.owningReceiverId
                  ).then((model) => {
                    model.receiver.internalTag = {
                      ...model.receiver.internalTag,
                      sensorType:
                        model.receiver.internalTag.sensor.sensorTypeName,
                      idCode: model.receiver.internalTag.sensor.idCode.toString(),
                      transmitterId:
                        model.receiver.internalTag.sensor.transmitterId
                    };
                    const modal = (
                      <Receiver
                        {...model}
                        id={model.receiver.id}
                        edit={true}
                        onUpdate={this.updateInternalTag}
                        onClose={() => {
                          this.setState({showEditReceiverModal: null});
                        }}
                      />
                    );
                    this.setState({showEditReceiverModal: modal});
                  });
                }
              });
            } else {
              this.actions.push({
                name: "Edit Transmitter",
                onClick: () => {
                  this.setState({
                    showEditModal: getSelectedTransmitterDeployment(
                      selectedTransmitter
                    )
                  });
                }
              });
            }

            const rowHasDeployment = selectedTransmitter.history?.length > 0;
            const rowHasRecovery =
              activeRelease.recoveryDate !== undefined &&
              activeRelease.recoveryDate !== null;

            if (rowHasDeployment) {
              this.actions.push({
                name: "Edit Transmitter Deployment",
                onClick: () => {
                  this.setState({
                    showEditDeployment: getSelectedTransmitterDeployment(
                      selectedTransmitter,
                      activeRelease.id
                    )
                  });
                }
              });

              if (
                selectedTransmitter.history?.length > 1 &&
                selectedTransmitter.history[
                  selectedTransmitter.history.length - 1
                ].release.id === activeRelease.id &&
                selectedTransmitter.history[
                  selectedTransmitter.history.length - 1
                ].recovery === null
              ) {
                if (isAdminNotATFAdmin(this.props.roles)) {
                  this.actions.push({
                    name: "Delete Transmitter Deployment",
                    destructive: true,
                    onClick: () => {
                      this.setState({
                        showDeleteDeployment: getSelectedTransmitterDeployment(
                          selectedTransmitter,
                          activeRelease.id
                        )
                      });
                    }
                  });
                } else {
                  this.actions.push({
                    name: "Request Transmitter Deployment Deletion",
                    destructive: true,
                    onClick: () => {
                      this.setState({
                        showRequestDeleteDeployment: getSelectedTransmitterDeployment(
                          selectedTransmitter,
                          activeRelease.id
                        )
                      });
                    }
                  });
                }
              }
            }

            if (rowHasRecovery) {
              this.actions.push({
                name: "Edit Transmitter Recovery",
                onClick: () => {
                  this.setState({
                    showEditRecovery: getSelectedTransmitterDeployment(
                      selectedTransmitter,
                      activeRelease.id
                    )
                  });
                }
              });
              if (
                selectedTransmitter.history[
                  selectedTransmitter.history.length - 1
                ].release.id === activeRelease.id
              ) {
                if (isAdminNotATFAdmin(this.props.roles)) {
                  this.actions.push({
                    name: "Delete Recovery",
                    destructive: true,
                    onClick: () => {
                      this.setState({
                        showDeleteRecovery: getSelectedTransmitterDeployment(
                          selectedTransmitter,
                          activeRelease.id
                        )
                      });
                    }
                  });
                } else {
                  this.actions.push({
                    name: "Request Tag Recovery Deletion",
                    destructive: true,
                    onClick: () => {
                      this.setState({
                        showRequestDeleteRecovery: getSelectedTransmitterDeployment(
                          selectedTransmitter,
                          activeRelease.id
                        )
                      });
                    }
                  });
                }
              }
            } else if (
              selectedTransmitter.status === "DEPLOYED" &&
              selectedTransmitter.history
            ) {
              this.actions.push({
                name: "Recover Transmitter",
                onClick: () => {
                  this.setState({
                    showAddRecovery: getSelectedTransmitterDeployment(
                      selectedTransmitter,
                      activeRelease.id
                    )
                  });
                }
              });
              if (selectedTransmitter.history?.length === 1) {
                if (isAdminNotATFAdmin(this.props.roles)) {
                  this.actions.push({
                    name: "Delete Transmitter",
                    destructive: true,
                    onClick: () => {
                      this.setState({
                        showDeleteTransmitter: getSelectedTransmitterDeployment(
                          selectedTransmitter
                        )
                      });
                    }
                  });
                } else {
                  this.actions.push({
                    name: "Request Transmitter Deletion",
                    destructive: true,
                    onClick: () => {
                      this.setState({
                        showRequestDeleteTransmitter: getSelectedTransmitterDeployment(
                          selectedTransmitter
                        )
                      });
                    }
                  });
                }
              }
            }
          }

          let detailData = [];
          if (
            selectedTransmitter.owningReceiverId &&
            this.allowEdit(selectedTransmitter)
          ) {
            detailData = [
              {
                data: [
                  <Message warning>
                    <p>
                      Transmitter {selectedTransmitter.serialNumber} is an
                      internal tag for a receiver.
                    </p>
                    <p>
                      To edit the tag you will need to edit the associated
                      receiver.
                    </p>
                  </Message>
                ],
                icon: "microphone",
                title: "Internal Tag"
              }
            ];
          }

          const releaseId =
            activeRelease.status === "NEW"
              ? activeRelease.tagId
              : activeRelease.id;

          resolve({
            header: `Transmitter ${selectedTransmitter.serialNumber}`,
            data: detailData,
            row: this.getRowFromTransmitter(selectedTransmitter, releaseId)
          });
        } else {
          reject();
        }
      });
    });
  }

  getNewFocus(tagId) {
    const dataSource = [...this.state.dataSource];
    const newFocus = dataSource.reduce((accum, r) => {
      if (r.tagId === tagId) {
        accum.push({id: r.id});
      }
      return accum;
    }, []);

    return newFocus;
  }

  update(deployment) {
    /*
    Use this to update transmitter properties or to add a new transmitter deployment
    To update the correct table rows a deployment id (not transmitter id) is required
    All rows(releases) for the transmitter are updated.
    */

    let focus = {
      id: this.state.showEditModal.id
        ? this.state.showEditModal.id
        : deployment.id
    };
    const dataSource = [...this.state.dataSource];

    if (deployment.tag.status !== "NEW") {
      let idx;
      idx = dataSource.findIndex((e) => e.id === deployment.id);
      if (idx === -1) {
        dataSource.push({id: deployment.id, tagId: deployment.tag.id});
      }
      const newFocus = this.getNewFocus(deployment.tag.id);
      if (newFocus.length > 1) {
        focus = newFocus.filter((i) => i.id === this.state.activeRelease.id);
      }
    } else {
      focus = {tagId: deployment.tag.id};
    }

    this.setState({
      dataSource: dataSource,
      focusQuery: focus
    });
  }

  updateRowAfterTagRecoveryDeleted(response) {
    const dataSource = [...this.state.dataSource];
    const idx = dataSource.findIndex((e) => e.id === response.release.id);
    if (idx >= 0) {
      dataSource[idx].recoveryDate = null;
      dataSource[idx].recoveryDatetime = null;
      dataSource[idx].recoveryLocation = null;
      dataSource[idx].status = "DEPLOYED";
    }
    this.setState({
      activeRelease: dataSource[idx],
      focusQuery: {id: response.release.id}
    });
  }

  updateRelease(release) {
    const dataSource = [...this.state.dataSource];
    const idx = dataSource.findIndex((e) => e.id === release.id);
    if (idx >= 0) {
      const row = dataSource[idx];
      row.deploymentLocality = release.deploymentLocality;
      row.releaseDate = toDisplayUtc(
        release.releaseDate,
        release.releaseTimezone
      );
      row.releaseLocation = `${release.releaseLongitude}, ${release.releaseLatitude}`;
      this.setState({
        dataSource: dataSource,
        focusQuery: {id: row.id}
      });
    }
  }

  updateRecovery(recovery) {
    const dataSource = [...this.state.dataSource];
    const idx = dataSource.findIndex((e) => e.id === recovery.animalReleaseId);
    if (idx >= 0) {
      const row = dataSource[idx];
      row.status = recovery.status;
      row.recoveryDate = toDisplayUtc(
        recovery.recoveryDate,
        recovery.recoveryTimezone
      );
      row.recoveryLocation = `${recovery.location.longitude}, ${recovery.location.latitude}`;
      this.setState({
        dataSource: dataSource,
        focusQuery: {id: row.id}
      });
    }
  }

  updateInternalTag(receiver) {
    const dataSource = [...this.state.dataSource];
    let idx = dataSource.findIndex((e) => e.owningReceiverId === receiver.id);
    if (idx >= 0 && receiver.internalTag) {
      dataSource[idx].transmitterIds = [
        <Fragment key={0}>
          {receiver.internalTag.transmitterId}
          <br />
        </Fragment>
      ];
      dataSource[idx].transmitterType = receiver.internalTag.deviceModelName;
      dataSource[idx].serialNumber = receiver.internalTag.serialNumber;
      dataSource[idx].projectName = receiver.projectName;
      this.setState({
        dataSource: [...dataSource]
      });
    }
  }

  modalOpener(modalName, id) {
    let tagId = id;
    if (modalName !== "addRecovery") {
      const idx = this.state.dataSource.findIndex((e) => e.id === id);
      // The tag will be "NEW" if it is not in the dataSource
      tagId = this.state.dataSource[idx]?.tagId ?? tagId;
    }

    this.fetchTransmitter(tagId).then((payload) => {
      const selectedTransmitter = payload.transmitter;

      switch (modalName) {
        case "edit":
          this.setState({
            showEditModal: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "deleteTransmitter":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteTransmitter: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "requestDeleteTransmitter":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: getSelectedTransmitterDeployment(
              selectedTransmitter
            )
          });
          break;
        case "create":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: selectedTransmitter,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteDeployment: false,
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "deleteDeployment":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteDeployment: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "requestDeleteDeployment":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteDeployment: false,
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showRequestDeleteTransmitter: false
          });
          break;
        case "deleteRecovery":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteDeployment: false,
            showDeleteRecovery: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "requestDeleteRecovery":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteDeployment: false,
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "view":
          this.setState({
            showEditModal: false,
            showViewModal: selectedTransmitter,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteDeployment: false,
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "editDeployment":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showEditRecovery: false,
            showDeleteDeployment: false,
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "editRecovery":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showDeleteDeployment: false,
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        case "addRecovery":
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showAddRecovery: getSelectedTransmitterDeployment(
              selectedTransmitter
            ),
            showDeleteDeployment: false,
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
          break;
        default:
          this.setState({
            showEditModal: false,
            showViewModal: false,
            showCreateModal: false,
            showEditDeployment: false,
            showEditRecovery: false,
            showDeleteDeployment: false,
            showDeleteRecovery: false,
            showDeleteTransmitter: false,
            showRequestDeleteRecovery: false,
            showRequestDeleteDeployment: false,
            showRequestDeleteTransmitter: false
          });
      }
    });
  }

  getTableActions() {
    return [
      {
        name: "Create and Deploy Transmitter",
        onClick: () => {
          this.setState({showCreateModal: true});
        }
      },
      {
        name: "Recover Transmitter",
        onClick: () => {
          this.setState({showRecoverModal: true});
        }
      },
      {
        name: "Download Data",
        disableOnClick: true,
        disabledNoData: !this.state.downloadDataEnabled,
        onClick: () => {
          return tagsZip(this.state.tableFilters).then((response) => {
            FileDownload(
              response.data,
              `IMOS_transmitter_deployment_metadata_${csvAndZipTimestamp()}.zip`
            );
          });
        }
      }
    ];
  }

  render() {
    const {
      tableColumns,
      actions,
      getTransmitterReleases,
      update,
      getTransmitter,
      modalOpener
    } = this;
    const {
      showViewModal,
      showEditModal,
      showCreateModal,
      showRecoverModal,
      showEditDeployment,
      showAddRecovery,
      showEditRecovery,
      showViewProjectModal,
      showEditReceiverModal,
      showDeleteDeployment,
      showDeleteRecovery,
      showRequestDeleteRecovery,
      showRequestDeleteDeployment,
      showRequestDeleteTransmitter,
      focusQuery,
      showDeleteTransmitter
    } = this.state;

    const closeModals = () => {
      this.setState({
        showViewModal: false,
        showCreateModal: false,
        showRecoverModal: false,
        showEditDeployment: false,
        showAddRecovery: false,
        showEditRecovery: false,
        showEditModal: false,
        showEditReceiverModal: false,
        showDeleteDeployment: false,
        showDeleteRecovery: false,
        showDeleteTransmitter: false,
        showRequestDeleteRecovery: false,
        showRequestDeleteDeployment: false,
        showRequestDeleteTransmitter: false
      });
    };

    const modal = showViewModal ? (
      <TransmitterView
        open
        transmitter={showViewModal}
        onClose={() => {
          this.setState({showViewModal: false});
        }}
        modalOpener={modalOpener}
      />
    ) : (showCreateModal || showRecoverModal) && !this.props.accessToken ? (
      <LoginForm
        open={!this.props.accessToken}
        onClose={(success) => {
          if (!success) closeModals();
        }}
      />
    ) : showCreateModal ? (
      <TransmitterCreateDeploy
        onClose={closeModals}
        onUpdate={update}
        modalOpener={modalOpener}
      />
    ) : showRecoverModal && this.props.roles?.length > 0 ? (
      <TransmitterRecoveryCreate
        value={showRecoverModal}
        onUpdate={this.updateRecovery}
        onClose={closeModals}
      />
    ) : showEditDeployment ? (
      <TransmitterReleaseRecoveryEdit
        editRelease
        onUpdate={this.updateRelease}
        transmitter={showEditDeployment}
        onClose={closeModals}
        openViewForTag={() => {
          this.setState({
            showEditDeployment: false,
            showViewModal: showEditDeployment
          });
        }}
      />
    ) : showDeleteDeployment ? (
      <TransmitterReleaseRecoveryDelete
        deleteDeployment
        onUpdate={(response) => {
          this.setState({
            focusQuery: {
              id: response.id,
              eventType: "delete"
            }
          });
        }}
        transmitter={showDeleteDeployment}
        onClose={closeModals}
        openViewForTag={() => {
          this.setState({
            showDeleteDeployment: false,
            showViewModal: showDeleteDeployment
          });
        }}
      />
    ) : showRequestDeleteDeployment ? (
      <RequestDelete
        entity="tag_deployment"
        name={
          "Project: " +
          showRequestDeleteDeployment.tagPostDto.project.name +
          ", TransmitterIDs: [" +
          showRequestDeleteDeployment.tagPostDto?.sensors
            .map((sensor) => sensor.transmitterId)
            .join("; ") +
          "], SerialNumber: " +
          showRequestDeleteDeployment.tagPostDto.serialNumber +
          ", DeploymentDate: " +
          showRequestDeleteDeployment.releasePostDto.releaseDate +
          ", DeploymentTimezone: " +
          showRequestDeleteDeployment.releasePostDto.releaseTimezone +
          ", DeploymentLocation: (" +
          showRequestDeleteDeployment.releasePostDto.longitude +
          "; " +
          showRequestDeleteDeployment.releasePostDto.latitude +
          "), Species: " +
          showRequestDeleteDeployment.tagPostDto.speciesName
        }
        id={showRequestDeleteDeployment.releasePostDto.id}
        onClose={closeModals}
      />
    ) : showEditRecovery ? (
      <TransmitterReleaseRecoveryEdit
        editRecovery
        onUpdate={this.updateRecovery}
        transmitter={showEditRecovery}
        onClose={closeModals}
        openViewForTag={() => {
          this.setState({
            showEditRecovery: false,
            showViewModal: showEditRecovery
          });
        }}
      />
    ) : showDeleteRecovery ? (
      <TransmitterReleaseRecoveryDelete
        deleteRecovery
        onUpdate={(response) => {
          this.updateRowAfterTagRecoveryDeleted(response);
        }}
        transmitter={showDeleteRecovery}
        onClose={closeModals}
        openViewForTag={() => {
          this.setState({
            showDeleteRecovery: false,
            showViewModal: showDeleteRecovery
          });
        }}
      />
    ) : showRequestDeleteRecovery ? (
      <RequestDelete
        entity="tag_recovery"
        name={
          "Project: " +
          showRequestDeleteRecovery.tagPostDto.project.name +
          ", TransmitterIDs: [" +
          showRequestDeleteRecovery.tagPostDto?.sensors
            .map((sensor) => sensor.transmitterId)
            .join("; ") +
          "], SerialNumber: " +
          showRequestDeleteRecovery.tagPostDto.serialNumber +
          ", RecoveryDate: " +
          showRequestDeleteRecovery.recoveryPostDto.recoveryDate +
          ", RecoveryTimezone: " +
          showRequestDeleteRecovery.recoveryPostDto.recoveryTimezone +
          ", RecoveryLocation: (" +
          showRequestDeleteRecovery.recoveryPostDto.longitude +
          "; " +
          showRequestDeleteRecovery.recoveryPostDto.latitude +
          "), DeploymentLocation: (" +
          showRequestDeleteRecovery.releasePostDto.longitude +
          "; " +
          showRequestDeleteRecovery.releasePostDto.latitude +
          "), Species: " +
          showRequestDeleteRecovery.tagPostDto.speciesName
        }
        id={showRequestDeleteRecovery.releasePostDto.id}
        onClose={closeModals}
      />
    ) : showDeleteTransmitter ? (
      <TransmitterReleaseRecoveryDelete
        deleteTransmitter
        onUpdate={(response) => {
          this.setState({
            focusQuery: {
              id: response.releaseId ? response.releaseId : null,
              eventType: "delete"
            }
          });
        }}
        transmitter={showDeleteTransmitter}
        onClose={closeModals}
        openViewForTag={() => {
          this.setState({
            showDeleteTransmitter: false,
            showViewModal: showDeleteTransmitter
          });
        }}
      />
    ) : showRequestDeleteTransmitter ? (
      <RequestDelete
        entity="transmitter"
        name={
          "Project: " +
          showRequestDeleteTransmitter.tagPostDto.project.name +
          ", TransmitterIDs: [" +
          showRequestDeleteTransmitter.tagPostDto?.sensors
            .map((sensor) => sensor.transmitterId)
            .join("; ") +
          "], CodeSpace: " +
          showRequestDeleteTransmitter.tagPostDto.codeSpaceName +
          ", Status: " +
          showRequestDeleteTransmitter.tagPostDto.status +
          ", TransmitterType: " +
          showRequestDeleteTransmitter.tagPostDto.deviceModelName +
          ", SerialNumber: " +
          showRequestDeleteTransmitter.tagPostDto.serialNumber +
          ", Species: " +
          showRequestDeleteTransmitter.tagPostDto.speciesName
        }
        id={showRequestDeleteTransmitter.releasePostDto.tagId}
        onClose={closeModals}
      />
    ) : showAddRecovery ? (
      <TransmitterRecoveryCreate
        value={showRecoverModal}
        onUpdate={this.updateRecovery}
        onClose={closeModals}
        transmitter={showAddRecovery}
      />
    ) : showEditModal ? (
      <TransmitterEdit
        onClose={closeModals}
        onUpdate={update}
        transmitter={showEditModal}
      />
    ) : (
      showEditReceiverModal ?? showViewProjectModal
    );

    return (
      <>
        <FilteredTable
          header="Transmitters"
          headerIcon="tag"
          fetchingData={true}
          filterOptionsFetcher={transmitterListConfig}
          columns={tableColumns}
          fetcher={getTransmitterReleases}
          detailFetcher={getTransmitter}
          pageSize={50}
          icon="tag"
          actions={actions}
          tableActions={this.getTableActions()}
          focus={focusQuery}
          detailKey="id"
        />
        {modal}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(TransmitterTable);
