import React from "react";
import {Button, Form, Segment} from "semantic-ui-react";
import PropTypes from "prop-types";

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialiseFilters();
    this.state.defaultInputValues = {};
    this.state.filterApplyButtonClicked = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
    this.handleApplyFilters = this.handleApplyFilters.bind(this);
  }

  initialiseFilters() {
    return this.props.filters.reduce((filters, item) => {
      return {
        ...filters,
        [item["dataSource"]]: item.component?.props?.isSearchable
          ? []
          : item.default
      };
    }, {});
  }

  handleChange(e, {name, value}) {
    if (e?.props?.narrows) {
      this.props.narrow(e.props.narrows, {
        fieldName: name,
        value: value
      });
    }

    this.setState(() => ({[name]: value}));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filterApplyButtonClicked !== this.state.filterApplyButtonClicked && this.state.filterApplyButtonClicked
    ) {
      this.setState({filterApplyButtonClicked: false});
    }
  }

  handleApplyFilters(e) {
    this.props.applyFilters(e);
    this.setState({filterApplyButtonClicked: true});
    this.props.filters.forEach((f) => {
      if (f.component?.props?.resetInputValueOnSubmit) {
        this.setState((s) => ({
          defaultInputValues: {
            ...s.defaultInputValues,
            [f["dataSource"]]: f.default
          }
        }));
      }
    });
  }

  handleResetFilters() {
    this.setState(this.initialiseFilters());
    this.props.resetNarrowing();
  }

  render() {
    const {filters, columns} = this.props;
    let j = 0;
    let accumulator = [[]];

    const filterInputs = filters.reduce((accumulator, filter, index) => {
      if (!filter.hideFilter) {
        if (columns && index % columns === 0) {
          j = index / columns;
          accumulator[j] = [];
        }
        const extraProps = filter.component ? filter.component.props : {};
        accumulator[j].push(
          <Form.Field
            {...extraProps}
            control={filter.component ? filter.component.control : Form.Input}
            label={filter.title}
            key={filter.title}
            placeholder={
              filter.placeholder ? filter.placeholder : `Filter ${filter.title}`
            }
            value={this.state[filter.dataSource] ?? filter.default}
            name={filter.dataSource}
            onChange={this.handleChange}
            disabled={filter.disabled}
            pattern={filter.pattern ? filter.pattern : ".*"}
            defaultInputValue={
              this.state.defaultInputValues[filter.dataSource] ?? null
            }
            filterApplyButtonClicked={this.state.filterApplyButtonClicked}
          />
        );
      }
      return accumulator;
    }, accumulator);

    if (filterInputs[j].length === columns) {
      j++;
      filterInputs.push([]);
    }
    for (let i = filterInputs[j].length; i < columns - 1; i++) {
      filterInputs[j][i] = <Form.Field key={i} />;
    }
    filterInputs[j][columns - 1] = (
      <Form.Field key="buttons">
        <Button className="at-filter-button" primary>
          Apply Filters
        </Button>
        <Button className="at-filter-button" onClick={this.handleResetFilters}>
          Reset Filters
        </Button>
      </Form.Field>
    );

    return (
      <Segment attached="top" className="at-filter">
        <Form size="tiny" onSubmit={this.handleApplyFilters}>
          {filterInputs.map((f, i) => (
            <Form.Group widths={columns} key={i}>
              {f}
            </Form.Group>
          ))}
        </Form>
      </Segment>
    );
  }
}

Filter.propTypes = {
  filters: PropTypes.array.isRequired,
  applyFilters: PropTypes.func.isRequired,
  columns: PropTypes.number,
  narrow: PropTypes.func,
  resetNarrowing: PropTypes.func
};

export default Filter;
