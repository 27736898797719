import React from "react";
import {connect} from "react-redux";
import StationView from "./StationView";
import StationEdit from "./StationEdit";
import StationCreate from "./StationCreate";
import FilteredTable from "./FilteredTable";
import axios from "axios";
import {
  station,
  stationById,
  stationListConfig,
  stationCsv
} from "../axios/api";
import FilterDropDown from "./FilterDropDown";
import {Roles, csvAndZipTimestamp, isAdminNotATFAdmin} from "./helpers";
import FileDownload from "js-file-download";
import ReceiverDeploymentCreate from "./ReceiverDeploymentCreate";
import LoginForm from "./LoginForm";
import ProjectCreate from "./ProjectCreate";
import Installation from "./Installation";
import StationDelete from "./StationDelete";
import RequestDelete from "./RequestDelete";

class StationTable extends React.Component {
  tableColumns = [
    {
      title: "Station Name",
      dataSource: "name",
      default: "",
      isAPIFilter: true,
      filter: () => "id",
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "station"
    },
    {
      title: "Installation Name",
      dataSource: "installationName",
      default: "",
      isAPIFilter: true,
      filter: () => "installationId",
      component: {
        control: FilterDropDown
      },
      filterOptionsKey: "installation",
      placeholder: "Filter Installation Name"
    },
    {
      title: "Project Name",
      dataSource: "installationProjectName",
      default: "",
      isAPIFilter: true,
      filter: () => "projectId",
      component: {
        control: FilterDropDown,
        props: {
          narrows: [{field: "installation", by: "projectId"}]
        }
      },
      filterOptionsKey: "project",
      placeholder: "Filter Project Name"
    },
    {
      title: "Active",
      dataSource: "active",
      default: "",
      width: "5%",
      isAPIFilter: true,
      filter: () => "isActive",
      icons: {
        YES: {color: "green", name: "circle"},
        NO: {color: "red", name: "circle"}
      },
      component: {
        control: FilterDropDown,
        props: {
          options: [
            {text: "NO", value: false},
            {text: "YES", value: true}
          ],
          narrows: [
            {field: "project", by: "isActive"},
            {field: "installation", by: "isActive"}
          ]
        }
      }
    },
    {
      title: "Last Deployed Receiver Location (Long, Lat) - WGS84 datum",
      dataSource: "lastDeployedLocation",
      default: "",
      width: "10%"
    },
    {
      title: "First Deployment Date (UTC)",
      dataSource: "firstDeploymentDate",
      default: "",
      width: "10%"
    },
    {
      title: "Last Receiver Recovery Date (UTC)",
      dataSource: "latestRecoveryDate",
      default: "",
      width: "10%"
    }
  ];

  actions = [];

  constructor(props) {
    super(props);
    this.state = {
      showModal: props.match?.params?.installationId ? "create" : "none",
      focusQuery: undefined,
      dataSource: [],
      downloadDataEnabled: true
    };

    this.getStations = this.getStations.bind(this);
    this.getStation = this.getStation.bind(this);
    this.modalOpener = this.modalOpener.bind(this);
  }

  componentWillUnmount() {
    if (this.stationCt) this.stationCt.cancel();
  }

  getStations(pageSize, page, filter) {
    if (this.stationCt) this.stationCt.cancel();
    this.stationCt = axios.CancelToken.source();
    return station(
      {size: pageSize, page: page, ...filter},
      this.stationCt.token
    ).then((result) => {
      this.setState({downloadDataEnabled: result.data.totalElements >= 1});
      this.stationCt = null;
      const content = result.data.content.map((s) => {
        return {
          ...s,
          active: s.active ? "YES" : "NO",
          lastDeployedLocation:
            s.lastDeployedLocation?.longitude &&
            s.lastDeployedLocation?.latitude
              ? `${s.lastDeployedLocation.longitude}, ${s.lastDeployedLocation.latitude}`
              : ""
        };
      });
      result.data.content = content;
      // Update dataSource
      this.setState((state) => {
        return {
          tableFilters: {...filter},
          dataSource: [...state.dataSource, ...content]
        };
      });
      return result.data;
    });
  }

  getStation(id) {
    return stationById(id)
      .then((result) => {
        this.setState({
          selectedStation: {
            name: result.data.name,
            id: result.data.id,
            project: result.data.installation.project.name
          }
        });

        const station = {
          header: result.data.name,
          data: [],
          row: {
            ...result.data,
            active: result.data.active ? "YES" : "NO",
            installationName: result.data.installation.name,
            installationProjectName: result.data.installation.project.name,
            installationProjectId: result.data.installation.project.id,
            lastDeployedLocation:
              result.lastDeployedLocation?.longitude &&
              result.lastDeployedLocation?.latitude
                ? `${result.data.lastDeployedLocation.longitude}, ${result.data.lastDeployedLocation.latitude}`
                : ""
          },
          id: id
        };
        this.actions = [];
        this.actions.push({
          name: "View Station",
          onClick: () => {
            this.setState({showModal: "view"});
          }
        });
        if (
          this.props.roles.includes(Roles.ROLE_ADMIN) ||
          this.props.roles.includes(Roles.ROLE_ATF_ADMIN) ||
          this.props.projects.find(
            (p) => station.row.installation.project.id === p.id
          )
        ) {
          this.actions.push({
            name: "Edit Station",
            onClick: () => {
              this.setState({showModal: "edit"});
            }
          });

          if (
            !isAdminNotATFAdmin(this.props.roles) &&
            result.data.lastDeployedLocation === null
          ) {
            this.actions.push({
              name: "Request Delete",
              destructive: true,
              onClick: () => {
                this.setState({showModal: "request-delete"});
              }
            });
          }
        }
        if (isAdminNotATFAdmin(this.props.roles)) {
          this.actions.push({
            name: "Delete Station",
            destructive: true,
            onClick: () => {
              this.setState({showModal: "delete"});
            }
          });
        }
        this.setState({station: id});
        return station;
      })
      .catch((result) => {
        return result;
      });
  }

  modalOpener(modalName) {
    this.setState({showModal: modalName});
  }

  getTableActions() {
    return [
      {
        name: "Create Station",
        onClick: () => {
          this.setState({showModal: "create"});
        }
      },
      {
        name: "Download Data",
        disableOnClick: true,
        disabledNoData: !this.state.downloadDataEnabled,
        onClick: () => {
          return stationCsv(this.state.tableFilters).then((response) => {
            FileDownload(
              response.data,
              `IMOS_receiver_stations_${csvAndZipTimestamp()}.csv`
            );
          });
        }
      }
    ];
  }

  render() {
    const {
      getStations,
      getStation,
      editStation,
      tableColumns,
      actions,
      modalOpener
    } = this;
    const {focusQuery, showModal, station} = this.state;

    let modal = null;
    if (showModal === "create" && !this.props.accessToken) {
      modal = (
        <LoginForm
          open
          onClose={(success) => {
            if (!success) this.setState({showModal: "none"});
          }}
        />
      );
    } else if (showModal === "create") {
      modal = (
        <StationCreate
          modalOpener={modalOpener}
          installationId={this.props.match?.params?.installationId}
          onUpdate={(newStation) => {
            this.setState(
              (state) => {
                return {
                  dataSource: [...state.dataSource, newStation]
                };
              },
              () => {
                this.setState({
                  focusQuery: {id: newStation.id},
                  newStation: newStation
                });
              }
            );
          }}
          onClose={() => {
            this.setState({showModal: "none"});
          }}
        />
      );
    } else if (showModal === "view") {
      modal = (
        <StationView
          station={station}
          modalOpener={modalOpener}
          onClose={() => {
            this.setState({showModal: "none"});
          }}
        />
      );
    } else if (showModal === "delete") {
      modal = (
        <StationDelete
          station={station}
          modalOpener={modalOpener}
          onClose={() => {
            this.setState({showModal: "none"});
          }}
          onUpdate={() => {
            this.setState({
              focusQuery: {
                id: station,
                eventType: "delete"
              }
            });
          }}
        />
      );
    } else if (showModal === "request-delete") {
      modal = (
        <RequestDelete
          entity="station"
          name={
            "Project: " +
            this.state.selectedStation.project +
            ", Station: " +
            this.state.selectedStation.name
          }
          id={this.state.selectedStation.id}
          onClose={() => {
            this.setState({showModal: "none"});
          }}
          onUpdate={() => {
            this.setState({
              focusQuery: null
            });
          }}
        />
      );
    } else if (showModal === "deployReceiver") {
      modal = (
        <ReceiverDeploymentCreate
          station={this.state.newStation}
          onUpdate={(deployment) => {
            this.getStation(deployment.stationId).then((updatedStation) => {
              this.setState(
                (state) => {
                  const dataSource = [...state.dataSource];
                  const idx = dataSource.findIndex(
                    (e) => e.id === updatedStation.row.id
                  );
                  dataSource[idx] = updatedStation.row;
                  return {
                    dataSource: dataSource
                  };
                },
                () => {
                  this.setState({
                    focusQuery: {id: updatedStation.row.id}
                  });
                }
              );
            });
          }}
          onClose={() => {
            this.setState({showModal: "none"});
          }}
        />
      );
    } else if (showModal === "edit") {
      modal = (
        <StationEdit
          station={station}
          modalOpener={modalOpener}
          onUpdate={(station) => {
            this.setState(
              (state) => {
                const dataSource = [...state.dataSource];
                const idx = dataSource.findIndex((e) => e.id === station.id);
                station.location = {
                  longitude: station.longitude,
                  latitude: station.latitude
                };
                dataSource[idx] = {...dataSource[idx], ...station};
                return {
                  dataSource: dataSource
                };
              },
              () => {
                this.setState({
                  focusQuery: {id: station.id},
                  station: station.id,
                  showModal: "view"
                });
              }
            );
          }}
          onClose={() => {
            this.setState({showModal: "none"});
          }}
        />
      );
    } else if (showModal === "createProject") {
      modal = (
        <ProjectCreate
          open={true}
          onClose={({success}) => {
            this.setState({open: !success});
          }}
        />
      );
    } else if (showModal === "createInstallation") {
      modal = <Installation open={true} />;
    }

    return (
      <>
        <FilteredTable
          header="Stations"
          headerIcon="dot circle"
          fetchingData={true}
          filterOptionsFetcher={stationListConfig}
          actions={actions}
          columns={tableColumns}
          detailKey="id"
          detailFetcher={getStation}
          fetcher={getStations}
          pageSize={50}
          icon="dot circle"
          tableActions={this.getTableActions()}
          focus={focusQuery}
          editRow={editStation}
        />
        {modal}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    userId: user.userId,
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(StationTable);
