export const COUNTRY_OPTIONS = [
  { text: "Andorra", key: "AD", value: "Andorra" },
  { text: "United Arab Emirates", key: "AE", value: "United Arab Emirates" },
  { text: "Afghanistan", key: "AF", value: "Afghanistan" },
  { text: "Antigua and Barbuda", key: "AG", value: "Antigua and Barbuda" },
  { text: "Anguilla", key: "AI", value: "Anguilla" },
  { text: "Albania", key: "AL", value: "Albania" },
  { text: "Armenia", key: "AM", value: "Armenia" },
  { text: "Angola", key: "AO", value: "Angola" },
  { text: "Antarctica", key: "AQ", value: "Antarctica" },
  { text: "Argentina", key: "AR", value: "Argentina" },
  { text: "American Samoa", key: "AS", value: "American Samoa" },
  { text: "Austria", key: "AT", value: "Austria" },
  { text: "Australia", key: "AU", value: "Australia" },
  { text: "Aruba", key: "AW", value: "Aruba" },
  { text: "Aland Islands", key: "AX", value: "Aland Islands" },
  { text: "Azerbaijan", key: "AZ", value: "Azerbaijan" },
  { text: "Bosnia and Herzegovina", key: "BA", value: "Bosnia and Herzegovina" },
  { text: "Barbados", key: "BB", value: "Barbados" },
  { text: "Bangladesh", key: "BD", value: "Bangladesh" },
  { text: "Belgium", key: "BE", value: "Belgium" },
  { text: "Burkina Faso", key: "BF", value: "Burkina Faso" },
  { text: "Bulgaria", key: "BG", value: "Bulgaria" },
  { text: "Bahrain", key: "BH", value: "Bahrain" },
  { text: "Burundi", key: "BI", value: "Burundi" },
  { text: "Benin", key: "BJ", value: "Benin" },
  { text: "Saint Barthelemy", key: "BL", value: "Saint Barthelemy" },
  { text: "Bermuda", key: "BM", value: "Bermuda" },
  { text: "Brunei", key: "BN", value: "Brunei" },
  { text: "Bolivia", key: "BO", value: "Bolivia" },
  { text: "Bonaire, Saint Eustatius and Saba ", key: "BQ", value: "Bonaire, Saint Eustatius and Saba " },
  { text: "Brazil", key: "BR", value: "Brazil" },
  { text: "Bahamas", key: "BS", value: "Bahamas" },
  { text: "Bhutan", key: "BT", value: "Bhutan" },
  { text: "Bouvet Island", key: "BV", value: "Bouvet Island" },
  { text: "Botswana", key: "BW", value: "Botswana" },
  { text: "Belarus", key: "BY", value: "Belarus" },
  { text: "Belize", key: "BZ", value: "Belize" },
  { text: "Canada", key: "CA", value: "Canada" },
  { text: "Cocos Islands", key: "CC", value: "Cocos Islands" },
  { text: "Democratic Republic of the Congo", key: "CD", value: "Democratic Republic of the Congo" },
  { text: "Central African Republic", key: "CF", value: "Central African Republic" },
  { text: "Republic of the Congo", key: "CG", value: "Republic of the Congo" },
  { text: "Switzerland", key: "CH", value: "Switzerland" },
  { text: "Ivory Coast", key: "CI", value: "Ivory Coast" },
  { text: "Cook Islands", key: "CK", value: "Cook Islands" },
  { text: "Chile", key: "CL", value: "Chile" },
  { text: "Cameroon", key: "CM", value: "Cameroon" },
  { text: "China", key: "CN", value: "China" },
  { text: "Colombia", key: "CO", value: "Colombia" },
  { text: "Costa Rica", key: "CR", value: "Costa Rica" },
  { text: "Cuba", key: "CU", value: "Cuba" },
  { text: "Cape Verde", key: "CV", value: "Cape Verde" },
  { text: "Curacao", key: "CW", value: "Curacao" },
  { text: "Christmas Island", key: "CX", value: "Christmas Island" },
  { text: "Cyprus", key: "CY", value: "Cyprus" },
  { text: "Czech Republic", key: "CZ", value: "Czech Republic" },
  { text: "Germany", key: "DE", value: "Germany" },
  { text: "Djibouti", key: "DJ", value: "Djibouti" },
  { text: "Denmark", key: "DK", value: "Denmark" },
  { text: "Dominica", key: "DM", value: "Dominica" },
  { text: "Dominican Republic", key: "DO", value: "Dominican Republic" },
  { text: "Algeria", key: "DZ", value: "Algeria" },
  { text: "Ecuador", key: "EC", value: "Ecuador" },
  { text: "Estonia", key: "EE", value: "Estonia" },
  { text: "Egypt", key: "EG", value: "Egypt" },
  { text: "Western Sahara", key: "EH", value: "Western Sahara" },
  { text: "Eritrea", key: "ER", value: "Eritrea" },
  { text: "Spain", key: "ES", value: "Spain" },
  { text: "Ethiopia", key: "ET", value: "Ethiopia" },
  { text: "Finland", key: "FI", value: "Finland" },
  { text: "Fiji", key: "FJ", value: "Fiji" },
  { text: "Falkland Islands", key: "FK", value: "Falkland Islands" },
  { text: "Micronesia", key: "FM", value: "Micronesia" },
  { text: "Faroe Islands", key: "FO", value: "Faroe Islands" },
  { text: "France", key: "FR", value: "France" },
  { text: "Gabon", key: "GA", value: "Gabon" },
  { text: "United Kingdom", key: "GB", value: "United Kingdom" },
  { text: "Grenada", key: "GD", value: "Grenada" },
  { text: "Georgia", key: "GE", value: "Georgia" },
  { text: "French Guiana", key: "GF", value: "French Guiana" },
  { text: "Guernsey", key: "GG", value: "Guernsey" },
  { text: "Ghana", key: "GH", value: "Ghana" },
  { text: "Gibraltar", key: "GI", value: "Gibraltar" },
  { text: "Greenland", key: "GL", value: "Greenland" },
  { text: "Gambia", key: "GM", value: "Gambia" },
  { text: "Guinea", key: "GN", value: "Guinea" },
  { text: "Guadeloupe", key: "GP", value: "Guadeloupe" },
  { text: "Equatorial Guinea", key: "GQ", value: "Equatorial Guinea" },
  { text: "Greece", key: "GR", value: "Greece" },
  { text: "South Georgia and the South Sandwich Islands", key: "GS", value: "South Georgia and the South Sandwich Islands" },
  { text: "Guatemala", key: "GT", value: "Guatemala" },
  { text: "Guam", key: "GU", value: "Guam" },
  { text: "Guinea-Bissau", key: "GW", value: "Guinea-Bissau" },
  { text: "Guyana", key: "GY", value: "Guyana" },
  { text: "Hong Kong", key: "HK", value: "Hong Kong" },
  { text: "Heard Island and McDonald Islands", key: "HM", value: "Heard Island and McDonald Islands" },
  { text: "Honduras", key: "HN", value: "Honduras" },
  { text: "Croatia", key: "HR", value: "Croatia" },
  { text: "Haiti", key: "HT", value: "Haiti" },
  { text: "Hungary", key: "HU", value: "Hungary" },
  { text: "Indonesia", key: "ID", value: "Indonesia" },
  { text: "Ireland", key: "IE", value: "Ireland" },
  { text: "Israel", key: "IL", value: "Israel" },
  { text: "Isle of Man", key: "IM", value: "Isle of Man" },
  { text: "India", key: "IN", value: "India" },
  { text: "British Indian Ocean Territory", key: "IO", value: "British Indian Ocean Territory" },
  { text: "Iraq", key: "IQ", value: "Iraq" },
  { text: "Iran", key: "IR", value: "Iran" },
  { text: "Iceland", key: "IS", value: "Iceland" },
  { text: "Italy", key: "IT", value: "Italy" },
  { text: "Jersey", key: "JE", value: "Jersey" },
  { text: "Jamaica", key: "JM", value: "Jamaica" },
  { text: "Jordan", key: "JO", value: "Jordan" },
  { text: "Japan", key: "JP", value: "Japan" },
  { text: "Kenya", key: "KE", value: "Kenya" },
  { text: "Kyrgyzstan", key: "KG", value: "Kyrgyzstan" },
  { text: "Cambodia", key: "KH", value: "Cambodia" },
  { text: "Kiribati", key: "KI", value: "Kiribati" },
  { text: "Comoros", key: "KM", value: "Comoros" },
  { text: "Saint Kitts and Nevis", key: "KN", value: "Saint Kitts and Nevis" },
  { text: "North Korea", key: "KP", value: "North Korea" },
  { text: "South Korea", key: "KR", value: "South Korea" },
  { text: "Kuwait", key: "KW", value: "Kuwait" },
  { text: "Cayman Islands", key: "KY", value: "Cayman Islands" },
  { text: "Kazakhstan", key: "KZ", value: "Kazakhstan" },
  { text: "Laos", key: "LA", value: "Laos" },
  { text: "Lebanon", key: "LB", value: "Lebanon" },
  { text: "Saint Lucia", key: "LC", value: "Saint Lucia" },
  { text: "Liechtenstein", key: "LI", value: "Liechtenstein" },
  { text: "Sri Lanka", key: "LK", value: "Sri Lanka" },
  { text: "Liberia", key: "LR", value: "Liberia" },
  { text: "Lesotho", key: "LS", value: "Lesotho" },
  { text: "Lithuania", key: "LT", value: "Lithuania" },
  { text: "Luxembourg", key: "LU", value: "Luxembourg" },
  { text: "Latvia", key: "LV", value: "Latvia" },
  { text: "Libya", key: "LY", value: "Libya" },
  { text: "Morocco", key: "MA", value: "Morocco" },
  { text: "Monaco", key: "MC", value: "Monaco" },
  { text: "Moldova", key: "MD", value: "Moldova" },
  { text: "Montenegro", key: "ME", value: "Montenegro" },
  { text: "Saint Martin", key: "MF", value: "Saint Martin" },
  { text: "Madagascar", key: "MG", value: "Madagascar" },
  { text: "Marshall Islands", key: "MH", value: "Marshall Islands" },
  { text: "Macedonia", key: "MK", value: "Macedonia" },
  { text: "Mali", key: "ML", value: "Mali" },
  { text: "Myanmar", key: "MM", value: "Myanmar" },
  { text: "Mongolia", key: "MN", value: "Mongolia" },
  { text: "Macao", key: "MO", value: "Macao" },
  { text: "Northern Mariana Islands", key: "MP", value: "Northern Mariana Islands" },
  { text: "Martinique", key: "MQ", value: "Martinique" },
  { text: "Mauritania", key: "MR", value: "Mauritania" },
  { text: "Montserrat", key: "MS", value: "Montserrat" },
  { text: "Malta", key: "MT", value: "Malta" },
  { text: "Mauritius", key: "MU", value: "Mauritius" },
  { text: "Maldives", key: "MV", value: "Maldives" },
  { text: "Malawi", key: "MW", value: "Malawi" },
  { text: "Mexico", key: "MX", value: "Mexico" },
  { text: "Malaysia", key: "MY", value: "Malaysia" },
  { text: "Mozambique", key: "MZ", value: "Mozambique" },
  { text: "Namibia", key: "NA", value: "Namibia" },
  { text: "New Caledonia", key: "NC", value: "New Caledonia" },
  { text: "Niger", key: "NE", value: "Niger" },
  { text: "Norfolk Island", key: "NF", value: "Norfolk Island" },
  { text: "Nigeria", key: "NG", value: "Nigeria" },
  { text: "Nicaragua", key: "NI", value: "Nicaragua" },
  { text: "Netherlands", key: "NL", value: "Netherlands" },
  { text: "Norway", key: "NO", value: "Norway" },
  { text: "Nepal", key: "NP", value: "Nepal" },
  { text: "Nauru", key: "NR", value: "Nauru" },
  { text: "Niue", key: "NU", value: "Niue" },
  { text: "New Zealand", key: "NZ", value: "New Zealand" },
  { text: "Oman", key: "OM", value: "Oman" },
  { text: "Panama", key: "PA", value: "Panama" },
  { text: "Peru", key: "PE", value: "Peru" },
  { text: "French Polynesia", key: "PF", value: "French Polynesia" },
  { text: "Papua New Guinea", key: "PG", value: "Papua New Guinea" },
  { text: "Philippines", key: "PH", value: "Philippines" },
  { text: "Pakistan", key: "PK", value: "Pakistan" },
  { text: "Poland", key: "PL", value: "Poland" },
  { text: "Saint Pierre and Miquelon", key: "PM", value: "Saint Pierre and Miquelon" },
  { text: "Pitcairn", key: "PN", value: "Pitcairn" },
  { text: "Puerto Rico", key: "PR", value: "Puerto Rico" },
  { text: "Palestinian Territory", key: "PS", value: "Palestinian Territory" },
  { text: "Portugal", key: "PT", value: "Portugal" },
  { text: "Palau", key: "PW", value: "Palau" },
  { text: "Paraguay", key: "PY", value: "Paraguay" },
  { text: "Qatar", key: "QA", value: "Qatar" },
  { text: "Reunion", key: "RE", value: "Reunion" },
  { text: "Romania", key: "RO", value: "Romania" },
  { text: "Serbia", key: "RS", value: "Serbia" },
  { text: "Russia", key: "RU", value: "Russia" },
  { text: "Rwanda", key: "RW", value: "Rwanda" },
  { text: "Saudi Arabia", key: "SA", value: "Saudi Arabia" },
  { text: "Solomon Islands", key: "SB", value: "Solomon Islands" },
  { text: "Seychelles", key: "SC", value: "Seychelles" },
  { text: "Sudan", key: "SD", value: "Sudan" },
  { text: "Sweden", key: "SE", value: "Sweden" },
  { text: "Singapore", key: "SG", value: "Singapore" },
  { text: "Saint Helena", key: "SH", value: "Saint Helena" },
  { text: "Slovenia", key: "SI", value: "Slovenia" },
  { text: "Svalbard and Jan Mayen", key: "SJ", value: "Svalbard and Jan Mayen" },
  { text: "Slovakia", key: "SK", value: "Slovakia" },
  { text: "Sierra Leone", key: "SL", value: "Sierra Leone" },
  { text: "San Marino", key: "SM", value: "San Marino" },
  { text: "Senegal", key: "SN", value: "Senegal" },
  { text: "Somalia", key: "SO", value: "Somalia" },
  { text: "Suriname", key: "SR", value: "Suriname" },
  { text: "South Sudan", key: "SS", value: "South Sudan" },
  { text: "Sao Tome and Principe", key: "ST", value: "Sao Tome and Principe" },
  { text: "El Salvador", key: "SV", value: "El Salvador" },
  { text: "Sint Maarten", key: "SX", value: "Sint Maarten" },
  { text: "Syria", key: "SY", value: "Syria" },
  { text: "Swaziland", key: "SZ", value: "Swaziland" },
  { text: "Turks and Caicos Islands", key: "TC", value: "Turks and Caicos Islands" },
  { text: "Chad", key: "TD", value: "Chad" },
  { text: "French Southern Territories", key: "TF", value: "French Southern Territories" },
  { text: "Togo", key: "TG", value: "Togo" },
  { text: "Thailand", key: "TH", value: "Thailand" },
  { text: "Tajikistan", key: "TJ", value: "Tajikistan" },
  { text: "Tokelau", key: "TK", value: "Tokelau" },
  { text: "East Timor", key: "TL", value: "East Timor" },
  { text: "Turkmenistan", key: "TM", value: "Turkmenistan" },
  { text: "Tunisia", key: "TN", value: "Tunisia" },
  { text: "Tonga", key: "TO", value: "Tonga" },
  { text: "Turkey", key: "TR", value: "Turkey" },
  { text: "Trinidad and Tobago", key: "TT", value: "Trinidad and Tobago" },
  { text: "Tuvalu", key: "TV", value: "Tuvalu" },
  { text: "Taiwan", key: "TW", value: "Taiwan" },
  { text: "Tanzania", key: "TZ", value: "Tanzania" },
  { text: "Ukraine", key: "UA", value: "Ukraine" },
  { text: "Uganda", key: "UG", value: "Uganda" },
  { text: "United States Minor Outlying Islands", key: "UM", value: "United States Minor Outlying Islands" },
  { text: "United States", key: "US", value: "United States" },
  { text: "Uruguay", key: "UY", value: "Uruguay" },
  { text: "Uzbekistan", key: "UZ", value: "Uzbekistan" },
  { text: "Vatican", key: "VA", value: "Vatican" },
  { text: "Saint Vincent and the Grenadines", key: "VC", value: "Saint Vincent and the Grenadines" },
  { text: "Venezuela", key: "VE", value: "Venezuela" },
  { text: "British Virgin Islands", key: "VG", value: "British Virgin Islands" },
  { text: "U.S. Virgin Islands", key: "VI", value: "U.S. Virgin Islands" },
  { text: "Vietnam", key: "VN", value: "Vietnam" },
  { text: "Vanuatu", key: "VU", value: "Vanuatu" },
  { text: "Wallis and Futuna", key: "WF", value: "Wallis and Futuna" },
  { text: "Samoa", key: "WS", value: "Samoa" },
  { text: "Yemen", key: "YE", value: "Yemen" },
  { text: "Mayotte", key: "YT", value: "Mayotte" },
  { text: "South Africa", key: "ZA", value: "South Africa" },
  { text: "Zambia", key: "ZM", value: "Zambia" },
  { text: "Zimbabwe", key: "ZW", value: "Zimbabwe" }
]