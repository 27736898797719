import {createStore, applyMiddleware, combineReducers} from "redux";
import {persistStore, persistReducer} from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import createSagaMiddleware from "redux-saga";

import mySaga from "../sagas/sagas";
import userReducer from "../reducers/userReducer";
import routeReducer from "../reducers/routeReducer";
import pendingRequestCountReducer from "../reducers/pendingRequestCountReducer";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  user: userReducer,
  route: routeReducer,
  pendingRequestCount: pendingRequestCountReducer
});

const persistConfig = {
  key: "root",
  storage: AsyncStorage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(mySaga);

export const persistor = persistStore(store);
