import React from "react";
import {Container, Header} from "semantic-ui-react";

export const Statistic = (props) => {
  let countLabel = props.count;
  const count = parseFloat(props.count);
  if (!isNaN(count)) {
    if (props.millions) countLabel = (count / 1000000).toFixed(1);
    else countLabel = count.toLocaleString();
  }
  return (
    <div>
      <Container textAlign="center">
        <Header
          as="h1"
          className={
            props.dark ? "at-statistics-cell-dark" : "at-statistics-cell-light"
          }
        >
          {countLabel}
        </Header>
      </Container>
      <Container textAlign="center">
        <Header as="h5">{props.label}</Header>
      </Container>
    </div>
  );
};

export default Statistic;
