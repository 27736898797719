import React from "react";
import {connect} from "react-redux";
import {Form, Segment} from "semantic-ui-react";
import PropTypes from "prop-types";

import DateSelector from "./DateSelector";
import FormModal from "./FormModal";
import Location from "./Location";
import ReceiverSummary from "./ReceiverSummary";

class ReceiverRecoveryView extends React.PureComponent {
  originalState = {};

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {

    const {value} = this.props;
    const {onClose} = this;

    const formInputs = (
      <>
        <Segment>
          <ReceiverSummary value={value.receiver} />
        </Segment>
        <Form.Input
          fluid
          readOnly
          transparent
          label="Recovery Status"
          value={value.status}
        />
        <Form.Input
          fluid
          label="Recovered By"
          value={value.recoverer.name}
          readOnly
          transparent
        />
        <DateSelector
          label="Recovery Date and Timezone"
          readOnly
          date={value.recoveryDate}
          timezone={value.recoveryTimezone}
        />
        <Form.Field
          control={Location}
          label="Location"
          readOnly
          value={{longitude: value.longitude, latitude: value.latitude}}
        />
        <Form.Input
          fluid
          readOnly
          transparent
          label="Comments"
          value={value.comments}
        />
      </>
    );

    return (
      <FormModal
        open={true}
        formData={value}
        testId="installation-form"
        successHeader="Success"
        formInputs={formInputs}
        headerIcon="microphone"
        headerContent={"Receiver Recovery"}
        onClose={onClose}
      />
    );
  }
}

ReceiverRecoveryView.propTypes = {
  onClose: PropTypes.func,
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    accessToken: user.accessToken,
    roles: user.roles,
  };
}

export default connect(mapStateToProps)(ReceiverRecoveryView);
