import React from "react";
import {Form} from "semantic-ui-react";
import PropTypes from "prop-types";

class FilterBoundingBox extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(e, {value}) {
    const arr = value.split(",");
    if (arr.length > 4) return;
    const coordsArr = arr.map((quad) => {
      // Allow if quad starts with minus or ends with decimal
      if (quad === "-" || quad.slice(-1) === ".") return quad;
      // Allow if quad parses to a valid float
      const coord = parseFloat(quad);
      if (!isNaN(coord)) {
        return coord.toString();
      }
      // Otherwise display this quad as empty
      return "";
    });
    const coordString = coordsArr.join(",");
    this.props.onChange(e, {name: this.props.name, value: coordString});
  }

  render() {
    return (
      <>
        <Form.Input
          name={this.props.name}
          label={this.props.label}
          spellCheck={false}
          autoComplete="none"
          list={this.props.name}
          onChange={this.handleOnChange}
          value={this.props.value}
        />
      </>
    );
  }
}

FilterBoundingBox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default FilterBoundingBox;
