import {PENDING_REQUEST_COUNTER, USER_LOGOUT} from "../constants/action-types";

const initialState = {pendingRequestCount: 0};

function pendingRequestReducer(state = initialState, action) {
  if (action.type === PENDING_REQUEST_COUNTER) {
    return {...state, ...action.payload};
  }

  if (action.type === USER_LOGOUT) {
    return {...state, ...initialState};
  }

  return state;
}

export default pendingRequestReducer;
