import React from "react";
import {connect} from "react-redux";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  GridColumn,
  Label,
  Segment
} from "semantic-ui-react";
import PropTypes from "prop-types";
import TransmitterSummary from "./TransmitterSummary";
import FormModal from "./FormModal";
import Location from "./Location";
import DateSelector from "./DateSelector";
import config from "react-global-configuration";
import {speciesSearch, tagRecoverUpdate, tagReleaseUpdate} from "../axios/api";
import FilterSearchable from "./FilterSearchable";
import Measurements from "./Measurements";
import {Link} from "react-router-dom";
import {
  fromUTC,
  getEmbargoUntilDate,
  isAdmin,
  noReleaseSensorTypes
} from "./helpers";
import ProjectSelect from "./ProjectSelect";
import AwesomeDebouncePromise from "awesome-debounce-promise";
const debouncedSpeciesSearch = AwesomeDebouncePromise(speciesSearch, 300);

class TransmitterReleaseRecoveryEdit extends React.PureComponent {
  originalState = {};

  constructor(props) {
    super(props);

    const existingMeasurements = props.transmitter.releasePostDto.measurements;
    const measurements = existingMeasurements
      ? Object.keys(existingMeasurements).map((m, i) => {
          return {
            measurementType: existingMeasurements[i].measurementType,
            value: existingMeasurements[i].value,
            unit: existingMeasurements[i].unit,
            comments: existingMeasurements[i].comments
          };
        })
      : [];
    this.state = {
      viewLabel: `Transmitter Details`,
      success: false,
      transmitter: props.transmitter,
      release: {
        ...props.transmitter.releasePostDto,
        id: props.transmitter.releasePostDto.id,
        tagId: props.transmitter.releasePostDto.tagId,
        projectId: props.transmitter.releasePostDto.projectId,
        measurements: measurements,
        description: `${props.transmitter.tagPostDto.speciesScientificName} ${props.transmitter.tagPostDto.speciesName}`,
        comments: props.transmitter.releasePostDto.comments
      },
      recovery: {...props.transmitter.recoveryPostDto}
    };
    this.originalState = {...this.state};
    this.sexes = config.get("animal_sex").map((s) => {
      return {text: s, value: s};
    });
    this.surgeryTypes = config.get("surgery_type").map((s) => {
      return {text: s, value: s};
    });

    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
  }

  onClose() {
    this.setState({...this.originalState});
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    let valid = true;
    return !valid;
  }

  successAction(response) {
    if (this.props.editRelease) {
      this.setState((s) => {
        return {
          release: {
            ...s.release,
            version: response.version
          }
        };
      });
    }
    if (this.props.editRecovery) {
      this.setState((s) => {
        return {
          recovery: {
            ...s.recovery,
            version: response.version
          }
        };
      });
    }
    if (this.props.onUpdate) {
      this.props.onUpdate({
        ...response,
        ...response.location
      });
    }
  }

  handleLocationChange(e, value) {
    this.setState(
      (s) => ({
        deployment: {
          ...s.deployment,
          latitude: parseFloat(value.latitude),
          longitude: parseFloat(value.longitude)
        }
      }),
      this.checkFormValid
    );
  }

  handleRecoveryDateChange(value) {
    this.setState(
      (s) => {
        return {
          recovery: {
            ...s.recovery,
            recoveryDate: value.utc,
            recoveryTimezone: value.timezone
          }
        };
      },
      () => this.checkFormValid()
    );
  }

  handleProjectChange(e, {value}) {
    this.setState((s) => {
      return {
        release: {
          ...s.release,
          projectId: value
        }
      };
    });
  }

  render() {
    const {roles} = this.props;

    let formInputs = null;
    let formData = this.state.recovery;
    let passiveElements = null;
    let endpoint = tagRecoverUpdate;
    let successContent = `Recovery Updated`;
    let submitContent = `Update Recovery`;
    let modalLabel = "Edit Transmitter Recovery";

    const headerRow = (
      <Segment>
        <TransmitterSummary value={this.props.transmitter.tagPostDto} />
        {this.props.openViewForTag ? (
          <Button primary onClick={this.props.openViewForTag}>
            View Transmitter
          </Button>
        ) : null}
      </Segment>
    );

    const hideAnimalRelease = noReleaseSensorTypes.includes(
      this.props.transmitter?.tagPostDto.sensors[0].sensorTypeName
    );

    if (this.props.editRelease) {
      endpoint = tagReleaseUpdate;
      formData = this.state.release;
      successContent = `Release Updated`;
      submitContent = `Update Release`;
      modalLabel = "Edit Transmitter Deployment";

      formInputs = (
        <Segment>
          <Label attached="top left">Release</Label>
          {hideAnimalRelease ? null : (
            <>
              <ProjectSelect
                required
                value={this.state.release.projectId}
                onChange={this.handleProjectChange}
                showAll={isAdmin(roles)}
                loadingText="Loading"
                readOnly={
                  !isAdmin(roles) &&
                  !this.props.projects.find(
                    (p) => this.state.release.projectId === p.id
                  )
                }
              />
              <Form.Field
                fluid
                required
                label="Species Name"
                placeholder="Species search"
                control={FilterSearchable}
                searchApi={debouncedSpeciesSearch}
                name="speciesSearchValue"
                value={{
                  code: this.state.release.speciesId,
                  description: this.state.release.description
                }}
                onChange={(e, {value}) => {
                  this.setState((s) => {
                    return {
                      release: {
                        ...s.release,
                        speciesId: value.code,
                        description: value.description
                      }
                    };
                  });
                }}
              />
              <Form.Dropdown
                fluid
                selection
                required
                options={this.sexes}
                label="Sex"
                onChange={(e, {value}) => {
                  this.setState((s) => {
                    return {
                      release: {
                        ...s.release,
                        sex: value
                      }
                    };
                  });
                }}
                value={this.state.release.sex}
              />
              <Form.Dropdown
                fluid
                selection
                required
                options={this.surgeryTypes}
                label="Placement"
                onChange={(e, {value}) => {
                  this.setState((s) => {
                    return {
                      release: {
                        ...s.release,
                        surgeryType: value
                      }
                    };
                  });
                }}
                value={this.state.release.surgeryType}
              />
              <Form.Field
                control={Measurements}
                label="Measurements"
                required
                fluid
                value={this.state.release.measurements}
                onChange={(valid, value) => {
                  if (valid) {
                    this.setState((s) => {
                      return {
                        measurementsValid: valid,
                        measurements: value,
                        release: {
                          ...s.release,
                          measurements:
                            value[0].measurementType === "N/A" ? [] : value
                        }
                      };
                    });
                  } else {
                    this.setState({measurementsValid: valid});
                  }
                }}
              />
            </>
          )}
          <Form.Input
            fluid
            required
            label="Deployment locality"
            placeholder="Deployment locality"
            onChange={(e, {value}) => {
              this.setState((s) => {
                return {
                  release: {
                    ...s.release,
                    releaseLocality: value
                  }
                };
              });
            }}
            value={this.state.release.releaseLocality}
          />
          <Form.Field
            control={Location}
            label="Deployment Location"
            required
            value={{
              longitude: this.state.release.longitude,
              latitude: this.state.release.latitude
            }}
            onChange={(e, value) => {
              this.setState((s) => {
                return {
                  locationValid: value.valid,
                  release: {
                    ...s.release,
                    longitude: value.longitude,
                    latitude: value.latitude
                  }
                };
              });
            }}
          />
          <DateSelector
            required
            timezone={this.state.release.releaseTimezone}
            date={fromUTC(
              {
                timestamp: this.state.release.releaseDate,
                timezone: this.state.release.releaseTimezone
              },
              "YYYY-MM-DDTHH:mm:ss.SSS"
            )}
            onChange={(value) => {
              this.setState({
                release: {
                  ...this.state.release,
                  releaseDate: value.utc,
                  releaseTimezone: value.timezone
                }
              });
            }}
            label="Deployment Date and Timezone"
          />
          <Form.TextArea
            label="Comments"
            defaultValue={this.state.transmitter.releasePostDto.comments}
            onBlur={(e) => {
              const value = e.target.value;
              this.setState((s) => {
                return {
                  release: {
                    ...s.release,
                    comments: value
                  }
                };
              });
            }}
          />
          {hideAnimalRelease ? null : (
            <Grid columns={3}>
              <GridColumn width={5}>
                <Checkbox
                  label="Embargo this transmitter?"
                  checked={this.state.release.embargoThisTag}
                  onChange={(e, {checked}) => {
                    const value = checked;
                    this.setState((s) => {
                      return {
                        release: {
                          ...s.release,
                          embargoThisTag: value
                        }
                      };
                    });
                  }}
                  toggle
                />
              </GridColumn>
              <GridColumn width={5}>
                <Link target="_blank" to="/datapolicy">
                  Data policy document
                </Link>
              </GridColumn>
              <GridColumn width={6}>
                {this.state.release.embargoThisTag ? (
                  <Label basic>
                    Embargo Until{" "}
                    {getEmbargoUntilDate(
                      this.state.release.releaseDate,
                      this.state.transmitter.tagPostDto.project.protected
                    ).format("YYYY-MM-DD")}
                  </Label>
                ) : null}
              </GridColumn>
            </Grid>
          )}
        </Segment>
      );
      passiveElements = this.state.transmitter.recoveryPostDto?.recoveryDate ? (
        <Segment>
          <Label attached="top left">Recovery</Label>
          <Form.Input
            fluid
            transparent
            readOnly
            label="Project"
            value={this.state.transmitter.tagPostDto.project.name}
          />
          <Form.Input
            fluid
            transparent
            readOnly
            label="Serial Number"
            value={this.state.transmitter.tagPostDto.serialNumber}
          />
          <Form.Field
            control={Location}
            label="Recovery Location"
            readOnly
            value={{
              longitude: this.state.transmitter.recoveryPostDto.longitude,
              latitude: this.state.transmitter.recoveryPostDto.latitude
            }}
          />
          <DateSelector
            readOnly
            date={fromUTC(
              {
                timestamp: this.state.transmitter.recoveryPostDto.recoveryDate,
                timezone: this.state.transmitter.recoveryPostDto
                  .recoveryTimezone
              },
              "YYYY-MM-DDTHH:mm:ss.SSS"
            )}
            timezone={this.state.transmitter.recoveryPostDto.recoveryTimezone}
            label="Recovery Date and Timezone"
          />
        </Segment>
      ) : null;
    }

    if (this.props.editRecovery) {
      formInputs = (
        <Segment>
          <Label attached="top left">Recovery</Label>
          <>
            <Form.Field
              control={Location}
              label="Recovery Location"
              required
              warn={false}
              value={{
                longitude: this.state.recovery.longitude,
                latitude: this.state.recovery.latitude
              }}
              onChange={(e, value) =>
                this.setState((s) => {
                  return {
                    recovery: {
                      ...s.recovery,
                      longitude: value.longitude,
                      latitude: value.latitude
                    }
                  };
                })
              }
            />
            <DateSelector
              required
              label="Recovery Date and Timezone"
              timezone={this.state.recovery.recoveryTimezone}
              date={fromUTC(
                {
                  timestamp: this.state.transmitter.recoveryPostDto
                    .recoveryDate,
                  timezone: this.state.transmitter.recoveryPostDto
                    .recoveryTimezone
                },
                "YYYY-MM-DDTHH:mm:ss.SSS"
              )}
              onChange={(value) => {
                this.setState((s) => {
                  return {
                    recovery: {
                      ...s.recovery,
                      recoveryDate: value.utc,
                      recoveryTimezone: value.timezone
                    }
                  };
                });
              }}
            />
            <Form.Input
              fluid
              transparent
              readOnly
              label="Status"
              value={this.state.recovery.status}
            />
            <Form.TextArea
              label="Comments"
              defaultValue={this.state.recovery.comments}
              onBlur={(e) => {
                const value = e.target.value;
                this.setState((s) => {
                  return {recovery: {...s.recovery, comments: value}};
                });
              }}
            />
          </>
        </Segment>
      );

      passiveElements = (
        <>
          <Segment>
            <Label attached="top left">Release</Label>
            {hideAnimalRelease ? null : (
              <>
                <ProjectSelect
                  required
                  value={this.state.release.projectId}
                  onChange={this.handleProjectChange}
                  showAll={isAdmin(roles)}
                  loadingText="Loading"
                  readOnly
                />
                <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Species"
                  value={this.state.release.description}
                />
                <Form.Input
                  fluid
                  transparent
                  readOnly
                  value={this.state.release.sex}
                />
                <Form.Input
                  fluid
                  transparent
                  readOnly
                  label="Placement"
                  value={this.state.release.surgeryType}
                />
                <Form.Field
                  control={Measurements}
                  label="Measurements"
                  required
                  fluid
                  readOnly
                  value={this.state.release.measurements}
                />
              </>
            )}

            <Form.Input
              fluid
              transparent
              readOnly
              label="Deployment locality"
              value={this.state.release.releaseLocality}
            />
            <Form.Field
              control={Location}
              label="Deployment Location"
              required
              readOnly
              value={{
                longitude: this.state.release.longitude,
                latitude: this.state.release.latitude
              }}
              onChange={(e, {value}) => {
                this.setState((s) => {
                  return {
                    locationValid: value.valid,
                    release: {
                      ...s.release,
                      longitude: value.longitude,
                      latitude: value.latitude
                    }
                  };
                });
              }}
            />
            <DateSelector
              required
              readOnly
              timezone={this.state.release.releaseTimezone}
              date={fromUTC(
                {
                  timestamp: this.state.release.releaseDate,
                  timezone: this.state.release.releaseTimezone
                },
                "YYYY-MM-DDTHH:mm:ss.SSS"
              )}
              onChange={(value) => {
                this.setState({
                  release: {
                    ...this.state.release,
                    releaseDate: value.utc,
                    releaseTimezone: value.timezone
                  }
                });
              }}
              label="Deployment Date and Timezone"
            />
            <Form.TextArea
              label="Comments"
              readOnly
              defaultValue={this.state.transmitter.releasePostDto.comments}
              onBlur={(e) => {
                this.setState({
                  transmitter: {
                    ...this.state.transmitter,
                    releasePostDto: {
                      ...this.state.transmitter.releasePostDto,
                      comments: e.target.value
                    }
                  }
                });
              }}
            />
            {hideAnimalRelease ? null : (
              <Grid columns={3}>
                <GridColumn width={5}>
                  <Checkbox
                    label="Embargo this transmitter?"
                    readOnly
                    checked={
                      this.state.transmitter.releasePostDto.embargoThisTag
                    }
                    toggle
                  />
                </GridColumn>
                <GridColumn width={5}>
                  <Link target="_blank" to="/datapolicy">
                    Data policy document
                  </Link>
                </GridColumn>
                <GridColumn width={6}>
                  {this.state.transmitter.releasePostDto.embargoThisTag ? (
                    <Label basic>
                      Embargo Until{" "}
                      {getEmbargoUntilDate(
                        this.state.transmitter.releasePostDto.releaseDate,
                        this.state.transmitter.tagPostDto.project.protected
                      ).format("YYYY-MM-DD")}
                    </Label>
                  ) : null}
                </GridColumn>
              </Grid>
            )}
          </Segment>
        </>
      );
    }

    return (
      <FormModal
        open
        headerRow={headerRow}
        passiveColumn={this.props.editRecovery ? 1 : 2}
        passiveElements={passiveElements}
        formSubmitAPI={endpoint}
        formData={formData}
        checkFormValid={this.checkFormValid}
        testId="transmitter-release-recovery-edit"
        successHeader="Success"
        onSuccess={this.successAction}
        success={this.state.success}
        formInputs={formInputs}
        headerIcon="microphone"
        successContent={successContent}
        headerContent={modalLabel}
        submitContent={submitContent}
        onClose={this.props.onClose}
      />
    );
  }
}

TransmitterReleaseRecoveryEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  openViewForTag: PropTypes.func,
  onUpdate: PropTypes.func,
  transmitter: PropTypes.any.isRequired,
  editRelease: PropTypes.bool,
  editRecovery: PropTypes.bool
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles,
    projects: user.projects
  };
}

export default connect(mapStateToProps)(TransmitterReleaseRecoveryEdit);
