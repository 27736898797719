import React from "react";
import {Header, List, Segment, Icon} from "semantic-ui-react";
import ActionsMenu, { menuItemsType } from "./ActionsMenu";
import PropTypes from "prop-types";

class DetailPanel extends React.Component {

  plainText(index, value) {

    let list = [];
    for (const [index2, value2] of value.data.entries()) {
      list.push(<List.Item key={index2} content={value2}/>);
    }
    const header = value.data.length > 1 ? `${value.title} (${value.data.length})` : `${value.title}`
    const detail = (
      <List.Item key={index}>
        <List.Icon name={value.icon}/>
        <List.Content>
          <List.Header content={header}/>
          <List.List>{list}</List.List>
        </List.Content>
      </List.Item>
    );

    return detail;

  }

  renderAs(index, value) {

    const detail = (
      <List.Item key={index}>
        <List.Icon name={value.icon}/>
        <List.Content>
          <>{ value.renderAs }</>
        </List.Content>
      </List.Item>
    );
    return detail;

  }

  render() {
    const { data, header, icon, actions } = this.props;

    let details = [];

    if (data) {
      for (const [index, value] of data.entries()) {
        details.push(value.renderAs ? this.renderAs(index, value) : this.plainText(index, value));
      }
    }

    return (
      <>
        <Header content={header} icon={icon} block attached="top" />
        <Segment attached>
          <List divided className="at-detail-font">
            {details}
          </List>
          <ActionsMenu fluid menuItems={actions} />
        </Segment>
      </>
    );
  }
}

DetailPanel.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({ entries: PropTypes.func.isRequired }),
    PropTypes.array
  ]).isRequired,
  header: PropTypes.node.isRequired,
  icon: Icon.propTypes.className.isRequired,
  actions: menuItemsType
};

export default DetailPanel;
