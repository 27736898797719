import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Form, Segment, Message} from "semantic-ui-react";
import config from "react-global-configuration";
import PropTypes from "prop-types";
import ReceiverFind from "./ReceiverFind";
import {
  doesReceiverHaveId,
  isAdmin,
  receiverDeploymentEventDatesAreNotInRange
} from "./helpers";

import {deploymentCreate, station, projectById} from "../axios/api";

import FormModal from "./FormModal";
import DateSelector from "./DateSelector";
import ReceiverSummary from "./ReceiverSummary";
import ProjectSelect from "./ProjectSelect";
import StationSelect from "./StationSelect";
import Location from "./Location";
import {ModalType} from "./ReceiverDeploymentTable";

const receiverOrientations = ["UP", "DOWN"];
const validReceiverDeploymentStatus = ["NEW", "RECOVERED", "LOST"];
const serialNumberLength = 6;

const emptyDeployment = {
  receiverId: "",
  stationId: null,
  latitude: null,
  longitude: null,
  deploymentDate: "",
  deploymentTimezone: "",
  acousticReleaseBrand: "",
  acousticReleaseId: "",
  mooringDescriptor: "",
  bottomDepthMeters: 0,
  depthBelowSurface: 0,
  receiverOrientation: "",
  comments: "",
  receiverError: null,
  projectError: null
};

const emptyStation = {latitude: null, longitude: null};

class ReceiverDeploymentCreate extends React.Component {
  originalState = {};

  constructor(props) {
    super(props);

    this.state = {
      receiverFullId: "",
      loadingReceiver: false,
      loadingStations: false,
      valid: false,
      stations: [],
      projectOrganisationIds: [],
      receiverTypes: config.get("receiver_device_model_names"),
      receiver: null,
      createLabel: "Deploy Receiver",
      viewLabel: "Deployment Details",
      success: false,
      deployment: {...emptyDeployment},
      stationLocation: {...emptyStation},
      stationIsActive: false
    };

    this.originalState = {...this.state};

    this.onClose = this.onClose.bind(this);
    this.successAction = this.successAction.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.handleReceiverIdChange = this.handleReceiverIdChange.bind(this);
    this.handleReceiverIdNext = this.handleReceiverIdNext.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleFoundReceiver = this.handleFoundReceiver.bind(this);
    this.setupDeployment = this.setupDeployment.bind(this);
  }

  componentDidMount() {
    if (this.props.deployReceiverId) {
      this.setState({receiverFullId: this.props.deployReceiverId});
      this.setupDeployment(this.props.deployReceiverId);
    }
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  checkFormValid() {
    const deployment = this.state.deployment;
    const valid =
      deployment.receiverId === this.state.receiver?.id &&
      deployment.stationId &&
      deployment.deploymentDate &&
      !this.state.dateNotValid &&
      deployment.deploymentTimezone &&
      deployment.latitude &&
      deployment.longitude &&
      deployment.bottomDepthMeters > 0 &&
      deployment.depthBelowSurface > 0 &&
      deployment.receiverOrientation;
    return !valid;
  }

  successAction(response) {
    this.setState((s) => ({
      ...this.originalState,
      success: true,
      successContent: `Receiver ${s.receiverFullId} Deployed. Enter Receiver ID to deploy another.`,
      recoverReceiverFullId: s.receiverFullId
    }));
    if (this.props.onUpdate) {
      this.props.onUpdate({
        ...response,
        ...response.location,
        projectName: this.state.projectName,
        stationName: this.state.stationName,
        receiver: {...this.state.receiver}
      });
    }
  }

  handleReceiverIdChange(value) {
    this.setupDeployment(value);
  }

  handleFoundReceiver(receiver) {
    if (receiver.status === "RECOVERED") {
      const hist = receiver.history;
      if (hist.length > 0 && hist[0].recovery) {
        receiver.recoveryDate = hist[0].recovery.recoveryDate;
      } else {
        this.setState({
          loadingReceiver: false,
          valid: false,
          receiverError:
            "Error: Receiver status is Recovered but is missing recovery information"
        });
        return;
      }
    }
    if (validReceiverDeploymentStatus.includes(receiver.status)) {
      this.setState(
        (s) => ({
          loadingReceiver: false,
          receiverFound: {...receiver},
          deployment: {...s.deployment, receiverId: receiver.id}
        }),
        this.checkFormValid
      );
    } else {
      this.setState(
        {
          loadingReceiver: false,
          valid: false,
          receiverError: `Error: Receiver status is ${receiver.status}.`
        },
        this.checkFormValid
      );
    }
  }

  handleReceiverIdNext(e) {
    e.preventDefault();
    this.setState((s) => {
      return {receiver: s.receiverFound};
    });
  }

  setupDeployment(id) {
    const preloaded = this.props.station
      ? {
          projectName: this.props.station.projectName,
          projectId: this.props.station.projectId,
          stationId: this.props.station.id,
          latitude: this.props.station.latitude,
          longitude: this.props.station.longitude
        }
      : {};

    const idx = id.lastIndexOf("-");
    const d =
      0 < idx && idx < id.length - 1
        ? {model: id.slice(0, idx), serial: id.slice(idx + 1)}
        : null;
    if (
      d?.serial.length === serialNumberLength &&
      this.state.receiverTypes
        .map((model) => model.toLowerCase())
        .includes(d.model.toLowerCase())
    ) {
      this.setState({
        receiverFullId: id,
        deployment: {...emptyDeployment, ...preloaded},
        loadingReceiver: true,
        receiverError: null,
        receiver: null
      });
    } else {
      this.setState((state) => ({
        success: false,
        receiverError: null,
        receiverFullId: id,
        deployment: {...state.deployment}
      }));
    }
  }

  handleLocationChange(e, value) {
    this.setState(
      (s) => ({
        deployment: {
          ...s.deployment,
          latitude: parseFloat(value.latitude),
          longitude: parseFloat(value.longitude)
        }
      }),
      this.checkFormValid
    );
  }

  render() {
    const {
      success,
      createLabel,
      deployment,
      receiverFound,
      receiverFullId,
      receiverWorkflow,
      successContent
    } = this.state;
    const {trigger, roles} = this.props;
    const {checkFormValid, successAction, onClose} = this;
    const canContinue = doesReceiverHaveId(receiverFound, receiverFullId);

    const formInputs = (
      <>
        {!this.state.receiver ? (
          <>
            <ReceiverFind
              noValidate={false}
              onChange={this.handleReceiverIdChange}
              onFound={this.handleFoundReceiver}
              value={this.state.receiverFullId}
              error={this.state.receiverError}
              deploy
            />
            <Button
              primary
              onClick={this.handleReceiverIdNext}
              disabled={!canContinue}
            >
              Next
            </Button>
            {this.state.success ? (
              <Button
                primary
                onClick={() => {
                  this.props.modalOpener(
                    ModalType.AddRecovery,
                    this.state.recoverReceiverFullId
                  );
                }}
              >
                {`Recover ${this.state.recoverReceiverFullId}`}
              </Button>
            ) : null}
          </>
        ) : null}
        {this.state.receiverError && receiverWorkflow ? (
          <>
            <p>{receiverWorkflow.content}</p>
            <Link to={receiverWorkflow.buttonRedirect}>
              <Button primary>{receiverWorkflow.buttonLabel}</Button>
            </Link>
          </>
        ) : null}
        {this.state.receiver ? (
          <>
            <Segment>
              <ReceiverSummary value={this.state.receiver} />
            </Segment>
            <Form.Field
              control={DateSelector}
              error={this.state.dateNotValid}
              required
              dontShowFieldsRequired
              timezone={this.state.deployment.deploymentTimezone}
              minDate={
                this.state.receiver.status === "RECOVERED"
                  ? this.state.receiver.recoveryDate
                  : null
              }
              onChange={(v) => {
                this.setState(
                  (s) => ({
                    dateNotValid:
                      v.timezone !== ""
                        ? receiverDeploymentEventDatesAreNotInRange(
                            s.receiver.history,
                            {deploymentDate: v, recoveryDate: null},
                            false
                          ).deploymentDateNotInRange
                        : false,
                    deployment: {
                      ...s.deployment,
                      deploymentDate: v.utc,
                      deploymentTimezone: v.timezone
                    }
                  }),
                  checkFormValid
                );
              }}
              label="Deployment Date and Timezone"
            />
            <ProjectSelect
              required
              value={deployment.projectId}
              error={this.state.projectError}
              showAll={isAdmin(roles)}
              onChange={(e, {value}) => {
                this.setState({
                  projectId: value,
                  projectName: e.target
                    ? e.target.textContent
                    : deployment.projectName,
                  stations: [],
                  loadingStations: true
                });
                station({projectId: value, size: 1000}).then((result) => {
                  const stations = result.data.content;
                  this.setState((s) => ({
                    loadingStations: false,
                    stations: stations.sort(function(a, b) {
                      return a.name > b.name ? 1 : -1;
                    }),
                    projectError:
                      stations?.length > 0 ? null : "Project has no stations",
                    stationLocation: {...emptyStation},
                    deployment: {
                      ...s.deployment
                    }
                  }));
                });

                projectById(value).then((result) => {
                  this.setState({
                    projectOrganisationIds: result.project.organisations.map(
                      (o) => o.id
                    )
                  });
                });
              }}
            />
            {this.state.projectOrganisationIds.length > 0 &&
            !this.state.projectOrganisationIds.includes(
              this.state.receiver.organisation.id
            ) ? (
              <Message color="orange">
                Warning: Project deploying the receiver is not registered under
                the organisation who purchased the receiver. The Admin and the
                Project Leader of the Project who registered the receiver will
                be notified via email of this new deployment.
              </Message>
            ) : null}
            <StationSelect
              required
              loading={this.state.loadingStations}
              disabled={this.state.stations.length === 0}
              stations={this.state.stations}
              options={this.state.stations.map((s) => ({text: s.name, value: s.id, description: s.active ? "active" : "inactive"}))}
              value={this.state.deployment.stationId}
              onChange={(station) => {
                this.setState(
                  (s) => ({
                    stationLocation: station.location,
                    stationName: station.name,
                    stationIsActive: station.active,
                    deployment: {
                      ...s.deployment,
                      stationId: station.id,
                      longitude: station.location.longitude,
                      latitude: station.location.latitude
                    }
                  }),
                  checkFormValid
                );
              }}
            />
            {this.state.stationIsActive && (
                <Message color="orange">
                  A concurrent entry already exists for the selected station. Please check that the details you have entered are correct.
                </Message>
            )}
            <Form.Field
              control={Location}
              label="Location"
              required
              warn={false}
              station={{
                longitude: this.state.stationLocation.longitude,
                latitude: this.state.stationLocation.latitude,
                radius: 500
              }}
              radiusWarning="Coordinates are outside a 500m radius from the station's created location"
              warning="The entered GPS coordinates exist under a different station name. Change it now if this is not correct."
              value={{
                longitude: deployment.longitude,
                latitude: deployment.latitude
              }}
              onChange={this.handleLocationChange}
            />
            <Form.Input
              autoComplete="none"
              placeholder="e.g. VEMCO"
              label="Acoustic Release Brand"
              maxLength={255}
              defaultValue={this.state.deployment.acousticReleaseBrand}
              onBlur={(e) => {
                const value = e.target.value;
                this.setState((s) => ({
                  deployment: {
                    ...s.deployment,
                    acousticReleaseBrand: value
                  }
                }));
              }}
            />
            <Form.Input
              label="Acoustic Release ID"
              maxLength={255}
              defaultValue={this.state.deployment.acousticReleaseId}
              autoComplete="none"
              onBlur={(e) => {
                const value = e.target.value;
                this.setState((s) => ({
                  deployment: {
                    ...s.deployment,
                    acousticReleaseId: value
                  }
                }));
              }}
            />
            <Form.Input
              label="Mooring Descriptor"
              maxLength={255}
              autoComplete="none"
              placeholder="eg. Star Picket, Float Mooring, Fish Aggregating Device, Permanent mooring (e.g. navigation aid), Other (please specify)"
              defaultValue={this.state.deployment.mooringDescriptor}
              onBlur={(e) => {
                this.setState({
                  deployment: {
                    ...deployment,
                    mooringDescriptor: e.target.value
                  }
                });
              }}
            />
            <Form.Input
              required
              label="Bottom (Sea Floor) Depth (m)"
              type="number"
              min={0}
              step={0.001}
              autoComplete="none"
              defaultValue={this.state.deployment.bottomDepthMeters}
              error={this.state.bottomDepthMetersError}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                this.setState(
                  {
                    bottomDepthMetersError:
                      value <= 0
                        ? "Depth below surface must be greater than zero"
                        : null,
                    deployment: {
                      ...deployment,
                      bottomDepthMeters: e.target.value
                    }
                  },
                  checkFormValid
                );
              }}
            />
            <Form.Input
              required
              type="number"
              min={0}
              step={0.001}
              autoComplete="none"
              label="Receiver Depth Below Surface (m)"
              value={this.state.deployment.depthBelowSurface}
              error={this.state.depthBelowSurfaceError}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                this.setState(
                  {
                    depthBelowSurfaceError:
                      value <= 0
                        ? "Depth below surface must be greater than zero"
                        : null,
                    deployment: {
                      ...deployment,
                      depthBelowSurface: e.target.value
                    }
                  },
                  checkFormValid
                );
              }}
            />
            <Form.Dropdown
              required
              fluid
              selection
              search
              label="Receiver Orientation"
              options={receiverOrientations.map((v) => ({text: v, value: v}))}
              value={this.state.deployment.receiverOrientation}
              onChange={(e, {value}) => {
                this.setState(
                  {deployment: {...deployment, receiverOrientation: value}},
                  checkFormValid
                );
              }}
            />
            <Form.TextArea
              label="Comments"
              defaultValue={this.state.deployment.comments}
              onBlur={(e) => {
                const value = e.target.value;
                this.setState((s) => ({
                  deployment: {...s.deployment, comments: value}
                }));
              }}
            />
          </>
        ) : null}
      </>
    );

    return (
      <FormModal
        open
        formSubmitAPI={deploymentCreate}
        formData={deployment}
        checkFormValid={checkFormValid}
        testId="installation-form"
        successHeader="Success"
        onSuccess={successAction}
        success={success}
        formInputs={formInputs}
        headerIcon="microphone"
        trigger={trigger}
        successContent={successContent}
        headerContent={createLabel}
        submitContent={`Deploy ${this.state.receiverFullId ?? ""}`}
        onClose={onClose}
      />
    );
  }
}

ReceiverDeploymentCreate.propTypes = {
  deployReceiverId: PropTypes.string,
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  const {user} = state;
  return {
    roles: user.roles
  };
}

export default connect(mapStateToProps)(ReceiverDeploymentCreate);
